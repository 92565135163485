<template>
  <div class="goods-list" v-loading="loading">
      <!-- 搜索关键字 -->
      <div class="search_bread">
          <router-link to="/">首页</router-link>
          <p class="padd-10">/</p>
          <p class="keyword">商品列表</p>
      </div>
      <div v-if="first_child_list" class="search_bread">
          <p>分类：</p>
          <ul>
              <li :class="{ active: first_index_active == 0 }" @click="setSiindex">
                  <a>全部</a>
                  <!--                    <router-link :to="{ path: '/list', query: { category_id: two_index, level: 2 } }"></router-link>-->
              </li>
              <li :class="{ active: item.category_id == first_index_active }" @click="setFiindex(item)" v-for="item in first_child_list">
                  <!--                    <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">-->
                  <!--                        {{ item.category_name }}-->
                  <!--                    </router-link>-->
                  <a> {{ item.category_name }}</a>
              </li>
          </ul>
      </div>
<!--      生产厂家-->
      <div v-if="factory" class="search_bread" style="display: flex;flex-direction: column">
          <span style="width: 300px;">厂家：
              <el-input size="small" placeholder="搜索厂家名称" v-model="factorySearch">
                  <i slot="suffix" style="cursor: pointer" @click="factorySearchBtn(factorySearch)" class="el-input__icon el-icon-search"></i>
              </el-input>
          </span>
          <div class="factory_context">
              <span></span>
              <ul :style="{height: factoryBtn?'30px':''}">
                  <li :class="{ active: first_index_active == 0 }" @click="setSiindex">
                      <a>不限</a>
                  </li>

                  <li :class="{ active: item.category_id == first_index_active }" @click="setFactory(item)" v-for="item in factory">
                      <a> {{ item}}</a>
                  </li>
              </ul>
              <span style="cursor: pointer" @click="factoryBtn=!factoryBtn">{{ factoryBtn?'展开':'收起' }}</span>
          </div>
      </div>
<!--      规格-->
      <div v-if="spec" class="search_bread">
          <div class="factory_context">
              <span style="line-height: 30px">规格：</span>
              <ul :style="{height: specBtn?'30px':''}">
                  <li :class="{ active: first_index_active == 0 }" @click="setSiindex">
                      <a>不限</a>
                  </li>
                  <li :class="{ active: item.category_id == first_index_active }" @click="setSpec(item)" v-for="item in spec">
                      <a> {{ item }}</a>
                  </li>
              </ul>
              <span @click="specBtn=!specBtn">{{ specBtn?'展开':'收起' }}</span>
          </div>
      </div>
<!--      剂型-->
      <div v-if="dosage_form" class="search_bread">
          <div class="factory_context">
              <span style="line-height: 30px">剂型：</span>
              <ul>
                  <li :class="{ active: first_index_active == 0 }" @click="setSiindex">
                      <a>不限</a>
                  </li>

                  <li :class="{ active: item.category_id == first_index_active }" @click="setDosageForm(item)" v-for="item in dosage_form">
                      <a> {{ item }}</a>
                  </li>
              </ul>
          </div>
      </div>
    <!-- 品牌过滤记录区 -->
    <div class="attr_filter" v-if="catewords">
      <el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
        <span v-if="choosedBrand" class="title">品牌：</span>
        {{ choosedBrand.brand_name }}
      </el-tag>
      <span v-for="item in attributeList" :key="item.attr_id">
        <el-tag
          type="info"
          v-if="item.selectedValue"
          closable
          @close="colseAttr(item)"
          effect="plain"
        >
          <span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
          {{ item.selectedValue }}
        </el-tag>
      </span>
    </div>

    <!-- 品牌属性筛选区 -->
    <template>
      <div class="category" v-if="brandList.length || attributeList.length">
        <!-- 品牌 -->
        <div class="brand">
          <div class="table_head">品牌：</div>
          <div class="table_body">
            <div class="initial">
              <span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">所有品牌</span>
              <span
                type="info"
                effect="plain"
                hit
                v-for="item in brandInitialList"
                :key="item"
                @mouseover="handleChangeInitial(item)"
              >{{ (item || '').toUpperCase() }}</span>
            </div>
            <div class="content">
              <el-card
                v-for="item in brandList"
                :key="item.id"
                body-style="padding: 0;height: 100%;"
                shadow="hover"
                v-show="currentInitial === '' || item.brand_initial === currentInitial"
                class="brand-item"
              >
                <el-image
                  :src="$img(item.image_url || defaultGoodsImage)"
                  :alt="item.brand_name"
                  :title="item.brand_name"
                  fit="contain"
                  @click="onChooseBrand(item)"
                />
              </el-card>
            </div>
          </div>
        </div>

        <!-- 属性 -->
        <div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
          <div class="table_head">{{ item.attr_name }}：</div>
          <div class="table_body">
            <div class="content">
              <span v-for="subitem in item.child" :key="subitem.attr_value_id">
                <el-checkbox
                  :label="subitem.attr_value_name"
                  v-if="item.isMuiltSelect"
                  :checked="subitem.selected"
                  @change="setAttrSelectedMuilt(item, subitem)"
                ></el-checkbox>
                <el-link
                  :underline="false"
                  v-else
                  @click="setAttrSelected(item, subitem)"
                >{{ subitem.attr_value_name }}</el-link>
              </span>
            </div>
          </div>
          <div class="table_op">
            <el-button
              size="small"
              icon="el-icon-circle-plus-outline"
              @click="setMuiltChoose(item)"
            >多选</el-button>
          </div>
        </div>
      </div>
    </template>

    <div class="list-wrap">
      <div class="goods-recommended" v-if="cargoList.length">
        <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
      </div>
      <div class="list-right">
        <!-- 排序筛选区 -->
        <div>
          <div class="sort">
            <div class="item" @click="changeSort('')">
              <div class="item-name">综合</div>
            </div>
            <div class="item" @click="changeSort('sale_num')">
              <div class="item-name">销量</div>
              <i
                v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                class="el-icon-arrow-down el-icon--down"
              />
              <i v-else class="el-icon-arrow-up el-icon--up" />
            </div>
            <div class="item" @click="changeSort('discount_price')">
              <div class="item-name">价格</div>
              <i
                v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                class="el-icon-arrow-down el-icon--down"
              />
              <i v-else class="el-icon-arrow-up el-icon--up" />
            </div>
            <div class="item-other">
              <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
            </div>
            <div class="item-other">
              <el-checkbox label="自营" v-model="is_own"></el-checkbox>
            </div>
            <div class="input-wrap">
              <div class="price_range">
                <el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
                <span>—</span>
                <el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
              </div>
              <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
            </div>
          </div>
        </div>
        <div class="cargo-list" v-if="cargoList.length">
          <div class="goods-info">
            <div
              class="item"
              v-for="(item, index) in cargoList"
              :key="item.goods_id"
              @click="$router.pushToTab({ path:'/sku?id=' + item.sku_id })"
            >
              <img
                class="img-wrap"
                :src="$img(item.sku_image, { size: 'mid' })"
              />
              <div class="property-details" v-if="item.extension_data">
                  <el-tooltip v-if="item.extension_data.country_code" class="country" effect="dark" :content="item.extension_data.country_code" placement="top">
                      <el-button>国家码</el-button>
                  </el-tooltip>
                  <p v-if="item.extension_data.business_scope">{{item.extension_data.business_scope}}</p>
              </div>
              <div class="price-wrap">
                <div class="price">
                  <p>￥</p>
                  {{ item.discount_price }}
                </div>
                <div class="market-price">￥{{ item.market_price }}</div>
              </div>
              <div class="goods-name">{{ item.goods_name }}</div>
<!--              <div class="sale-num">-->
<!--                <p>{{ item.sale_num || 0 }}</p>人付款-->
<!--              </div>-->
              <div v-if="item.extension_data">
                  <div class="shop_name">{{ item.extension_data.factory }}</div>
                  <div class="shop_name">规格：{{ item.extension_data.spec }}</div>
                  <div class="shop_name">剂型：{{ item.extension_data.dosage_form }}</div>
                  <div class="shop_name"><p>件装量：{{ item.extension_data.unit_package }}</p><P>中包装：{{ item.extension_data.min_buy }}</P></div>
                  <div v-if="item.shop_info" class="shop_name">
                      <p>效期：{{ item.extension_data.expiry_date }}</p>
                      <P>库存：{{item.stock>=item.shop_info.max_stock?item.shop_info.max_stock_label:item.stock}}</P>
                  </div>
              </div>
              <div class="saling">
                <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
                <div class="is_own" v-if="item.is_own == 1">自营</div>
                <div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
              </div>
              <div class="cart-layout">
                  <div class="cart-num">
                    <span class="el-icon-minus" @click.stop="item.num>1?item.num--:item.num"></span>
                    <div class="number"><el-input v-model="item.num" size="small" placeholder="0"></el-input></div>
                    <span class="el-icon-plus" @click.stop="item.num++"></span>
                  </div>
                  <div class="cart-add" @click.stop="joinCart(item)">
                      加入购物车
                  </div>
              </div>
              <!-- <div class="item-bottom">
                  <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                    <i class="iconfont iconlike"></i>
                    收藏
                  </div>
				  <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                    <i class="iconfont iconlikefill ns-text-color"></i>
                    取消收藏
                  </div>
                  <div class="cart" @click.stop="addToCart(item)">
                    <i class="el-icon-shopping-cart-2"></i>
                    加入购物车
                  </div>
              </div>-->
            </div>
          </div>
          <div class="pager">
            <el-pagination
				background
				:pager-count="5"
				:total="total"
				prev-text="上一页"
				next-text="下一页"
				:current-page.sync="currentPage"
				:page-size.sync="pageSize"
				@size-change="handlePageSizeChange"
				@current-change="handleCurrentPageChange"
				hide-on-single-page
			></el-pagination>
          </div>
        </div>
        <div class="empty" v-else>
          <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import GoodsRecommend from "@/components/GoodsRecommend";
import list from "./list";
import { mapGetters } from "vuex"
import { tree } from "@/api/goods/goodscategory"

export default {
    name: "list",
    components: {
        BreadCrumbs,
        GoodsRecommend
    },
    data: () => {
        return {
            num:1,
            first_child_list:[],
            first_index_active:0,
            factoryBtn:true,
            specBtn:true,
        };
    },
    mixins: [list],
    created() {
    },
    computed: {
        ...mapGetters(["cartCount", "siteInfo", "member"]),
        logined: function () {
            return this.member !== undefined && this.member !== "" && this.member !== {}
        }
    },
    methods: {
        showPrice(data) {
            let price = data.discount_price
            if (data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price
            return price
        },

    }
};
</script>

<style lang="scss" scoped>
.goods-list {
  background: #fff;
  padding-top: 10px;
}
.search_bread {
  display: flex;
  font-size: 14px;
  margin: 0px auto;
  width: 100%;
  line-height: 30px;
  span{
      display: block;
      width: 80px;
      white-space: nowrap;
      height: 30px;
      line-height: 30px;
  }
  .factory_context{
      width: 1040px;
      display: flex;
      ul{
          display: block;
          width: 100%;
          overflow: hidden;
          li{
              display: block;
              height: 30px;
              line-height: 30px;
              white-space: nowrap;
              float: left;
          }
          span{
              width: 80px;
          }
      }
  }
  ul{
      display: flex;
      li{
          margin: 0 10px;
          cursor: pointer;
          a:hover{
              color: $base-color;
          }
      }
  }
}

.selected_border {
  border: 1px solid $base-color;
}

.attr_filter {
  .el-tag {
    margin-right: 5px;
    margin-bottom: 10px;
    border-radius: 0;
    .title {
      color: $base-color;
      font-size: 15px;
    }
  }
}

.category {
  margin: 0 auto 10px auto;
  border: 1px solid #eeeeee;

  .brand {
    border-bottom: 1px solid #eeeeee;
    display: flex;
    flex-direction: row;

    &:last-child {
      border-bottom: none;
    }

    .table_head {
      width: 140px;
      min-width: 140px;
      border-right: 1px solid #eeeeee;
      padding-left: 10px;
      padding-top: 10px;
      background-color: #f2f6fc;
    }
    .table_body {
      flex-grow: 1;
      .initial {
        margin: 5px auto 10px 10px;

        span {
          border: 0;
          margin: 0;
          padding: 5px 10px;
          border: 0;
          border-radius: 0;

          &:hover {
            background-color: $base-color;
            color: #ffffff;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10px;
        width: 900px;
        .brand-item{
          margin-right: 10px;
          margin-top: 10px;
        }

        .el-card {
          width: 125px;
          height: 45px;

          &:hover {
            border: 1px solid $base-color;
            cursor: pointer;
          }
        }

        span {
          margin: auto 25px;
        }
      }
    }

    .table_op {
      margin-top: 5px;
      margin-right: 5px;
    }

    .el-image {
      width: 100%;
      height: 100%;
    }
  }
}

.list-wrap {
  overflow: hidden;
}
.goods-recommended {
  width: 200px;
  background-color: #fff;
  float: left;
  margin-right: 10px;
}

.sort {
  display: flex;
  align-items: center;

  .item {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #f1f1f1;
    border-left: none;
    cursor: pointer;
    &:hover {
      background: $base-color;
      color: #fff;
    }
  }
  .item-other {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    border: 1px solid #f1f1f1;
    border-left: none;
    cursor: pointer;
  }
  .input-wrap {
    display: flex;
    align-items: center;
    .price_range {
      margin-left: 60px;
    }
    span {
      padding-left: 10px;
    }
    .el-input {
      width: 150px;
      margin-left: 10px;
    }
    .el-button {
      margin: 0 17px;
    }
  }

  >div:first-child {
    border-left: 1px solid #f1f1f1;
  }
}
.goods-info {
  margin-top: 5px;
  display: flex;
  flex-wrap: wrap;
  .item {
    width: 220px;
    margin: 10px 20px 0 0;
    border: 1px solid #eeeeee;
    padding-top: 10px;
    position: relative;
    padding-bottom: 5px;
    &:nth-child(4n) {
      margin-right: initial !important;
    }
    &:hover {
      border: 1px solid $base-color;
    }
    .img-wrap {
      width: 198px;
      height: 198px;
      cursor: pointer;
      padding: 10px;
    }
    .goods-name {
      padding: 0 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      word-break: break-all;
      height: 30px;
      cursor: pointer;
      &:hover {
        color: $base-color;
      }
    }
    .property-details{
        padding: 0 10px;
        display: flex;
        .country{
            width: 60px;
            margin-right: 4px;
            border: 1px #0cb95f solid;
            line-height: 20px;
            padding: 0px 2px;
            box-sizing: border-box;
            color: #0cb95f;
        }
        p{
            margin-right: 4px;
            border-radius: 4px;
            border: 1px #0cb95f solid;
            line-height: 20px;
            padding: 0px 2px;
            box-sizing: border-box;
            color: #0cb95f;
        }
    }
    .price-wrap {
      padding: 0 10px;
      display: flex;
      align-items: center;
      .price {
        display: flex;
        color: $base-color;
        font-size: $ns-font-size-lg;
        p {
          font-size: $ns-font-size-base;
          display: flex;
          align-items: flex-end;
        }
      }
      .market-price {
        color: #838383;
        text-decoration: line-through;
        margin-left: 10px;
      }
    }
    .sale-num {
      padding: 0 10px;
      display: flex;
      color: #838383;
       font-size: $ns-font-size-sm;
      p {
        color: #4759a8;
      }
    }
    .shop_name {
      font-size: 12px;
      margin: 0 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      color: #838383;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .saling {
      padding: 0 10px;
      display: flex;
      font-size: $ns-font-size-sm;
      line-height: 1;
      .free-shipping {
        background: $base-color;
        color: #ffffff;
        padding: 3px 4px;
        border-radius: 2px;
        margin-right: 5px;
      }
      .is_own {
        color: #ffffff;
        background: #ff850f;
        border: 1px solid;
        margin-right: 5px;
        display: flex;
        align-items: center;
        padding: 3px 4px;
        border-radius: 2px;
      }
      .promotion-type {
        color: $base-color;
        border: 1px solid $base-color;
        display: flex;
        align-items: center;
        padding: 1px 3px;
      }
    }
    .cart-layout{
        margin: 10px;
        display: flex;
        align-items: center;
      .cart-num{
          margin-right: 10px;
          position: relative;
          width: 30%;
          border-radius: 4px;
          display: flex;
          border: 1px solid #ccc;
          justify-content: center;
          align-items: center;
          span{
              z-index: 1;
              position: absolute;
              cursor: pointer;
              width: 20px;
              text-align: center;
          }
          span:first-child{
              left: 0;
          }
          span:last-child{
              right: 0;
          }
          .number{
              .el-input{
                  ::v-deep(.el-input__inner){
                      border: 0;
                      padding: 0px 5px;
                      text-align: center;
                  }
              }
          }
      }
      .cart-add{
          cursor: pointer;
          border: 1px solid $base-color;
          color: $base-color;
          border-radius: 4px;
          padding: 2px 10px;
      }
    }
    .item-bottom {
      display: flex;
      margin-top: 5px;
      .collection {
        flex: 1;
        border: 1px solid #e9e9e9;
        border-right: none;
        border-left: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
      .cart {
        flex: 2;
        border: 1px solid #e9e9e9;
        border-right: none;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
}
.empty {
  margin-top: 45px;
}
.pager {
  text-align: center;
  margin-top: 30px;
}
</style>
