<template>
    <div v-loading="loading">
        <!-- 轮播图 -->
        <div class="swiper">
            <el-carousel height="400px">
                <el-carousel-item v-for="item in swiperList" :key="item.id">
                    <el-image @click="swiperInfo(item)" fit="cover" :src="$img(item.adv_image)" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 导航栏 -->
        <div class="nav">
            <!-- 左侧导航栏 -->
            <div class="left-nav">
                <el-button size="mini" :class="{ actives: success == idnexs}" v-for="(items,idnexs) in recommends" :key="idnexs" @click="tonav(idnexs,items.tag_id)">{{items.tag_name}}</el-button>
            </div>
            <!-- 右侧搜索框 -->
            <div class="right-input">
                <el-input v-model="input" @change="inputs" placeholder="请输入搜索内容/商品名称" suffix-icon="el-icon-search"></el-input>
            </div>
        </div>
        <div class="goods-recommended" v-if="cargoList.length">
                <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
        </div>
        <!-- 内容主体 -->
        <div class="contant">
            <div v-if="cargoList.length">
                  <div style="display: flex; flex-wrap: wrap;">
                     <goods-info-item
                        v-for="(item) in cargoList"
                        :key="item.goods_id"
                        :goods-name="item.goods_name"
                        :goods="item"
                        :factory="item.extension_data.factory"
                        :spec="item.extension_data.spec"
                        :dosage_form="item.extension_data.dosage_form"
                        :unit_package="item.extension_data.unit_package"
                        :min_buy="item.extension_data.min_buy"
                        :expiry_date="item.extension_data.expiry_date"
                        :stock="item.stock"
                        :tag_list="item.tag_list"
                        :control="item.control"
                        :img_url="item.sku_image"
                        :left="25"
                        @click.native="$router.pushToTab({ path: '/sku?id=' + item.sku_id })"
                    >
                        <!-- <div class="item-bottom">
                                <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                                <i class="iconfont iconlike"></i>
                                收藏
                                </div>
                                <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                                <i class="iconfont iconlikefill ns-text-color"></i>
                                取消收藏
                                </div>
                                <div class="cart" @click.stop="addToCart(item)">
                                <i class="el-icon-shopping-cart-2"></i>
                                加入购物车
                                </div>
                            </div>-->
                    </goods-info-item>
                  </div>
                   <div style="display: flex;">
                    <el-pagination style="padding-right: 30px; margin-top: 20px" background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"></el-pagination>
                   </div>
                </div>

                <div class="empty" v-else>
                    <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
                </div>
        </div>
        <!-- 分页 -->
        <div class="pages">
            <!-- <el-pagination background layout="prev, pager, next" :total="1000"> </el-pagination> -->
        </div>
    </div>
</template>

<script>
import prefectures from "./prefectures"
import GoodsRecommend from "@/components/GoodsRecommend"
import {  goodsInfoItem } from "@/components/goods-info"
export default {
    components: {
        goodsInfoItem,
        GoodsRecommend
    },
    mixins:[prefectures],
    data() {
        return {
        }
    },
  
}
</script>

<style lang="scss" scoped>
// 轮播图
.swiper {
    height: 400px;
    // background-color: aquamarine;
    .el-carousel {
        width: 100%;
        .el-image {
            width: 100%;
            height: 100%;
        }
    }
}
// 导航栏
.nav {
    height: 70px;
    display: flex;
    justify-content: space-between;
    // 左侧导航栏
    .left-nav {
        width: 40%;
        height: 100%;
        // background-color: cadetblue;
        box-sizing: border-box;
        padding-left: 20px;
        display: flex;
        align-items: center;
        .actives {
            background-color: rgb(51, 191, 107);
            color: #fff;
            border: 1px solid rgb(51, 191, 107);
        }
    }
    // 右侧搜索框
    .right-input {
        width: 25%;
        height: 100%;
        // background-color: rgb(160, 160, 95);
        display: flex;
        align-items: center;
    }
}

// 内容主体区
.contant {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    // background-color: chocolate;

    .outer-box {
        width: 19%;
        height: 370px;
        margin: 5px;
        border: 1px solid #999999;
        .top-img {
            width: 100%;
            height: 35%;
            box-sizing: border-box;
            padding: 5px;
            cursor: pointer;
            // background-color: blueviolet;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .bottom-contant {
            height: 65%;
            width: 100%;
            background-color: #fff;
            box-sizing: border-box;
            padding: 5px;
            .price {
                display: flex;
                line-height: 1.2;
                .special-price {
                    color: red;
                    font-weight: 700;
                }
                .original-price {
                    color: #999999;
                    text-decoration: line-through;
                    font-size: 12px;
                }
            }
            .title-txt {
                width: 80%;
                line-height: 1.2;
                // background-color: aquamarine;
                font-weight: 700;
                white-space: nowrap; /* 防止文本换行 */
                overflow: hidden; /* 裁剪溢出文本 */
                text-overflow: ellipsis; /* 显示省略号 */
            }
            .discounts-price {
                display: flex;
                line-height: 1.2;
                .left-discounts {
                    width: 27px;
                    height: 18px;
                    text-align: center;
                    line-height: 20px;
                    background-color: rgb(236, 133, 59);
                    color: #fff;
                    font-size: 10px;
                    border-radius: 3px;
                }
                .right-discounts {
                    color: #53b493;
                    border: 1px solid #53b493;
                    border-radius: 3px;
                    font-size: 10px;
                    width: 38px;
                    height: 16px;
                    text-align: center;
                    line-height: 18px;
                }
            }
            // 药品信息
            .drug-info {
                margin-top: 10px;
                font-size: 12px;
                line-height: 1.5;
                color: #999999;
                .company-name {
                    white-space: nowrap; /* 防止文本换行 */
                    overflow: hidden; /* 裁剪溢出文本 */
                    text-overflow: ellipsis; /* 显示省略号 */
                }

                .info-flex {
                    display: flex;
                    flex-wrap: wrap;
                    .period {
                        width: 75%;
                        color: red;
                    }
                    .info-nums {
                        width: 75%;
                    }
                }
                .info-times {
                    color: rgb(241 159 97);
                }
            }
            // 数量计数器
            .num-count {
                width: 100%;
                height: 60px;
                // background-color: blanchedalmond;
                display: flex;
                align-items: end;
                justify-content: space-around;
                .num-btn {
                    width: 41%;
                    height: 100%;
                    // background-color: aquamarine;
                    display: flex;
                    align-items: end;
                }
                .add-cart {
                    width: 40%;
                    // background-color: aqua;
                }
            }
        }
    }
}
.goods-recommended {
    margin-top: 10px;
    width: 200px;
    background-color: #fff;
    float: left;
    margin-right: 10px;
}
// 分页
.pages {
    width: 100%;
    height: 50px;
    // background-color: blueviolet;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
}
</style>