<template>
    <div class="header">
        <ns-header-top />
        <ns-header-mid />

        <div class="nav">
            <div class="shop-sort" @mouseover="shopHover" @mouseleave="shopLeave">
                <router-link to="/goodsList">所有商品分类</router-link>
                <i class="iconfont iconweibiaoti35"></i>
            </div>
            <div
                class="shop-list-content"
                :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
            >
                <div
                    class="shop-list"
                    v-if="categoryConfig.category == 1"
                    @mouseover="shopHover"
                    @mouseleave="shopLeave"
                >
                    <div
                        class="list-item"
                        v-for="(item, index) in goodsCategoryTree"
                        :key="index"
                        @mouseover="selectedCategory = item.category_id"
                        @mouseleave="selectedCategory = -1"
                    >
                        <router-link
                            :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
                            target="_blank"
                        >
                            <div>
                                <img
                                    v-if="item.image"
                                    alt="图片错误"
                                    class="category-img"
                                    :src="$util.img(item.image)"
                                />
                                <p class="category-name">{{ item.category_name }}</p>
                            </div>
                            <i class="el-icon-arrow-right" aria-hidden="true"></i>
                        </router-link>
                    </div>
                </div>

                <!-- 分类类型2 -->
                <div
                    class="shop-list"
                    :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
                    v-else-if="categoryConfig.category == 2"
                    @mouseover="shopHover"
                    @mouseleave="shopLeave"
                >
                    <div
                        class="list-item"
                        v-for="(item, index) in goodsCategoryTree"
                        :key="index"
                        @mouseover="selectedCategory = item.category_id"
                        @mouseleave="selectedCategory = -1"
                    >
                        <router-link
                            :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
                            target="_blank"
                        >
                            <div>
                                <img
                                    class="category-img"
                                    v-if="item.image"
                                    :src="$util.img(item.image)"
                                />
                                <p class="category-name">{{ item.category_name }}</p>
                            </div>
                            <i class="el-icon-arrow-right" aria-hidden="true"></i>
                        </router-link>
                        <div
                            class="cate-part"
                            v-if="item.child_list"
                            :class="{ show: selectedCategory == item.category_id }"
                        >
                            <div class="cate-part-col1">
                                <div class="cate-detail">
                                    <div class="cate-detail-item">
                                        <div class="cate-detail-con">
                                            <router-link
                                                v-for="(second_item, second_index) in item.child_list"
                                                :key="second_index"
                                                target="_blank"
                                                :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }"
                                            >
                                                <img
                                                    class="cate-detail-img"
                                                    v-if="categoryConfig.img == 1"
                                                    :src="$util.img(second_item.image)"
                                                />
                                                <p
                                                    class="category-name"
                                                >{{ second_item.category_name }}</p>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- 分类类型3 -->
                <div
                    class="shop-list"
                    :class="forceExpand || isShopHover || isIndex ? 'shop-list-active' : 'shadow'"
                    v-else-if="categoryConfig.category == 3"
                    @mouseover="shopHover"
                    @mouseleave="shopLeave"
                >
                    <div
                        class="list-item"
                        v-for="(item, index) in goodsCategoryTree"
                        :key="index"
                        @mouseover="selectedCategory = item.category_id"
                        @mouseleave="selectedCategory = -1"
                    >
                        <router-link
                            :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }"
                            target="_blank"
                        >
                            <div class="list-item-left">
                                <img
                                    class="category-img"
                                    v-if="categoryConfig.img == 1"
                                    :src="$util.img(item.image)"
                                />
                                <p class="category-name">{{ item.category_name }}</p>
                            </div>
                            <i
                                class="el-icon-arrow-right"
                                aria-hidden="true"
                                v-if="item.child_list"
                            ></i>
                        </router-link>
                        <!--						<div class="item-itm " :class="{'item-itm-img':categoryConfig.img == 1}">-->
                        <!--							<router-link v-for="(second_item, second_index) in item.child_list" :key="second_index" :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }" target="_blank"-->
                        <!--							 v-show="second_index < 3"-->
                        <!--							 style="display:inline-block;">-->
                        <!--								{{ second_item.short_name?second_item.short_name : second_item.category_name }}-->
                        <!--							</router-link>-->
                        <!--						</div>-->
                        <div
                            class="cate-part"
                            v-if="item.child_list"
                            :class="{ show: selectedCategory == item.category_id }"
                        >
                            <div class="cate-part-col1">
                                <div class="cate-detail">
                                    <dl
                                        class="cate-detail-item"
                                        v-for="(second_item, second_index) in item.child_list"
                                        :key="second_index"
                                    >
                                        <dt class="cate-detail-tit">
                                            <router-link
                                                target="_blank"
                                                :to="{ path: '/list', query: { category_id: second_item.category_id, level: second_item.level } }"
                                            >{{ second_item.category_name }}</router-link>
                                        </dt>
                                        <dd class="cate-detail-con" v-if="second_item.child_list">
                                            <router-link
                                                v-for="(third_item, third_index) in second_item.child_list"
                                                :key="third_index"
                                                target="_blank"
                                                :to="{ path: '/list', query: { category_id: third_item.category_id, level: third_item.level } }"
                                            >
                                                <img
                                                    class="cate-detail-img"
                                                    v-if="categoryConfig.img == 1"
                                                    :src="$util.img(third_item.image)"
                                                />
                                                <p
                                                    class="category-name"
                                                >{{ third_item.category_name }}</p>
                                            </router-link>
                                        </dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <nav>
                <ul>
                    <li
                        v-for="(nav_item, nav_index) in navList"
                        :key="nav_index"
                        :class="nav_item.url == navSelect ? 'router-link-active' : ''"
                        @click="navUrl(nav_item.url, nav_item.is_blank, nav_item.view_style)"
                        style="display: flex; align-items: center"
                    >
                        <div
                            v-if="nav_item.nav_icon"
                            style="height: 18px; width: 25px; border-radius: 5px"
                        >
                            <el-image
                                fit="cover"
                                style="width: 18px; height: 18px; margin-right: 3px"
                                :src="$img(nav_item.nav_icon)"
                                alt
                            ></el-image>
                        </div>
                        <span>{{ nav_item.nav_title }}</span>
                    </li>
                    <el-dropdown class="more" v-if="more.length>0" @command="handleCommand">
                        <span class="el-dropdown-link">
                            更多
                            <i class="el-icon-arrow-down el-icon-arrow-right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item
                                v-for="(more_item, index) in more"
                                :key="index"
                                :command="more_item"
                            >
                                <span>
                                    <img
                                        style="height: 18px; width: 18px;"
                                        :src="more_item.nav_icon"
                                        alt="Icon"
                                    />
                                    {{ more_item.nav_title }}
                                </span>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </ul>
            </nav>
        </div>
    </div>
</template>

<script>
import NsHeaderTop from "./NsHeaderTop"
import NsHeaderMid from "./NsHeaderMid"
import { tree, categoryConfig } from "@/api/goods/goodscategory"
import { navList } from "@/api/website"
export default {
    props: {
        forceExpand: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isShopHover: false,
            isIndex: false,
            thisRoute: "",
            goodsCategoryTree: [],
            categoryConfig: {},
            selectedCategory: -1,
            navList: [],
            navSelect: "",
            isHide: false,
            coordinate: "",
            flog: true,
            more: []
        }
    },
    components: {
        NsHeaderTop,
        NsHeaderMid
    },
    computed: {},
    beforeCreate() {},
    created() {
        this.$store.dispatch("cart/cart_count")
        this.getCategoryConfig()
        // this.getTree();
        this.nav()
        if (this.$route.path == "/" || this.$route.path == "/index") this.isIndex = true
    },
    mounted() {},
    watch: {
        $route: function(curr) {
            this.initNav(this.$route.fullPath)
            if (curr.path == "/" || curr.path == "/index") this.isIndex = true
            else this.isIndex = false
            // if (curr.path == '/list') {
            // 	this.navSelect = '';
            // }
        }
    },
    methods: {
        // 获取配置
        getCategoryConfig() {
            categoryConfig({})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.categoryConfig = res.data
                        this.getTree(res.data)
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        getTree(categoryConfig) {
            tree({
                level: 3,
                template: 2
            })
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.goodsCategoryTree = res.data || []
                        for (let i = 0; i < this.goodsCategoryTree.length; i++) {
                            if (this.goodsCategoryTree[i].child_list > 3) {
                                this.isHide = true
                            }
                        }
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        nav() {
            navList({})
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.navList = res.data.list
                        for (let i in this.navList) {
                            this.navList[i]["url"] = JSON.parse(this.navList[i]["nav_url"]).url
                            if (i > 8) {
                                this.more = this.navList.splice(i, this.navList.length)
                            }
                        }
                        this.initNav(this.$route.fullPath)
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        handleCommand(Commandobj) {
            let { is_blank, view_style } = Commandobj
            let url = JSON.parse(Commandobj.nav_url).url
            this.navUrl(url, is_blank, view_style, 1)
        },
        navUrl(url, target, wbstyle, val) {
            if (!url) return
            if (url.indexOf("http") == -1) {
                if (target) this.$router.pushToTab({ path: url, query: { wbstyle: wbstyle } })
                else this.$router.push({ path: url, query: { wbstyle: wbstyle } })
            } else {
                if (target) window.open(url)
                else window.location.href = url
            }
            if (val == 1) this.navSelect = ""
        },
        initNav(path) {
            let getdegree = this.indexDegree(path)

            for (let i in this.navList) {
                let index = this.navList[i]["url"].indexOf("?")
                if (path == "/") return (this.navSelect = this.$route.fullPath) //为首页直接返回不进行筛选
                // 筛选出携带参数的路由地址
                if (index != -1 && this.navList[i]["url"] == getdegree.extractedString) {
                    this.navSelect = getdegree.extractedString
                    continue
                } else if (this.navList[i]["url"] == getdegree.extractedString) {
                    this.navSelect = getdegree.extractedString
                    continue
                }
            }
        },
        //初始化筛选
        indexDegree(url) {
            let degree = {}
            let wbstyleStart = url.indexOf("&wbstyle=")
            let wbstyleStart2 = url.indexOf("?wbstyle=")
            let isurl = wbstyleStart != -1 ? wbstyleStart : wbstyleStart2
            let extractedString = this.$route.fullPath.substring(0, isurl)
            degree = {
                wbstyleStart: isurl,
                extractedString: extractedString
            }
            return degree
        },
        //鼠标移入显示商品分类
        shopHover() {
            this.isShopHover = true
        },
        //鼠标移出商品分类隐藏
        shopLeave() {
            this.isShopHover = false
        }
    }
}
</script>

<style scoped lang="scss">
.header {
    width: 100%;
    height: 180px;
    background-color: #fff;

    .shadow {
        box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
    }
    .border {
        border: 1px solid #f5f5f5;
    }

    .nav {
        width: 1210px;
        height: 41px;
        margin: 0 auto;
        position: relative;
        .shop-sort {
            width: 210px;
            height: 41px;
            color: #fff;
            background-color: $base-color;
            float: left;
            padding: 10px 10px 11px 10px;
            box-sizing: border-box;
            a {
                font-size: 14px;
                line-height: 20px;
                float: left;
                color: #fff;
            }
            i {
                font-size: 14px;
                float: right;
            }
        }
        .shop-list-content {
            width: 210px;
            height: 400px;
            position: absolute;
            left: 0;
            top: 41px;
            background-color: #ffffff;
            display: none;
            padding: 0;
            box-sizing: border-box;
            font-size: $ns-font-size-base;
            z-index: 10;
            color: #333333;
            &::-webkit-scrollbar {
                display: none;
            }
            // overflow: hidden;
            .shop-list {
                width: 210px;
                height: 400px;
                overflow-y: auto;
                overflow-x: hidden;
                // box-shadow: -1px 3px 12px -1px rgba(0, 0, 0, 0.3);
                &::-webkit-scrollbar {
                    width: 4px;
                    // display: none;
                }
                &::-webkit-scrollbar-track{background-color:#fff;}
                &::-webkit-scrollbar-thumb{background-color:#d3d3d3;}
                a:hover {
                    color: $base-color;
                }
                .list-item {
                    padding-left: 12px;
                    box-sizing: border-box;
                    // min-height:50px;
                    // height: 45px;
                    // line-height: 50px;
                    padding: 13px;
                    a {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        // width: 95%;
                        // display: block;
                        color: #333;
                        > div {
                            display: flex;
                            align-items: center;
                        }
                    }
                    &:hover {
                        background-color: $base-color-em;
                        -webkit-transition: 0.2s ease-in-out;
                        -moz-transition: -webkit-transform 0.2s ease-in-out;
                        -moz-transition: 0.2s ease-in-out;
                        transition: 0.2s ease-in-out;
                        a:hover {
                            color: #fff;
                        }
                    }
                    //span:hover {
                    //	color: #34c0f1;
                    //}
                    .category-img {
                        width: 17px;
                        height: 17px;
                        margin-right: 10px;
                    }
                    .category-name {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .item-itm {
                        font-size: 14px;
                        line-height: 15px;
                        height: 28px;
                        overflow: hidden;

                        a {
                            margin-top: 5px;
                            margin-right: 10px;
                            color: #bfbfbf;
                            &:hover {
                                color: #fd274a !important;
                            }
                        }

                        &.item-itm-img {
                            margin-left: 27px;
                        }
                    }
                    .cate-part {
                        display: none;
                        position: absolute;
                        left: 210px;
                        top: 0;
                        z-index: auto;
                        // width: 998px;
                        width: 760px;
                        height: 398px;
                        overflow-y: auto;
                        border: 1px solid #f7f7f7;
                        background-color: #fff;
                        -webkit-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
                        -moz-box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
                        box-shadow: 2px 0 5px rgba(0, 0, 0, 0.05);
                        -webkit-transition: top 0.25s ease;
                        -o-transition: top 0.25s ease;
                        -moz-transition: top 0.25s ease;
                        transition: top 0.25s ease;
                        &.show {
                            display: block;
                        }
                        &::-webkit-scrollbar {
                            display: none;
                        }
                    }
                    .cate-part-col1 {
                        float: left;
                        width: 100%;
                        .cate-detail-item {
                            position: relative;
                            min-height: 36px;
                            padding-left: 20px;
                            &:last-child {
                                margin-bottom: 30px;
                            }
                            .cate-detail-tit {
                                margin-top: 30px;
                                margin-right: 20px;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                font-weight: 700;
                                a {
                                    display: block;
                                    color: #333333;
                                }
                                a:hover {
                                    color: #fd274a;
                                }
                            }
                            .cate-detail-con {
                                overflow: hidden;
                                padding: 6px 0 6px 17px;
                                display: flex;
                                flex-wrap: wrap;
                                // border-top: 1px dashed #eee;
                                a {
                                    justify-content: start;
                                    font-size: 12px;
                                    height: 30px;
                                    /* float: left; */
                                    margin: 4px 40px 4px 0;
                                    padding: 0 10px;
                                    white-space: nowrap;
                                    line-height: 30px;
                                    color: #666;
                                    width: calc((100% - 120px) / 4);
                                    display: flex;
                                    box-sizing: border-box;
                                    margin-top: 20px;
                                    .cate-detail-img {
                                        width: 30px;
                                        height: 30px;
                                        margin-right: 10px;
                                    }
                                    &:nth-child(4n + 4) {
                                        margin-right: 0;
                                    }
                                }
                                a:hover {
                                    color: #fd274a;
                                }
                            }
                            &:first-child .cate-detail-con {
                                border-top: none;
                            }
                        }
                    }

                    // .sub-class-right {
                    // 	display: block;
                    // 	width: 240px;
                    // 	height: 439px;
                    // 	float: right;
                    // 	border-left: solid 1px #e6e6e6;
                    // 	overflow: hidden;
                    // 	.adv-promotions {
                    // 		display: block;
                    // 		height: 441px;
                    // 		margin: -1px 0;
                    // 		a {
                    // 			background: #fff;
                    // 			display: block;
                    // 			width: 240px;
                    // 			height: 146px;
                    // 			border-top: solid 1px #e6e6e6;
                    // 			img {
                    // 				background: #d3d3d3;
                    // 				width: 240px;
                    // 				height: 146px;
                    // 			}
                    // 		}
                    // 	}
                    // }
                }
            }
        }

        .shop-list-active {
            display: block;
        }
        nav {
            width: 1000px;
            height: 36px;
            float: left;
            font-size: 14px;
            ul {
                // display: flex;
                margin: 0;
                padding: 0;
                width: 100%;
                height: 100%;
                li {
                    // white-space: nowrap;
                    cursor: pointer;
                    list-style: none;
                    float: left;
                    padding: 7px 15px;
                    a {
                        &:hover {
                            color: $base-color;
                        }
                    }
                }
                .more {
                    padding: 7px 0px;
                    color: #303133;
                    &:hover {
                        color: $base-color;
                        font-weight: bold;
                    }
                }
                li:hover {
                    color: $base-color;
                    font-weight: bold;
                }
                .router-link-active {
                    color: $base-color;
                    font-weight: bold;
                    border-bottom: 2px solid $base-color;
                }
            }
        }
    }
}
</style>
