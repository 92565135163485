<template>
    <div class="navList">
        <div class="navList-linsTo" v-if="showgoodsList==1">
            <div style="display: flex" class="nv-factory" v-if="factory.length > 0">
                <div class="fen">厂家：</div>
                <ul :class="factory_collapsetype ? 'ul-height-30' : 'ul-expand'">
                    <li style="display: flex; align-items: content">
                        <span style="padding: 0px 5px; white-space: nowrap; margin-top: 4px" :class="[{ factory_selected: factory_index === '' }]" @click="setFactorytype('')">不限：</span>
                        <el-input v-model.trim="inptName" @change="changeinouttype($event, 1)" type="text" size="small" placeholder="请输入厂家">
                            <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                        </el-input>
                    </li>
                    <li :class="[{ factory_selected: factory_index === factoryItem }]" v-for="(factoryItem, f_index) in factory" :key="f_index" @click="setFactorytype(factoryItem, f_index)" @mouseover="showLog(factoryItem)" @mouseleave="hideLog">
                        <div class="li-box">
                            <span v-html="highLight(factoryItem, inptName)"></span>
                            <div class="showlog" v-show="factoryItem == indextex && factoryItem.length > 10">{{ factoryItem }}</div>
                        </div>
                    </li>
                </ul>
                <template v-if="factory.length > 5">
                    <div class="collapse-btn" @click="collapse(1)">
                        {{ factory_collapsetype ? "展开" : "收起" }}
                    </div>
                </template>
            </div>
            <div style="display: flex" class="nv-spec" v-if="spec.length > 0">
                <div class="fen">规格：</div>
                <ul :class="spec_collapsetype ? 'ul-height-30' : 'ul-expand'">
                    <li style="display: flex; align-items: content">
                        <span style="padding: 0px 5px; white-space: nowrap; margin-top: 4px" :class="[{ factory_selected: spec_index === '' }]" @click="setSpec('')">不限：</span>
                        <el-input v-model.trim="specificationInput" type="text" size="small" placeholder="请输入价格" @change="changeinouttype($event, 2)">
                            <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                        </el-input>
                    </li>
                    <li :class="[{ factory_selected: spec_index === specItem }]" v-for="(specItem, specindex) in spec" :key="specindex" @click="setSpec(specItem)" @mouseover="showLog(specItem)" @mouseleave="hideLog">
                        <div class="li-box">
                            <span v-html="highLight(specItem, specificationInput)"> </span>
                            <div class="showlog" v-show="specItem == indextex && specItem.length > 15">{{ specItem }}</div>
                        </div>
                    </li>
                </ul>
                <template v-if="spec.length > 7">
                    <div class="collapse-btn" @click="collapse(2)">
                        {{ spec_collapsetype ? "展开" : "收起" }}
                    </div>
                </template>
            </div>
            <div style="display: flex" class="nv-brand" v-if="brand.length > 0">
                <div class="fen">品牌：</div>
                <ul :class="brand_collapsetype ? 'ul-height-30' : 'ul-expand'">
                    <li style="display: flex">
                        <span style="padding: 0px 5px; white-space: nowrap; margin-top: 4px" :class="[{ factory_selected: brand_index === '' }]" @click="setBrand('')">不限：</span>
                        <el-input v-model.trim="barndInput" type="text" size="small" placeholder="请输入品牌" @change="changeinouttype($event, 4)">
                            <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                        </el-input>
                    </li>

                    <li :class="[{ factory_selected: brand_index === brandItem }]" v-for="(brandItem, f_index) in brand" :key="f_index" @click="setBrand(brandItem, f_index)">
                        <div class="li-box">
                            <span v-html="highLight(brandItem, barndInput)"></span>
                        </div>
                    </li>
                </ul>
                <template v-if="brand.length > 5">
                    <div class="collapse-btn" @click="collapse(3)">
                        {{ brand_collapsetype ? "展开" : "收起" }}
                    </div>
                </template>
            </div>
            <div style="display: flex" class="nv-dosage_form" v-if="dosage_form.length > 0">
                <div class="fen">剂型：</div>
                <ul :class="dosage_form_collapsetype ? 'ul-height-30' : 'ul-expand'">
                    <div style="display: flex">
                        <span style="padding: 10px 5px" :class="[{ factory_selected: brand_index === '' }]" @click="setBrand('')">不限：</span>
                        <li>
                            <el-input v-model.trim="inputtype" type="text" size="small" placeholder="请输入剂型" @change="changeinouttype($event, 3)">
                                <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                            </el-input>
                        </li>
                    </div>
                    <li :class="{ active: dosage_form_index === dosageItem }" v-for="(dosageItem, dosage_formindex) in dosage_form" :key="dosage_formindex" @click="setDosageForm('')">
                        <span v-html="highLight(dosageItem, inputtype)"></span>
                    </li>
                </ul>
                <template v-if="dosage_form.length > 7">
                    <div class="collapse-btn" @click="collapse(4)">
                        {{ dosage_form_collapsetype ? "展开" : "收起" }}
                    </div>
                </template>
            </div>
            <div style="display: flex">
                <div class="fen">商品分类：</div>
                <div>
                    <div class="sort">
                        <div class="item" @click="changeSort('')">
                            <div class="item-name">综合</div>
                        </div>
                        <div class="item" @click="changeSort('sale_num')">
                            <div class="item-name">销量</div>
                            <i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <div class="item" @click="changeSort('discount_price')">
                            <div class="item-name">价格</div>
                            <i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <!-- <div class="item-other">
                            <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
                        </div>
                        <div class="item-other">
                            <el-checkbox label="自营" v-model="is_own"></el-checkbox>
                        </div> -->
                        <div class="input-wrap">
                            <div class="price_range">
                                <el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
                                <span>—</span>
                                <el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
                            </div>
                            <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="search_bread-swtich" v-for="(item,index) in streamer" :key="index">
                <el-image  :src="$util.img(item.src)"  @click="onckicswich(item.url)"></el-image>
        </div>
    </div>
</template>

<script>
import { highLightTableMsg } from "../filter.js"
export default {
    name: "PcScreenlist3",
    props: {
        first_child_listtype: {
            type: Array,
            default: () => []
        },
        keyword: {
            type: String,
            default: ""
        },
        catewords: {
            type: Array,
            default: () => []
        },
        filters: {
            type: Object,
            default: () => ({})
        },
        factory: {
            type: Array,
            default: () => []
        },
        spec: {
            type: Array,
            default: () => []
        },
        brand: {
            type: Array,
            default: () => []
        },
        dosage_form: {
            type: Array,
            default: () => []
        },
        first_index_active: {
            type: Number,
            default: 0
        },
        first_index: {
            type: Number,
            default: 0
        },
        factory_index: {
            type: String,
            default: ""
        },
        factory_collapse: {
            type: Boolean,
            default: true
        },
        spec_collapse: {
            type: Boolean,
            default: true
        },
        spec_index: {
            type: String,
            default: ""
        },
        brand_collapse: {
            type: Boolean,
            default: true
        },
        brand_index: {
            type: String,
            default: ""
        },
        dosage_form_collapse: {
            type: Boolean,
            default: true
        },
        dosage_form_index: {
            type: String,
            default: ""
        },
        showgoodsList:{
            type:Number,
            default:0
        },
        streamer:{
            type:Array,
            default:()=>[]
        },
    },
    data() {
        return {
            inptName: "",
            specificationInput: "",
            inputtype: "",
            barndInput: "",
            value: true,
            factory_collapsetype: this.factory_collapse,
            spec_collapsetype: this.spec_collapse,
            dosage_form_collapsetype: this.dosage_form_collapse,
            brand_collapsetype: this.brand_collapse,
            isHovered: false,
            indextex: ""
        }
    },
    watch: {
        factory_collapse(vla) {
            this.factory_collapsetype = vla
        },
        spec_collapse(vla) {
            this.spec_collapsetype = vla
        },
        dosage_form_collapse(vla) {
            this.dosage_form_collapsetype = vla
        },
        brand_collapse(val) {
            this.brand_collapsetype = val
        }
    },
    mounted() {},

    methods: {
        showLog(item) {
            this.indextex = item
        },
        hideLog() {
            this.indextex = ""
        },
        setFiindextype(item) {
            this.$emit("setFiindex", item)
        },
        setFactorytype(factoryItem, f_index) {
            this.inptName = ""
            this.$emit("setFactory", factoryItem, f_index)
        },
        setSpec(setSpecItem, f_index) {
            this.specificationInput = ""
            this.$emit("setSpec", setSpecItem, f_index)
        },
        setBrand(brandItem, f_index) {
            this.barndInput = ""
            this.$emit("setBrand", brandItem, f_index)
        },
        setDosageForm(dosageItem, d_index) {
            this.inputtype = ""
            this.$emit("setDosageForm", dosageItem, d_index)
        },
        changeinouttype(val, idnex) {
            let nameprm = {
                // category_id: 0,
                factory_index: this.inptName,
                factoryStr: this.inptName,
                // specStr: "",
                // dosageFormStr: "",
                inptName: this.inptName,
                factory_collapsetype: false
            }
            let specification = {
                // category_id: 0,
                // dosageFormStr: "",
                // factoryStr: "",
                spec_index: this.specificationInput,
                specStr: this.specificationInput,
                spec_collapsetype: false
            }
            let dosage = {
                // category_id: 0,
                // factoryStr: "",
                // specStr: "",
                dosage_form_index: this.inputtype,
                dosageFormStr: this.inputtype,
                dosage_form_collapsetype: false
            }
            let category = {
                // category_id: 0,
                // factoryStr: "",
                // specStr: "",
                // dosageFormStr: "",
                brand_index: this.barndInput,
                brandStr: this.barndInput,
                brand_collapsetype: false
            }
            switch (idnex) {
                case 1:
                    if (!this.inptName) {
                        nameprm.factory_collapsetype = true
                        nameprm.factory_index = ""
                    }
                    this.$emit("changeinout", nameprm, idnex)
                    break
                case 2:
                    if (!this.specificationInput) {
                        specification.spec_collapsetype = true
                        specification.spec_index = ""
                    }
                    this.$emit("changeinout", specification, idnex)
                    break
                case 3:
                    if (!this.inputtype) {
                        dosage.dosage_form_collapsetype = true
                        dosage.dosage_form_index = ""
                    }
                    this.$emit("changeinout", dosage, idnex)
                    break
                case 4:
                    if (!this.barndInput) {
                        category.brand_collapsetype = true
                        category.brand_index = ""
                    }
                    this.$emit("changeinout", category, idnex)
                    break
            }
        },
        collapse(val) {
            switch (val) {
                case 1:
                    this.factory_collapsetype = !this.factory_collapsetype
                    this.$emit("collapse", this.factory_collapsetype,val)
                    break
                case 2:
                    this.spec_collapsetype = !this.spec_collapsetype
                    this.$emit("collapse", this.spec_collapsetype,val)
                    break
                case 3:
                   this.brand_collapsetype = !this.brand_collapsetype
                   this.$emit("collapse", this.brand_collapsetype,val)
                    break
                case 4:
                   this.dosage_form_collapsetype = !this.dosage_form_collapsetype
                   this.$emit("collapse", this.dosage_form_collapsetype,val)
                    break
            }
        },
        // 对高亮进行封装
        highLight(item, highLight) {
            return highLightTableMsg(item, highLight)
        },
        handlePriceRange() {
            this.$emit("handlePriceRange")
        },
        changeSort(item) {
            this.$emit("changeSort", item)
        }
    }
}
</script>

<style lang="scss" scoped>
.navList {
   width: 100%;
    &-titl {
        padding: 2px 0;
    }
    .search_bread-swtich{
        width: 1170px;
        margin: auto;
        padding-right: 40px;
    
        img {
                max-width: 100%;
                height: auto;
                display: block;
            }
    }
    &-linsTo {
        width: 1200px;
        margin: 0 auto;
        // margin-bottom: 20px;
        box-sizing: border-box;
        .nv-factory {
            width: 100%;
            position: relative;
            .collapse-btn {
                position: absolute;
                right: -10px;
                top: 0px;
                cursor: pointer;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                color: $base-color;
                padding: 0 10px;
            }
            li {
                padding-left: 5px;
                width: 168px;
            }
        }
        .nv-spec {
            position: relative;
            .collapse-btn {
                position: absolute;
                right: -10px;
                top: 0px;
                cursor: pointer;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                color: $base-color;
                padding: 0 10px;
            }
            li {
                width: 168px;
                padding-left: 5px;
            }
        }
        .nv-dosage_form {
            position: relative;

            .collapse-btn {
                position: absolute;
                right: -10px;
                top: 0px;
                cursor: pointer;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                color: $base-color;
                padding: 0 10px;
            }
            li {
                padding-left: 5px;
                width: 168px;
            }
        }
        .nv-brand {
            position: relative;
            .collapse-btn {
                position: absolute;
                right: -10px;
                top: 0px;
                cursor: pointer;
                text-align: right;
                font-size: 16px;
                font-weight: bold;
                color: $base-color;
                padding: 0 10px;
            }
            li {
                padding-left: 5px;
                width: 168px;
            }
        }
        .sort {
            display: flex;
            align-items: center;

            .item {
                display: flex;
                align-items: center;
                padding: 5px 15px;
                border: 1px solid #f1f1f1;
                border-left: none;
                cursor: pointer;

                &:hover {
                    background: $base-color;
                    color: #fff;
                }
            }

            .item-other {
                display: flex;
                align-items: center;
                padding: 8px 15px;
                border: 1px solid #f1f1f1;
                border-left: none;
                cursor: pointer;
            }

            .input-wrap {
                display: flex;
                align-items: center;

                .price_range {
                    margin-left: 60px;
                }

                span {
                    padding-left: 10px;
                }

                .el-input {
                    width: 150px;
                    margin-left: 10px;
                }

                .el-button {
                    margin: 0 17px;
                }
            }

            > div:first-child {
                border-left: 1px solid #f1f1f1;
            }
        }
    }
    ul {
        display: flex;
        width: 100%;
        flex-flow: wrap;
        border-bottom: 1px solid #ddd;

        li {
            position: relative;
            .li-box {
                display: flex;
                padding: 5px 0;
                align-items: center;
                margin-right: 20px;
                cursor: pointer;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                display: block;
            }
            .showlog {
                position: absolute;
                display: block;
                font-size: 10px;
                left: 70px;
                top: 25px;
                transition: 0.3s;
                width: 100px;
                height: 30px;
            }
            &:hover {
                color: $base-color;
            }
        }
    }

    .ul-height-30 {
        padding-right: 30px;
        max-height: 35px;
        transition: max-height 0.6s ease-out;
        overflow: hidden;
    }

    .ul-expand {
        padding-right: 30px;
        transition: max-height 0.6s ease-in;
        max-height: 600px; /*一个足够大的值*/
        overflow: hidden;
    }
    .factory_selected {
        color: $base-color;
    }
    .fen {
        display: flex;
        justify-content: center;
        min-width: 100px;
        padding: 9px 0;
        border-radius: 2px;
        text-align: center;
        font-weight: bold;
        background-color: #eaeaea;
    }
}
</style>