<template>
    <div class="excekkent">
        <!-- 头部区域 -->
        <div class="excekkent-hede">
            <div class="hede-l">
                <el-carousel indicator-position="outside" arrow="hover" height="400px">
                    <el-carousel-item v-for="(item, index) in adListWiper" :key="index">
                        <el-image class="el-image" :src="$img(item.adv_image)" fit="cover"
                            @click="$router.pushToTab(item.adv_url.url)" />
                          
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="hede-r">
                <div v-for="(item, index) in adListRigth" :key="index" class="r-box">
                    <el-image style="width: 100%; height: 100%" :src="$img(item.adv_image)" fit="cover"
                        @click="$router.pushToTab(item.adv_url.url)">
                    </el-image>
                </div>
            </div>
        </div>
        <!-- 金牌店铺推荐 -->
        <div class="excekkent-recommend">
            <el-image v-for="(item, index) in recommend" :key="index" style="width: 100%; height:80px"
                :src="$img(item.adv_image)" @click="$router.pushToTab(item.adv_url.url)" fit="cover">
            
            </el-image>
        </div>
        <!-- 总和排名 -->
        <div class="excekkent-ranking" v-for="(item, index) in rankingList" :key="index">
            <div class="ranking-heder">
                金牌店铺.综合第{{ index + 1 }}
                <div class="ranking-img">
                    <el-image style="width: 80px; height:80px" :src="$img(item.gold_image)" fit="cover">
                        <div slot="error" class="image-slot">
                                <img :src="$img(defaultSupplyImage)" alt="">
                            </div>
                    </el-image>
                </div>
            </div>
            <div class="ranking-box">
                <div class="ranking-botm" @click=" $router.pushToTab({
                    path: '/shop-' + item.site_id
                })">
                    <div class="botm-img">
                        <!-- <el-image style="width:150px; height:90%" :src="$img(item.avatar)" @error="imageError"
                            fit="cover"></el-image> -->
                        <el-image style="width:150px; height:90%" :src="$img(item.logo)" >
                            <div slot="error" class="image-slot">
                                <img :src="$img(defaultStoreImage)" alt="">
                            </div>
                        </el-image>
                    </div>
                    <div class="botm-cont">
                        <div class="cont-t">
                            <span class="t-1">{{ item.site_name }}</span>
                            <span class="t-2">综合评分{{ item.score }}</span>
                            <i class="el-icon-star-on" :class="item.is_subscribe == 1 ? 'collect-active' : 'collect'"
                                @click="showCollectClick(item)"></i>
                        </div>
                        <p class="cont-c">
                            上架
                            <span>{{ item.on_shelves }}</span> 种 发货
                            <i v-html="formattedDelivery(item)"></i>
                        </p>
                        <div class="cont-b">
                            <p v-if="item.promotion.length > 0">
                                <span class="promotion">促销</span>
                                <span v-for="(x, i) in item.promotion" :key="i">
                                    <span v-if="x.type == 'reward'">
                                        <span v-if="x.at_least == 0">无门槛</span>
                                        满{{ x.at_least }}元减{{ x.discount }}，
                                    </span>
                                    <span v-else>
                                        <span v-if="x.at_least == 0">无门槛</span>
                                        满{{ x.at_least }}元{{ x.discount }}折，
                                    </span>
                                </span>
                            </p>
                            <p v-if="item.coupon.length>0">
                                <span class="roll">劵</span>
                                <span v-for="(x, i) in item.coupon" :key="i">
                                    <span v-if="x.type == 'reward'">
                                        <span v-if="x.at_least == 0">无门槛</span>
                                        满{{ x.at_least }}元减{{ x.discount }}，
                                    </span>
                                    <span v-else>
                                        <span v-if="x.at_least == 0">无门槛</span>
                                        满{{ x.at_least }}元{{ x.discount }}折，
                                    </span>
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <!-- 商品 -->
                <div class="name">
                    <div class="ranking-r" v-for="(x, i) in item.goods_sku_list.slice(3)" :key="i">
                        <el-image @click="$router.pushToTab({ path: '/sku?id=' + x.sku_id })" :src="$img(x.sku_image)"
                            fit="scale-down">
                            <div slot="error" class="image-slot">
                                <img :src="defaultGoodsImage" alt="">
                            </div>
                        </el-image>
                        <div class="info-1">
                            <span>{{ x.price !== undefined ? x.price : '' }}</span> 折后价
                            <span>{{ x.discount_price !== undefined ? x.discount_price : '' }}</span>
                        </div>
                        <div class="info-2">{{ x.sku_name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 搜索 -->
        <div class="excekkent-sou">
            <div class="swicths">
                <div :class="active == index ? 'screens' : 'isscreen'" v-for="(item, index) in screen" :key="index"
                    @click="onScreen(index)">
                    {{ item.title }}
                    <i
                        :class="{ 'el-icon-caret-bottom': sort.sorts == 'desc' && active === index, 'el-icon-caret-top': sort.sorts != 'desc' && active === index }"></i>
                </div>
            </div>
            <div class="search">
                <el-input placeholder="搜索 店铺" v-model="infokeyword" maxlength="50" class="input-with-select"
                    @change="getpagediyview" size="medium" clearable>
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
        </div>
        <!-- 列表展示 -->
        <div class="excekkent-list">
            <div class="infos" v-for="(item, index) in Listsort" :key="index">
                <div class="infos-l" @click=" $router.pushToTab({
                    path: '/shop-' + item.site_id
                })">
                    <div class="l-t">
                        <div class="l-img">
                            <el-image style="width: 100px; height:100px" :src="$img(item.logo)" fit="cover">
                                <div slot="error" class="image-slot">
                                <img :src="$img(defaultStoreImage)" alt="">
                            </div>
                            </el-image>
                        </div>
                        <div class="l-r">
                            <h4>{{ item.site_name }}</h4>
                            <p>
                                上架
                                <span>{{ item.on_shelves }}</span>种 发货
                                <i v-html="formattedDelivery(item)"></i>
                            </p>
                        </div>
                    </div>
                    <div class="l-c">
                        <div :class="j.color ? 'c-1' : 'c-2'" :style="{ backgroundColor: j.color }"
                            v-for="(j, c) in item.label" :key="c">{{ j.name }}</div>
                    </div>
                    <div class="l-b" v-if="item.coupon.length > 0">
                        <span class="b-1">劵</span>
                        <span v-for="(x, i) in item.coupon" :key="i">
                            <span v-if="x.type == 'reward'">
                                <span v-if="x.at_least == 0">无门槛</span>
                                满{{ x.at_least }}元减{{ x.discount }}，
                            </span>
                            <span v-else>
                                <span v-if="x.at_least == 0">无门槛</span>
                                满{{ x.at_least }}元{{ x.discount }}折，
                            </span>
                        </span>
                    </div>
                </div>
                <div class="infos-r">
                    <span class="r-2">综合评分{{ item.score }}</span>
                    <div class="r-i">
                        <i class="el-icon-star-on" :class="item.is_subscribe == 1 ? 'collect-active' : 'collect'"
                            @click="showCollectClick(item)"></i>
                    </div>
                    <div class="r-img">
                        <el-image style="width: 130px; height:120px;border-radius: 5px; overflow: hidden;"
                            @click="$router.pushToTab({ path: '/sku?id=' + item.goods_sku_list[0].sku_id })"
                            :src="$img(item.goods_sku_list.length > 0 ? item.goods_sku_list[0].sku_image : '#')"
                            fit="scale-down">
                            <div slot="error" class="image-slot">
                                <img :src="defaultGoodsImage" alt="">
                            </div>
                        </el-image>
                    </div>
                </div>
            </div>
        </div>
        <!-- 排序 -->
        <div style="display: flex; justify-content: flex-end">
            <el-pagination style="padding-right: 30px; margin-top: 20px" background :pager-count="5" :total="total"
                prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize"
                @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"></el-pagination>
        </div>
    </div>
</template>
<script>
import excellents from "./excellent"
export default {
    name: "excellent_shop",
    mixins: [excellents],
    data: () => {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.excekkent {
    &-hede {
        width: 100%;
        display: flex;
        margin-bottom: 20px;

        .hede-l {
            width: 70%;
        }

        .hede-r {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-bottom: 30px;

            &>div {
                // flex: 1;
                // background: dodgerblue;
                margin: 0 0 10px 10px;
            }

            .r-box {
                height: 125px;
            }
        }
    }

    &-recommend {
        margin-bottom: 30px;
    }

    &-ranking {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
        margin-bottom: 20px;
        border-radius: 5px;
        overflow: hidden;

        &:hover {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }

        .ranking-heder {
            position: relative;
            background: #c0c4cc5c;
            padding: 1px 10px;
            margin-bottom: 20px;

            .ranking-img {
                position: absolute;
                right: 0;
                top: -5px;
            }
        }

        .ranking-box {
            padding: 0 10px;
            display: flex;
            justify-content: space-between;
        }

        .ranking-botm {
            display: flex;

            .botm-img {
                margin-right: 20px;

                &:hover {
                    cursor: pointer;
                }
            }

            .botm-cont {
                min-width: 400px;
                min-height: 160px;

                .cont-t {
                    display: flex;
                    align-items: center;
                    margin-bottom: 20px;

                    &:hover {
                        cursor: pointer;
                    }

                    .t-1 {
                        font-size: 18px;
                        margin-right: 10px;
                    }

                    .t-2 {
                        display: inline-block;
                        padding: 0px 8px;
                        border-radius: 5px;
                        font-size: 12px;
                        font-weight: 600;
                        color: #409eff;
                        background: #409eff21;
                        margin: 0 10px;
                    }

                    .collect {
                        color: #c0c4cc;
                        font-size: 18px;
                    }

                    .collect-active {
                        color: rgb(247, 186, 42);
                        font-size: 18px;
                    }
                }
            }

            .cont-c {
                font-size: 18px;
                margin-bottom: 5px;

                &>span {
                    color: rgb(255, 145, 41);
                }
            }

            .cont-b {
                background: rgb(242, 246, 261);
                border-radius: 5px;
                font-size: 12px;

                .promotion {
                    padding: 2px;
                    color: #fff;
                    background: rgb(247, 186, 42);
                }

                .roll {
                    padding: 2px;
                    color: #fff;
                    background: red;
                    margin-right: 10px;
                }
            }
        }

        .name {
            display: flex;
            margin-right: 70px;

            .ranking-r {
                width: 150px;
                height: 180px;

                &:hover {
                    cursor: pointer;
                }

                .el-image {
                    width: 80%;
                    height: 60%;
                    padding: 0 10px;
                }

                .info-1 {
                    font-size: 12px;
                    color: #c0c4cc;
                    text-align: center;
                }

                .info-2 {
                    padding: 0 5px;
                    font-weight: 400;
                    font-size: 13px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                }
            }
        }
    }

    &-sou {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;

        .swicths {
            display: flex;

            .isscreen {
                border-radius: 15px;
                padding: 0px 20px;
                height: 30px;
                line-height: 30px;
            }

            .screens {
                border-radius: 15px;
                background: #e6a23c;
                padding: 0px 20px;
                height: 30px;
                line-height: 30px;
                color: #fff;
            }
        }
    }

    &-list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .infos {
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            border-radius: 5px;

            &:hover {
                box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
            }

            margin: 0 0px 10px 0;
            width: 48%;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px;

            .infos-l {
                width: 70%;

                .l-t {
                    display: flex;

                    .l-r {
                        &>p {
                            &>span {
                                color: rgb(255, 145, 41);
                            }
                        }
                    }

                    .l-img {
                        margin-right: 5px;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .l-c {
                    display: flex;
                    flex-wrap: wrap;
                    min-height: 30px;

                    .c-1 {
                        border-radius: 2px;
                        padding: 0 10px;
                        border: 1px solid #e6a23c;
                        margin-right: 10px;
                        margin-bottom: 10px;
                        color: #fff;
                    }

                    .c-2 {
                        border-radius: 2px;
                        padding: 0 10px;
                        margin-right: 10px;
                        background: rgb(255 0 0 / 68%);
                        ;
                        color: #fff;
                        margin-bottom: 10px;
                    }

                    // .c-2 {
                    //     color: #fff;
                    //     border-radius: 2px;
                    //     padding: 1px 5px;
                    //     margin-right: 10px;
                    //     background: #07c160;
                    //     margin-bottom: 10px;
                    // }

                    // .c-3 {
                    //     background: #ff000014;
                    //     color: red;
                    //     border-radius: 2px;
                    //     padding: 1px 5px;
                    //     margin-right: 10px;
                    //     margin-bottom: 10px;
                    // }

                    // .c-4 {
                    //     border-radius: 2px;
                    //     background: #c0c4cc40;
                    //     padding: 1px 5px;
                    //     color: #c0c4cc;
                    //     margin-right: 10px;
                    //     margin-bottom: 10px;
                    // }
                }

                .l-b {
                    border-radius: 2px;
                    background: rgb(242, 246, 261);
                    font-size: 13px;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 1;
                    overflow: hidden;
                    margin-right: 40px;

                    .b-1 {
                        padding: 2px;
                        color: #fff;
                        background: red;
                        margin-right: 10px;
                    }
                }
            }

            .infos-r {
                display: flex;
                flex-direction: column;
                align-items: center;

                .r-2 {
                    display: block;
                    padding: 0px 8px;
                    border-radius: 5px;
                    font-size: 12px;
                    font-weight: 600;
                    color: #409eff;
                    background: #409eff21;
                    margin: 0 10px;
                }

                .r-i {
                    text-align: center;
                    margin: 10px 0;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .r-img {
                    &:hover {
                        cursor: pointer;
                    }
                }

                .collect {
                    color: #c0c4cc;
                    font-size: 23px;
                }

                .collect-active {
                    color: rgb(247, 186, 42);
                    font-size: 23px;
                }
            }
        }
    }
}

::v-deep .el-carousel {
    width: 100%;

    .el-carousel {
        width: 100%;

        .el-image__inner {
            width: auto;
        }
    }

    .el-carousel__arrow--right {
        right: 60px;
    }
}
</style>