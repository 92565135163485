<template>
    <div class="notepper">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="邀请记录" name="1"></el-tab-pane>
            <el-tab-pane label="推荐礼记录" name="2"></el-tab-pane>
        </el-tabs>
          <div v-loading="loading">  
            <vActive1 v-if="activeName==1" :data="tableData" :count="count" ></vActive1>
            <vActive2 v-if="activeName==2" :data="tableData" :count="count"></vActive2>
          </div>
         <div><el-pagination style="padding-right: 30px; margin-top: 20px" background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"></el-pagination></div>
    </div>
</template>

<script>
import http from "@/utils/http"
import vActive1 from "./componts/active-1"
import vActive2 from "./componts/active-2"
export default {
    components:{
        vActive1,
        vActive2,
    },
    data() {
        return {
            activeName: "1",
            tableData: [],
            count:5,
            loading:true,
            currentPage:1,
            pageSize:8,
            total:5
        }
    },
    created(){
        this.onteeper()
    },
    methods: {
        handleClick(tab) {
            this.loading=true
            this.onteeper()
        },
        onteeper(){
           let url=""
            switch(this.activeName){
               case "1" :
                // 邀请记录
                url="/api/Member/getRegisterLog "
                break;
                case "2":
                 // 推荐礼记录
                 url="/api/Member/getGiftLog"
                    break;
            }
       let params={
        page :this.currentPage,
        page_size :this.page_size 
       }
        http({url:url, data: params,forceLogin: true}).then((res)=>{
            let {code,data}=res
           if(code>=0){
               this.loading=false
                this.tableData=data.list
                this.count=Number(data.count)
                this.tableData.map((item)=>{
                    // 转换推荐记录时间戳
                    if( item.create_time){
                        item.create_time = this.$util.timeStampTurnTime(item.create_time)
                    }else{
                         // 转换邀请记录时间戳
                        item.reg_time = this.$util.timeStampTurnTime(item.reg_time)
                    }
                 
                })
           }
        }).then((err)=>{
         
        })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.onteeper()
        },
        handleCurrentPageChange(page) {
            // window.scrollTo({
            //     top: document.querySelector('.list-wrap').offsetTop - 10
            // })
            this.currentPage = page
            this.onteeper()
        },
    }
}
</script>

<style lang="scss" scoped>
</style>