<template>
    <div class="active-page">
        <!--        顶部轮播-->
        <div class="banner">
            <el-carousel class="inner" v-loading="loadingAd" arrow="hover" height="300px">
                <el-carousel-item v-for="item in adList" :key="item.adv_id">
                    <el-image class="el-image" :src="$img(item.adv_image)" fit="cover"
                        @click="$router.pushToTab(item.adv_url.url)" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <!--        选项卡区域-->
        <div class="tabs">
            <template v-for="(item, index) in activityList">
                <div :class="['tab-item']"  :key="item.label" @click="tabItemClick(item, index)">
                    <img :src='$img(item.icon)' alt="">
                    <p :style="{backgroundColor: item.color}">{{ item.name }}</p>
                    <div class="bar" :style="defaultActive === index ? `backgroundColor: ${item.color}` : ''"></div>
                </div>
            </template>
        </div>
        <!--        内容区域-->
        <div class="content">
            <activity-list-component :info="info"></activity-list-component>
        </div>
    </div>
</template>

<script>
import http from "@/utils/http"
import { adList } from '@/api/website';
import activityListComponent from "./components/list.vue"
import activityMap from '@/utils/activityMap'
export default {
    components: {
        activityListComponent
    },
    data() {
        return {
            defaultActive: 0,
            activityList: [],
            activityName: "",
            info: {},
            adList: [],
            loadingAd: true
        }
    },
    created() {
        this.getAdList();
        this.activeInfo();
    },
    methods: {
        changeTab() {
            let hash = this.$route.hash
            if (!hash) {
                this.addHashToLocation(this.activityList[0]["label"])
                this.info = this.activityList[0]
            } else {
                this.defaultActive = this.activityList.findIndex(item => item.label === hash.replace("#", ""))
                this.info = this.activityList[ this.defaultActive]
            }
        },
        addHashToLocation(params) {
            let url = this.$route.path;
            history.pushState(
                {},
                null,
                url + '#' + encodeURIComponent(params)
            );
        },
        changeActivityTypeOnUrl(type){
            this.addHashToLocation(type)
        },
        // 获取活动
        async activeInfo() {
            const res = await http({ url: "/api/Promotion/activeInfo" })
            if (res.code >= 0) {
                const resData = [];
                // res.data.push({
                //     color: "#4BB1FA",
                //     icon:"https://cloud.2myxc.com/upload/common/images/20240120/20240120031309170573478921281.png",
                //     info: [],
                //     label: "getfree",
                //     name: "换购"
                // })
                res.data.map(item => resData.push({ ...item, ...activityMap[item.label] }));
                this.activityList = resData;
                this.changeTab();
            }
        },
        getAdList() {
            adList({ keyword: 'ACTIVITY_PC' })
                .then(res => {
                    this.adList = res.data.adv_list;
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url);
                    }
                    this.loadingAd = false;
                })
                .catch(err => {
                    this.loadingAd = false;
                });
        },
        tabItemClick(item, index) {
            this.defaultActive = index;
            // this.activityName = item.label;
            this.info = item;
            this.changeActivityTypeOnUrl(item.label);
        }
    },

}
</script>

<!--<style>-->
<!--.active-page .banner .el-carousel__arrow&#45;&#45;right {-->
<!--    right: 60px;-->
<!--}-->
<!--</style>-->
<style lang="scss" scoped>
::v-deep .activity-list {
    margin-left: -18px;
}

::v-deep .goods-info {
    justify-content: space-between;
}

.tabs,
.content, .banner {
    width: $width;
    margin: 0 auto;
}

.banner {
    //width: 100%;
    height: 305px;
    position: relative;
    .inner {
        position: absolute;
        width: 100%;
        height: 305px;
    }

    .el-image {
        width: 100%;
        height: 300px;
    }

    .img {
        max-width: 100%;
        height: auto;
    }
}

.active-page {
    margin-left: -5px;
    box-sizing: border-box;

    .tabs {
        padding-top: 20px;
        display: flex;
        gap: 20px;
        justify-content: center;
    }

    .content {
        padding-top: 20px;
    }

    .tab-item {
        padding: 0 20px;
        text-align: center;
        font-weight: 600;
        color: white;
        font-size: 0;
        cursor: pointer;
        img {
            display: inline-block;
            width: 70px;
            padding-bottom: 5px;
        }
        p {
            width: 7em;
            margin: auto;
            font-size: 16px;
            line-height: 30px;
            color: #fff;
            border-radius: 20px;
        }
        .bar {
            content: '';
            display: block;
            width: 30px;
            height: 3px;
            margin: 5px auto;
            background-color: #D5D5D5;
        }
    }

}

.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>