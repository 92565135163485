import { goodsSkuPage } from "@/api/goods/goods"
import { adList } from '@/api/website';
import http from "@/utils/http"
export default{
  data:()=> {
    return {
        currentPage: 1,
        cargoList: [],
        keyword:'',
        total: 0,
        loading: true,
        filters: {
          sort: "desc",
          platform_coupon_type: 0,
      },
      recommends:[],
        pageSize: 12,
            // 轮播图数据
            swiperList: [
            //   {
            //       id: 0,
            //       url: "https://img-qn-2.51miz.com/preview/muban/00/00/69/45/M-694554-5D0DBD82.jpg"
            //   },
            //   {
            //       id: 1,
            //       url: "https://5b0988e595225.cdn.sohucs.com/images/20190427/77f7cf89f1514248868f19b1522794e5.jpeg"
            //   },
            //   {
            //       id: 2,
            //       url: "https://img.ppt20.com/thumb2019/20190502/small_thumb_650_3291_big_2019050205796.jpg"
            //   },
            //   {
            //       id: 3,
            //       url: "https://p1.itc.cn/q_70/images03/20201120/0ff21f253ac24e2bb4e7934833fd7096.png"
            //   }
          ],
          input: "",
          num: 1,
          drugList: [
              {
                  id: 0,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 1,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 2,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 3,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 4,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 5,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              },
              {
                  id: 6,
                  url: "https://n.sinaimg.cn/sinakd202134s/672/w2048h1024/20210304/a8a1-kkxpczc5075128.jpg",
                  specialPrice: "5.90",
                  originalPrice: "8.50",
                  titleTxt: "依苏/马来酸依那普利片(ws)特价国家码",
                  leftDiscounts: "特价",
                  rightDiscounts: "国家码",
                  companyName: "扬子江药业集团江苏制药股份有限公司",
                  specification: "10mg*8片*2板",
                  period: "2024-09-30",
                  infoUnits: "盒",
                  infoNums: 300,
                  infoPack: 1,
                  infoInventory: 100,
                  infoTimes: "2023-11-06至2023-12-03"
              }
          ],
          success: 0,
          tag_id:1
    };
  },
  created(){
    this.getprefecture()
    this.getadList()
    this.getTagsList()
  },
  methods: {
    async getTagsList(){
       let res =await http({url:'/api/Goodstag/getTagsList'})
       if(res.code>=0){
        this.recommends=res.data
        this.loading=false
       }else{
        this.loading=false
       }
    },
    getadList(){
        adList({ keyword: 'QUKONG' }).then(res=>{
            this.loading=false
            this.swiperList=res.data.adv_list
        }).catch(err=>{
            this.loading=false
        })
    },
    handleChange(value) {
    },
    // 导航栏事件
    tonav(value,id) {
        this.success = value
        this.tag_id=id
        this.getprefecture()
    },
    // 搜索框事件
    inputs() {
        this.keyword=this.input
       this.getprefecture()
    },
    // 图片详情事件
    toinfo() {
    },
    // 添加购物车
    addCart() {
    },
    // 轮播图事件
    swiperInfo(item) {
        let url=JSON.parse(item.adv_url)
        this.$router.pushToTab(url.url)
    },
    getprefecture(){
        const currentArr=[]
        const params = {
            page: this.currentPage,
            page_size: this.pageSize,
            tag_id:this.tag_id,
            keyword: this.keyword,
            attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
        }
        goodsSkuPage(params || {})
            .then(res => {
                const { count, page_count, list } = res.data
                this.total=count
                // 加入需要加入购物车的数量1
                list.forEach(item => {
                    item.num = 1
                })
                this.cargoList = list
                this.loading = false
            })
            .catch(err => {
                this.loading = false
            })
       },
       handlePageSizeChange(size) {
        this.pageSize = size
        this.getprefecture()
    },
    handleCurrentPageChange(page) {
        this.currentPage = page
        this.getprefecture()
    },
}
}