import Config from "@/utils/config"
import {
    login
} from "@/api/auth/login"
import {
    adList
} from "@/api/website"
import {
    noticesList
} from "@/api/cms/notice"
import {
    floors,
    floatLayer,
    apiDefaultSearchWords
} from "@/api/pc"
import {
    mapGetters
} from "vuex"
import {
    couponList,
    receiveCoupon
} from "@/api/coupon"
import {
    goodsPage,
    timeList
} from "@/api/seckill"
import {
    goodsSkuPage
} from "@/api/goods/goods"
import CountDown from "vue2-countdown"
import http from '@/utils/http'
import {onTip} from "@/utils/tips"
export default {
    name: "index",
    components: {
        CountDown
    },
    data: () => {
        return {
            loadingAd: true,
            loadingFloor: true,
            adList: [],
            adLeftList: [],
            adRightList: [],
            noticeList: [],
            couponList: [],
            floorList: [],
            floatLayer: {
                is_show: false,
                link: {
                    url: ""
                }
            },
            indexFloatLayerNum: 0,
            shopApplyUrl: Config.baseUrl + "/shop/login/register",
            shopCenterUrl: Config.baseUrl + "/shop/index/index",
            storeUrl: Config.baseUrl + "/store",
            isSub: false,
            siteId: 0,
            listData: [],
            seckillTimeMachine: {
                currentTime: 0,
                startTime: 0,
                endTime: 0
            },
            seckillText: "距离结束",
            backgroundColor: "", // 顶部banner背景颜色
            // 悬浮搜索
            searchType: "goods",
            searchTypeText: "商品",
            keyword: "",
            defaultSearchWords: "",
            isShow: false,
            isShowFloors: false,
            goodsList: [],
            more_floor:{},
            isScroll:false
        }
    },
    watch: {
        searchType() {
            this.searchTypeText = this.searchType == "goods" ? "商品" : "店铺"
        }
        // backgroundColor(value){
        // 	console.log('value---',value)
        // }
    },
    created() {
        this.getmoreConfig()
        this.getAdList()
        this.getNotice()
        this.getBigAdList()
        this.getSmallAdList()
        if (this.city.id > 0) {
            this.getIndexGoodsList()
        } else {
            this.getFloors()
            this.getFloatLayer()
            if (this.addonIsExit && this.addonIsExit.seckill == 1) {
                this.getTimeList()
            }
            this.getCanReceiveCouponQuery()
        }

        // this.getFloatLayer()
        this.$store.dispatch("site/siteInfo") // 站点信息
        this.getDefaultSearchWords() // 默认搜索类型
        // if(this.addonIsExit && this.addonIsExit.seckill == 1){
        // 	this.getTimeList()
        // }
        // this.getCanReceiveCouponQuery()
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
    },
    computed: {
        ...mapGetters(["defaultHeadImage", "addonIsExit", "defaultGoodsImage", "member", "siteInfo", "cartCount", "city", "storeWithoutAuthority","token"]),
        optionLeft() {
            return {
                direction: 2,
                limitMoveNum: 1,
                autoPlay: this.listData.length >= 5?true :false
            }
        },
        storeToken() {
            return this.listData.length >= 5?true :false
        }

    },
    methods: {
     async   getmoreConfig(){
           let  res=await http({url:"/pc/api/pc/moreConfig"})
            const {code,data}=res
            if(code>=0){
               this.more_floor=data.more_floor
            }
        },
        /*
         * 解决秒杀组件复制出来的没有点击事件
         */
        clickProps(e) {
         
            if (!e.target.dataset.obj) {
                return
            }
            let item = JSON.parse(e.target.dataset.obj)
           
          
            let token = this.$store.state.member.token
            if (!token) return this.$router.push("/login")
            
            
            if(onTip(item.control.show_price,item.control.control_msg)) return 
            this.$router.pushToTab("/sku?activity=seckill&id=" + item.id)
        },
        countDownS_cb() {
        },
        countDownE_cb() {
            this.seckillText = "活动已结束"
        },
        getIndexGoodsList() {
            goodsSkuPage({})
                .then(res => {
                    this.goodsList = res.data.list
                })
                .catch(err => {
                })
        },
        getAdList() {
            adList({
                keyword: "NS_PC_INDEX"
            })
                .then(res => {
                    this.adList = res.data.adv_list
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                    }
                    this.backgroundColor = this.adList[0].background
                    this.loadingAd = false
                })
                .catch(err => {
                    this.loadingAd = false
                })
        },
        handleChange(curr, pre) {
            this.backgroundColor = this.adList[curr].background
        },
        /* 秒杀图片处理 */
        goodsImg(imgStr) {
            let imgs = imgStr.split(",")
            return imgs[0] ? this.$util.img(imgs[0], { size: "mid" }) : this.defaultGoodsImage
        },
        /**
         * 广告位大图
         */
        getBigAdList() {
            adList({
                keyword: "NS_PC_INDEX_MID_LEFT"
            })
                .then(res => {
                    this.adLeftList = res.data.adv_list
                    for (let i = 0; i < this.adLeftList.length; i++) {
                        if (this.adLeftList[i].adv_url) this.adLeftList[i].adv_url = JSON.parse(this.adLeftList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch(err => {
                    this.loadingAd = false
                })
        },
        /**
         * 广告位小图
         */
        getSmallAdList() {
            adList({
                keyword: "NS_PC_INDEX_MID_RIGHT"
            })
                .then(res => {
                    this.adRightList = res.data.adv_list
                    for (let i = 0; i < this.adRightList.length; i++) {
                        if (this.adRightList[i].adv_url) this.adRightList[i].adv_url = JSON.parse(this.adRightList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch(err => {
                    this.loadingAd = false
                })
        },
        getNotice() {
            noticesList({
                page: 1,
                page_size: 5,
                receiving_type: "web"
            })
                .then(res => {
                    this.noticeList = res.data.list
                })
                .catch(err => err)
        },
        //获取优惠券列表
        getCanReceiveCouponQuery() {
            couponList({
                activeName: "second",
                site_id: this.siteId,
                can_receive :1
            })
                .then(res => {
                    let data = res.data
                    this.couponList = []
                    if (data != null) {
                        for (let i = 0; i < data.list.length; i++) {
                            if (i < 5) {
                                this.couponList.push(data.list[i])
                            }
                        }
                        this.couponList.forEach(v => {
                            if(!v.useState){
                                v.useState = 0
                            }
                        })
                    }
                })
                .catch(err => {
                })
        },
        /**
         * 领取优惠券
         */
        receiveCoupon(item, index) {
            if (this.isSub) return
            this.isSub = true

            var data = {
                site_id: item.site_id,
                activeName: "second",
                platformcoupon_type_id: item.platformcoupon_type_id
            }

            receiveCoupon(data)
                .then(res => {
                    var data = res.data
                    let msg = res.message
                    if (res.code == 0) {
                        msg = "领取成功"
                        this.$message({
                            message: msg,
                            type: "success"
                        })
                    } else {
                        this.$message({
                            message: msg,
                            type: "warning"
                        })
                    }
                    let list = this.couponList
                    if (res.data.is_exist == 1) {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
                                list[i].useState = 1
                            }
                        }
                    } else {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
                                list[i].useState = 2
                            }
                        }
                    }

                    this.isSub = false
                    this.$forceUpdate()
                })
                .catch(err => {
                    if (err.message == "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                    this.isSub = false
                })
        },
        /**
         * 点击优惠券
         */
        couponTap(item, index) {
            if (item.useState == 0) this.receiveCoupon(item, index)
            else this.toGoodsList(item)
        },
        /**
         * 去购买
         */
        toGoodsList(item) {
            if (item.use_scenario != 1) {
                this.$router.push({
                    path: "/list",
                    query: {
                        platform_coupon_type: item.platformcoupon_type_id
                    }
                })
            } else {
                this.$router.push("/list")
            }
        },
        /**
         * 限时秒杀
         */
        getTimeList() {
            timeList()
                .then(res => {
                    if (res.code == 0 && res.data) {
                        let time = new Date(res.timestamp * 1000)
                        let currentTimes = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()
                        res.data.list.forEach((v, k) => {
                            if (v.seckill_start_time <= currentTimes && currentTimes < v.seckill_end_time) {
                                let seckillId = v.id
                                this.getGoodsList(seckillId)

                                let endTime = parseInt(time.getTime() / 1000) + (v.seckill_end_time - currentTimes)
                                this.seckillTimeMachine = {
                                    currentTime: res.timestamp,
                                    startTime: res.timestamp,
                                    endTime: endTime
                                }
                            }
                        })
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        /**
         * 秒杀商品
         */
        getGoodsList(id) {
            goodsPage({
                page_size: 0,
                seckill_id: id,
                site_id: this.siteId
            })
                .then(res => {
                    if (res.code == 0 && res.data.list) {
                        this.listData = res.data.list
                    }
                })
                .catch(err => {
                })
        },
        /**
         * 图片加载失败
         */
        imageError(e) {
            // this.listData[index].sku_image = this.defaultGoodsImage
            e.target.src= this.defaultGoodsImage
           
        },
        /**
         * 图片加载失败
         */
        adLeftImageError(e) {
            e.target.src= this.defaultGoodsImage
            // this.adLeftList[index].adv_image = this.defaultGoodsImage
           
        },
        /**
         * 图片加载失败
         */
        adRightImageError(index) {
            this.adRightList[index].adv_image = this.defaultGoodsImage
        },
        getFloors() {
            floors()
                .then(res => {
                    res.data.forEach(item => {
                        let styleList = ["floor-style-1", "floor-style-2", "floor-style-4", "floor-style-5", "floor-style-6","floor-style-7","floor-style-8","floor-style-9"]
                        if (styleList.includes(item.block_name)) {
                            item.value.goodsList.value.list.forEach(v => {
                                v.num = 1
                            })
                        } else {
                            item.value.rightGoodsList.value.list.forEach(v => {
                                v.num = 1
                            })
                            item.value.bottomGoodsList.value.list.forEach(v => {
                                v.num = 1
                            })
                        }
                    })
                    this.floorList = res.data
                    this.floorList.forEach(ele => {
                        const has = this.floor_ids.findIndex(item => item.id === ele.id)
                        if (has < 0) {
                            let floorObj = {
                                id: ele.block_name,
                                name: ele.title,
                                active: 0
                            }
                            this.floor_ids.push(floorObj)
                        }
                    })
                    this.$nextTick(() => {
                        this.floor_ids_ActiveSection()
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getFloatLayer() {
            floatLayer()
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.floatLayer = res.data
                        this.floatLayer.link = JSON.parse(this.floatLayer.url)
                        // 弹框形式，首次弹出 1，每次弹出 0

                        if (!this.floatLayer.img_url) return

                        if (parseInt(this.floatLayer.number) == 1) {
                            var index_popwindow_count = this.$store.state.app.indexFloatLayerNum
                            if (index_popwindow_count == "" || index_popwindow_count == 1) {
                                this.floatLayer.is_show = true
                                this.$store.commit("app/SET_FLOAT_LAYER", 1)
                            }
                        } else if (parseInt(this.floatLayer.number) == 0) {
                            this.floatLayer.is_show = true
                            this.$store.commit("app/SET_FLOAT_LAYER", 0)
                        }
                    }
                })
                .catch(err => err)
        },
        closeFloat() {
            this.floatLayer.is_show = false
            this.$forceUpdate()
            this.$store.commit("app/SET_FLOAT_LAYER", -1)
        },
        // 监听滚动条
        handleScroll() {
            var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

            if (scrollTop >= 680) {
                this.isShow = true
            } else {
                this.isShow = false
            }

            if (scrollTop >= 400) {
                this.isShowFloors = true
            } else {
                this.isShowFloors = false
            }
        },
        destroyed() {
            // 离开该页面需要移除这个监听的事件，不然会报错
            window.removeEventListener("scroll", this.handleScroll)
        },
        /**
         * 默认搜索类型（goods/shop）
         */
        getDefaultSearchWords() {
            apiDefaultSearchWords({}).then(res => {
                if (res.code == 0 && res.data.words) {
                    this.defaultSearchWords = res.data.words
                }
            })
        },
        handleCommand(command) {
            this.searchType = command
        },
        search() {
            if (this.searchType == "goods") this.$router.push({
                path: "/list",
                query: {
                    keyword: this.keyword
                }
            })
            else this.$router.push({
                path: "/street",
                query: {
                    keyword: this.keyword
                }
            })
        }
    }
}
