<template>
    <div class="index-page">
        <!-- 首页固定区 -->
        <div class="index-wrap" :style="{ background: backgroundColor }">
            <div class="banner">
                <el-carousel height="400px" arrow="hover" v-loading="loadingAd" @change="handleChange">
                    <el-carousel-item v-for="item in adList" :key="item.adv_id">
                        <el-image :src="$img(item.adv_image)" fit="cover"
                            @click="$router.pushToTab(item.adv_url.url)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="bannerInfo">
                <div class="index">
                    <div class="news">
                        <div class="login-wrap">
                            <div class="avtar">
                                <router-link to="/member/info">
                                    <img v-if="member.headimg" :src="$img(member.headimg)"
                                        @error="member.headimg = defaultHeadImage" />
                                    <img v-else :src="$img(defaultHeadImage)" />
                                </router-link>
                            </div>
                            <p style="text-align: center">欢迎来貳美药械城</p>
                            <div class="btn" v-if="!member">
                                <router-link to="/login" class="login">登录</router-link>
                                <router-link to="/register" class="register">注册</router-link>
                            </div>
                            <div class="memeber-name" v-else>{{ member.nickname }}</div>
                        </div>

                        <!-- 商城服务 -->
                        <div class="server-wrap">
                            <div class="item-wrap">
                                <div class="item">
                                    <router-link to="/member/order_list">
                                        <a target="_blank">
                                            <i class="iconfont icon-gerenzhongxin" />
                                            <span>个人中心</span>
                                        </a>
                                    </router-link>
                                </div>
                                <div class="item">
                                    <router-link to="/member/order_list">
                                        <a target="_blank">
                                            <i class="iconfont icon-dingdanxiangqing-dingdanbianhao" />
                                            <span>我的订单</span>
                                        </a>
                                    </router-link>
                                </div>
                                <div class="item">
                                    <a :href="shopApplyUrl" target="_blank">
                                        <i class="iconfont iconshang" />
                                        <span>招商入驻</span>
                                    </a>
                                </div>
                                <div class="item">
                                    <a :href="shopCenterUrl" target="_blank">
                                        <i class="iconfont iconshangjiazhongxin" />
                                        <span>商家中心</span>
                                    </a>
                                </div>
                                <div class="item" v-if="addonIsExit.store == 1">
                                    <a :href="storeUrl" target="_blank">
                                        <i class="iconfont iconshangjiazhongxin-" />
                                        <span>门店管理</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="xian"></div>
                        <!-- 商城快讯 -->
                        <div class="notice-wrap">
                            <div class="notice">
                                <div class="notice-name">商城资讯</div>
                                <router-link to="/cms/notice" class="notice-more">
                                    更多
                                    <i class="iconfont iconarrow-right"></i>
                                </router-link>
                            </div>
                            <div v-for="(item, index) in noticeList" :key="item.id" class="item" v-if="index < 1">
                                <router-link :to="'/cms/notice-' + item.id" target="_blank" tag="a">
                                    <div class="notice-title">{{ item.title }}</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- 领券中心 -->
            <div class="content-div" v-if="addonIsExit.coupon == 1 && couponList.length > 0 && (city.id == 0 || !city)">
                <div class="coupon">
                    <div class="coupon-title">
                        <p class="coupon-font">领券中心</p>
                        <p class="coupon-en">coupon</p>
                        <p class="coupon-more" @click="$router.push('/coupon')">
                            <span>更多</span>
                            <i class="iconfont iconarrow-right"></i>
                        </p>
                    </div>
                    <ul class="coupon-list">
                        <li v-for="(item, index) in couponList" :key="index">
                            <p class="coupon-price ns-text-color">
                                ￥
                                <span>{{ item.money }}</span>
                            </p>
                            <p class="cunpon-name ns-text-color">
                                <span>{{ item.platformcoupon_name }}</span>
                            </p>
                            <p class="coupon-term">
                                <span>满{{ item.at_least }}可用</span>
                                <span class="coupon-receive ns-text-color" @click="couponTap(item, index)">
                                    <span v-if="item.useState == 0">立即领取</span>
                                    <span v-else>去使用</span>
                                    <i class="iconfont iconarrow-right"></i>
                                </span>
                            </p>
                        </li>
                    </ul>
                </div>
            </div>

            <!-- 广告 -->
            <div class="content-div" v-if="adLeftList.length > 0 || adRightList.length > 0">
                <div class="ad-wrap">
                    <div class="ad-big">
                        <div class="ad-big-img" v-for="(item, index) in adLeftList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adLeftImageError"
                                @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                    <div class="ad-small">
                        <div class="ad-small-img" v-for="(item, index) in adRightList" :key="index">
                            <el-image :src="$img(item.adv_image)" fit="cover" @error="adRightImageError"
                                @click="$router.pushToTab(item.adv_url.url)"></el-image>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 限时秒杀 -->
            <div class="content-div" v-if="addonIsExit.seckill == 1 && listData.length > 0 && (city.id == 0 || !city)">
                <div id="seckill-floor" class="seckill-wrap">
                    <div class="seckill-time">
                        <div class="seckill-time-left">
                            <i class="iconfont iconmiaosha1 ns-text-color"></i>
                            <span class="seckill-time-title ns-text-color">限时秒杀</span>
                            <span>{{ seckillText }}</span>
                            <count-down class="count-down" v-on:start_callback="countDownS_cb()"
                                v-on:end_callback="countDownE_cb()" :currentTime="seckillTimeMachine.currentTime"
                                :startTime="seckillTimeMachine.startTime" :endTime="seckillTimeMachine.endTime"
                                :dayTxt="'：'" :hourTxt="'：'" :minutesTxt="'：'" :secondsTxt="''"></count-down>
                        </div>
                        <div class="seckill-time-right" @click="$router.push('/promotion/seckill')">
                            <span>更多商品</span>
                            <i class="iconfont iconarrow-right"></i>
                        </div>
                    </div>
                    <div class="seckill-content" @click="clickProps($event)">
                        <!-- <div class="seckill-content"> -->
                        <vue-seamless-scroll ref="listData-scroll" :data="listData" :class-option="optionLeft" class="seamless-warp2">
                            <ul class="item" :style="{ width: 250 * listData.length + 'px' }">
                                <li v-for="(item, index) in listData" :key="index">
                                    <div class="seckill-goods" :data-obj="JSON.stringify(item)">
                                        <div class="seckill-goods-img" :data-obj="JSON.stringify(item)">
                                            <img :src="goodsImg(item.goods_image)" @error="imageError"
                                                :data-obj="JSON.stringify(item)" />
                                        </div>
                                        <p :data-obj="JSON.stringify(item)">{{ item.goods_name }}</p>
                                        <div class="seckill-price-wrap" :data-obj="JSON.stringify(item)">
                                            <p class="ns-text-color" :data-obj="JSON.stringify(item)">
                                                ￥
                                                <template v-if="token">
                                                    <span :data-obj="JSON.stringify(item)">
                                                        {{ item.control?.show_price ?
                                                            Number(item.seckill_price) : storeWithoutAuthority
                                                        }}
                                                    </span>
                                                </template>

                                                <template v-else>
                                                    <span>登录可见</span>
                                                </template>
                                            </p>

                                            <template v-if="token">
                                                <p class="primary-price" :data-obj="JSON.stringify(item)">
                                                    ￥{{ item.control?.show_price ? Number(item.price) :
                                                        storeWithoutAuthority
                                                    }}
                                                </p>
                                            </template>

                                            <template v-else>
                                                <p class="primary-price">￥登录可见</p>
                                            </template>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </vue-seamless-scroll>
                    </div>
                </div>
            </div>

            <!-- 楼层区 -->
            <div class="content-div" v-if="city.id == 0 || !city">
                <div class="floor">
                    <div v-for="(item, index) in floorList" :key="index" class="floor_item">
                        <floor-style-1 v-if="item.block_name == 'floor-style-1'" :data="item" :c_index="index" />
                        <floor-style-2 v-if="item.block_name == 'floor-style-2'" :data="item" :c_index="index" />
                        <floor-style-3 v-if="item.block_name == 'floor-style-3'" :data="item" :c_index="index" />
                        <floor-style-4 v-if="item.block_name == 'floor-style-8'" :data="item" :c_index="index" />
                        <floor-style-5 v-if="item.block_name == 'floor-style-9'" :data="item" :c_index="index" />
                        <floorStyleErmeiDesign1 style="margin-bottom: 20px" v-if="item.block_name == 'floor-style-4'"
                            :data="item" :c_index="index" />
                        <floorStyleErmeiDesign2 style="margin-bottom: 20px" v-if="item.block_name == 'floor-style-5'"
                            :data="item" :c_index="index" />
                        <floorStyleErmeiDesign3 style="margin-bottom: 20px" v-if="item.block_name == 'floor-style-6'"
                            :data="item" :c_index="index" />
                        <floorStyleErmeiDesign4 style="margin-bottom: 20px" v-if="item.block_name == 'floor-style-7'"
                            :data="item" :c_index="index" />
                    </div>
                </div>
            </div>

            <!-- 分站商品列表 -->
            <div>
                <!-- <div class="more">
                    <router-link to="/list">
                        <span>更多</span>
                        <i class="iconfont iconarrow-right"></i>
                    </router-link>
                </div>-->
                <div class="goods-info" v-if="goodsList.length">
                    <div class="item" v-for="(item, index) in goodsList" :key="item.goods_id"
                        @click="$router.pushToTab({ path: '/sku?id=' + item.sku_id })">
                        <img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @error="imageError" />
                        <div class="price-wrap">
                            <div class="price">
                                <p>￥</p>
                                {{ item.discount_price }}
                            </div>
                            <div class="market-price">￥{{ item.market_price }}</div>
                        </div>
                        <div class="goods-name">{{ item.goods_name }}</div>
                        <div class="sale-num">
                            <p>{{ item.sale_num || 0 }}</p>人付款
                        </div>
                        <div class="saling">
                            <div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
                            <div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 浮层区 -->
            <div class="floatLayer-wrap" v-if="floatLayer.is_show && city.id == 0">
                <div class="floatLayer">
                    <div class="img-wrap">
                        <img :src="$img(floatLayer.img_url)" @click="$router.pushToTab(floatLayer.link.url)" />
                    </div>
                    <i class="el-icon-circle-close" @click="closeFloat"></i>
                </div>
            </div>

            <!-- 悬浮搜索 -->
            <div class="fixed-box" :style="{ display: isShow ? 'block' : 'none' }">
                <div class="header-search">
                    <el-row>
                        <el-col :span="6">
                            <router-link to="/" class="logo-wrap">
                                <img :src="$img(siteInfo.logo)" />
                            </router-link>
                        </el-col>
                        <el-col :span="13">
                            <div class="in-sousuo">
                                <div class="sousuo-box">
                                    <el-dropdown @command="handleCommand" trigger="click">
                                        <span class="el-dropdown-link">
                                            {{ searchTypeText }}
                                            <i class="el-icon-arrow-down"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <el-dropdown-item command="goods">商品</el-dropdown-item>
                                            <el-dropdown-item command="shop">店铺</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                    <input type="text" :placeholder="defaultSearchWords" v-model="keyword"
                                        @keyup.enter="search" maxlength="50" />
                                    <el-button type="primary" size="small" @click="search">搜索</el-button>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="2">
                            <div class="cart-wrap">
                                <router-link class="cart" to="/cart">
                                    <span>我的购物车</span>
                                    <el-badge v-if="cartCount" :value="cartCount" type="primary">
                                        <i class="iconfont icongouwuche"></i>
                                    </el-badge>
                                    <i v-else class="iconfont icongouwuche"></i>
                                </router-link>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        <div class="floors—going" :style="{ display: isShowFloors ? 'block' : 'none' }">
            <div class="floors-top-img-2">
                <img :src="more_floor.adv_image" />
                <ul>
                    <!-- :class="{ actives: index === activeSectionIndex }" -->
                    <li @click="goto(item, index)" v-for="(item, index) in floor_ids" :key="index">
                        <a :style="index === activeSectionIndex ? { color: more_floor.checked_color } : { color: more_floor.common_color }"
                            :class="item.id + index">{{ item.name }}</a>
                    </li>
                    <li class="back-top" @click="updateActive">
                        <a :style="{ color: more_floor.common_color }" data-scroll href="#header-top">返回顶部</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import floorStyle1 from "./components/floor-style-1.vue"
import floorStyle2 from "./components/floor-style-2.vue"
import floorStyle3 from "./components/floor-style-3.vue"
import floorStyle4 from "./components/foor-style-4.vue"
import floorStyle5 from "./components/foor-style-5.vue"
import floorStyleErmeiDesign1 from "./components/floor-style-ermei-design-1.vue"
import floorStyleErmeiDesign2 from "./components/floor-style-ermei-design-2.vue"
import floorStyleErmeiDesign3 from "./components/floor-style-ermei-design-3.vue"
import floorStyleErmeiDesign4 from "./components/floor-style-ermei-design-4.vue"
import index from "./_index/index.js"
import SmoothScroll from "smooth-scroll"
import debounce from "lodash/debounce"

let scroll = new SmoothScroll('a[href*="#"]', {
    speed: 500
})
export default {
    name: "index",
    mixins: [index],
    components: {
        floorStyle1,
        floorStyle2,
        floorStyle3,
        floorStyleErmeiDesign1,
        floorStyleErmeiDesign2,
        floorStyleErmeiDesign3,
        floorStyleErmeiDesign4,
        floorStyle4,
        floorStyle5
    },
    data() {
        // { id: "seckill-floor", name: "秒杀专区", active: 0 },
        return {
            floor_ids: [],
            activeSectionIndex: 0,
            scrollFlag: false
        }
    },
    mounted() { },
    destroy() {
        // 必须移除监听器，不然当该vue组件被销毁了，监听器还在就会出错
        window.removeEventListener("scroll", this.updateActiveSection)
    },
    methods: {
        updateActive() {
            setTimeout(() => {
                this.activeSectionIndex = 0
            }, 1000)
        },
        updateActiveSection() {
            let floorsDom = document.querySelectorAll(".floor-wsw")
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
            floorsDom.forEach((ele, index) => {
                if (scrollTop >= ele.offsetTop - 100) {
                    this.activeSectionIndex = index
                }
            })
        },
        floor_ids_ActiveSection() {
            // { id: "floor-style-erMei-design-1", name: "测试楼层一", active: 0 },
            //获取每个模块的参数，因为模块在整页渲染完毕后，offsetTop和scrollHeight值已经是确立的，所以在滚动监听之前可以先获取，暂存在menu中。
            this.floor_ids.forEach((item, index) => {
                let dom = document.getElementById(item.id + index)
                if (dom) {
                    item.offsetTop = dom.offsetTop
                    item.scrollHeight = dom.scrollHeight
                }
            })
            // 监听滚动事件
            window.addEventListener("scroll", debounce(this.updateActiveSectionThrottled, 50))
        },
        // 节流函数

        updateActiveSectionThrottled() {
            // 设置一个时间阈值，例如每200毫秒执行一次
            const throttleInterval = 200

            // 获取当前时间戳
            const now = Date.now()

            // 检查是否距离上一次执行已经过去足够的时间
            if (!this.lastScrollTime || now - this.lastScrollTime >= throttleInterval) {
                this.updateActiveSection()
                // 更新上一次执行的时间戳
                this.lastScrollTime = now
            }
        },
        goto(item, index) {
            // if (index === 0) index = ""
            let anchor = document.querySelector("#" + item.id + index)
            let toggle = document.querySelector("." + item.id + index)
            let options = { speed: 500, easing: "easeOutCubic", offset: 200 }
            scroll.animateScroll(anchor, toggle, options)
        }
    }
}
</script>

<style>
.index-page .index-wrap .banner .el-carousel__arrow--right {
    right: 50px;
}
</style>

<style lang="scss" scoped>
@import "./_index/index.scss";
</style>

<style lang="scss" scoped>
.index-page {
    position: relative;
}

.floors—going {
    position: fixed;
    z-index: 100;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    border-radius: 4px;
    padding-top: 10px;

    .floors-top-img-2 {
        position: relative;
        margin-top: -24px;
        overflow: hidden;
        width: 150px;
        min-height: 260px;
        text-align: center;
    }

    ul {
        position: absolute;
        top: 104px;
        left: 40px;
    }

    ul>li {
        box-sizing: border-box;
        height: 31px;
        line-height: 32px;
        padding: 0 10px;
        font-size: 12px;
        cursor: pointer;

        a {
            color: #e83a4f;
        }

        //&:hover {
        //    background-color: $base-color-em;
        //    cursor: pointer;
        //
        //    a {
        //        color: #FFFFFF;
        //    }
        //}
    }

    // .actives {
    //     color: $base-color-em;
    //     a {
    //         background-color: unset !important;
    //         color: #ebab34;
    //     }
    // }

    .back-top {
        a {
            color: $base-color-em;
            cursor: pointer;
        }

        border-top: 1px solid hsla(0, 0%, 47.1%, 0.3);
    }
}

.count-down {
    span {
        display: inline-block;
        width: 22px;
        height: 22px;
        line-height: 22px;
        text-align: center;
        background: #383838;
        color: #ffffff;
        border-radius: 2px;
    }
}

.more {
    width: 1200px;
    margin: auto;
    display: flex;
    justify-content: flex-end;

    a {
        color: #ff547b;
    }
}

.goods-info {
    width: 1200px;
    margin: 5px auto 40px;
    display: flex;
    flex-wrap: wrap;

    .item {
        width: 202px;
        margin: 10px 20px 0 0;
        border: 1px solid #eeeeee;
        padding: 10px;
        position: relative;

        &:nth-child(5n) {
            margin-right: initial !important;
        }

        &:hover {
            border: 1px solid $base-color;
        }

        .img-wrap {
            width: 198px;
            height: 198px;
            cursor: pointer;
        }

        .goods-name {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            &:hover {
                color: $base-color;
            }
        }

        .price-wrap {
            display: flex;
            align-items: center;

            .price {
                display: flex;
                color: $base-color;
                font-size: $ns-font-size-lg;

                p {
                    font-size: $ns-font-size-base;
                    display: flex;
                    align-items: flex-end;
                }
            }

            .market-price {
                color: #838383;
                text-decoration: line-through;
                margin-left: 10px;
            }
        }

        .sale-num {
            display: flex;
            color: #838383;

            p {
                color: #4759a8;
            }
        }

        .saling {
            display: flex;
            font-size: $ns-font-size-sm;
            line-height: 1;

            .free-shipping {
                background: $base-color;
                color: #ffffff;
                padding: 3px 4px;
                border-radius: 2px;
                margin-right: 5px;
            }

            .promotion-type {
                color: $base-color;
                border: 1px solid $base-color;
                display: flex;
                align-items: center;
                padding: 1px;
            }
        }
    }
}
</style>
