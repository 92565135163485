var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"floor-style-3 floor-wsw",attrs:{"id":_vm.data.block_name+_vm.c_index}},[_c('div',{staticClass:"item-wrap"},[_c('div',{staticClass:"head-wrap"},[(_vm.data.value.title.value.text)?_c('div',{staticClass:"title-name"},[_c('span',{style:({ backgroundColor: _vm.data.value.title.value.color })}),_c('h2',{style:({ color: _vm.data.value.title.value.color }),on:{"click":function($event){return _vm.$router.pushToTab(_vm.data.value.title.value.link.url)}}},[_vm._v(_vm._s(_vm.data.value.title.value.text))])]):_vm._e(),_c('div',{staticClass:"category-wrap"},_vm._l((_vm.data.value.categoryList.value.list),function(item,index){return _c('li',{key:index},[_c('router-link',{attrs:{"target":"_blank","to":{ path: '/list', query: { category_id: item.category_id, level: item.level } }}},[_vm._v(_vm._s(item.category_name))])],1)}),0)]),_c('div',{staticClass:"body-wrap"},[_c('div',{staticClass:"left-img-wrap"},[(_vm.data.value.leftImg.value.url)?_c('img',{attrs:{"src":_vm.$img(_vm.data.value.leftImg.value.url)},on:{"click":function($event){return _vm.$router.pushToTab(_vm.data.value.leftImg.value.link.url)}}}):_vm._e()]),_c('ul',{staticClass:"right-goods-wrap"},_vm._l((_vm.data.value.rightGoodsList.value.list),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.goSku(item)}}},[_c('h4',[_vm._v(_vm._s(item.goods_name))]),_c('p',{staticClass:"ns-text-color"},[_vm._v(_vm._s(item.introduction))]),_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"alt":"商品图片","src":_vm.$img(item.sku_image)},on:{"error":_vm.imageErrorRight}})]),_c('div',{staticClass:"cart-layout"},[_c('div',{staticStyle:{"width":"100px"}},[_c('shareCounter',{attrs:{"number":item.min_buy,"goodsSkuDetail":{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    },"size":"mini"},on:{"upnumber":_vm.upnumber}})],1),_c('div',{staticClass:"cart-add",on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v(" 加入购物车 ")])])])}),0),_c('ul',{staticClass:"bottom-goods-wrap"},_vm._l((_vm.data.value.bottomGoodsList.value.list),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.goSku(item)}}},[_c('div',{staticClass:"info-wrap"},[_c('h4',[_vm._v(_vm._s(item.goods_name))]),_c('p',{staticClass:"ns-text-color"},[_vm._v(_vm._s(item.introduction))])]),_c('div',{staticClass:"img-wrap"},[_c('img',{attrs:{"alt":"商品图片","src":_vm.$img(item.sku_image)},on:{"error":function($event){return _vm.imageErrorBottom(index)}}})]),_c('div',{staticClass:"cart-layout"},[_c('div',{staticStyle:{"width":"100px"}},[_c('shareCounter',{attrs:{"number":item.min_buy,"goodsSkuDetail":{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    },"size":"mini"},on:{"upnumber":_vm.upnumber}})],1),(item.package_num<item.stock)?_c('div',{staticClass:"cart-add",on:{"click":function($event){$event.stopPropagation();return _vm.joinCart(item)}}},[_vm._v(" 加入购物车 ")]):_vm._e()])])}),0),_c('ul',{staticClass:"brand-wrap"},_vm._l((_vm.data.value.brandList.value.list),function(item,index){return _c('li',{key:index,on:{"click":function($event){return _vm.$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })}}},[(item.image_url)?_c('img',{attrs:{"alt":"品牌图片","src":_vm.$img(item.image_url)}}):_vm._e()])}),0)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }