<template>
    <div class="basic-info-wrap">
        <h1>{{ goodsSkuDetail.sku_name }}</h1>
        <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>
        <!-- begin -->
        <div class="discount-banner ns-bg-color" v-if="seckillTimeMachine.currentTime">
            <div class="activity-name"><i class="discount-icon iconfont iconicon_naozhong"></i><span>限时秒杀</span>
            </div>
            <div class="surplus-time">
                <span>{{ seckillText }}</span>
                <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
                    :currentTime="seckillTimeMachine.currentTime" :startTime="seckillTimeMachine.startTime"
                    :endTime="seckillTimeMachine.endTime" :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'"
                    :secondsTxt="'秒'"></count-down>
            </div>
        </div>

        <div class="item-block">
            <div class="promotion-price">

                <dl class="item-line">
                    <dt class="ns-text-color-gray">{{ activityMapItem.priceText }}</dt>
                    <dd>
                        <em class="yuan ns-text-color">¥</em>
                        <span class="price ns-text-color" v-if="token">{{ goodsSkuDetail.control &&
                            goodsSkuDetail.control.show_price ? Number(goodsSkuDetail[activityMapItem.price.discount_price]) :
                            storeWithoutAuthority }}<span style="font-size:13px">/{{goodsSkuDetail.unit}}</span></span>
                        <span class="price ns-text-color" v-else>登录可见</span>
                    </dd>
                </dl>
                <dl class="item-line" v-if="goodsSkuDetail[activityMapItem.price.market_price]>0">
                    <dt class="ns-text-color-gray">原价</dt>
                    <dd>
                        <em class="market-yuan">¥</em>
                        <span class="market-price" v-if="token">{{ goodsSkuDetail.control &&
                            goodsSkuDetail.control.show_price ? Number(goodsSkuDetail[activityMapItem.price.market_price]) :
                            storeWithoutAuthority }}</span>
                        <span class="market-price" v-else>登录可见</span>
                    </dd>
                </dl>


                <div class="statistical">
                    <ul>
                        <li>
                            <p>累计评价</p>
                            <span>{{ goodsSkuDetail.evaluate }}</span>
                        </li>
                        <li>
                            <p>累计销量</p>
                            <span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
                        </li>
                    </ul>
                </div>

                <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
                    <dt>运费</dt>
                    <dd>
                        <i class="i-activity-flag ns-text-color ns-border-color"
                            v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
                        <i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
                    </dd>
                </dl>
            </div>
        </div>
        <!-- end -->

        <dl class="item-line service">
            <dt>服务</dt>
            <dd>
                <span>
                    由
                    <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name
                    }}</router-link>
                    发货并提供售后服务
                </span>
            </dd>
        </dl>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec">
                <dt>商品规格</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%" v-if="goodsSkuDetail.unit">
                <dt>包装单位</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.unit }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope">
                <dt>经营类型</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy">
                <dt>中包装</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory">
                <dt>生产厂家</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package">
                <dt>件装量</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code">
                <dt>国家码</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date">
                <dt>效期</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date }}
                    </span>
                </dd>
            </dl>
        </div>
       <div style="display: flex">
        <dl class="item-line service"  style="width: 60%" v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number">
            <dt>批准文号</dt>
            <dd>
                <span>
                    {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number }}
                </span>
            </dd>
        </dl>
        <dl class="item-line service" style="width: 40%" v-if="goodsSkuDetail.reference_price">
              <dt>建议零售价</dt>
               <dd>
                {{goodsSkuDetail.reference_price}}
               </dd>
             </dl>
       </div>
        <hr class="divider" />
        <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
            <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
                <dt>{{ item.spec_name }}</dt>
                <dd>
                    <ul>
                        <li v-for="(item_value, index_value) in item.value" :key="index_value">
                            <div :class="{
                                'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                            }" @click="changeSpec(item_value.sku_id, item_value.spec_id)">
                                <img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
                                <span>{{ item_value.spec_value_name }}</span>
                                <i class="iconfont iconduigou1 ns-text-color"></i>
                            </div>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>

        <div class="buy-number" v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 &&goodsSkuDetail.stock > 0">
            <dl class="item-line">
                <dt>数量</dt>
                <dd style="display: flex;">
                    <div>
                        <!-- <el-input-number v-model="number"
                            :step="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy > 0 ? Number(goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy) : 1"
                            size="mini"
                            :min="min_buy ? min_buy : (goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy > 0 ? Number(goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy) : 1)"
                            :max="goodsSkuDetail.stock" @change="keyInput($event)" @blur="deve($event)">
                        </el-input-number> -->
                        <shareCounter :number="number" :goodsSkuDetail="goodsSkuDetail" size="mini" @upnumber="upnumber"></shareCounter>
                    </div>
                    <span class="inventory" v-if="goodsSkuDetail.package_num<goodsSkuDetail.stock">库存{{ goodsSkuDetail.stock }}</span>
                    <span
                        style="display: none; margin-left: 20px; width: 179px;height: 13px;font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #FD274A;">由官方旗舰店发货并提供售后服务</span>
                </dd>
            </dl>
        </div>

        <dl class="item-line buy-btn">
            <dt></dt>
            <dd>
                <template
                    v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.stock > 0">
                    <!-- plain  鼠标划入变换效果 -->
                    <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.is_member_buy">
                        <el-button type="primary" v-if="activityType === 'sku'" @click="buyNow">立即购买</el-button>
                        <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_virtual == 0"
                            @click="joinCart" style="background: #FF860F;">加入购物车</el-button>
                    </template>
                    <template v-else>
                        <el-button type="infor" :disabled="true">{{ storeWithoutAuthority }}</el-button>
                    </template>
                </template>
                <template v-else>
                    <el-button type="info" plain disabled>库存不足</el-button>
                </template>
                <!--					<div href="javascript:;" class="go-phone">-->
                <!--						<span style="color: #666666;margin-right: 10px;">手机购买</span>-->
                <!--						<img src="@/assets/images/goods/qrcode.png" /> -->
                <!--						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>-->
                <!--					</div>-->
            </dd>
        </dl>

        <!-- 店铺信息 -->
        <dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 ||
            shopInfo.shop_qtian == 1 ||
            shopInfo.shop_zhping == 1 ||
            shopInfo.shop_erxiaoshi == 1 ||
            shopInfo.shop_tuihuo == 1 ||
            shopInfo.shop_shiyong == 1 ||
            shopInfo.shop_shiti == 1 ||
            shopInfo.shop_xiaoxie == 1
            ">
            <dt>商家服务</dt>
            <div>
                <dd v-if="shopInfo.shop_baozh == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="保证服务">保证服务</span>
                </dd>
                <dd v-if="shopInfo.shop_qtian == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
                </dd>
                <dd v-if="shopInfo.shop_zhping == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
                </dd>
                <dd v-if="shopInfo.shop_erxiaoshi == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
                </dd>
                <dd v-if="shopInfo.shop_tuihuo == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
                </dd>
                <dd v-if="shopInfo.shop_shiyong == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="试用中心">试用中心</span>
                </dd>
                <dd v-if="shopInfo.shop_shiti == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="实体验证">实体验证</span>
                </dd>
                <dd v-if="shopInfo.shop_xiaoxie == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
import mixin from './common/mixin';
import CountDown from "vue2-countdown"

export default {
    mixins: [mixin],
    data() {
        return {
            seckillText: "距离结束仅剩",
			seckillTimeMachine: {
				currentTime: 0,
				startTime: 0,
				endTime: 0
			},
        }
    },
    components: {
		CountDown
	},
    methods: {
        init() {
            //秒杀倒计时
            let time = new Date(this.detailRes.timestamp * 1000)
            let currentTime = time.getHours() * 60 * 60 + time.getMinutes() * 60 + time.getSeconds()
            console.log(JSON.parse(JSON.stringify(this.detailRes)));
            if (this.goodsSkuDetail.seckill_start_time <= currentTime && currentTime < this.goodsSkuDetail.seckill_end_time) {
                let endTime = parseInt(time.getTime() / 1000) + (this.goodsSkuDetail.seckill_end_time - currentTime)
                this.seckillTimeMachine = {
                    currentTime: this.detailRes.timestamp,
                    startTime: this.detailRes.timestamp,
                    endTime: endTime
                }
            } else if (this.goodsSkuDetail.seckill_start_time > currentTime && currentTime < this.goodsSkuDetail.seckill_end_time) {
                this.$message({
                    message: '限时秒杀活动还未开始',
                    type: 'warning',
                    duration: 2000,
                    onClose: () => {
                        this.$router.push('/sku?id=' + this.goodsSkuDetail.sku_id)
                    }
                });
            } else if (currentTime < this.goodsSkuDetail.seckill_start_time && currentTime > this.goodsSkuDetail.seckill_end_time) {
                this.$message({
                    message: '限时秒杀活动已结束',
                    type: 'warning',
                    duration: 2000,
                    onClose: () => {
                        this.$router.push('/sku?id=' + this.goodsSkuDetail.sku_id)
                    }
                });
            }
        },
        countDownS_cb() {},
		countDownE_cb() {
			this.discountText = "活动已结束"
		},
    },
    watch: {
        detailRes() {
            this.init();
        }
    }
}
</script>