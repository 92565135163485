<template>
  <div class="feslist">
    <div class="feslist-content">
        <div class="first">
          <div  class="first-b">
            <!-- <img src="https://h2.appsimg.com/b.appsimg.com/upload/momin/2024/02/06/47/1707197827830_2000x450_90.jpg!75.webp"> -->
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          </div>
        </div>
        <div class="first" style="background: url('https://h2.appsimg.com/b.appsimg.com/upload/momin/2024/02/06/63/1707198351968_2000x450_90.jpg!75.webp');   background-position: center;  background-size: 100% 100%;">
          <div  class="first-b">
            <!-- <img src="https://h2.appsimg.com/b.appsimg.com/upload/momin/2024/02/06/47/1707197827830_2000x450_90.jpg!75.webp"> -->
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          <div class="recommend">
            <!-- <div class="l">
               <p class="recom-t1">3折狂抢</p>
               <p class="recom-t2">大牌折扣</p>
            </div> -->
            <div class="r">
              <el-image src="https://cloud.2myxc.com/upload/1/common/images/20240104/20240104015228170434754846656.jpg"   style="width: 120px; height: 120px"></el-image>
              <p class="r-s1">12¥</p>
            </div>
          </div>
          </div>
        </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'PcFestivalList1',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.feslist{
  &-content{
 
  .first{
    background: url('https://h2.appsimg.com/b.appsimg.com/upload/momin/2024/02/06/47/1707197827830_2000x450_90.jpg!75.webp');
    background-position: center;
    background-size: 100% 100%;
    margin: 10px 0;
  .first-b{
  display: flex;
 
  width:100%;
  height: 225px;
  //   img{
  //     height: 100%;
  //     width: 100%;
  //  position: absolute;
  // }
    }

   .recommend{
  display: flex;
  // justify-content: center;
  align-items: center;
  width: 350px;
  height: 225px;
  padding: 0px 20px;
  border-radius: 5px;
  margin-bottom: 10px;
  margin-right: 10px;
  position: relative;
  cursor:pointer;
  //  .l{
  //   z-index: 2;
  //   margin-right: 20px;
  //   .recom-t1{
  //     padding: 37px 0;
  //   font-size: 30px;
  //   text-align: center;
  // }
  // .recom-t2{
  //   font-size: 18px;
  //   text-align: center;
  // }
  //  }
   .r{
    position: absolute;
   right:25px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 156px;
    height: 170px;
    background: #fff;
    border-radius: 5px;
    .r-s1{
      font-size: 18px;
    }
   }
   }
  }
 }
}
</style>