<template>
	<div class="cart" v-loading="loading">
		<template v-if="cartList.length || invalidGoods.length">
			<nav>
				<li>
					<el-checkbox v-model="checkAll" @change="allElection"></el-checkbox>
				</li>
				<li>商品信息</li>
				<li>价格</li>
				<li>数量</li>
				<li>小计</li>
				<li>操作</li>
			</nav>
			<div class="list" v-for="(siteItem, siteIndex) in cartList" :key="siteIndex">
				<div class="item">
					<div class="head">
						<el-checkbox v-model="siteItem.checked" @change="siteAllElection(siteIndex)"></el-checkbox>
						<router-link to="/shop" target="_blank">{{ siteItem.siteName }}</router-link>
						<el-tag size="small" v-if="siteItem.cartList[0].is_own == 1">自营</el-tag>
					</div>
                    <div class="goods-item" v-for="(item, cartIndex) in siteItem.cartList" :key="cartIndex">
                        <ul>
                            <li>
                                <el-checkbox v-model="item.checked" @change="singleElection(siteIndex, cartIndex)"></el-checkbox>
                            </li>
                            <li class="goods-info-wrap" @click="$router.pushToTab({ path: activityMap[item.promotion || 'sku'].getDetailUrl(item.promotion_id || item.sku_id) })">
                                <goods-info-item-row :goods="item"></goods-info-item-row>
                            </li>
                            <li>
                                <template v-if="item.promotion && item.promotion !== 'getfree'">
                                    <p>￥{{ item.promotion_price }}</p>
                                    <p class="market-price">￥{{ item.price }}</p>
                                    <p class="market" v-if="item.promotion_limit">限{{ item.promotion_limit }}件</p>
                                </template>
                                <p v-else>￥{{ item.discount_price }}</p>
                            </li>
                            <li>
                                <el-input-number v-model="item.num" :step="item.min_buy > 0 ? item.min_buy : modifyNum" size="mini" :min="item.min_buy > 0 ? Number(item.min_buy) : 1" :max="Number(item.stock)" @change="cartNumChange($event, { siteIndex, cartIndex })" @blur="dev($event, { siteIndex, cartIndex })"></el-input-number>
                            </li>
                            <li>
                                <strong class="subtotal ns-text-color" v-if="item.promotion_price * item.num">
                                    <template v-if="item.promotion && item.promotion !== 'getfree'">
                                        <p>
                                            ￥{{ item.promotion_price }}
                                            {{ item.promotion_label }}
                                            x
                                            {{ !item.promotion_limit || item.promotion_limit > item.num ? item.num : item.promotion_limit}}
                                        </p>
                                        <p v-if="item.promotion_limit && item.promotion_limit < item.num">￥{{ item.price }} 原价 x {{ item.num - item.promotion_limit}}</p>
                                        <p>小计: ￥{{ calcUnitPrice(item)  }}</p>
                                    </template>
                                    <p v-else>￥{{  calcUnitPrice(item)   }}</p>
                                </strong>
                                <strong class="subtotal ns-text-color" v-else>￥0</strong>
                            </li>
                            <li>
                                <el-button type="text" @click="deleteCart(siteIndex, cartIndex)">删除</el-button>
                            </li>
                        </ul>
                        <template v-if="item.promotion === 'getfree'">
                            <p class="tips">
                                <i class="el-icon-info"></i>
								该商品{{item.num>=item.getfree_info.exchange_num ?'满足换购活动，结算选择赠送商品：':'始购数量不满足换购活动，结算不能选择赠送商品：'}}
                                {{
                                    item.exchange_goods_list.map(item => item.goods_name + ' x ' + item.exchange_num)
                                        .join(', ')
                                }}
                            </p>
                        </template>
                    </div>


					<div class="addhint">
						 <p>单笔订单满<span>{{cartListIfon[siteIndex].minimal_charge}}</span>才能进行结算，运费<span>{{cartListIfon[siteIndex].express_money}}</span>
							<span class="to" v-if="cartListIfon[siteIndex].freeshippings">，订单满<span>{{cartListIfon[siteIndex].freeshippings}}</span>包邮</span></p>
						 <p>药品为特殊商品，若无质量问题不予退货，如需退货需自行承担运费，产生具体退货运费按照实际为准。</p>
			  </div>
				</div>
			</div>

			<div class="lose-list" v-if="invalidGoods.length">
				<div class="head">
					失效商品
					<span class="ns-text-color">{{ invalidGoods.length }}</span>
					件
				</div>
				<ul v-for="(goodsItem, goodsIndex) in invalidGoods" :key="goodsIndex">
					<li>
						<el-tag size="small" type="info">失效</el-tag>
					</li>
					<li class="goods-info-wrap">
						<goods-info-item-row :goods="goodsItem"></goods-info-item-row>
					</li>
					<li>
						<span>￥{{ goodsItem.promotion_price }}</span>
					</li>
					<li>
						<p >{{ goodsItem.num }}</p>
					</li>
					<li>
						<strong class="subtotal">￥{{ goodsItem.promotion_price * goodsItem.num }}</strong>
					</li>
				</ul>
				<!-- <div class="addhint">
					<p>商品订单必须大于<span>1</span>个品种才能进行结算，单笔订单满<span>{{item.shop_info.minimal_charge}}</span>才能进行结算，运费<span>12.00</span>
							<span v-if="hintIfon.freeshippings">，订单满<span>{{hintIfon.freeshippings}}</span>包邮</span></p>
					<p>药品为特殊商品，若无质量问题不予退货，如需退货需自行承担运费<span>20</span>元起，产生具体退货运费按照实际为准</p>
			  </div> -->
			</div>

			<footer>
				<el-checkbox v-model="checkAll" @change="allElection">全选</el-checkbox>
				<ul class="operation">
					<li>
						<el-button type="text" @click="deleteCartSelected">删除</el-button>
					</li>
					<li>
						<el-button type="text" @click="clearInvalidGoods" v-if="invalidGoods.length!=0">清除失效宝贝</el-button>
					</li>
				</ul>
				<div class="sum-wrap">
					<div class="selected-sum">
						<span>已选商品</span>
						<em class="total-count">{{ totalCount }}</em>
						<span>件</span>
					</div>
					<div class="price-wrap">
						<span>合计（不含运费）：</span>
						<strong class="ns-text-color">￥{{ totalPrice }}</strong>
					</div>

					<el-button type="primary" v-if="totalCount != 0" @click="settlement">结算</el-button>
					<el-button type="info" v-else disabled @click="settlement">结算</el-button>
				</div>
			</footer>
		</template>
		<div class="empty-wrap" v-else-if="!loading && (!cartList.length || !invalidGoods.length)">
			<router-link to="/">您的购物车是空的，赶快去逛逛，挑选商品吧！</router-link>
		</div>
	</div>
</template>

<script>
	import cart from './cart';
	import { goodsInfoItemRow } from "@/components/goods-info"
	export default {
		name: 'cart',
		mixins: [cart],
		components: {
			goodsInfoItemRow
		},
	};
</script>
<style lang="scss" scoped>
	@import './cart.scss';
</style>
