<template>
	<div class="floor-style-3 floor-wsw" :id="data.block_name+c_index">
		<div class="item-wrap">
			<div class="head-wrap">
				<div class="title-name" v-if="data.value.title.value.text">
					<span :style="{ backgroundColor: data.value.title.value.color }"></span>
					<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
				</div>
				<div class="category-wrap">
					<li v-for="(item, index) in data.value.categoryList.value.list" :key="index">
						<router-link target="_blank" :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{ item.category_name }}</router-link>
					</li>
				</div>
			</div>
			<div class="body-wrap">
				<div class="left-img-wrap">
					<img v-if="data.value.leftImg.value.url" :src="$img(data.value.leftImg.value.url)" @click="$router.pushToTab(data.value.leftImg.value.link.url)" />
				</div>
				<ul class="right-goods-wrap">
					<li v-for="(item, index) in data.value.rightGoodsList.value.list" :key="index" @click="goSku(item)">
						<h4>{{ item.goods_name }}</h4>
						<p class="ns-text-color">{{ item.introduction }}</p>
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageErrorRight" /></div>
                        <div  class="cart-layout">
                            <!-- <div class="cart-num">
                                <span class="el-icon-minus" @click.stop="item.num>1?item.num--:item.num"></span>
                                <div class="number"><el-input v-model="item.num" size="small" placeholder="0"></el-input></div>
                                <span class="el-icon-plus" @click.stop="item.num++"></span>
                            </div> -->
							<div style="width: 100px;"> <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    }" size="mini" @upnumber="upnumber"></shareCounter></div>
                            <div class="cart-add" @click.stop="joinCart(item)">
                                加入购物车
                            </div>
                        </div>
					</li>
				</ul>
				<ul class="bottom-goods-wrap">
					<li v-for="(item, index) in data.value.bottomGoodsList.value.list" :key="index" @click="goSku(item)">
						<div class="info-wrap">
							<h4>{{ item.goods_name }}</h4>
							<p class="ns-text-color">{{ item.introduction }}</p>
						</div>
						<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageErrorBottom(index)" /></div>
                        <div  class="cart-layout">
                            <!-- <div class="cart-num">
                                <span class="el-icon-minus" @click.stop="item.num>1?item.num--:item.num"></span>
                                <div class="number"><el-input v-model="item.num" size="small" placeholder="0"></el-input></div>
                                <span class="el-icon-plus" @click.stop="item.num++"></span>
                            </div> -->
							<div style="width: 100px;"> <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    }" size="mini" @upnumber="upnumber"></shareCounter></div>
                            <div class="cart-add" @click.stop="joinCart(item)"  v-if="item.package_num<item.stock">
                                加入购物车
                            </div>
                        </div>
					</li>
				</ul>

				<ul class="brand-wrap">
					<li v-for="(item, index) in data.value.brandList.value.list" :key="index" @click="$router.pushToTab({ path: '/list', query: { brand_id: item.brand_id } })">
						<img v-if="item.image_url" alt="品牌图片" :src="$img(item.image_url)" />
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {onTip} from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
export default {
	name: 'floor-style-3',
	components:{
        shareCounter
    },
	props: {
		data: {
			type: Object
		},
        c_index:{
            type: Number
        }
	},
	data() {
		return {
			number:1
		};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage',"token"])
	},
	methods: {
		upnumber(val){
              this.number=val
        },
		goSku(item) {
			if(!this.token){
            this.$router.pushToTab('/login')
            return
           }
		   if(onTip(item.control.show_price,item.control.control_msg)) return 
			this.$router.pushToTab('/sku?id=' + item.sku_id);
		},
		imageErrorRight(e) {
			e.target.src =this.defaultGoodsImage
			// this.data.value.rightGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		},
		imageErrorBottom(index) {
			this.data.value.bottomGoodsList.value.list[index].sku_image = this.defaultGoodsImage;
		},
        // 加入购物车
        joinCart(item){
            this.$store.dispatch("cart/add_to_cart", {
                site_id: item.site_id,
                sku_id: item.sku_id,
                num: this.number
            }).then(res=>{
                if(res.code===0){
                    this.$message({
                        message: "加入购物车成功",
                        type: "success"
                    })
                }
            }).catch(err=>{
                if (err.message === 'token不存在') {
                    this.$router.pushToTab('/login')
                } else {
                    this.$message.error(err.message);
                }
            })
        }
	}
};
</script>

<style lang="scss">
.floor-style-3 {
	overflow: hidden;

	.item-wrap .head-wrap {
		height: 50px;
		line-height: 50px;
		.title-name {
			display: inline-block;
			span {
				float: left;
				width: 5px;
				height: 21px;
				margin-top: 15px;
			}
			h2 {
				float: left;
				margin-left: 10px;
				font-weight: bold;
				font-size: 20px;
			}
		}
		.category-wrap {
			float: right;
			display: flex;
			li {
				margin-right: 10px;
			}
		}
	}
	.body-wrap {
        .left-img-wrap {
            width: 190px;
            height: 360px;
            float: left;
            cursor: pointer;
            img {
                max-width: 100%;
                max-height: 100%;
            }
        }
		.right-goods-wrap {
			margin-left: 190px;
			text-align: center;
			overflow: hidden;
			li {
				float: left;
				width: 19.9%;
				background: #ffff;
				border-width: 0 0 1px 1px;
				border-color: #f9f9f9;
				border-style: solid;
				cursor: pointer;
				h4 {
					font-size: 12px;
					margin: 10px 20px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					font-weight: normal;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 4px 30px;
					height: 20px;
				}
			}
            .cart-layout{
                margin: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .cart-num{
                    margin-right: 10px;
                    position: relative;
                    width: 30%;
                    border-radius: 4px;
                    display: flex;
                    border: 1px solid #ccc;
                    justify-content: center;
                    align-items: center;
                    span{
                        z-index: 1;
                        position: absolute;
                        cursor: pointer;
                        width: 20px;
                        text-align: center;
                    }
                    span:first-child{
                        left: 0;
                    }
                    span:last-child{
                        right: 0;
                    }
                    .number{
                        .el-input{
                            .el-input__inner{
                                text-align: center;
                            }
                        }
                    }
                }
                .cart-add{
                    cursor: pointer;
                    border: 1px solid $base-color;
                    color: $base-color;
                    border-radius: 4px;
                    padding: 2px 4px;
                }
            }
			.img-wrap {
				width: 105px;
				height: 105px;
				line-height: 105px;
				display: inline-block;
				margin-bottom: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
		}
	}
	.bottom-goods-wrap {
        text-align: center;
		overflow: hidden;
		display: flex;
		li {
			flex: 1;
			background: #fff;
			border-width: 0 0 1px 1px;
			border-color: #f9f9f9;
			border-style: solid;
			cursor: pointer;
			&:first-child {
				border-left: 0;
			}

			.info-wrap {
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				h4 {
					font-size: 14px;
					margin: 0 10px 5px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					width: 90px;
					font-weight: normal;
				}
				p {
					font-size: 12px;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
					margin: 0 20px;
					width: 70px;
				}
			}
			.img-wrap {
				width: 70px;
				height: 70px;
				line-height: 70px;
				display: inline-block;
				vertical-align: middle;
				text-align: center;
				padding: 10px;
				img {
					max-width: 100%;
					max-height: 100%;
				}
			}
            .cart-layout{
                margin: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
                .cart-num{
                    margin-right: 10px;
                    position: relative;
                    width: 30%;
                    border-radius: 4px;
                    display: flex;
                    border: 1px solid #ccc;
                    justify-content: center;
                    align-items: center;
                    span{
                        z-index: 1;
                        position: absolute;
                        cursor: pointer;
                        width: 20px;
                        text-align: center;
                    }
                    span:first-child{
                        left: 0;
                    }
                    span:last-child{
                        right: 0;
                    }
                    .number{
                        .el-input{
                            .el-input__inner{
                                text-align: center;
                            }
                        }
                    }
                }
                .cart-add{
                    cursor: pointer;
                    border: 1px solid $base-color;
                    color: $base-color;
                    border-radius: 4px;
                    padding: 2px 4px;
                }
            }
		}
	}

	.brand-wrap {
		display: flex;
		background: #fff;
		li {
			flex: 1;
			height: 50px;
			cursor: pointer;
			line-height: 50px;
			text-align: center;
			background: #fff;
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
.el-input-number{
width: 90px;
}
}
</style>
