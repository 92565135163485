import { mapGetters } from "vuex"
import activityMap from '@/utils/activityMap'
import shareCounter from "@/components/shareCounter.vue"
export default {
    components:{
        shareCounter
    },
    data() {
        return {
            number:1,
            ismodifyNum: 1,
            activityMapItem: null,
            
            // 适配批发商品
            min_buy: 0,
           
        }
    },
    props: {
        detailRes: {
            type: Object,
            default: () => {
                return {}
            }
        },
        activityType: {
            type: String,
            default: 'sku'
        },
        goodsSkuDetail: {
            type: Object,
            default: () => {
                return {}
            }
        },
        addonIsExit: {
            type: Object,
            default: () => {
                return {}
            }
        },
        couponList: {
            type: Array,
            default: () => {
                return []
            }
        },
        manjian: {
            type: Object,
            default: () => {
                return {}
            }
        },
        shopInfo: {
            type: Object,
            default: () => {
                return {}
            }
        },
        nums: {
            type: Number,
        },
        priceArray:{
            type:Array,
            default:()=>{
                return []
            }
        },
        skuId:{
            type:String,
        },
        specDisabled:{
            type:Boolean,
            default:false
        }
    },
    created() {
        this.activityMapItem = activityMap[this.activityType];
    },

    watch: {
    nums(newValue, oldValue) {
    this.number=newValue;
    },
  },
    methods: {
        changeSpec(sku_id,spec_id){
             this.$emit('changeSpec',sku_id,spec_id)
        },
        upnumber(val){
            this.number=val
        },
        // 加入购物车
        joinCart() {
            //纠正数量
            if (this.goodsSkuDetail.stock == 0) {
                this.$message({
                    message: "商品已售罄",
                    type: "warning"
                })
                return
            }

            if (this.number.length == 0 || this.number == 0) {
                this.$message({
                    message: "购买数量不能为0",
                    type: "warning"
                })
                return
            }
            if (this.btnSwitch) return
            this.btnSwitch = true
            const data = {
                site_id: this.goodsSkuDetail.site_id,
                sku_id: this.goodsSkuDetail.sku_id,
                num: this.number
            }
            console.log(this.number)
            const activityMapItem = activityMap[this.activityType]
            activityMapItem &&
                activityMapItem.promotion &&
                Object.assign(data, {
                    promotion_type: activityMapItem.promotion.promotion_type,
                    promotion_id: activityMapItem.promotion.getPromotion_id(this.goodsSkuDetail)
                })

            this.$store
                .dispatch("cart/add_to_cart", data)
                .then((res) => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                    this.btnSwitch = false
                })
                .catch((err) => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        // 立即购买
        buyNow() {
            if (this.goodsSkuDetail.stock === 0) {
                this.$message({
                    message: "商品已售罄",
                    type: "warning"
                })
                return
            }

            if (this.number.length == 0 || this.number == 0) {
                this.$message({
                    message: "购买数量不能为0",
                    type: "warning"
                })
                return
            }

            if (parseInt(this.number) + parseInt(this.goodsSkuDetail.purchased_num) > this.goodsSkuDetail.max_buy && this.goodsSkuDetail.max_buy != 0) {
                let _newNum = parseInt(this.goodsSkuDetail.max_buy) - parseInt(this.goodsSkuDetail.purchased_num)
                this.$message({
                    message: "商品限购" + this.goodsSkuDetail.max_buy + "件，现在已经购买" + this.goodsSkuDetail.purchased_num + "件,还能购买" + _newNum + "件",
                    type: "warning"
                })
                return
            }

            const data = {
                sku_id: this.goodsSkuDetail.sku_id,
                num: this.number,
                activityType: this.activityType
            }
            const activityMapItem = activityMap[this.activityType]
            if (activityMapItem && activityMapItem.payment) {
                Object.assign(data, {
                    [activityMapItem.payment.paramsKeys.id]: this.goodsSkuDetail[activityMapItem.payment.paramsKeys.id]
                })

                // this.$store.dispatch(activityMapItem.payment.dispatch, data)
                // this.$router.push({
                // 	path: activityMapItem.payment.path
                // })
            }
            this.$store.dispatch("order/setOrderCreateData", data)
            this.$router.push({
                path: "/payment"
            })
        }
    },
    computed: {
        ...mapGetters(["token", "storeWithoutAuthority"])
    }
}
