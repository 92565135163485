<template>
	<div class="goods-recommend" v-loading="loading">
		<h4>商品精选</h4>
		<ul v-if="list.length">
			<li v-for="(item, index) in list" :key="index" @click="goSku(item)">
				<div class="img-wrap"><img :src="$img(item['sku_image'], { size: 'mid' })" @error="imageError(index)" /></div>
                <template v-if="token">
                    <div class="price">￥{{ item.control.show_price ? Number(item.price) : storeWithoutAuthority}}</div>
                </template>
                <template v-else>
                    <div class="price">￥登录可见</div>
                </template>
				<p class="sku-name">{{ item.goods_name }}</p>
				<div class="info-wrap">
                </div>
                <div class="cart-layout" v-if="item.control && item.control.show_price">
                    <div class="cart-num">
                        <span class="el-icon-minus" @click.stop="item.num>1?item.num--:item.num"></span>
                        <div class="number"><el-input v-model="item.num" size="small" placeholder="0"></el-input></div>
                        <span class="el-icon-plus" @click.stop="item.num++"></span>
                    </div>
                    <div class="cart-add" @click.stop="joinCart(item)">
                        加入购物车
                    </div>
                </div>
			</li>
		</ul>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { goodsRecommend } from '../api/goods/goods';
import {onTip} from "@/utils/tips"
export default {
	name: 'goods_recommend',
	props: {
		page: {
			type: [Number, String],
			default: 1
		},
		pageSize: {
			type: [Number, String],
			default: 5
		}
	},
	data: () => {
		return {
			loading: true,
			list: []
		};
	},
	created() {
		this.getGoodsRecommend();
	},
	computed: {
		...mapGetters(['defaultGoodsImage', 'token', 'storeWithoutAuthority'])
	},
	methods: {
        goSku(item) {
           
			if(onTip(item.control.show_price)) return 

			this.$router.pushToTab({ path: '/sku?id=' + item.sku_id });
		},
		getGoodsRecommend() {
			goodsRecommend({
				page: this.page,
				page_size: this.pageSize
			})
				.then(res => {
					if (res.code == 0){
                        res.data.list.forEach(item=>{
                            item.num=1
                        })
                        this.list = res.data.list;
                    }
					this.loading = false;
				})
				.catch(res => {
					this.loading = false;
				});
		},
		imageError(index) {
			this.list[index].sku_image = this.defaultGoodsImage;
		},
        // 加入购物车
        joinCart(item){
            this.$store.dispatch("cart/add_to_cart", {
                site_id: item.site_id,
                sku_id: item.sku_id,
                num: item.num
            }).then(res=>{
                if(res.code===0){
                    this.$message({
                        message: "加入购物车成功",
                        type: "success"
                    })
                }
            }).catch(err=>{
                if (err.message === 'token不存在') {
                    this.$router.pushToTab('/login')
                } else {
                    this.$message.error(err.message);
                }
            })
        }
	}
};
</script>

<style lang="scss" scoped>
.goods-recommend {
	border: 1px solid #eeeeee;
	h4 {
		margin: 10px;
	}
	ul {
		li {
			padding: 10px;
			cursor: pointer;
			.price {
				color: $base-color;
				font-size: 16px;
			}
			.sku-name {
				font-size: $ns-font-size-sm;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
                &:hover {
                    color: $base-color;
                }
			}
		}
	}
}
.cart-layout{
    display: flex;
    align-items: center;
    .cart-num{
        margin-right: 10px;
        position: relative;
        width: 30%;
        border-radius: 4px;
        display: flex;
        border: 1px solid #ccc;
        justify-content: center;
        align-items: center;
        span{
            z-index: 1;
            position: absolute;
            cursor: pointer;
            width: 20px;
            text-align: center;
        }
        span:first-child{
            left: 0;
        }
        span:last-child{
            right: 0;
        }
        .number{
            .el-input{
                ::v-deep(.el-input__inner){
                    border: 0;
                    padding: 0px 5px;
                    text-align: center;
                }
            }
        }
    }
    .cart-add{
        cursor: pointer;
        border: 1px solid $base-color;
        color: $base-color;
        border-radius: 4px;
        padding: 2px 10px;
    }
}
</style>
