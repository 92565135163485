<template>
  <div class="festival" :style="{backgroundImage:'url(' + rgbaColor.navImg + ')'}">
   <div class="festival-cou"></div>
   <div class="festival-box" :style="{background:rgbaColor.navcolor}">
    <vFestivalList></vFestivalList>
    <vFestiva2List></vFestiva2List>
    <vFestiva3List></vFestiva3List>
   </div>
  </div>
</template>
<script>
import vFestivalList from './component/festivalList-1.vue'
import vFestiva2List from './component/festivalList-2.vue'
import vFestiva3List from './component/festivalList-3.vue'
import fetivals from './fetivals';
export default {
  name: 'PcFestival',
components:{
  vFestivalList,
  vFestiva2List,
  vFestiva3List,
  
},
  data:() => {
    return {
    };
  },
  mixins:[fetivals],
};
</script>

<style lang="scss" scoped>
.festival{
  background-repeat: no-repeat;
   background-size: 100% 100%;
   background-position: center;
  &-cou{
    height: 600px;
    width: 100%;
  }
  // &-img{
  //   position: absolute;
  //    width: 100%;
  //    height: auto;
  //    .el-image {
  //      width: 100%;
  //      height: 80%;
  //       .img {
  //           width: 100%;
  //           height: auto;
  //       }
  //       }
     
  // }
  &-box{
    z-index: 1;
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 47px;
  }
}
::v-deep .el-carousel__arrow--right {
    right: 60px;
}
</style>