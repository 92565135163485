<template>
    <div class="license">
        <div class="license-l">
            <div class="shop-left">
                <div class="shop-wrap">
                    <div class="head-wrap">
                        <div class="img-wrap">
                            <img class="img-responsive center-block"
                                :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
                                @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
                        </div>
                        <h5>
                            <span class="ns-text-color">{{ shopInfo.site_name }}</span>
                            <el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">自营</el-tag>
                        </h5>
                    </div>
                    <div class="info-wrap">
                        <div class="item">
                            描述相符
                            <b>{{ shopInfo.shop_desccredit }}</b>分
                        </div>
                        <div class="item">
                            服务态度
                            <b>{{ shopInfo.shop_servicecredit }}</b>分
                        </div>
                        <div class="item">
                            发货速度
                            <b>{{ shopInfo.shop_deliverycredit }}</b>分
                        </div>
                    </div>
                    <div class="other-info">
                        <div class="tell" v-if="shopInfo.telephone">
                            <div class="tell-name">电话:</div>
                            <div class="tell-detail">{{ shopInfo.telephone }}</div>
                        </div>
                        <div class="item-info" v-if="shopInfo.full_address">
                            <div class="item-name">地址:</div>
                            <div class="item-detail">{{ shopInfo.full_address }}{{ shopInfo.address }}</div>
                        </div>
                    </div>
                    <div class="operation">
                        <el-button size="medium" @click="getIsFollow" v-if="hasFollow">取消关注</el-button>
                        <el-button size="medium" @click="getIsFollow" v-else>关注店铺</el-button>
                    </div>
                </div>
                <div class="shop-goods-search">
                    <el-input placeholder="搜索 商品" v-model="keyword" class="input-with-select" size="medium"
                        maxlength="50" clearable>
                        <el-button slot="append" icon="el-icon-search" @click="goGoodsList"></el-button>
                    </el-input>
                </div>
                <div class="shop-goods-cate" :class="{ border: categoryList.length }">
                    <div class="item" v-for="item in categoryList" :key="item.category_id">
                        <div class="menu-name"
                            @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id } })">
                            {{ item.category_name }}</div>
                        <div class="submenu" v-for="cate in item.child_list" :key="cate.category_id"
                            @click="$router.push({ path: '/shop_list', query: { site_id: id, shop_category_id: item.category_id } })">
                            {{ cate.category_name }}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="license-img">
            <el-image fit="scale-down"  v-if="shopInfo.business_licence_number_electronic"  style="height:500px;width:100%" :src="shopInfo.business_licence_number_electronic" :preview-src-list="[shopInfo.business_licence_number_electronic]">
            </el-image>
            <el-image fit="scale-down"  v-if="shopInfo.drug_license" style="height:500px;width:100%" :src="shopInfo.drug_license" :preview-src-list="[shopInfo.drug_license]">
            </el-image>
            <el-image  fit="scale-down" v-if="shopInfo.food_license" style="height:500px;width:100%" :src="shopInfo.food_license" :preview-src-list="[shopInfo.food_license]">
            </el-image>
            <el-image fit="scale-down"  v-if="shopInfo.medical_net_cert" style="height:500px;width:100%" :src="shopInfo.medical_net_cert" :preview-src-list="[shopInfo.medical_net_cert]">
            </el-image>
        </div>
    </div>
</template>

<script>
import license from './license';
export default {
    mixins: [license],
    
    data: () => {
        return {
        }
    },
    mounted(){
        document.addEventListener('click',function(e){
        if(e.target.className=="el-image-viewer__mask"){
            let close = document.querySelector(".el-icon-circle-close");
            close.click();
        }
    });
    },
    beforeDestroy() {
        document.removeEventListener('click',function(e){
        if(e.target.className=="el-image-viewer__mask"){
            let close = document.querySelector(".el-icon-circle-close");
            close.click();
        }
    });
},
    methods:{
        // handleClick(){
        //     console.log(6666)
        // }
    } 

}
</script>

<style lang="scss" scoped>
.license {
    display: flex;

    &-l {
        .shop-left {
            width: 234px;

            .shop-wrap {
                width: 191px;
                float: right;
                border: 1px solid #eeeeee;
                padding: 0 21px;

                .head-wrap {
                    text-align: center;
                    padding: 10px 0;
                    border-bottom: 1px solid #eeeeee;

                    .img-wrap {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        display: inline-block;
                    }

                    .tag {
                        margin-left: 5px;
                    }
                }

                .info-wrap {
                    padding: 10px;
                    color: #838383;

                    b {
                        color: #383838;
                    }

                    border-bottom: 1px solid #eeeeee;
                }

                .other-info {
                    padding: 10px;
                    color: #838383;

                    .item-info {
                        display: flex;

                        .item-name {
                            width: 75px;
                        }
                    }

                    .tell {
                        display: flex;

                        .tell-name {
                            width: 35px;
                        }
                    }
                }

                .operation {
                    border-top: 1px solid #eeeeee;
                    padding: 10px 0;
                    text-align: center;
                }
            }

            .shop-goods-search {
                .el-input {
                    margin: 10px 0;
                }
            }

            .border {
                border: 1px solid #f1f1ff;
            }

            .shop-goods-cate {
                width: 100%;

                .menu-name {
                    padding-left: 16px;
                    background: #f8f8f8;
                    font-size: $ns-font-size-base;
                    height: 40px;
                    cursor: pointer;
                    color: #666666;
                    display: flex;
                    align-items: center;
                }

                .submenu {
                    font-size: $ns-font-size-base;
                    line-height: 2;
                    border-top: 1px solid #f1f1f1;
                    padding-left: 26px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    height: 40px;
                    background: #ffffff;
                    color: #666666;
                }
            }
        }
    }
    &-img{
        width: 100%;
        // height: 500px;
    //    .el-image{
    //     width: 100%;
    //     height: 500px;
    //     .img{
    //       max-width: 100%;
    //       height: 0 auto;
    //     }
    //    }
    }
}
</style>