<template>
    <div>
    <el-table
    :data="data"
    stripe
    style="width: 100%">
    <el-table-column
      prop="username"
      label="受邀会员账号"
     >
    </el-table-column>
    <el-table-column
      prop="reg_time"
      label="获取时间"
      >
    </el-table-column>
  </el-table>
    </div>
</template>

<script>
    export default {
        props:{
            data:{
                type:Array,
                default:()=>[]
            },
            count:{
                type:Number,
                default:5
            }
        },
        data(){
         return{

         }
        }
    }
</script>

<style lang="postcss" scoped>

</style>