<template>
    <div class="popup" v-if="vals">
        <div class="popup-moke"></div>
        <div class="popup-cont">
            <div class="box" :style="{width:width + 'px'}">
                <div class="heder">
                  <div>
                    <slot name="poheder"></slot>
                  </div>
                </div>
                <div class="by"><slot name="poboady"></slot></div>
                <div class="footer"><slot name="pofooter"></slot></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcPopUpDialogs",
    props:{
      value:{
      type:Boolean,
      default:false
      },
      width:{
        type:Number,
        default:500
      }
    },
    data() {
        return {
          vals:this.value
        }
    },
     watch:{
      value(val){
        this.vals=val
      }
     },
    mounted() {},

    methods: {
      
    }
}
</script>

<style lang="scss" scoped>
.popup {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    &-moke {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.5);
    }
    &-cont {
        position: absolute;
        height: 100%;
        width: 100%;
        overflow: auto;
        
        .box {
            position: relative;
            background: #fff;
            width: 500px;
            border-radius: 5px;
            max-width: 100%;
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
            margin: 10vh auto;
            .by{
              padding: 10px 20px;
              border-radius:5px ;
            }
            .heder {
               position: relative;
                padding: 10px 20px;
                text-align: center;
                font-weight: 600;
                font-size: 16px;
            }
            .footer {
                display: flex;
                justify-content: center;
                align-items: center;
                padding-bottom: 20px;
            }
        }
    }
}
</style>