<template>
    <div class="fes2">
        <img style="width: 100%; height: 100px" src="https://h2.appsimg.com/b.appsimg.com/upload/mst/2024/02/06/44/707141de59d5ccff26453958af256063.jpg" alt="" />
        <div class="fes2-list">
            <div class="list" v-for="(item,index) in list" :key="index">
                <div class="lirgb">
            <img :src="item.url1" alt="" />
                    <img class="rgb-log" :src="item.url2" />
                </div>
                <p class="cont-1">{{item.text1}}</p>
                <p class="cont-2">{{item.text2}}</p>
                <div class="onck">立即抢购</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcFestivalList2",

    data() {
        return {
          list:[
            {
              url1:"https://a.appsimg.com/upload/brand/upcb/2023/06/02/98/ias_c315b09b17d3529295a0503eebdfa962.jpg!75.webp",
              url2:"https://a.appsimg.com/upload/brandcool/0/LOGO/170235466506302a6f/primary.png!75.webp",
              text1:"薇诺娜（柔彩幻颜隔离霜）30g/瓶",
              text2:"2.2折起",
            },
            {
              url1:"https://a.appsimg.com/upload/brand/upcb/2023/06/02/98/ias_c315b09b17d3529295a0503eebdfa962.jpg!75.webp",
              url2:"https://a.appsimg.com/upload/brandcool/0/LOGO/170235466506302a6f/primary.png!75.webp",
              text1:"薇诺娜（柔彩幻颜隔离霜）30g/瓶",
              text2:"2.2折起",
            },
            {
              url1:"https://a.appsimg.com/upload/brand/upcb/2023/06/02/98/ias_c315b09b17d3529295a0503eebdfa962.jpg!75.webp",
              url2:"https://a.appsimg.com/upload/brandcool/0/LOGO/170235466506302a6f/primary.png!75.webp",
              text1:"薇诺娜（柔彩幻颜隔离霜）30g/瓶",
              text2:"2.2折起",
            },
            {
              url1:"https://a.appsimg.com/upload/brand/upcb/2023/06/02/98/ias_c315b09b17d3529295a0503eebdfa962.jpg!75.webp",
              url2:"https://a.appsimg.com/upload/brandcool/0/LOGO/170235466506302a6f/primary.png!75.webp",
              text1:"薇诺娜（柔彩幻颜隔离霜）30g/瓶",
              text2:"2.2折起",
            },
            {
              url1:"https://a.appsimg.com/upload/brand/upcb/2023/06/02/98/ias_c315b09b17d3529295a0503eebdfa962.jpg!75.webp",
              url2:"https://a.appsimg.com/upload/brandcool/0/LOGO/170235466506302a6f/primary.png!75.webp",
              text1:"薇诺娜（柔彩幻颜隔离霜）30g/瓶",
              text2:"2.2折起",
            },
          ]
        }
    },

    mounted() {},

    methods: {}
}
</script>

<style lang="scss" scoped>
.fes2 {
    &-list {
        display: flex;
        flex-wrap: wrap;
        .list {
          cursor:pointer;
        margin-right: 10px;
        width: 230px;
        height: 397px;
        background: #fff;
        &:hover{
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
        }
            .lirgb {
                position: relative;
                .rgb-log {
                    position: absolute;
                    left: 67px;
                    bottom: -27px;
                    width: 104px;
                    height: 54px;
                    border: 1px solid #ddd;
                }
            }
            .cont-1 {
                text-align: center;
                font-weight: 500;
                margin-top: 50px;
            }
            .cont-2 {
                text-align: center;
                font-weight: 600;
                color: red;
                margin: 5px 0;
            }
            .onck {
                margin: 0 auto;
                width: 190px;
                height: 28px;
                line-height: 28px;
                overflow: hidden;
                text-overflow: ellipsis;
                word-break: break-all;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* 行数*/
                -webkit-box-orient: vertical;
                border: 1px solid red;
                text-align: center;
                color: red;
                &:hover{
                  background: red;
                  color: #fff;
                }
            }
        }
    }
}
</style>