<template>
    <div class="floor-style-erMei-design-3 floor-wsw" :id="data.block_name+Number(c_index)">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image
                    @click="$router.pushToTab(data.value.more.value.link)"
                    :src="data.value.titleImg.value.url"
                    fit="scale-down"
                ></el-image>
                <h2
                    @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }"
                >{{ data.value.title.value.text }}</h2>
            </div>
            <div class="right-head-warp">
                <div
                    @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }"
                >
                    <span
                        :style="{ color: data.value.more.value.color }"
                    >{{ data.value.more.value.text }}</span>
                    <i
                        :style="{ color: data.value.more.value.color }"
                        class="icon el-icon-arrow-right"
                    ></i>
                </div>
            </div>
        </div>
        <div class="body-wrap">
            <div class="swiper-container swiper-for-design-3">
                <div class="goods-container">
                    <template v-for="(item, index) in data.value.goodsList.value.list">
                        <div
                            class="goods-item"
                            v-if="index <= 4"
                            :key="index"
                            :title="item.goods_name"
                            @click="goSku(item)"
                        >
                            <div class="img-wrap">
                                <img
                                    alt="商品图片"
                                    :src="$img(item.sku_image)"
                                    @error="imageError"
                                />
                            </div>
                            <div class="goods-a">
                                <div class="goods-name">{{ item.goods_name }}</div>
                                <div class="goods-attr">0.5g*50支/盒</div>
                            </div>
                            <div class="price-box">
                                <div class="price">
                                    <template v-if="token">
                                        <span
                                            class="price-num"
                                        >￥{{ item.control.show_price ? item.price : storeWithoutAuthority }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="price-num">￥登录可见</span>
                                    </template>
                                </div>
                                <div class="market-price">
                                    <template v-if="token">
                                        <div class="market-t">
                                            <span
                                                class="delete-price dled"
                                                v-if="item.reference_price>0&& item?.reference_price"
                                            >零：￥{{ item.control.show_price ? item.reference_price : storeWithoutAuthority }}</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span class="delete-price dled">￥登录可见</span>
                                    </template>
                                </div>
                                <!--                            <p class="desc">{{ item.introduction }}</p>-->
                            </div>
                        </div>
                        <!-- <div class="goods-item" v-if="index <= 4" :key="index" :title="item.goods_name" @click="goSku(item.sku_id)">
                            <div class="img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError(index)" />
                            </div>
                            <div class="goods-a">
                                <div class="goods-name"></div>
                                <div class="goods-attr">0.5g*50支/盒</div>
                            </div>
                            <div class="price-box">
                                <div class="price">
                                    <template v-if="token">
                                        <span class="price-num">￥{{ item.control.show_price ? item.price : storeWithoutAuthority }}</span>
                                    </template>
                                    <template v-else>
                                        <span class="price-num">￥登录可见</span>
                                    </template>
                                </div>
                                <div class="market-price">
                                    <template v-if="token">
                                        <div class="market-t">
                                            <span class="delete-price">￥{{ item.control.show_price ? item.market_price : storeWithoutAuthority }}</span>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <span class="delete-price dled">￥登录可见</span>
                                    </template>
                                </div>
                            </div>
                        </div>-->
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import Swiper from "swiper"
import { onTip } from "@/utils/tips"
export default {
    name: "floor-style-erMei-design-4",
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number
        }
    },
    data() {
        return {}
    },
    mounted() {
        new Swiper(".swiper-for-design-3", {
            loop: true, // 循环模式选项
            autoplay: {
                delay: 3000
            },
            slidesPerView: 7,
            centeredSlides: true,
            centeredSlidesBounds: true,
            navigation: {
                nextEl: ".test2",
                prevEl: ".test1"
            },
            pagination: {
                el: ".swiper-pagination"
            }
        })
    },
    watch: {},
    created() {},
    computed: {
        ...mapGetters(["defaultGoodsImage", "token", "storeWithoutAuthority"])
    },
    methods: {
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price,item.control.control_msg)) return
            this.$router.pushToTab("/sku?id=" + item.skuId)
        },
        imageError(e) {
            // this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage
            e.target.src =this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: item.num
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper-slide {
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    border: 1px solid #eee;
    box-sizing: border-box;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.floor-style-erMei-design-3 {
    margin-bottom: 40px;
    background: #fff;

    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;
            .el-image {
                width: 30px;
                height: 30px;
            }
            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }

    .body-wrap {
        .goods-container {
            padding: 10px;
            // flex-wrap: wrap;
            display: flex;
            //justify-content: space-between;
            .goods-item {
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 182px;
                height: 240px;
                box-sizing: border-box;
                margin-right: 14px;
                // margin-bottom: 10px;
                transition: all 0.3s ease-out;
                border: 1px solid #f0f0f0;

                &:hover {
                    // box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
                    opacity: 0.8;
                }

                div {
                    line-height: normal;
                }

                .goods-name {
                    font-size: 12px;
                    color: #000;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .goods-a {
                    width: 100%;
                    padding-left: 20px;
                    .goods-attr {
                        margin: 5px 0px;
                        font-size: 12px;
                        color: #000;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .img-wrap {
                    width: 130px;
                    height: 140px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 10px;
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .price-box {
                    width: 100%;
                    display: flex;
                    padding-left: 20px;
                    .price {
                        display: inline-block;
                        height: 28px;
                        line-height: 28px;
                        font-size: 18px;
                        font-weight: bold;
                        margin-right: 5px;
                        color: red;
                        // background-color: rgb(255, 86, 12);
                    }
                }
                .market-price {
                    color: #ccc;
                    font-size: 13px;
                    margin-top: 7px;
                    max-width: 100%;
                    .market-t {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                    .delete-price {
                        padding-right: 10px;
                    }
                }
            }
        }
    }
    .dled {
        text-decoration: line-through;
    }
}
</style>
