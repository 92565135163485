
// 默认结算接口
import {
	payment,
	calculate,
	orderCreate
} from "@/api/order/payment"

import * as skuApis from "@/api/goods/goods"
import * as seckillApis from "@/api/seckill"
import * as wholesaleApis from "@/api/wholesale"
import * as groupbuyApis from "@/api/groupbuy"
import * as pintuanApis from "@/api/pintuan"
import * as discountApis from "@/api/discount"
import * as getfreeApis from "@/api/getfree"
/*
* listApi 列表接口
* price
*   market_price 划线
*   discount_price 标
*
* sessionInfo 场次信息
*   key: 场次id字段名称
*   getId 获取场次id值
* tagText 显示标签
* priceText 实际价格label
* 
* getDetailUrl 获取详情页链接
* detail 详情页参数
*   request 详情接口
*   paramsKeys 接口传参字段
*   init this指向 -> 活动页活动组件实例， 用于修改数量
* promotion 加入购物车传参
*   promotion_type 商品活动类型
*   getPromotion_id（detail : goods详细） 获取活动id
* payment 结算页传参
*   payment paymentApi,
*   calculate calculateApi,
*   orderCreate orderCreateApi,
*   paramsKeys 接口传参字段
* */
export default {
    sku: {
        priceText: '销售价',
        price: {
            market_price: 'market_price',
            discount_price: 'discount_price'
        },
        detail: {
            request: skuApis.goodsSkuDetail,
            paramsKeys: {
                id: 'sku_id'
            }
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.sku_id)
            return `/sku?id=${id}`
        },
        // payment: {
        //     payment,
        //     calculate,
        //     orderCreate,
        //     paramsKeys: {
        //         id: 'sku_id'
        //     }
        // },
    },
    seckill: {
        type: 'seckill',
        listApi: seckillApis.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'seckill_price'
        },
        tagText: '秒杀',
        priceText: '秒杀价',
        sessionInfo: {
            key: 'seckill_id',
            getId() {
                return this.info.seckill_time.id
            },
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.id)
            return `/sku?id=${id}&activity=${this.type}`
        },
        detail: {
            request: seckillApis.goodsSkuDetail,
            paramsKeys: {
                id: 'seckill_id'
            }
        },
        promotion: {
            promotion_type: 'seckill',
            getPromotion_id(detail) {
                return detail.seckill_id
            }
        },
        // payment: {
        //     payment: seckillApis.payment,
        //     calculate: seckillApis.calculate,
        //     orderCreate: seckillApis.orderCreate,
        //     paramsKeys: {
        //         id: 'seckill_id'
        //     }
        // },
    },
    wholesale: {
        type: 'wholesale',
        tagText: '批发',
        priceText: '批发价',
        listApi: wholesaleApis.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'min_price'
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.goods_id)
            return `/sku?id=${id}&activity=${this.type}`
        },
        detail: {
            request: wholesaleApis.goodsSkuDetail,
            paramsKeys: {
                id: 'goods_id'
            },
            // .call(detail_vm)
            init(data) {
                this.$nextTick(() => {
                    this.number = data.goods_sku_detail.min_num;
                    this.min_buy = data.goods_sku_detail.min_num;
                })
            }
        },
        // 加入购物车传参
        promotion: {
            promotion_type: 'wholesale',
            getPromotion_id(detail) {
                return detail.wholesale_sku_id
            }
        },
        // 结算页传参
        // payment: {
        //     dispatch: "order/setWholesaleOrderCreateData",
        //     path: "/activity/payment/wholesale",
        //     paramsKeys: {
        //         id: 'sku_id'
        //     }
        // }
    },
    groupbuy: {
        type: 'groupbuy',
        tagText: '团购',
        priceText: '团购价',
        listApi: groupbuyApis.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'groupbuy_price'
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.groupbuy_id)
            return `/sku?id=${id}&activity=${this.type}`
        },
        detail: {
            request: groupbuyApis.goodsSkuDetail,
            paramsKeys: {
                id: 'groupbuy_id'
            }
        },
        promotion: {
            promotion_type: 'groupbuy',
            getPromotion_id(detail) {
                return detail.groupbuy_id
            }
        },
    },
    pintuan: {
        type: 'pintuan',
        tagText: '拼团',
        priceText: '拼团价',

        listApi: pintuanApis.goodsPage,
        price: {
            market_price: 'price',
            discount_price: 'pintuan_price'
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.pintuan_id)
            return `/sku?id=${id}&activity=${this.type}`
        },
        detail: {
            request: pintuanApis.goodsSkuDetail,
            paramsKeys: {
                id: 'pintuan_id'
            }
        },
        promotion: {
            promotion_type: 'pintuan',
            getPromotion_id(detail) {
                return detail.pintuan_id
            }
        },
    },
    // discount: {
    //     type: 'discount',
    //     tagText: '抢购',
    //     priceText: '抢购价',
    //
    //     listApi: discountApis.goodsPage,
    //     price: {
    //         market_price: 'price',
    //         discount_price: 'discount_price'
    //     },
    //     getDetailUrl(id) {
    //         typeof id === 'object' && (id = id.discount_id)
    //         return `/sku?id=${id}&activity=${this.type}`
    //     },
    //     detail: {
    //         request: discountApis.goodsSkuDetail,
    //         paramsKeys: {
    //             id: 'discount_id'
    //         }
    //     },
    //     promotion: {
    //         promotion_type: 'discount',
    //         getPromotion_id(detail) {
    //             return detail.discount_id
    //         }
    //     },
    // },
    getfree: {
        type: 'getfree',
        tagText: '换购',
        priceText: '销售价',
        listApi: getfreeApis.goodsPage,
        price: {
            market_price: '',
            discount_price: 'price'
        },
        getDetailUrl(id) {
            typeof id === 'object' && (id = id.id)
            return `/sku?id=${id}&activity=${this.type}`
        },
        detail: {
            request: getfreeApis.goodsSkuDetail,
            paramsKeys: {
                id: 'getfree_goods_id'
            }
        },
        promotion: {
            promotion_type: 'getfree',
            getPromotion_id(detail) {
                return detail.id
            }
        },
    }
}