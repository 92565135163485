<template>
    <div class="basic-info-wrap">
        <h1>{{ goodsSkuDetail.sku_name }}</h1>
        <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>
        <!-- begin -->
        <div class="discount-banner ns-bg-color" style="background-color: rgb(209, 121, 7) !important;">
            <div class="activity-name" style="">
                <!-- <i class="discount-icon iconfont iconicon_naozhong"></i> -->
                <span>{{text}}</span>
            </div>
            <div class="surplus-time">
                <span></span>
            </div>
        </div>

        <div class="item-block">
            <div class="promotion-price">

                <dl class="item-line">
                    <dt class="ns-text-color-gray">{{ activityMapItem.priceText }}</dt>
                    <dd>
                        <em class="yuan ns-text-color">¥</em>
                        <span class="price ns-text-color" v-if="token">{{ goodsSkuDetail.control &&
                            goodsSkuDetail.control.show_price ? Number(goodsSkuDetail[activityMapItem.price.discount_price])
                            :
                            storeWithoutAuthority }}</span>
                        <span class="price ns-text-color" v-else>登录可见</span>
                    </dd>
                </dl>
                <!-- <dl class="item-line" v-if="goodsSkuDetail[activityMapItem.price.market_price]>0">
                    <dt class="ns-text-color-gray">原价</dt>
                    <dd>
                        <em class="market-yuan">¥</em>
                        <span class="market-price" v-if="token">{{ goodsSkuDetail.control &&
                            goodsSkuDetail.control.show_price ? Number(goodsSkuDetail[activityMapItem.price.market_price]) :
                            storeWithoutAuthority }}</span>
                        <span class="market-price" v-else>登录可见</span>
                    </dd>
                </dl> -->


                <div class="statistical">
                    <ul>
                        <li>
                            <p>累计评价</p>
                            <span>{{ goodsSkuDetail.evaluate }}</span>
                        </li>
                        <li>
                            <p>累计销量</p>
                            <span>{{ goodsSkuDetail.sale_num }}{{ goodsSkuDetail.unit }}</span>
                        </li>
                    </ul>
                </div>

                <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
                    <dt>运费</dt>
                    <dd>
                        <i class="i-activity-flag ns-text-color ns-border-color"
                            v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
                        <i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
                    </dd>
                </dl>
            </div>
        </div>
        <!-- end -->

        <dl class="item-line service">
            <dt>服务</dt>
            <dd>
                <span>
                    由
                    <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name
                    }}</router-link>
                    发货并提供售后服务
                </span>
            </dd>
        </dl>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec">
                <dt>商品规格</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%" v-if="goodsSkuDetail.unit">
                <dt>包装单位</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.unit }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope">
                <dt>经营类型</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy">
                <dt>中包装</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory">
                <dt>生产厂家</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package">
                <dt>件装量</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code">
                <dt>国家码</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date">
                <dt>效期</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date }}
                    </span>
                </dd>
            </dl>
        </div>
        <dl class="item-line service" v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number">
            <dt>批准文号</dt>
            <dd>
                <span>
                    {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number }}
                </span>
            </dd>
        </dl>
        <hr class="divider" />
        <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
            <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
                <dt>{{ item.spec_name }}</dt>
                <dd>
                    <ul>
                        <li v-for="(item_value, index_value) in item.value" :key="index_value">
                            <div :class="{
                                'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                            }" @click="changeSpec(item_value.sku_id, item_value.spec_id)">
                                <img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
                                <span>{{ item_value.spec_value_name }}</span>
                                <i class="iconfont iconduigou1 ns-text-color"></i>
                            </div>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>

        <div class="buy-number" v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 &&goodsSkuDetail.stock > 0">
            <dl class="item-line">
                <dt>数量</dt>
                <dd style="display: flex;">
                    <div>
                        <!-- <el-input-number v-model="number"
                            :step="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy > 0 ? Number(goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy) : 1"
                            size="mini"
                            :min="min_buy ? min_buy : (goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy > 0 ? Number(goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy) : 1)"
                            :max="goodsSkuDetail.stock" @change="keyInput($event)" @blur="deve($event)">
                        </el-input-number> -->
                        <shareCounter :number="number" :goodsSkuDetail="goodsSkuDetail" size="mini" @upnumber="upnumber"></shareCounter>
                    </div>
                    <span class="inventory" v-if="goodsSkuDetail.package_num<goodsSkuDetail.stock">库存{{ goodsSkuDetail.stock }}</span>
                    <span
                        style="display: none; margin-left: 20px; width: 179px;height: 13px;font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #FD274A;">由官方旗舰店发货并提供售后服务</span>
                </dd>
            </dl>
        </div>
        <!-- 换购区 -->
        <dl class="item-line">
            <dt>赠送商品</dt>
            <dd class="trade-in-goods" style="display: flex">
                <div class="goods-item"
                    v-for="(exchangeitem, exchangeindex) in goodsSkuDetail.exchange_goods_list"
                    @click="toGoodsDetial(exchangeitem.sku_id)"
                    :key="exchangeindex">
                        <img :src="$img(exchangeitem.goods_image)" alt=""/>
                        <div class="name_count">
                            <div class="left-name">{{ exchangeitem.goods_name }}</div>
                            <div class="right-count">x{{ exchangeitem.exchange_num }}</div>
                        </div>

                </div>
            </dd>
        </dl>

        <dl class="item-line buy-btn">
            <dt></dt>
            <dd>
                <template
                    v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.stock > 0">
                    <!-- plain  鼠标划入变换效果 -->
                    <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.is_member_buy">
                        <el-button type="primary" v-if="activityType === 'sku'" @click="buyNow">立即购买</el-button>
                        <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_virtual == 0"
                            @click="joinCart" style="background: #FF860F;">加入购物车</el-button>
                    </template>
                    <template v-else>
                        <el-button type="infor" :disabled="true">{{ storeWithoutAuthority }}</el-button>
                    </template>
                </template>
                <template v-else>
                    <el-button type="info" plain disabled>库存不足</el-button>
                </template>
                <!--					<div href="javascript:;" class="go-phone">-->
                <!--						<span style="color: #666666;margin-right: 10px;">手机购买</span>-->
                <!--						<img src="@/assets/images/goods/qrcode.png" /> -->
                <!--						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>-->
                <!--					</div>-->
            </dd>
        </dl>

        <!-- 店铺信息 -->
        <dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 ||
            shopInfo.shop_qtian == 1 ||
            shopInfo.shop_zhping == 1 ||
            shopInfo.shop_erxiaoshi == 1 ||
            shopInfo.shop_tuihuo == 1 ||
            shopInfo.shop_shiyong == 1 ||
            shopInfo.shop_shiti == 1 ||
            shopInfo.shop_xiaoxie == 1
            ">
            <dt>商家服务</dt>
            <div>
                <dd v-if="shopInfo.shop_baozh == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="保证服务">保证服务</span>
                </dd>
                <dd v-if="shopInfo.shop_qtian == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
                </dd>
                <dd v-if="shopInfo.shop_zhping == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
                </dd>
                <dd v-if="shopInfo.shop_erxiaoshi == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
                </dd>
                <dd v-if="shopInfo.shop_tuihuo == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
                </dd>
                <dd v-if="shopInfo.shop_shiyong == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="试用中心">试用中心</span>
                </dd>
                <dd v-if="shopInfo.shop_shiti == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="实体验证">实体验证</span>
                </dd>
                <dd v-if="shopInfo.shop_xiaoxie == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
                </dd>
            </div>
        </dl>



    </div>
</template>
<script>
import mixin from './common/mixin';
import activityMap from '@/utils/activityMap';

export default {
    mixins: [mixin],
    data() {
        return {

        }
    },
    methods: {
        toGoodsDetial(id) {
            window.open(activityMap.sku.getDetailUrl(id), '_blank');
        }
    },
    computed: {
        text() {
            return this.goodsSkuDetail.exchange_num && `换购活动商品，买${this.goodsSkuDetail.exchange_num}${this.goodsSkuDetail.unit}赠送以下商品（任选${this.goodsSkuDetail.choose_num}种）`
        }
    },
    watch: {
        goodsSkuDetail(newVal, oldVal) {
            console.log(JSON.parse(JSON.stringify(newVal)));
        }
    }
}
</script>

<style lang="scss" scoped>
    .trade-in-goods {
        flex-wrap: wrap;
        .goods-item {
            width: 100px;
            display: flex;
            align-items: center;
            flex-direction: column;
            cursor: pointer;
            padding: 5px;
            position: relative;
            &::after {
                position: absolute;
                content: '查看详细';
                inset: 0;
                color: #fff;
                background: rgba(0, 0, 0, .5);
                z-index: 10;
                display: flex;
                justify-content: center;
                align-items: center;
                opacity: 0;
                transition: opacity .3s;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }

            img {
                display: block;
                width: 100%;
                margin-bottom: 5px;
            }
        }

        .name_count {
            line-height: normal;
            width: 100%;
            display: flex;
            align-items: center;

            .left-name {
                text-align: center;
                width: 90%;
                // 文字溢出显示省略号
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .right-count {
                color: red;
            }
        }
    }
</style>