import { render, staticRenderFns } from "./inforefund.vue?vue&type=template&id=05afe92e&scoped=true&"
import script from "./inforefund.vue?vue&type=script&lang=js&"
export * from "./inforefund.vue?vue&type=script&lang=js&"
import style0 from "./inforefund.vue?vue&type=style&index=0&id=05afe92e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "05afe92e",
  null
  
)

export default component.exports