<template>
    <div class="floor-style-erMei-design-2 floor-wsw" :id="data.block_name + c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image
                    @click="$router.pushToTab(data.value.more.value.link)"
                    :src="data.value.titleImg.value.url"
                    fit="scale-down"
                ></el-image>
                <h2
                    @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }"
                >{{ data.value.title.value.text }}</h2>
                <span
                    class="warp-title"
                    :style="{ color: data.value.subTitle.value.color }"
                >{{ data.value.subTitle.value.text }}</span>
            </div>
            <div class="right-head-warp">
                <div
                    @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }"
                >
                    <span
                        :style="{ color: data.value.more.value.color }"
                    >{{ data.value.more.value.text }}</span>
                    <i
                        :style="{ color: data.value.more.value.color }"
                        class="icon el-icon-arrow-right"
                    ></i>
                </div>
            </div>
        </div>
        <div class="body-wrap">
            <div :class="['swiper-container','swiper-for-design-2'+ c_index]">
                <div class="swiper-wrapper">
                    <!--   @click="$router.pushToTab(item.value.link.url)" -->
                    <div
                        class="swiper-slide"
                        v-for="(item,index) in data.value.bannerImgs"
                        :key="index"
                        :data-card="JSON.stringify(item)"
                    > 
                        <img :src="$img(item.value.url)"  alt />
                    </div>
                </div>
                <!--                <div class="swiper-pagination"></div>-->
                <!-- 如果需要导航按钮 -->
                <!--                <div class="swiper-button-prev"></div>-->
                <!--                <div class="swiper-button-next"></div>-->
                <!--                <div class="swiper-button-prev  swiper-button test1">-->
                <!--                    <i class="icon el-icon-arrow-left"></i>-->
                <!--                </div>-->
                <!--                <div class="swiper-button-next swiper-button test2">-->
                <!--                    <i class="icon el-icon-arrow-right"></i>-->
                <!--                </div>-->
            </div>
            <div class="goods-container">
                <template v-for="(item, index) in data.value.goodsList.value.list">
                    <div
                        class="goods-item"
                        v-if="index <= 4"
                        :key="index"
                        :title="item.goods_name"
                        @click="goSku(item)"
                    >
                        <div class="goods-name">{{ item.goods_name }}</div>
                        <div class="goods-attr">0.5g*50支/盒</div>
                        <div class="img-wrap">
                            <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError" />
                        </div>
                        <div class="price">
                            <template v-if="token">
                                <span class="price-num">
                                    ￥{{ item.control.show_price ? item.price : storeWithoutAuthority
                                    }}
                                </span>
                            </template>
                            <template v-else>
                                <span class="price-num">￥登录可见</span>
                            </template>
                        </div>
                        <div class="market-price">
                            <template v-if="token">
                                <span class="delete-price" v-if="item.reference_price>0&&item?.reference_price">
                                    零：￥{{ item.control.show_price ? item.reference_price : storeWithoutAuthority
                                    }}
                                </span>
                            </template>
                            <template v-else>
                                <span class="delete-price">￥登录可见</span>
                            </template>
                        </div>
                        <!--                            <p class="desc">{{ item.introduction }}</p>-->
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex"
import Swiper from "swiper"
import { onTip } from "@/utils/tips"
export default {
    name: "floor-style-erMei-design-2",
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number || String
        }
    },
    data() {
        return {
            swiperObj: null
        }
    },
    mounted() {
        const c_index = this.c_index
        this.swiperObj = new Swiper(".swiper-for-design-2" + c_index, {
            slidesPerView: 3,
            pagination: {
                el: ".swiper-pagination"
            },
            loop: true,
            autoplay: {
                delay: 2000
            },
            allowTouchMove:true, // 默认值为true，表示允许通过触摸或鼠标拖拽来切换幻灯片
            on:{
                click:(e)=>{
                    this.handleClick(e)
                }
            },
        })
        if (this.data.value.bannerImgs.length <= 3) {
            this.swiperObj.loop = false
            this.swiperObj.autoplay.stop()
            this.swiperObj.allowTouchMove = false // 当幻灯片数量小于或等于3时，禁止通过鼠标拖拽切换幻灯片
            this.swiperObj.update()
        }
    },
    watch: {
        // data: {
        //     handler(val) {
        //         console.log("val---", val)
        //     },
        //     immediate: true
        // }
        // token: {
        //     handler(val) {
        //         console.log(val)
        //     },
        //     immediate: true
        // }
    },
    created() {},
    computed: {
        ...mapGetters(["defaultGoodsImage", "token", "storeWithoutAuthority"])
    },
    methods: {
        handleClick(e) {
            const dataCard = e.target.closest('.swiper-slide').getAttribute('data-card');
            let  dataall=JSON.parse(dataCard)
            this.$router.pushToTab(dataall.value.link.url)
        },
        // goSku(skuId) {
        //     this.$router.pushToTab("/sku?id=" + skuId)
        // },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price,item.control.control_msg)) return

            this.$router.pushToTab("/sku?id=" + item.sku_id)
        },
        imageError(e) {
            e.target.src =this.defaultGoodsImage
            // this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: item.num
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.swiper {
    width: 100%;
    height: 300px;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    //border: 1px solid red;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.floor-style-erMei-design-2 {
    margin-bottom: 40px;
    background: #fff;

    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;
            // img {
            //     display: inline-block;
            //     vertical-align: middle;
            //     width: 30px;
            //     height: 30px;
            //     margin-right: 10px;
            // }
            .el-image {
                width: 30px;
                height: 30px;
            }
            .warp-title {
                margin-left: 10px;
            }
            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }

    .swiper-button {
        .icon {
            font-weight: 800;
        }
    }

    .test1,
    .test2 {
        display: block;
        width: 30px;
        height: 30px;
        z-index: 99;
        color: #fff;
        font-weight: 700;
        text-align: center;
        line-height: 30px;
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.2);
        border-radius: 15px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.4);
        }
    }

    .swiper-button-prev::after {
        display: none;
    }

    .swiper-button-next::after {
        display: none;
    }

    .body-wrap {
        .goods-container {
            padding: 10px;
            display: flex;
            gap: 10px;
            //justify-content: space-between;
            .goods-item {
                flex-grow: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 228px;
                border-radius: 10px;
                box-sizing: border-box;
                padding: 10px;
                transition: all 0.3s ease-out;
                border: 1px solid #f0f0f0;

                &:hover {
                    box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
                }

                div {
                    line-height: normal;
                }

                .goods-name {
                    font-size: 12px;
                    color: #000;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .goods-attr {
                    font-size: 12px;
                    color: #999;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .img-wrap {
                    width: 130px;
                    height: 140px;
                    padding: 10px 10px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .price {
                    display: inline-block;
                    height: 28px;
                    line-height: 28px;
                    font-size: 20px;
                    padding: 0 14px;
                    border-radius: 15px;
                    color: rgb(255, 255, 255);
                    background-color: rgb(255, 86, 12);
                }

                .market-price {
                    color: rgb(255, 0, 0);
                    font-size: 16px;
                    margin-top: 8px;
                    font-weight: 700;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: 100%;
                }
            }
        }
    }
}
</style>
