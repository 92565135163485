import {
	mapGetters
} from "vuex"
import {
	cartList
} from "@/api/goods/cart"
import activityMap from '@/utils/activityMap'

export default {
	data: () => {
		return {
			cartList: [], // 购物车
			checkAll: false,
			totalPrice: "0.00",
			totalCount: 0,
			invalidGoods: [], // 失效商品集合
			loading: true,
			modifyNum: 1, // 防止数量跳动
			params:null, //点击累计器当前的位置,
			activityMap,
			cartListIfon: [],
			
		}
	},
	created() {
		this.getCartList()
	},
	computed: {
		...mapGetters(["defaultGoodsImage"])
	},
	methods: {
		// 获取购物车数据
		getCartList() {
			cartList({})
				.then(res => {
					if (res.code >= 0 && res.data.length) {
						this.handleCartList(res.data)
					}
					this.loading = false
				})
				.catch(res => {
					this.loading = false
				})
		},
		// 处理购物车数据结构
		handleCartList(data) {
			this.invalidGoods = []
			this.cartList = []
			var temp = {}
			data.forEach((item, index) => {
				if (item.goods_state == 1 && item.verify_state == 1) {
					item.checked = true
					if (temp["site_" + item.site_id] != undefined) {
						temp["site_" + item.site_id].cartList.push(item)
					} else {
						temp["site_" + item.site_id] = {
							siteId: item.site_id,
							siteName: item.site_name,
							checked: true,
							cartList: [item]
						}

					}
				} else {
					this.invalidGoods.push(item)
				}
			})


			this.invalidGoods.forEach(v => {
				if (v.sku_spec_format) {
					v.sku_spec_format = JSON.parse(v.sku_spec_format)
				} else {
					v.sku_spec_format = []
				}
			})
			Object.keys(temp).forEach(key => {
				this.cartList.push(temp[key])
			})

			this.calculationTotalPrice()
			console.log("最大",this.cartList)
	        // for (let index = 0; index < this.cartList.length; index++) {
			// 		  for (let i = 0; i<this.cartList[index].cartList.length; i++) {
			// 			 console.log(1111,this.cartList[index].cartList[i])
			// 		  }
			// 	 }    
			
			this.cartList.forEach((v,index) => {
				
				v.cartList.forEach((k,i) => {
					if (this.cartList[index].cartList[i].sku_spec_format) {
						this.cartList[index].cartList[i].sku_spec_format = JSON.parse(this.cartList[index].cartList[i].sku_spec_format)
					} else {
						this.cartList[index].cartList[i].sku_spec_format = []
					}
				//提示息过滤出来药品拆装不予退还的相关细则
			    // 确保 cartList 不为空
				if (v.cartList.length > 0 && i<1) {
					const firstItem = v.cartList[0];
					// 提取并推入当前 cartList 的第一个元素到 cartListIfon
					this.cartListIfon.push({
						minimal_charge: Number(Math.ceil(v.cartList[0].shop_info.minimal_charge * 100) / 100).toFixed(2),
						freeshippings: Number(Math.ceil(v.cartList[0].shop_info.freeshipping * 100) / 100).toFixed(2),
						express_money: Number(Math.ceil(v.cartList[0].shop_info.express_money * 100) / 100).toFixed(2)
					});
				}
				})
				
			})
		},
		// 单选
		singleElection(siteIndex, index) {
			this.calculationTotalPrice()
		},
		// 店铺全选
		siteAllElection(index) {
			this.cartList[index].cartList.forEach(item => {
				item.checked = this.cartList[index].checked
			})
			this.calculationTotalPrice()
		},
		// 全选
		allElection() {
			if (this.cartList.length) {
				this.cartList.forEach(siteItem => {
					siteItem.checked = this.checkAll
					siteItem.cartList.forEach(item => {
						item.checked = this.checkAll
					})
				})
			}
			this.calculationTotalPrice()
		},
		// 计算购物车总价
		calculationTotalPrice() {
		let minBuy=0
		 if(this.params){
			   minBuy =this.cartList[this.params.siteIndex].cartList[this.params.cartIndex].min_buy
			}
			if (this.cartList.length) {
				let totalPrice = 0,
					totalCount = 0,
					siteAllElectionCount = 0

				this.cartList.forEach(siteItem => {
					let siteGoodsCount = 0
					siteItem.cartList.forEach(item => {
						if (item.checked) {
							siteGoodsCount += 1;
							totalCount += parseInt(item.num);
							// 先判断是否有促销活动 如秒杀活动
							// 在限购范围内享受折扣价（promotion_price或member_price， member_price优先于promotion_price）
							// 限购范围外原价price
							if (!item.promotion_limit) {
									
									
								totalPrice += item.discount_price * item.num;
								
							
							
								return;
							}
							let withinPromotionLimit = Math.min(item.num, item.promotion_limit);//范围内
							let overPromotionLimit = Math.max(0, item.num - item.promotion_limit);//超出范围

							if (Number(item.member_price) > 0 && Number(item.member_price) < Number(item.promotion_price)) {
								
								
								totalPrice += item.member_price * withinPromotionLimit;
							} else {
							
								totalPrice += item.discount_price * withinPromotionLimit;
							}
							if (overPromotionLimit > 0) {
							
								totalPrice += item.price * overPromotionLimit;
							}
						}
					})
					if (siteItem.cartList.length == siteGoodsCount) {
						siteItem.checked = true
						siteAllElectionCount += 1
					} else {
						siteItem.checked = false
					}
				})
				this.totalPrice = totalPrice.toFixed(2)
				this.totalCount = totalCount
				this.checkAll = this.cartList.length == siteAllElectionCount
			} else {
				this.totalPrice = "0.00"
				this.totalCount = 0
			}
			this.modifyNum = minBuy > 0 ? minBuy : 1;
		},
		calcUnitPrice(item) {
			if (item.promotion) {
				// 不限制活动商品数量
				if(!item.promotion_limit) {
					return (item.promotion_price * item.num).toFixed(2);
				}
				let totalPrice = 0
				let withinPromotionLimit = Math.min(item.num, item.promotion_limit); //范围内
				let overPromotionLimit = Math.max(0, item.num - item.promotion_limit); //超出范围

				if (Number(item.member_price) > 0 && Number(item.member_price) < Number(item.promotion_price)) {
					totalPrice += item.member_price * withinPromotionLimit;
				} else {
					totalPrice += item.promotion_price * withinPromotionLimit;
				}
				if (overPromotionLimit > 0) {
					totalPrice += item.price * overPromotionLimit;
				}
				return totalPrice.toFixed(2)
			} else {
				return (item.discount_price * item.num).toFixed(2);
			}

		},
		// 删除单个
		deleteCart(siteIndex, cartIndex) {
			this.$confirm("确定要删除该商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: this.cartList[siteIndex].cartList[cartIndex].cart_id.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							this.cartList[siteIndex].cartList.splice(cartIndex, 1)
							if (this.cartList[siteIndex].cartList.length == 0) this.cartList.splice(siteIndex, 1)
							this.calculationTotalPrice()
							this.$message({
								type: "success",
								message: "删除成功"
							})
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			})
		},
		// 删除选择的购物车
		deleteCartSelected() {
			var cartIds = []
			var selectedItem = []
			this.cartList.forEach((siteItem, siteIndex) => {
				siteItem.cartList.forEach((item, cartIndex) => {
					if (item.checked) {
						cartIds.push(item.cart_id)
						selectedItem.push({
							siteIndex: siteIndex,
							cartIndex: cartIndex,
							siteId: siteItem.siteId,
							cartId: item.cart_id
						})
					}
				})
			})

			if (cartIds.length == 0) {
				this.$message({
					message: "请选择要删除的商品",
					type: "warning"
				})
				return
			}

			this.$confirm("确定要删除选择的商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$store
					.dispatch("cart/delete_cart", {
						cart_id: cartIds.toString()
					})
					.then(res => {
						if (res.code >= 0) {
							selectedItem.forEach(selectedItem => {
								this.cartList.forEach((siteItem, siteIndex) => {
									siteItem.cartList.forEach((item, cartIndex) => {
										if (selectedItem.cartId == item.cart_id) {
											siteItem.cartList.splice(cartIndex, 1)
										}
										if (siteItem.cartList.length == 0) {
											this.cartList.splice(siteIndex, 1)
										}
									})
								})
							})
							this.calculationTotalPrice()
							this.$message({
								type: "success",
								message: "删除成功"
							})
						} else {
							this.$message({
								message: res.message,
								type: "warning"
							})
						}
					})
					.catch(err => {
						this.$message.error(err.message)
					})
			})
		},
		// 清空失效商品
		clearInvalidGoods() {
			this.$confirm("确认要清空这些商品吗？", "提示信息", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				var cartIds = []
				this.invalidGoods.forEach(goodsItem => {
					cartIds.push(goodsItem.cart_id)
				})
				if (cartIds.length) {
					this.$store
						.dispatch("cart/delete_cart", {
							cart_id: cartIds.toString()
						})
						.then(res => {
							if (res.code >= 0) {
								this.invalidGoods = []
								this.$message({
									type: "success",
									message: "删除成功"
								})
							} else {
								this.$message({
									message: res.message,
									type: "warning"
								})
							}
						})
						.catch(err => {
							this.$message.error(err.message)
						})
				}
			})
		},
		// 计算累计器数量起始值是否乘以中包装的倍数
		dev(e, params) {
			let num = e.target.value
			this.modifyNum = this.pcs > 0 ? this.pcs : 1;
			if (num < 1 || !num) num = this.modifyNum;
			// this.cartList[params.siteIndex].cartList[params.cartIndex].min_buy=50
			this.pcs = Number(this.cartList[params.siteIndex].cartList[params.cartIndex].min_buy)
			if (this.pcs > 0 && num > this.pcs) {
				num = Math.floor(num / this.pcs) * this.pcs;
			} else if (this.pcs > 0 && num < this.pcs) {
				num = this.pcs
			} else if (this.pcs == 0) {
				this.cartList[params.siteIndex].cartList[params.cartIndex].num = num
			}
			this.cartList[params.siteIndex].cartList[params.cartIndex].num = num
		},
		// 变更购物车数量
		cartNumChange(num, params) {
			// 防止数量跳动
			// this.modifyNum = 0;
			this.params=params
			if (!num) return
			this.$store
				.dispatch("cart/edit_cart_num", {
					num,
					cart_id: this.cartList[params.siteIndex].cartList[params.cartIndex].cart_id
				})
				.then(res => {
					if (res.code >= 0) {
						// this.cartList[params.siteIndex].cartList[params.cartIndex].num = num;
						this.calculationTotalPrice();
					} else {
						this.$message({
							message: res.message,
							type: "warning"
						});
						this.modifyNum = this.pcs > 0 ? this.pcs : 1;
					}
				})
				.catch(err => {
					this.$message.error(err.message);
					this.modifyNum = this.pcs > 0 ? this.pcs : 1;
				})
		},
		// 结算
		settlement() {
			if (this.totalCount > 0) {
				let cart_ids = []
				this.cartList.forEach(siteItem => {
					siteItem.cartList.forEach(item => {
						if (item.checked) {
							cart_ids.push(item.cart_id)
						}
					})
				})

				var data = {
					cart_ids: cart_ids.toString()
				}
				this.$store.dispatch("order/setOrderCreateData", data)
				this.$router.push({
					path: "/payment"
				})
			}
		},
		imageError(siteIndex, cartIndex) {
			this.cartList[siteIndex].cartList[cartIndex].sku_image = this.defaultGoodsImage
		},
		imageErrorInvalid(index) {
			this.invalidGoods[index].sku_image = this.defaultGoodsImage
		}
	}
}
