<template>
    <div class="shareCounter" @click.stop>
       <template v-if="goodsSkuDetail.package_num<goodsSkuDetail.stock">
        <el-input-number @change="hangenumbers($event)" 
                            :value="numbers"
                            :placeholder="String(numbers)"
                            :step="goodsSkuDetail.min_buy > 0 && goodsSkuDetail.package_num ? Number(goodsSkuDetail.package_num) : 1"
                            size="mini"
                            :min="goodsSkuDetail.package_num?goodsSkuDetail.min_buy:1"
                            step-strictly
                            :max="goodsSkuDetail.stock"
                            >
                       
                        </el-input-number>
                           <!-- <div class="cart-num">
                                <span class="el-icon-minus" @click.stop="step(1)"></span>
                                <div class="number"><el-input v-model="numbers" size="small" placeholder="0" @change="keyInput($event)" ></el-input></div>
                                <span class="el-icon-plus" @click.stop="step(2)"></span>
                            </div> -->
       </template>
       <template v-else>
          <el-button disabled size="small">库存不足</el-button>
       </template>
    </div>
</template>

<script>
    export default {
        props:{
         number:{
            tyep:Number,
            default:1
         },
         goodsSkuDetail:{
            tyep:Object,
            default:()=>{}
         },
         size:{
            tyep:String,
            default:'mini'
         }
        },
        data(){
            return{
                numbers:this.number,
            }
        },
        watch:{
            number(val,neval){
                    this.numbers=val
                    this.$emit('upnumber',val)
            },
        },
        methods:{
            hangenumbers(e){
                 this.numbers=e
                 this.$emit('upnumber',this.numbers)
            },
                   // 累计器相加倍数
        // step(num){
        //     // switch(val){
        //     //   case 1 : 
        //     //   this.numbers-=this.goodsSkuDetail.package_num
        //     //   break
        //     //   case 2:
        //     //   this.numbers+=this.goodsSkuDetail.package_num
        //     // }
        //      let package_num=this.goodsSkuDetail.package_num?this.goodsSkuDetail.package_num:1
        //      if(this.goodsSkuDetail.stock - num < package_num){
        //         this.numbers=this.goodsSkuDetail.stock 
        //      }
        //      else if(num - package_num <this.goodsSkuDetail.min_buy){
        //          this.numbers=this.goodsSkuDetail.min_buy 
        //      }
        // },
        // //输入数量
        // keyInput(num) {
        //     var stock = this.goodsSkuDetail.stock
        //     // 库存为0
        //     if (this.goodsSkuDetail.stock == 0) {
        //         this.numbers = 0
        //         return
        //     }
        //     // this.goodsSkuDetail.extension_data.min_buy=50
        //     // 防止空
        //     if (num < 1 || !num) num = this.numbers
        //     if (this.numbers > stock) {
        //         this.numbers = stock
        //     }
        //     this.numbers = num
        //         // this.step(num)
        //     this.deve(num)
        // },
        // deve(e) {
        //     let num = Number(e)
        //     let ismodifyNum = this.pcs > 0 ? this.pcs : 1
        //     if (num < 1 || !num) num = ismodifyNum
        //     ismodifyNum = this.goodsSkuDetail.min_buy > 0 ? this.goodsSkuDetail.min_buy : 1
        //     let package_num=this.goodsSkuDetail.package_num?this.goodsSkuDetail.package_num:1
        //     if (num == this.goodsSkuDetail.stock) {
        //         this.numbers = num
        //     }
        //     else if (this.goodsSkuDetail.min_buy > 0 && num > this.goodsSkuDetail.min_buy) {
        //         this.numbers = Math.floor(num / package_num) * package_num
        //     } else if (this.goodsSkuDetail.min_buy > 0 && num < this.goodsSkuDetail.min_buy) {
        //         this.numbers = this.goodsSkuDetail.min_buy
        //         console.log(this.numbers)
        //     }
        // },
        }
    }
</script>

<style lang="scss" scoped>
        //    .cart-num{
        //                 margin-right: 10px;
        //                 position: relative;
        //                 width: 30%;
        //                 border-radius: 4px;
        //                 display: flex;
        //                 border: 1px solid #ccc;
        //                 justify-content: center;
        //                 align-items: center;
        //                 span{
        //                     z-index: 1;
        //                     position: absolute;
        //                     cursor: pointer;
        //                     width: 20px;
        //                     text-align: center;
        //                 }
        //                 span:first-child{
        //                     left: 0;
        //                 }
        //                 span:last-child{
        //                     right: 0;
        //                 }
        //                 .number{
        //                     .el-input{
        //                         .el-input__inner{
        //                             text-align: center;
        //                         }
        //                     }
        //                 }
        //             }
</style>