<template>
	<div class="floor-style-2 floor-wsw" :id="data.block_name+c_index">
		<div class="head-wrap" v-if="data.value.title.value.text">
			<h2 @click="$router.pushToTab(data.value.title.value.link.url)" :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
			<p @click="$router.pushToTab(data.value.subTitle.value.link.url)" :style="{ color: data.value.subTitle.value.color }">{{ data.value.subTitle.value.text }}</p>
		</div>
		<div class="body-wrap">
			<ul class="goods-list">
				<li v-for="(item, index) in data.value.goodsList.value.list" :key="index" :title="item.goods_name" @click="goSku(item)">
					<div class="img-wrap"><img alt="商品图片" :src="$img(item.sku_image)" @error="imageError" /></div>
					<h3>{{ item.goods_name }}</h3>
					<p class="desc">{{ item.introduction }}</p>
                    <p class="price">
                        <template v-if="token">
                            <span class="num">￥{{ item.control.show_price ? item.discount_price : storeWithoutAuthority }}</span>
                        </template>
                        <template v-else>
                            <span class="num">￥登录可见</span>
                        </template>
                        <template v-if="token">
                            <del v-if="item.market_price>0">￥{{ item.control.show_price ? item.market_price : storeWithoutAuthority }}</del>
                        </template>
                        <template v-else>
                            <del>￥登录可见</del>
                        </template>
                    </p>
                    <template v-if="token && item.control.is_member_buy">
                        <div  class="cart-layout">
                            <!-- <div class="cart-num">
                                <span class="el-icon-minus" @click.stop="item.num>1?item.num--:item.num"></span>
                                <div class="number"><el-input v-model="item.num" size="small" placeholder="0"></el-input></div>
                                <span class="el-icon-plus" @click.stop="item.num++"></span>
                            </div> -->
							<div style="width: 100px;"> <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    }" size="mini" @upnumber="upnumber"></shareCounter></div>
                            <div class="cart-add" @click.stop="joinCart(item)" v-if="item.package_num<item.stock">
                                加入购物车
                            </div>
                        </div>
                    </template>
				</li>
			</ul>
		</div>
		<div class="bottom-wrap" v-if="data.value.bottomImg.value.url">
			<img :src="$img(data.value.bottomImg.value.url)" @click="$router.pushToTab(data.value.bottomImg.value.link.url)" />
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {onTip} from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
export default {
	name: 'floor-style-2',
	components:{
        shareCounter
    },
	props: {
		data: {
			type: Object
		},
        c_index:{
            type: Number
        }
	},
	data() {
		return {
			number:1
		};
	},
	created() {},
	computed: {
		...mapGetters(['defaultGoodsImage','token','storeWithoutAuthority'])
	},
	methods: {
		upnumber(val){
              this.number=val
        },
		goSku(item) {
			if(!this.token){
            this.$router.pushToTab('/login')
            return
           }
			if(onTip(item.control.show_price,item.control.control_msg)) return 

			this.$router.pushToTab('/sku?id=' + item.sku_id);
		},

		
		imageError(e) {
			e.target.src =this.defaultGoodsImage
			// this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage;
		},
        // 加入购物车
        joinCart(item) {
            this.$store.dispatch("cart/add_to_cart", {
                site_id: item.site_id,
                sku_id: item.sku_id,
                num: this.number
            }).then(res => {
                if (res.code === 0) {
                    this.$message({
                        message: "加入购物车成功",
                        type: "success"
                    })
                }
            }).catch(err => {
                if (err.message === 'token不存在') {
                    this.$router.pushToTab('/login')
                } else {
                    this.$message.error(err.message);
                }
            })
        }
	}
};
</script>

<style lang="scss">
.floor-style-2 {
	.head-wrap {
		text-align: center;
		h2 {
			line-height: 30px;
			color: #333;
			padding: 10px;
			font-size: 22px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		p {
			color: #b0b0b0;
			padding: 0 10px;
			font-size: 14px;
			cursor: pointer;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			margin-bottom: 20px;
		}
	}
	.body-wrap {
		.goods-list {
			display: flex;
			flex-wrap: wrap;
			li {
				width: 19%;
				margin-left: 15px;
				margin-bottom: 15px;
				background: #fff;
				cursor: pointer;
				padding: 10px 0;
				transition: all 0.2s linear;
				&:nth-child(5n + 1) {
					margin-left: 0;
				}
				&:hover {
					z-index: 2;
					-webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
				}
				.img-wrap {
					width: 160px;
					height: 160px;
					margin: 0 auto 18px;
					text-align: center;
					line-height: 160px;
					img {
						max-width: 100%;
						max-height: 100%;
					}
				}
				h3 {
					font-size: 12px;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					margin: 5px 15px;
				}
				.desc {
					margin: 0 30px 10px;
					height: 20px;
					font-size: 12px;
					color: #b0b0b0;
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
				}
				.price {
					margin: 0 10px 14px;
					text-align: center;
					color: $base-color;
					del {
						margin-left: 0.5em;
						color: #b0b0b0;
					}
				}
                .cart-layout{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .cart-num{
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;
                        span{
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }
                        span:first-child{
                            left: 0;
                        }
                        span:last-child{
                            right: 0;
                        }
                        .number{
                            .el-input{
                                .el-input__inner{
                                    text-align: center;
                                }
                            }
                        }
                    }
                    .cart-add{
                        cursor: pointer;
                        border: 1px solid $base-color;
                        color: $base-color;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
			}
		}
	}
	.bottom-wrap {
		margin-top: 10px;
		width: $width;
		height: 118px;
		cursor: pointer;
		overflow: hidden;
		img {
			max-width: 100%;
		}
	}
 .el-input-number{
width: 90px;
}		
}
</style>
