<template>
    <div>
        <vPopUpDialogs v-model="showdlog" :width="600" v-loading="loading">
            <template #poheder>
                <div class="herdrs">检测到你的手机号绑定多个账号,请选择切换账号</div>
            </template>
            <template #poboady>
                <div class="acoontbox">
                    <div class="colrs" @click="clors()">X</div>
                    <div class="acoont" :class="index == indexof ? 'bodr' : ''" @click="switchover(item.member_id, index)" v-for="(item, index) in data" :key="index">
                        <p>
                            <span class="fw">账号名称：</span><span>{{ item.username }}</span>
                        </p>
                        <p>
                            <span>单位名称：</span><span>{{ item.nickname }}</span>
                        </p>
                        <!-- <p><span>所属公司：</span><span>{{item.belog}}</span></p> -->
                    </div>
                </div>
            </template>
        </vPopUpDialogs>
    </div>
</template>

<script>
import vPopUpDialogs from "@/components/popUpDialogs.vue"
import {
	captcha,
} from "@/api/website"
export default {
    name: "PcShowDialogs",
    components: {
        vPopUpDialogs
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            default: () => []
        },
        formData: {
            type: Object,
            default: () => {}
        },
        dynacodeData: {
            type: Object,
            default: () => {}
        },
        captcha: {
            type: Object,
            default: () => {}
        },
        onkdatas: {
            type: Object,
            default: () => {}
        },
    },
    data() {
        return {
            loading: false,
            showdlog: this.value,
            contData: [
            ],
            indexof: 0
        }
    },

    mounted() {},
    watch: {
        value(val) {
            this.showdlog = val
        },
        showdlog(val) {
            this.$emit("input", val) // 保持父组件的 v-model 同步
        }
    },
    methods: {
        clors() {
            this.$emit("input", false)
        },
        switchover(val, index) {
            this.indexof = index
            let datas = {
						username: this.formData.account,
						password: this.formData.password,
                        member_id:val
					}
            if (this.captcha.id != "") {
                datas.captcha_id = this.captcha.id
                datas.captcha_code = this.formData.vercode
            }
            if (this.formData.checked) {
                datas.autoLoginRange = this.formData.autoLoginRange
            }
            this.getAccountBindUnit(datas)
        },
        // 登录账号
        getAccountBindUnit(data) {
            this.$store
                .dispatch("member/login", data)
                .then((res) => {
                    if (res.code >= 0) {
                        this.$message({
                            message: "登录成功！",
                            type: "success"
                        })

                        this.$router.push({
                            name: "index"
                        })
                    } else {
                        this.getCaptcha()
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                    this.getCaptcha()
                })
        },
        		/**
		 * 获取验证码
		 */
		getCaptcha() {
			captcha({
				captcha_id: this.captcha.id
			})
				.then(res => {
					if (res.code >= 0) {
						this.captcha.id = res.data.id
						this.captcha.img = res.data.img
						this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
					}
				})
				.catch(err => {
					this.$message.error(err.message)
				})
		},
    }
}
</script>

<style lang="scss" scoped>
.acoont {
    padding: 10px;
    background: #f6f6f6;
    border-radius: 5px;
    margin-bottom: 10px;
}
.herdrs {
    margin-top: 20px;
}
.fw {
    font-weight: 500;
}
.colrs {
    border-radius: 50%;
    position: absolute;
    height: 25px;
    width: 25px;
    line-height: 25px;
    text-align: center;
    right: 10px;
    top: 10px;
    background: #ddd;
}
.acoontbox {
    box-sizing: border-box;
}
.bodr {
    border: 1px solid red;
}
</style>