<template>
    <div class="floor-style-erMei-design-1 floor-wsw" :id="data.block_name + c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <div class="left-head-warp">
                <el-image @click="$router.pushToTab(data.value.more.value.link)" :src="data.value.titleImg.value.url"
                    fit="scale-down"></el-image>
                <h2 @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.title.value.color }">{{ data.value.title.value.text }}</h2>
            </div>
            <div class="right-head-warp">
                <div @click="$router.pushToTab(data.value.more.value.link.url)"
                    :style="{ color: data.value.more.value.color }">
                    <span :style="{ color: data.value.more.value.color }">{{ data.value.more.value.text }}</span>
                    <i :style="{ color: data.value.more.value.color }" class="icon el-icon-arrow-right"></i>
                </div>
            </div>
        </div>
        <div class="body-wrap">
            <div class="left-swiper">
                <el-carousel height="280px">
                    <el-carousel-item v-for="(item, index) in carouselsNumIsTwoUseCopy(data.value.bannerImgs)"
                        :key="index">
                        <img alt :src="$img(item.value.url)" @click.stop="$router.pushToTab(item.value.link.url)" />
                    </el-carousel-item>
                </el-carousel>
            </div>
            <div class="right-goods-list">
                <ul class="goods-list">
                    <template v-for="(item, index) in data.value.goodsList.value.list">
                        <li v-if="index <= 3" :key="index"
                            :style="{ backgroundImage: 'url(' + data.value.goodsBgImg.value.url + ')' }"
                            :title="item.goods_name" @click="goSku(item)">
                            <div class="img-wrap">
                                <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError" />
                            </div>
                            <div class="goods-name">{{ item.goods_name }}</div>
                            <div class="goods-attr">0.5g*50支/盒</div>
                            <div class="goods-attr">{{ item.site_name }}</div>
                            <div class="price">
                                <template v-if="token">
                                    <span class="price-num">￥{{ item.control.show_price ? item.price :
                                        storeWithoutAuthority }}</span>
                                </template>
                                <template v-else>
                                    <span class="price-num">￥登录可见</span>
                                </template>
                            </div>
                            <div class="market-price">
                                <template v-if="token">
                                    <span class="delete-price" v-if="item.reference_price>0 && item.reference_price">零：￥{{ item.control.show_price ? item.reference_price :
                                        storeWithoutAuthority }}</span>
                                </template>
                                <template v-else>
                                    <span class="delete-price">￥登录可见</span>
                                </template>
                            </div>
                            <!--                            <p class="desc">{{ item.introduction }}</p>-->
                        </li>
                    </template>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { onTip } from "@/utils/tips"
export default {
    name: "floor-style-erMei-design-1",
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number || String
        }
    },
    data() {
        return {}
    },
    watch: {
        // data: {
        //     handler(val) {
        //         console.log("val---", val)
        //     },
        //     immediate: true
        // },
        // token:{
        //     handler(val){
        //         console.log(val)
        //     },
        //     immediate: true
        // }
    },
    created() { },
    computed: {
        ...mapGetters(["defaultGoodsImage", "token", "storeWithoutAuthority"]),
        /*
         * 由于只有两个Carousel-Item的时候滑动方向不是始终一个方向，但是超过2个的时候就是一个方向了，
         * 所以我们可以对data（用于loop循环的数据）进行改造，假设当前data =[“1”,“2”],
         * 那么我们需要将该data改造成[“2”,“1”,“2”,“1”],即在首尾分别添加尾 首的数据，实现时，
         * 我们可以先设置初始化时的默认幻灯片索引为1，那么它就能按顺序，1212的滑动了，且方向也是相同的了
         * */
        carouselsNumIsTwoUseCopy() {
            return arr => {
                let arrayResult = [...arr]
                if (arr.length === 2) {
                    let copyArr = arr.slice(0)
                    arrayResult.push(...copyArr)
                }
                return arrayResult
            }
        }
    },
    methods: {
        // goSku(skuId) {
        //     this.$router.pushToTab('/sku?id=' + skuId)
        // },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price, item.control.control_msg)) return

            this.$router.pushToTab("/sku?id=" + item.sku_id)
        },
        imageError(e) {
            e.target.src = this.defaultGoodsImage
            // this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: item.num
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    }
}
</script>

<style lang="scss">
.el-carousel__item img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
}

.floor-style-erMei-design-1 {
    margin-bottom: 40px;
    background: #fff;

    .head-wrap {
        height: 50px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 20px;
        border-bottom: 1px solid #f9f9f9;
    }

    .head-wrap {
        .left-head-warp {
            text-align: center;
            display: flex;
            align-items: center;

            .el-image {
                width: 30px;
                height: 30px;
            }

            // img {
            //     display: inline-block;
            //     vertical-align: middle;

            //     margin-right: 10px;
            // }

            h2 {
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
                text-align: center;
                font-size: 18px;
                line-height: 30px;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        span {
            color: #b0b0b0;
            font-size: 14px;
            cursor: pointer;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .el-icon-arrow-right {
            font-size: 16px;
        }
    }

    .body-wrap {
        margin-top: 10px;
        display: flex;

        .left-swiper {
            width: 380px;
        }

        .right-goods-list {
            height: 280px;
            width: 0;
            flex: 1;
        }

        .goods-list {
            height: 100%;
            display: flex;

            li {
                position: relative;
                width: 190px;
                height: 280px;
                border: 1px solid #f9f9f9;
                margin-left: 10px;
                padding: 0 14px;
                background-image: url("https://scjuchuang.oss-cn-chengdu.aliyuncs.com/files/PNG/2023-11-28/1701155200000_30.png?x-oss-process=style/normal");
                cursor: pointer;
                box-sizing: border-box;

                //&:first-child {
                //    margin-left: 0;
                //}

                //&:hover {
                //    z-index: 2;
                //    border: 1px solid $base-color;
                //    box-sizing: border-box;
                //}

                .img-wrap {
                    box-sizing: border-box;
                    width: 100%;
                    height: 140px;
                    //margin: 30px;
                    margin: 25px auto 0;
                    text-align: center;
                    line-height: 140px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                .goods-name {
                    color: rgb(35, 135, 235);
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    font-size: 14px;
                    font-weight: 600;
                    height: 30px;
                    line-height: 30px;
                    display: block;
                    float: left;
                    text-align: center;
                }

                .goods-attr {
                    text-align: center;
                    line-height: normal;
                    width: 100%;
                    color: rgb(102, 102, 102);
                    font-size: 14px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }

                .price {
                    line-height: normal;
                    color: rgb(255, 255, 255);
                    font-size: 18px;
                    position: absolute;
                    font-weight: 600;
                    left: 2px;
                    bottom: 4px;
                }

                .market-price {
                    position: absolute;
                    left: 97px;
                    bottom: 6px;
                    line-height: normal;
                    color: rgb(255, 255, 255);
                    font-size: 13px;

                }
            }
        }
    }

    .el-carousel__indicators--horizontal {
        display: none;
    }
}
</style>
