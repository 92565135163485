import { shopInfo, isFollow, unFollow, follow, tree } from "@/api/shop/index"
import { goodsSkuPage } from "@/api/goods/goods";
import { mapGetters } from "vuex";
export default {
    data:()=>{
        return{
            // shopInfo: {}, //店铺信息
            hasFollow: false, //是否关注
            keyword: "",
            categoryList: [],
            id: 0,
            currentPage: 1,
            pageSize: 12,
        }
    },
    created(){
        this.getGoodsList()

        if(this.$route.query.site_id){
            this.id = this.$route.query.site_id
        }
        this.getCategoryList()
    },
    watch: {
        $route(curr) {
          this.id = curr.params.pathMatch;
          this.getCategoryList();
        }
      },
    computed: {
        ...mapGetters([
          "token",
          "storeWithoutAuthority",
          "defaultShopImage",
          "addonIsExit",
          "defaultGoodsImage",
          "shopInfo"
        ])
      },
      methods:{
           //关注与取消
    getIsFollow() {
        if (this.hasFollow) {
          unFollow({ site_id: this.id }).then(res => {
            if (res.code == 0 && res.data) {
              this.hasFollow = !this.hasFollow;
              this.$message({
                message: "取消关注成功",
                type: "success"
              });
            }
          }).catch(err => {
              if (err.message == 'token不存在') {
                  this.$router.push({path: '/login', query: {redirect: "/shop-"+this.shopInfo.site_id}});
              } else {
                  this.$message.error(err.message);
              }
          });
        } else {
          follow({ site_id: this.id }).then(res => {
            if (res.code == 0) {
              this.hasFollow = !this.hasFollow;
              this.$message({
                message: "关注成功",
                type: "success"
              });
            }
          }).catch(err => {
              if (err.message == 'token不存在') {
                  this.$router.push({path: '/login', query: {redirect: "/shop-"+this.shopInfo.site_id}});
              } else {
                  this.$message.error(err.message);
              }
          });
  }
      },
      goGoodsList() {
        this.$router.push({
          path: "/shop_list",
          query: { site_id: this.id, keyword: this.keyword }
        });
      },
          //获取店铺商品列表
    getGoodsList() {
        const params = {
          page: this.currentPage,
          page_size: this.pageSize,
          site_id: this.id,
          keyword: this.keyword,
          sort: "desc"
        };
        goodsSkuPage(params || {})
          .then(res => {
            if (res.code == 0 && res.data) {
              let data = res.data;
              this.goodsList = data.list;
              this.total = res.data.count;
              this.loading = false;
            }
          })
          .catch(err => {
            this.loading = false;
            this.$message.error(err.message);
          });
      },
         //获取店铺商品分类列表
    getCategoryList() {
        tree({ site_id: this.id })
          .then(res => {
            this.categoryList = res.data;
          })
          .catch(err => {
            this.loading = false;
            this.$message.error(err.message);
          });
      },
      }
}