<template>
    <el-dialog width="400px" :title="'发起' + words" :visible.sync="dialogTableVisible">
        <img src="https://cloud.2myxc.com/upload/common/images/20231121/20231121074047170056684791372.jpg" />
        <p style="text-align: center;padding-top: 40px;">请使用微信小程序进行{{words}}</p>
    </el-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialogTableVisible: false
        }
    },
    props: ['words'],
    methods: {
        show() {
            this.dialogTableVisible = true
        }
    }
}
</script>