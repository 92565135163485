<template>
	<div class="goods-detail">
		<div class="preview-wrap">
			<div class="video-player-wrap" :class="{ show: switchMedia == 'video' }" v-if="goodsSkuDetail.video_url != ''">
				<video-player v-if="goodsSkuDetail.video_url != ''" ref="videoPlayer" :playsinline="true" :options="playerOptions"
				 @play="onPlayerPlay($event)" @pause="onPlayerPause($event)" @ended="onPlayerEnded($event)" @waiting="onPlayerWaiting($event)"
				 @playing="onPlayerPlaying($event)" @loadeddata="onPlayerLoadeddata($event)" @timeupdate="onPlayerTimeupdate($event)"
				 @canplay="onPlayerCanplay($event)" @canplaythrough="onPlayerCanplaythrough($event)" @statechanged="playerStateChanged($event)"
				 @ready="playerReadied"></video-player>

				<div class="media-mode" v-if="goodsSkuDetail.video_url != ''">
					<span :class="{ 'ns-bg-color': switchMedia == 'video' }" @click="switchMedia = 'video'">视频</span>
					<span :class="{ 'ns-bg-color': switchMedia == 'img' }" @click="switchMedia = 'img'">图片</span>
				</div>
			</div>
			<!-- , { size: 'big' } -->
			<div class="magnifier-wrap">
				<pic-zoom ref="PicZoom" :url="$img(picZoomUrl)" :scale="2"></pic-zoom>
			</div>

			<div class="spec-items">
				<span class="left-btn iconfont iconarrow-left-copy" :class="{ move: moveThumbLeft }" @click="changeThumbImg('prev')"></span>
				<span class="right-btn iconfont iconarrow-right" :class="{ move: moveThumbRight }" @click="changeThumbImg('next')"></span>
				<ul :style="{ left: 30 + thumbPosition + 'px' }">
					<!-- 商品缩率图 -->
					<li v-for="(item, index) in goodsSkuDetail.sku_images" :key="index" @mousemove="picZoomUrl = item" :class="{ selected: picZoomUrl == item }">
						<img :src="$img(item, { size: 'small' })" @error="imageErrorSpec(index)" />
					</li>
				</ul>
			</div>

			<div class="share-collect">
				<div @click="editCollection">
					<i class="iconfont" :class="whetherCollection == 1 ? 'iconlikefill ns-text-color' : 'iconlike'"></i>
					<span data-collects="0" class="focus-text" v-if="goodsSkuDetail.collect_num > 0">关注商品（{{ goodsSkuDetail.collect_num }}）</span>
					<span data-collects="0" class="focus-text" v-else>关注商品</span>
				</div>
				<div @click="service_link">
					<i class="iconfont iconzhanghao"></i>
					<span data-collects="0">联系客服</span>
				</div>

			</div>
		</div>

		<!-- 商品信息 -->
		<component
			:is="activityType + 'Activity'"

			ref="goodsDetailRef"
			v-loading="loading"
			:activityType="activityType"
			:detailRes="detailRes"
			:goodsSkuDetail="goodsSkuDetail" 
			:addonIsExit="addonIsExit"
			:couponList="couponList"
			:manjian="manjian"
			:shopInfo="shopInfo"
			:nums="goodsSkuDetail.min_buy"
			:priceArray="priceArray"
			:skuId="skuId"
			:specDisabled="specDisabled"
			@changeSpec="changeSpec"
		>
		</component>

		<!-- 店铺信息 -->
		<div class="shop-wrap">
			<div class="head-wrap">
				<div class="img-wrap">
					<img class="img-responsive center-block" :src="shopInfo.avatar ? $img(shopInfo.avatar) : $img(defaultShopImage)"
					 @error="shopInfo.avatar = defaultShopImage" :alt="shopInfo.site_name" />
				</div>
				<h5>
					<span class="site-name">{{ shopInfo.site_name }}</span>
					<el-tag class="tag" size="small" v-if="shopInfo.is_own == 1">自营</el-tag>
				</h5>
			</div>
			<div class="info-wrap">
				<dl>
					<dt class="site-score">店铺评分</dt>
					<dd>
						<el-rate v-model="score" disabled></el-rate>
					</dd>
					<dt>商品描述：</dt>
					<dd>
						<span>{{ shopInfo.shop_desccredit }}</span>
						分
					</dd>
					<dt>卖家服务：</dt>
					<dd>
						<span>{{ shopInfo.shop_servicecredit }}</span>
						分
					</dd>
					<dt>发货速度：</dt>
					<dd>
						<span>{{ shopInfo.shop_deliverycredit }}</span>
						分
					</dd>
				</dl>
			</div>
			<div class="info-wrap" v-if="shopInfo.telephone">
				<dl>
					<dt>联系电话：</dt>
					<dd>{{ shopInfo.telephone }}</dd>
				</dl>
			</div>
			<div class="operation">
				<el-button class="btn btn-default" size="medium" @click="$router.pushToTab('shop-' + shopInfo.site_id)">进店逛逛</el-button>
				<el-button size="medium" @click="follow" v-if="hasFollow">取消关注</el-button>
				<el-button size="medium" @click="follow" v-else>关注店铺</el-button>
			</div>
		</div>

		<!-- 组合套餐 -->
		<el-tabs class="bundling-wrap" v-model="tabBundling" @tab-click="bundlingChange" v-if="addonIsExit.bundling && bundling.length && bundling[0].bl_name">
			<el-tab-pane :label="item.bl_name" :name="'bundling_' + item.bl_id" v-for="(item, index) in bundling" :key="index">
				<div class="master">
					<div class="sku-img"><img :src="$img(goodsSkuDetail.sku_image, { size: 'mid' })" /></div>
					<div class="sku-name">{{ goodsSkuDetail.sku_name }}</div>
					<div>数量：{{bundlingGoods}}</div>
				 <template v-if="showPrice">
					<div class="sku-price ns-text-color"><span>￥{{activePrice}}</span><span class="price">{{ goodsSkuDetail.price }}</span></div>
				 </template>
				 <template v-else>
                    <div class="sku-price ns-text-color">{{storeWithoutAuthority}}</div>
				 </template>
					<i class="el-icon-plus"></i>
				</div>
				<div class="operation">
					<div class="price-wrap">
						<span>组合套餐价</span>
						<template v-if="showPrice">
							<strong class="bl-price ns-text-color">￥{{ item.bl_price }}</strong>
						</template>
						<template v-else>
							<strong class="bl-price ns-text-color">{{storeWithoutAuthority}}</strong>
						</template>
					</div>
					<template v-if="showPrice">

						<el-button type="primary" size="medium" @click="isacion(item)">立即购买</el-button>
					</template>
					<template v-else>
						<!-- <el-button type="primary" size="medium" @click="$router.pushToTab('/member/certiys')">立即购买</el-button> -->
					</template>
					<i class="equal">=</i>
				</div>

				<div class="suits">
					<ul>
						<li v-for="(goods, goods_index) in item.bundling_goods" :key="goods_index" @click="$router.pushToTab({ path:'/sku?id=' + goods.sku_id })">
							<div class="sku-img"><img :src="$img(goods.sku_image, { size: 'mid' })" /></div>
							<div class="sku-name">{{ goods.sku_name }}</div>
							<div>数量：{{goods.active_num}}</div>
							<template v-if="goods.control.show_price">
								<div class="sku-price ns-text-color"><span>￥{{goods.active_price}}</span><span class="price">{{ goods.price }}</span></div>
							</template>
							<template v-else>
                               <div class="sku-price ns-text-color"	>
								{{storeWithoutAuthority}}
							   </div>
							</template>
						</li>
					</ul>
				</div>
			</el-tab-pane>
		</el-tabs>

		<div class="detail-wrap">
			<div class="goods-recommended">
				<goods-recommend />
			</div>

			<el-tabs class="goods-tab" v-model="tabName" type="card" @tab-click="tabChange">
				<el-tab-pane label="商品详情" name="detail">
					<div v-html="goodsSkuDetail.goods_content"></div>
				</el-tab-pane>
				<el-tab-pane label="商品属性" name="attr">
					<ul class="attr-list">
						<template v-if="goodsSkuDetail.goods_attr_format && goodsSkuDetail.goods_attr_format.length > 0">
							<li v-for="(item, index) in goodsSkuDetail.goods_attr_format" :key="index">{{ item.attr_name }}：{{ item.attr_value_name }}</li>
						</template>
					</ul>
				</el-tab-pane>
				<el-tab-pane :label="goodsSkuDetail.evaluate ? '商品评价(' + goodsSkuDetail.evaluate + ')' : '商品评价'" name="evaluate"
				 class="evaluate">
					<template v-if="goodsEvaluateList.length">
						<nav>
							<li class="selected">全部评价</li>
							<!-- <li>好评</li>
						<li>中评</li>
                            <li>差评</li>-->
						</nav>
						<ul class="list">
							<li v-for="(item, index) in goodsEvaluateList" :key="index">
								<div class="member-info">
									<img :src="$img(item.member_headimg)" @error="imageErrorEvaluate(index)" class="avatar" />
									<span>{{ item.member_name }}</span>
								</div>
								<div class="info-wrap">
									<el-rate v-model="item.star" disabled></el-rate>
									<p class="content">{{ item.content }}</p>
									<div class="img-list" v-if="item.images">
										<el-image v-for="(img, img_index) in item.images" :key="img_index" :src="$img(img)" :preview-src-list="item.imagesFormat"></el-image>
									</div>
									<div class="sku-info">
										<span>{{ item.sku_name }}</span>
										<span class="create-time">{{ $util.timeStampTurnTime(item.create_time) }}</span>
									</div>
									<div class="evaluation-reply" v-if="item.explain_first != ''">店家回复：{{ item.explain_first }}</div>
									<template v-if="item.again_content != ''">
										<div class="review-evaluation">
											<span>追加评价</span>
											<span class="review-time">{{ $util.timeStampTurnTime(item.again_time) }}</span>
										</div>
										<p class="content">{{ item.again_content }}</p>
										<div class="img-list">
											<el-image v-for="(again_img, again_index) in item.again_images" :key="again_index" :src="$img(again_img)"
											 :preview-src-list="item.againImagesFormat"></el-image>
										</div>
										<div class="evaluation-reply" v-if="item.again_explain != ''">店家回复：{{ item.again_explain }}</div>
									</template>
								</div>
							</li>
						</ul>
						<div class="pager">
							<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage"
							 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
							 hide-on-single-page></el-pagination>
						</div>
					</template>
					<div class="empty" v-else>该商品暂无评价哦</div>
				</el-tab-pane>
				<el-tab-pane label="售后保障" name="after_sale" class="after-sale" v-if="service_is_display.is_display==1">
					<div v-html="service"></div>
				</el-tab-pane>
                <el-tab-pane label="引流教程" name="after_sale" class="after-sale"  v-if="goodsSkuDetail.drainage_tutorial">
                    <div v-html="goodsSkuDetail.drainage_tutorial"></div>
                </el-tab-pane>
			</el-tabs>
		</div>
		<!--联系客服弹窗-->
		<servicerMessage ref="servicerMessage" class="kefu" :shop="{shop_id:shopInfo.site_id,logo:shopInfo.logo,shop_name:shopInfo.site_name}"></servicerMessage>
	</div>
</template>

<script>
	import PicZoom from 'vue-piczoom';
	import detail from './detail';
	import GoodsRecommend from '@/components/GoodsRecommend';
	import servicerMessage from "@/components/message/servicerMessage";
	export default {
		name: 'detail',
		components: {
			PicZoom,
			GoodsRecommend,
			servicerMessage,

			skuActivity: () => import("./activity/sku.vue"),
			seckillActivity: () => import("./activity/seckill.vue"),
			wholesaleActivity: () => import("./activity/wholesale.vue"),
			pintuanActivity: () => import("./activity/pintuan.vue"),
			groupbuyActivity: () => import("./activity/groupbuy.vue"),
      getfreeActivity: () => import("./activity/getfree.vue")
		},
		mixins: [detail]
	};
</script>
<style lang="scss">
	@import './detail.scss';
</style>
