import { render, staticRenderFns } from "./floor-style-ermei-design-4.vue?vue&type=template&id=6b3a0fa6&scoped=true&"
import script from "./floor-style-ermei-design-4.vue?vue&type=script&lang=js&"
export * from "./floor-style-ermei-design-4.vue?vue&type=script&lang=js&"
import style0 from "./floor-style-ermei-design-4.vue?vue&type=style&index=0&id=6b3a0fa6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b3a0fa6",
  null
  
)

export default component.exports