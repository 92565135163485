import { render, staticRenderFns } from "./shareCounter.vue?vue&type=template&id=22494832&scoped=true&"
import script from "./shareCounter.vue?vue&type=script&lang=js&"
export * from "./shareCounter.vue?vue&type=script&lang=js&"
import style0 from "./shareCounter.vue?vue&type=style&index=0&id=22494832&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22494832",
  null
  
)

export default component.exports