<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <div v-loading="loading">
            <el-card class="box-card order-list">
                <div slot="header" class="clearfix">
                    <el-breadcrumb separator="/">
                        <el-breadcrumb-item :to="{ path: '/member/order_list' }">我的订单</el-breadcrumb-item>
                        <el-breadcrumb-item
                            :to="{ path: '/member/order_detail?order_id=' + orderId }"
                        >订单详情</el-breadcrumb-item>
                        <el-breadcrumb-item>退款</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>

                <!--商品信息-->
                <div class="goods-list">
                    <table>
                        <tr>
                            <td width="62.5%">
                                <el-checkbox v-model="checkAll" @change="handleCheckAllChange"></el-checkbox>商品
                            </td>
                            <td width="12.5%">数量</td>
                            <td width="12.5%">金额</td>
                            <td width="12.5%">申请退货数量</td>
                        </tr>
                    </table>
                </div>

                <div class="goods-list">
                    <table>
                        <tr v-for="(item,index) in refundData.order_goods_info" :key="index">
                            <td width="62.5%" style="display: flex;">
                                <el-checkbox
                                    v-model="item.is_show"
                                    @change="handleCheckoneChange($event,item)"
                                ></el-checkbox>
                                <div class="goods-info">
                                    <div class="goods-info-left">
                                        <router-link
                                            :to="{ path: '/sku?id=' + item.sku_id }"
                                            target="_blank"
                                        >
                                            <img
                                                class="goods-img"
                                                :src="$img(item.sku_image, { size: 'mid' })"
                                                @error="item.sku_image = defaultGoodsImage"
                                            />
                                        </router-link>
                                    </div>
                                    <div class="goods-info-right">
                                        <router-link
                                            :to="{ path: '/sku?id=' + item.sku_id }"
                                            target="_blank"
                                        >
                                            <div class="goods-name">{{ item.sku_name }}</div>
                                        </router-link>
                                    </div>
                                </div>
                            </td>
                            <td width="12.5%" class="goods-num">{{ item.num }}</td>
                            <td width="12.5%" class="goods-money">￥{{ item.goods_money }}</td>
                            <td width="12.5%" class="goods-money">
                                <el-input
                                    type="text"
                                    :disabled="!item.is_show"
                                    v-model="item.is_num"
                                    placeholder="请输入内容"
                                    @blur="blurchange(item)"
                                ></el-input>
                            </td>
                        </tr>
                    </table>
                </div>
            </el-card>

            <!--退款类型 -->
            <div class="item-block">
                <div class="block-text">退款类型</div>

                <div class="pay-type-list">
                    <div
                        class="pay-type-item"
                        :class="refundType == 1 ? 'active' : ''"
                        @click="selectRefundType(1)"
                    >退款无需退货</div>
                    <div
                        v-if="refundData.refund_type.length == 2"
                        class="pay-type-item"
                        :class="refundType == 2 ? 'active' : ''"
                        @click="selectRefundType(2)"
                    >退货退款</div>
                    <div class="clear"></div>
                </div>
            </div>

            <!--退款填写-->
            <div class="item-block">
                <div class="block-text"></div>

                <el-form ref="form" label-width="80px" class="refund-form">
                    <el-form-item label="退款金额">
                        <el-input disabled :value="money.refund_money"></el-input>
                    </el-form-item>
                    <el-form-item label="退款原因">
                        <el-select placeholder="请选择" v-model="refundReason">
                            <el-option
                                v-for="(item, itemIndex) in refundData.refund_reason_type"
                                :key="itemIndex"
                                :label="item"
                                :value="item"
                            ></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="退款说明">
                        <el-input
                            maxlength="140"
                            show-word-limit
                            resize="none"
                            rows="5"
                            placeholder="请输入退款说明（选填）"
                            type="textarea"
                            v-model="refundRemark"
                        ></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <div class="item-block">
                <div class="order-submit">
                    <el-button type="primary" class="el-button--primary" @click="submit">提交</el-button>
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
import { refundData, refund, detail, delivery, refundDataorder, refundMoney, orderrefund } from "@/api/order/refund"
import { mapGetters } from "vuex"

export default {
    name: "refund",
    components: {},
    data: () => {
        return {
            refund_no: "",
            orderId: "",
            refundType: 1,
            refundReason: "",
            refundRemark: "",
            isIphoneX: false,
            refundData: {
                refund_type: [],
                order_goods_info: []
            },
            isSub: false,
            show_type: 0, //退款状态 1-待退款 2-已退款
            detail: {
                refund_action: []
            },
            loading: true,
            yes: true,
            checked: true,
            input: 0,
            isinput: false,
            isIndeterminate: true,

            one: false,
            checkedCities: ["上海"],
            cities: ["上海", "北京", "广州", "深圳"],
            money: {},
            refundjson: []
        }
    },
    created() {
        if (this.$route.query.refund_no) this.refund_no = this.$route.query.refund_no
        if (this.$route.query.order_id) this.orderId = this.$route.query.order_id
        // this.getRefundData()
        this.getrefundDataorder()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"]),
        checkAll: {
            get() {
                // 判断是否所有的 item.is_show 都为 true
                return this.refundData.order_goods_info.every(item => item.is_show)
            },
            set(value) {
                // 设置所有的 item.is_show 为 true 或者 false

                this.refundData.order_goods_info.forEach(item => {
                    item.is_show = value
                    if (value) {
                     this.handleCheckoneChange('',item)
                    } else {
                        this.refundjson.splice(0, this.refundjson.length)
                    }
                })
                this.getrefundMoney()
            }
        }
    },
    mounted() {
        let self = this
        setTimeout(function() {
            self.yes = false
        }, 300)
    },
    methods: {
        handleCheckAllChange(val) {},
        handleCheckoneChange(val, item) {
            const index = this.refundjson.findIndex(element => element.order_goods_id === item.order_goods_id)
            if (item.is_show) {
                this.refundjson.push({ order_goods_id: item.order_goods_id, num: item.is_num })
                if (index >= 0) {
                    this.refundjson.splice(index, 1)
                }
            } else {
                if (index >= 0) {
                    this.refundjson.splice(index, 1)
                }
            }
            this.getrefundMoney()
        },
        blurchange(item) {
            if (item.is_num > item.num) {
                item.is_num = item.num
            } else if (item.is_num <= 0) {
                item.is_num = item.num
            }
            this.handleCheckoneChange("", item)
            this.getrefundMoney()
        },
        // handleCheckedCitiesChange(value) {
        //     if (!value) {
        //         this.isinput = true
        //         this.input = 0
        //     } else {
        //         this.isinput = false
        //     }
        // },
        /**
         * 选择退款方式
         * @param {Object} type
         */
        selectRefundType(type) {
            this.refundType = type
        },
        getrefundMoney() {
            const refundJsonString = JSON.stringify(this.refundjson)
            refundMoney({ order_id: this.orderId, refund_goods: refundJsonString })
                .then(res => {
                    const { code, message, data } = res
                    if (code >= 0) {
                        this.money = data
                    }
                })
                .catch(err => {})
        },
        // 订单数据
        getrefundDataorder() {
            refundDataorder({ order_id: this.orderId, refund_no: this.refund_no })
                .then(res => {
                    const { code, message, data } = res
                    if (code >= 0) {
                        this.refundData = data
                        this.refundData.order_goods_info.forEach(item => {
                            this.$set(item, "is_show", true)
                            this.$set(item, "is_num", item.num)
                        })
                        this.checkAll = true
                        this.getrefundMoney()
                    } else {
                        this.$message({
                            message: "未获取到该订单项退款信息！",
                            type: "warning",
                            duration: 2000,
                            onClose: () => {
                                this.$router.push({ path: "/member/activist" })
                            }
                        })
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$message.error({
                        message: err.message,
                        duration: 2000,
                        onClose: () => {
                            this.$router.push({ path: "/member/activist" })
                        }
                    })
                })
        },
        // getRefundData() {
        //     refundData({ order_goods_id: this.orderGoodsId })
        //         .then(res => {
        //             const { code, message, data } = res
        //             if (code >= 0) {
        //                 this.refundData = data
        //                 console.log(data)
        //             } else {
        //                 this.$message({
        //                     message: "未获取到该订单项退款信息！",
        //                     type: "warning",
        //                     duration: 2000,
        //                     onClose: () => {
        //                         this.$router.push({ path: "/member/activist" })
        //                     }
        //                 })
        //             }
        //             this.loading = false
        //         })
        //         .catch(err => {
        //             this.loading = false
        //             this.$message.error({
        //                 message: err.message,
        //                 duration: 2000,
        //                 onClose: () => {
        //                     this.$router.push({ path: "/member/activist" })
        //                 }
        //             })
        //         })
        // },
        submit() {
            if (this.verify()) {
                if (this.isSub) return
                this.isSub = true
                if (this.refundjson.length <= 0) {
                    this.$message({ message: "选择商品不能为空！", type: "warning" })
                    return
                }
                const oderrefundJsonString = JSON.stringify(this.refundjson)
                let submit_data = {
                    order_id: this.orderId,
                    refund_goods: oderrefundJsonString,
                    refund_type: this.refundType,
                    refund_reason: this.refundReason,
                    refund_remark: this.refundRemark
                }
                orderrefund(submit_data)
                    .then(res => {
                        const { code, message, data } = res
                        if (code >= 0) {
                            this.$message({
                                message: "申请成功！",
                                type: "success",
                                duration: 2000,
                                onClose: () => {
                                    this.$router.push({ path: "/member/activist" })
                                }
                            })
                        } else {
                            this.isSub = false
                            this.$message({ message: message, type: "warning" })
                        }
                    })
                    .catch(err => {
                        this.$message.error({
                            message: err.message,
                            duration: 2000,
                            onClose: () => {
                                this.$router.push({ path: "/member/activist" })
                            }
                        })
                    })
            }
        },
        verify() {
            if (this.refundReason == "") {
                this.$message({ message: "请选择退款原因", type: "warning" })
                return false
            }
            return true
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
    box-shadow: unset;
}

.el-card {
    border: 0;
}
.clear {
    clear: both;
}
.item-block {
    padding: 0 15px 1px;
    margin: 10px 0;
    border-radius: 0;
    border: none;
    background: #ffffff;
    .block-text {
        border-color: #eeeeee;
        color: $ns-text-color-black;
        padding: 7px 0;
        border-bottom: 1px;
    }
}
.refund-form {
    width: 350px;
    .el-select {
        width: 100%;
    }
}
.order-submit {
    text-align: center;
    padding: 10px;
}
.goods-list {
    padding: 15px 0;
    table {
        width: 100%;
    }
    .goods-info-left {
        width: 60px;
        height: 60px;
        float: left;
        .goods-img {
            margin-left: 10px;
            width: 60px;
            height: 60px;
        }
    }
    .goods-info-right {
        float: left;
        height: 60px;
        margin-left: 10px;
        color: $base-color;
        width: 80%;
        .goods-name {
            line-height: 20px;
            padding-top: 10px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
        }
        .goods-spec {
            color: #999;
        }
    }
}
.pay-type-list {
    padding: 20px 0;
}
.pay-type-item {
    display: inline-block;
    border: 2px solid #eeeeee;
    padding: 5px 20px;
    margin-right: 20px;
    cursor: pointer;
}
.pay-type-item.active {
    border-color: $base-color;
}
.status-wrap {
    color: #999;
}
.media-left {
    float: left;
}
.media-right {
    float: right;
    i.rotate {
        transform: rotate(180deg);
        transition: 0.3s;
    }
}
.action-box {
    padding: 10px 0;
}
.action-way {
    float: left;
    color: #999;
}
.head .time {
    float: right;
    color: #999;
}
.record-item {
    margin-bottom: 10px;
}
.order-statistics {
    float: left;
    padding: 10px;
    // color: #999;
}
.el-textarea .el-input__count {
    line-height: 20px;
}
</style>
