import { adList } from '@/api/website';
import { ShopPagediyview } from "@/api/shop"
import {unFollow, follow,isFollow } from "@/api/shop/index";
import {mapGetters}  from 'vuex';
export default {
    data: () => {
        return {
            showCollect: false,
            screen: [
                {
                    title: '按销量排序',
                },
                {
                    title: '按评分排序',
                }
            ],
            active: 0,
            adListWiper: [],
            adListRigth: [],
            Listsort: [],
            currentPage: 1,
            pageSize: 5,
            total: 0,
            rankingList: [],
            recommend: [],
            sort: {
                order: "shop_sales",
                sorts: "desc"
            },
            showonScreen: false, //是否排序
            infokeyword: ""
        }
    },
    created() {
        this.getexcellentAdList()
        this.getexcellentAdListritg()
        this.getpagediyview()
        this.getrankingPagediyview()
        this.getexcellentrecommend()
    },
    computed:{
        ...mapGetters([
            "defaultGoodsImage",
            "defaultShopImage",
            "defaultStoreImage",
            "defaultSupplyImage",
            "defaultCategoryImage"
        ])
    },
    methods: {
      //关注与取消 
        showCollectClick(item) {
            if (item.is_subscribe==1) {
                unFollow({ site_id:item.site_id }).then(res => {       //0 否 1 是   是否收藏
                  if (res.code == 0 && res.data) {
                    this.$message({
                       message: '取消收藏',
                      type: "success"
                    });
                    this.getpagediyview()
                    this.getrankingPagediyview()
                  }
                }).catch(err => {
                    if (err.message == 'token不存在') {
                        this.$router.push({path: '/login', query: {redirect: "/shop-"+item.site_id}});
                    } else {
                        this.$message.error(err.message);
                    }
                });
              } else {
                follow({ site_id: item.site_id }).then(res => {
                  if (res.code == 0) {
                    this.$message({
                      message: '收藏成功',
                      type: "success"
                    });
                    this.getpagediyview()
                    this.getrankingPagediyview()
                  }
                }).catch(err => {
                    if (err.message == 'token不存在') {
                        this.$router.push({path: '/login', query: {redirect: "/shop-"+item.site_id}});
                    } else {
                        this.$message.error(err.message);
                    }
                });
        }
        },
        formattedDelivery(item) {
            // 假设 this.item 是在组件中定义的数据对象
            if (item && typeof item.delivery != 'undefined') {
                if (item.delivery >= 10000) {
                    return `<span style='color: rgb(255, 145, 41);'>${(item.delivery / 10000).toFixed(1)}</span> 万`;
                } else {
                    return `<span style='color: rgb(255, 145, 41);'>${item.delivery}</span> 件`;
                }
            } else {
                return ""
            }

        },
        onScreen(val) {
            this.active = val
            let order = "";
            switch (val) {
                case 0:
                    order = "shop_sales"  //asc：正序 desc：倒叙   shop_sales： 销量 shop_desccredit：评分
                    break;
                case 1:
                    order = "shop_desccredit"
                    break;
            }
            // 判断当前按钮是否已经是高亮状态
            if (this.sort.order === order) {
                // 切换排序方式
                if (this.sort.sorts === 'asc') {
                    this.sort.sorts = 'desc';
                } else {
                    this.sort.sorts = 'asc';
                }
            } else {
 
                // 当前按钮不是高亮状态时，统一设置为向下箭头
                this.sort.sorts = 'desc';
            }
            this.sort.order = order

            this.getpagediyview()
        },
        // 轮播图
        getexcellentAdList() {
            adList({ keyword: "PC_GOLD_STORE" }).then((res) => {
                this.adListWiper = res.data.adv_list;
                for (let i = 0; i < this.adListWiper.length; i++) {
                    if (this.adListWiper[i].adv_url) this.adListWiper[i].adv_url = JSON.parse(this.adListWiper[i].adv_url);
                }
            }).catch((err) => {

            })
        },
        // 金牌广告推荐
        getexcellentrecommend() {
            adList({ keyword: "PC_GOLD_HOTLISTS" }).then((res) => {
                this.recommend = res.data.adv_list;
                for (let i = 0; i < this.recommend.length; i++) {
                    if (this.recommend[i].adv_url) this.recommend[i].adv_url = JSON.parse(this.recommend[i].adv_url);
                }
            }).catch((err) => {

            })
        },
        // 轮播图右侧图片
        getexcellentAdListritg() {
            adList({ keyword: "PC_GOLD_STORE_IMG" }).then((res) => {
                this.adListRigth = res.data.adv_list;
                for (let i = 0; i < this.adListRigth.length; i++) {
                    if (this.adListRigth[i].adv_url) this.adListRigth[i].adv_url = JSON.parse(this.adListRigth[i].adv_url);
                }
            }).catch((err) => {
            })
        },
        //店铺排序
        getpagediyview() {
            let data = {
                group_id: "",
                site_id: "",//店铺id
                keyword: this.infokeyword,
                page: this.currentPage,
                page_size: this.pageSize,
                order: this.sort.order,
                sort: this.sort.sorts
            }
            ShopPagediyview({ ...data }).then((res) => {
                let { code, data } = res
                if (code >= 0) {
                    this.total = data.page_count
                    this.Listsort = data.list
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        // 店铺排名
        getrankingPagediyview() {
            let data = {
                group_id: "",
                site_id: "",
                keyword: "",
                page_size: "",
                page: 1,
                page_size: 3,
                order: 'comprehensive',
                sort: 'asc'
            }
            ShopPagediyview({ ...data }).then((res) => {
                let { code, data } = res
                if (code >= 0) {
                    this.rankingList = data.list
                }
            }).catch((err) => {
                console.log(err)
            })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.getpagediyview()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getpagediyview()
        },
    }
}