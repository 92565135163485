<template>
    <div class="floor-style-5 floor-wsw" :id="data.block_name + c_index">
        <div class="head-wrap" v-if="data.value.title.value.text">
            <h2
                @click.stop="$router.pushToTab(data.value.title.value.link.url)"
                :style="{ color: data.value.title.value.color }"
            >{{ data.value.title.value.text }}</h2>
            <!-- <div>{{ data.block_name + c_index }}</div> -->
            <div class="right-head-warp">
                <div
                    @click="$router.pushToTab(data.value.title.value.link.url)"
                    :style="{ color: data.value.more.value.color }"
                >
                    <span
                        :style="{ color: data.value.more.value.color }"
                    >{{ data.value.more.value.text }}</span>
                    <i
                        :style="{ color: data.value.more.value.color }"
                        class="icon el-icon-arrow-right"
                    ></i>
                </div>
            </div>
        </div>
        <div
            :style="'padding-top: 130px; background-image: url(' + data.value.backgroundImg.value.url + ');'"
            class="bodus"
            @click.stop="$router.pushToTab(data.value.backgroundImg.value.link.url)"
        >
            <div class="body-wrap">
                <ul class="goods-list">
                    <!-- <li><img :src="$img(data.value.leftImg.value.url)"
                    @click="$router.pushToTab(data.value.leftImg.value.link.url)" /></li>-->
                    <li
                        v-for="(item, index) in data.value.goodsList.value.list"
                        :key="index"
                        :title="item.goods_name"
                        
                    >
                  <div > 
                    <div class="img-wrap" @click.stop="goSku(item)">
                            <img alt="商品图片" :src="$img(item.sku_image)" @error="imageError" />
                        </div>
                        <h3>{{ item.goods_name }}</h3>
                        <p class="desc">{{ item.introduction }}</p>
                        <p class="price">
                            <template v-if="token">
                                <span class="num">
                                    ￥{{ item.control.show_price ? item.discount_price :
                                    storeWithoutAuthority
                                    }}
                                </span>
                            </template>

                            <template v-else>
                                <span class="num">￥登录可见</span>
                            </template>

                            <template v-if="token">
                                <del v-if="item.market_price>0">￥{{ item.control.show_price ? item.market_price : storeWithoutAuthority }}</del>
                            </template>

                            <template v-else>
                                <del>￥登录可见</del>
                            </template>
                        </p>
                  </div>

                        <template v-if="token && item.control.is_member_buy">
                            <div class="cart-layout">
                                <!-- <div class="cart-num">
                                    <span
                                        class="el-icon-minus"
                                        @click.stop="item.num > 1 ? item.num-- : item.num"
                                    ></span>
                                    <div class="number">
                                        <el-input v-model="item.num" size="small" placeholder="0"></el-input>
                                    </div>
                                    <span class="el-icon-plus" @click.stop="item.num++"></span>
                                  
                                </div> -->
                               <div style="width: 100px;"> <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    }" size="mini" @upnumber="upnumber"></shareCounter></div>
                                <div class="cart-add" @click.stop="joinCart(item)" v-if="item.package_num<item.stock">加入购物车</div>
                            </div>
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex"
import { onTip } from "@/utils/tips"
import shareCounter from "@/components/shareCounter.vue"
export default {
    name: "floor-style-5",
    components:{
        shareCounter
    },
    props: {
        data: {
            type: Object
        },
        c_index: {
            type: Number
        }
    },
    data() {
        return {
            number:1
        }
    },
    created() {},
    computed: {
        ...mapGetters(["defaultGoodsImage", "token", "storeWithoutAuthority"])
    },
    methods: {
        upnumber(val){
              this.number=val
        },
        goSku(item) {
            console.log(item)
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price,item.control.control_msg)) return
            this.$router.pushToTab("/sku?id=" + item.sku_id)
        },
        imageError(e) {
            e.target.src =this.defaultGoodsImage
            // this.data.value.goodsList.value.list[index].sku_image = this.defaultGoodsImage
        },
        // 加入购物车
        joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then(res => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.floor-style-5 {
    position: relative;
    border: 1px sloid red;
    margin-bottom: 20px;
    overflow: hidden;
    margin-top: 40px;
    .head-wrap {
        display: flex;
    }
    .head-wrap h2 {
        line-height: 30px;
        color: #333;
        padding: 10px;
        font-size: 18px;
        cursor: pointer;
        width: 93%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .body-wrap {
        //display: flex;
        padding: 70px 10px 35px 10px;
        margin: 10px 0;

        .left-wrap {
            //float: left;
            //width: 234px;
            //width: 23%;
            margin: 0 auto;
            height: 300px;
            cursor: pointer;
            transition: all 0.2s linear;

            &:hover {
                z-index: 2;
                -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
                -webkit-transform: translate3d(0, -2px, 0);
                transform: translate3d(0, -2px, 0);
            }

            img {
                width: 100%;
                height: 100%;
                cursor: pointer;
            }
        }

        .small-wrap {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            img {
                width: 590px;
            }
        }

        .goods-list {
            //margin-left: 235px;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;

            li {
                border: 1px solid #f9f9f9;
                width: 19%;
                //margin-left: 19px;
                margin: 10px 10px 10px 0 ;
                background: #fff;
                cursor: pointer;
                padding: 10px 0;
                box-sizing: border-box;

                &:hover {
                    z-index: 2;
                    border: 1px solid $base-color;
                    box-sizing: border-box;
                }

                .img-wrap {
                    width: 160px;
                    height: 160px;
                    margin: 0 auto 18px;
                    text-align: center;
                    line-height: 160px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                    }
                }

                h3 {
                    font-size: 12px;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    margin: 5px 15px;
                }

                .desc {
                    margin: 0 30px 10px;
                    height: 20px;
                    font-size: 12px;
                    color: #b0b0b0;
                    text-align: center;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                }

                .price {
                    margin: 0 10px 14px;
                    text-align: center;
                    color: $base-color;

                    del {
                        margin-left: 0.5em;
                        color: #b0b0b0;
                    }
                }

                .cart-layout {
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .cart-num {
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;

                        span {
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }

                        span:first-child {
                            left: 0;
                        }

                        span:last-child {
                            right: 0;
                        }

                        .number {
                           ::v-deep .el-input {
                                .el-input__inner {
                                    text-align: center;
                                }
                            }
                        }
                    }

                    .cart-add {
                        cursor: pointer;
                        border: 1px solid $base-color;
                        color: $base-color;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
            }
        }
    }

    .bottom-wrap {
        position: absolute;
        margin-top: 10px;
        width: $width;
        height: 100%;
        z-index: -1;
        cursor: pointer;
        overflow: hidden;

        img {
            height: auto;
            max-width: 100%;
        }
    }

    .bodus {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-position: center;
    }
    ::v-deep .el-input-number{
width: 90px;
}
}
</style>
