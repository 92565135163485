<template>
    <div class="scee1">
        <!-- 搜索关键字 -->
        <div v-if="showgoodsList==1">
            <div class="search_bread" v-if="keyword">
            <router-link to="/">首页</router-link>
            <span class="bias">/</span>
            <span class="keyword">{{ keyword }}</span>
        </div>
        <div class="search_bread" v-else-if="!keyword && catewords.length > 0">
            <router-link to="/">首页</router-link>
            <template v-if="filters.category_level >= 1">
                <span class="bias">/</span>
                <router-link :to="{ path: '/list', query: { category_id: first_index, level: 1 } }">
                    {{ catewords[0] }}
                </router-link>
            </template>
            <template v-if="filters.category_level >= 2">
                <span class="bias">/</span>
                <router-link :to="{ path: '/list', query: { category_id: two_index, level: 2 } }">
                    {{ catewords[1] }}
                </router-link>
            </template>
            <template v-if="filters.category_level >= 3 && catewords[2]">
                <span class="bias">/</span>
                <router-link :to="{ path: '/list', query: { category_id: third_index, level: 3 } }">
                    {{ catewords[2] }}
                </router-link>
            </template>
        </div>
        <div v-if="!keyword && first_child_listtype" class="search_bread categoryBox">
            <p :class="[{ categoryLevel2: filters.category_level == 1 }]" v-if="!keyword && catewords.length > 0">分类：</p>
            <ul class="grid-container">
                <li v-if="filters.category_level == 2" :class="{ active: first_index_active === 0 }" @click="setSiindex()">
                    <template v-if="filters.category_level == 2">
                        <router-link :to="{ path: '/list', query: { category_id: first_index, level: 1 } }"> 全部 </router-link>
                    </template>
                    <template v-if="filters.category_level == 3">
                        <router-link :to="{ path: '/list', query: { category_id: two_index, level: 2 } }"> 全部 </router-link>
                    </template>
                </li>

                <li :class="{ boxactive: item.category_id == first_index_active, boxCategory: true }" @click="setFiindextype(item)" v-for="item in first_child_listtype">
                    <!-- <router-link :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">
                    </router-link> -->
                    <a> {{ item.category_name }}</a>
                    <img style="width: 30px" :src="item.image" alt="" />
                </li>
            </ul>
        </div>
        <div class="search-type-item" v-if="factory.length > 0">
            <div class="title">厂家：</div>
            <div class="search-content">
                <div class="attr-list">
                    <ul :class="factory_collapsetype ? 'ul-height-30' : 'ul-expand'">
                        <div style="display: flex">
                            <li :class="[{ factory_selected: factory_index === '' }]" @click="setFactorytype('')">全部</li>
                            <li style="margin: 0 10px 0 10px">
                                <el-input v-model.trim="inptName" @change="changeinouttype($event, 1)" style="width: 65%" type="text" placeholder="请输入厂家" size="mini">
                                    <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                                </el-input>
                            </li>
                        </div>
                        <li :class="[{ factory_selected: factory_index === factoryItem }]" v-for="(factoryItem, f_index) in factory" @click="setFactorytype(factoryItem, f_index)">
                            <span v-html="highLight(factoryItem, inptName)"></span>
                        </li>
                        <template v-if="factory.length > 5">
                            <div class="collapse-btn" @click="collapse(1)">
                                {{ factory_collapsetype ? "展开" : "收起" }}
                            </div>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="search-type-item" v-if="spec.length > 0">
            <div class="title">规格：</div>
            <div class="search-content">
                <div class="attr-list spec-list">
                    <ul :class="spec_collapsetype ? 'ul-height-30' : 'ul-expand'">
                        <div style="display: flex">
                            <li :class="[{ spec_selected: spec_index === '' }]" @click="setSpec('')">全部</li>
                            <li style="margin: 0 10px 0 10px">
                                <el-input v-model.trim="specificationInput" style="width: 65%" @change="changeinouttype($event, 2)" type="text" placeholder="请输入规格" size="mini">
                                    <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                                </el-input>
                            </li>
                        </div>
                        <li :class="[{ spec_selected: spec_index === specItem }]" v-for="(specItem, s_index) in spec" @click="setSpec(specItem)">
                            <span v-html="highLight(specItem, specificationInput)"></span>
                        </li>
                    </ul>
                    <template v-if="spec.length > 7">
                        <div class="collapse-btn" @click="collapse(2)">
                            {{ spec_collapsetype ? "展开" : "收起" }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="search-type-item" v-if="brand.length > 0">
            <div class="title">品牌：</div>
            <div class="search-content">
                <div class="attr-list">
                    <ul :class="brand_collapsetype ? 'ul-height-30' : 'ul-expand'">
                        <div style="display: flex">
                            <li :class="[{ factory_selected: brand_index === '' }]" @click="setBrand('')">全部</li>
                            <li style="margin: 0 10px 0 10px">
                                <el-input v-model.trim="barndInput" @change="changeinouttype($event, 4)" style="width: 65%" type="text" placeholder="请输入品牌" size="mini">
                                    <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                                </el-input>
                            </li>
                        </div>
                        <li :class="[{ factory_selected: brand_index === brandItem }]" v-for="(brandItem, f_index) in brand" @click="setBrand(brandItem, f_index)">
                            <span v-html="highLight(brandItem, barndInput)"></span>
                        </li>
                        <template v-if="brand.length > 5">
                            <div class="collapse-btn" @click="collapse(3)">
                                {{ brand_collapsetype ? "展开" : "收起" }}
                            </div>
                        </template>
                    </ul>
                </div>
            </div>
        </div>
        <div class="search-type-item" v-if="dosage_form.length > 0">
            <div class="title">剂型：</div>
            <div class="search-content">
                <div class="attr-list dosage_form-list">
                    <ul :class="dosage_form_index ? 'ul-height-30' : 'ul-expand'">
                        <div style="display: flex; margin-right: 30px">
                            <li :class="[{ dosage_form__selected: dosage_form_index === '' }]" @click="setDosageForm('')">全部</li>
                            <li style="margin: 0 10px 0 10px">
                                <el-input v-model.trim="inputtype" style="width: 65%" @change="changeinouttype($event, 3)" type="text" placeholder="请输入剂型" size="mini">
                                    <i class="el-icon-search el-input__icon" slot="suffix"> </i>
                                </el-input>
                            </li>
                        </div>
                        <li :class="{ active: dosage_form_index === dosageItem }" v-for="(dosageItem, d_index) in dosage_form" @click="setDosageForm(dosageItem, d_index)">
                            <span v-html="highLight(dosageItem, inputtype)"></span>
                        </li>
                    </ul>
                    <template v-if="dosage_form.length > 7">
                        <div class="collapse-btn" @click="collapse(4)">
                            {{ dosage_form_collapsetype ? "展开" : "收起" }}
                        </div>
                    </template>
                </div>
            </div>
        </div>
        </div>
        <div class="search_bread-swtich" v-for="(item,index) in streamer" :key="index">
                <el-image  :src="$util.img(item.src)"  @click="onckicswich(item.url)"></el-image>
        </div>
    </div>
</template>

<script>
import { highLightTableMsg } from "../filter.js"
export default {
    name: "PcScreenList1",
    props: {
        first_child_listtype: {
            type: Array,
            default: () => []
        },
        keyword: {
            type: String,
            default: ""
        },
        catewords: {
            type: Array,
            default: () => []
        },
        filters: {
            type: Object,
            default: () => ({})
        },
        factory: {
            type: Array,
            default: () => []
        },
        spec: {
            type: Array,
            default: () => []
        },
        brand: {
            type: Array,
            default: () => []
        },
        dosage_form: {
            type: Array,
            default: () => []
        },
        first_index_active: {
            type: Number,
            default: 0
        },
        first_index: {
            type: Number,
            default: 0
        },
        factory_index: {
            type: String,
            default: ""
        },
        factory_collapse: {
            type: Boolean,
            default: true
        },
        spec_collapse: {
            type: Boolean,
            default: true
        },
        spec_index: {
            type: String,
            default: ""
        },
        brand_collapse: {
            type: Boolean,
            default: true
        },
        brand_index: {
            type: String,
            default: ""
        },
        dosage_form_collapse: {
            type: Boolean,
            default: true
        },
        dosage_form_index: {
            type: String,
            default: ""
        },
        streamer:{
            type:Array,
            default:()=>[]
        },
        showgoodsList:{
            type:Number,
            default:1
        }
    },
    data() {
        return {
            highLightTableMsg,
            inptName: "",
            specificationInput: "",
            inputtype: "",
            barndInput: "",
            factory_collapsetype: this.factory_collapse,
            spec_collapsetype: this.spec_collapse,
            dosage_form_collapsetype: this.dosage_form_collapse,
            brand_collapsetype: this.brand_collapse
        }
    },
    watch: {
        factory_collapse(vla) {
            this.factory_collapsetype = vla
        },
        spec_collapse(vla) {
            this.spec_collapsetype = vla
        },
        dosage_form_collapse(vla) {
            this.dosage_form_collapsetype = vla
        },
        brand_collapse(val) {
            this.brand_collapsetype = val
        }
    },
    mounted() {},

    methods: {
        onckicswich(url){
            console.log(url)
           if(url) this.$router.pushToTab({path:url})
        },
        setFiindextype(item) {
            this.$emit("setFiindex", item)
        },
        setFactorytype(factoryItem, f_index) {
            this.inptName = ""
            this.$emit("setFactory", factoryItem, f_index)
        },
        setSpec(setSpecItem, f_index) {
            this.specificationInput = ""
            this.$emit("setSpec", setSpecItem, f_index)
        },
        setBrand(brandItem, f_index) {
            this.barndInput = ""
            this.$emit("setBrand", brandItem, f_index)
        },
        setDosageForm(dosageItem, d_index) {
            this.inputtype = ""
            this.$emit("setDosageForm", dosageItem, d_index)
        },
        changeinouttype(val, idnex) {
            let nameprm = {
                // category_id: 0,
                factory_index: this.inptName,
                factoryStr: this.inptName,
                // specStr: "",
                // dosageFormStr: "",
                inptName: this.inptName,
                factory_collapsetype: false
            }
            let specification = {
                // category_id: 0,
                // dosageFormStr: "",
                // factoryStr: "",
                spec_index: this.specificationInput,
                specStr: this.specificationInput,
                spec_collapsetype: false
            }
            let dosage = {
                // category_id: 0,
                // factoryStr: "",
                // specStr: "",
                dosage_form_index: this.inputtype,
                dosageFormStr: this.inputtype,
                dosage_form_collapsetype: false
            }
            let category = {
                //    category_id :0,
                //    factoryStr : "",
                //    specStr : "",
                //    dosageFormStr:"",
                brand_index: this.barndInput,
                brandStr: this.barndInput,
                brand_collapsetype: false
            }
            switch (idnex) {
                case 1:
                    if (!this.inptName) {
                        nameprm.factory_collapsetype = true
                        nameprm.factory_index = ""
                    }
                    this.$emit("changeinout", nameprm, idnex)
                    break
                case 2:
                    if (!this.specificationInput) {
                        specification.spec_collapsetype = true
                        specification.spec_index = ""
                    }
                    this.$emit("changeinout", specification, idnex)
                    break
                case 3:
                    if (!this.inputtype) {
                        dosage.dosage_form_collapsetype = true
                        dosage.dosage_form_index = ""
                    }
                    this.$emit("changeinout", dosage, idnex)
                    break
                case 4:
                    if (!this.barndInput) {
                        category.brand_collapsetype = true
                        category.brand_index = ""
                    }
                    this.$emit("changeinout", category, idnex)
                    break
            }
        },
        collapse(val) {
            switch (val) {
                case 1:
                    this.factory_collapsetype = !this.factory_collapsetype
                    this.$emit("collapse", this.factory_collapsetype,val)
                    break
                case 2:
                    this.spec_collapsetype = !this.spec_collapsetype
                    this.$emit("collapse", this.spec_collapsetype,val)
                    break
                case 3:
                   this.brand_collapsetype = !this.brand_collapsetype
                   this.$emit("collapse", this.brand_collapsetype,val)
                    break
                case 4:
                   this.dosage_form_collapsetype = !this.dosage_form_collapsetype
                   this.$emit("collapse", this.dosage_form_collapsetype,val)
                    break
            }
        },
        // 对高亮进行封装
        highLight(item, highLight) {
            return highLightTableMsg(item, highLight)
        }
    }
}
</script>

<style lang="scss" scoped>
.scee1{
    width: 1200px;
    margin: 0 auto;
    padding: 0 10px;
}
.list-img {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    .el-image {
        width: 400px;
        height: 100px;
        .img {
            width: 100%;
            height: auto;
        }
    }
}

.bias {
    margin: 0 4px;
}

.ul-height-30 {
    padding-right: 30px;
    max-height: 30px;
    transition: max-height 0.6s ease-out;
    overflow: hidden;
}

.ul-expand {
    padding-right: 30px;
    transition: max-height 0.6s ease-in;
    max-height: 500px; /*一个足够大的值*/
    overflow: hidden;
}

.factory_selected {
    color: $base-color;
}

.spec_selected {
    color: $base-color;
}

.dosage_form__selected {
    color: $base-color;
}

.search-type-item {
    padding: 10px;
    font-size: 14px;
    display: flex;

    .title {
        flex-shrink: 0;
    }

    .search-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .factory-search {
            width: 40%;
        }

        .attr-list {
            position: relative;

            &:nth-child(2) {
                margin-top: 10px;
            }

            width: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding: 10px;

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                column-gap: 25px;
                grid-template-rows: minmax(20px, auto);

                li {
                    cursor: pointer;
                    //文字溢出显示省略号
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    //margin: 0 20px;
                    flex-shrink: 0;

                    &:hover {
                        color: $base-color;
                    }

                    &.active {
                        color: $base-color;
                    }
                }
            }
        }

        .spec-list {
            ul {
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            }
        }

        .dosage_form-list {
            ul {
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            }
        }
    }

    .collapse-btn {
        position: absolute;
        right: 0;
        top: 8px;
        cursor: pointer;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        color: $base-color;
        padding: 0 10px;
    }
}

// .goods-list {
//     background: #fff;
//     padding-top: 10px;
// }

.search_bread {
    display: inline-block;
    font-size: 14px;
    margin: 0px auto;
    width: 100%;
    padding: 10px;

    p {
        float: left;
    }

    li {
        float: left;
        padding: 0 10px;
    }

    .active a {
        color: white !important;
        border: 1px solid $base-color !important;
        padding: 5px;
        border-radius: 5px;
        background: $base-color !important;
    }
}

.categoryLevel2 {
    transform: translateY(10px);
}

.grid-container {
    flex: 1;
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.categoryBox {
    padding: 10px 0;
    display: flex;
    margin-left: 10px;
}

.boxCategory {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px !important;
}

.boxactive {
    background-color: #ffffff;
    border: 1px solid $base-color !important;
    a {
        color: $base-color;
    }
}

.selected_border {
    border: 1px solid $base-color;
}

.attr_filter {
    .el-tag {
        margin-right: 5px;
        margin-bottom: 10px;
        border-radius: 0;

        .title {
            color: $base-color;
            font-size: 15px;
        }
    }
}

.category {
    margin: 0 auto 10px auto;
    border: 1px solid #eeeeee;

    .brand {
        border-bottom: 1px solid #eeeeee;
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }

        .table_head {
            width: 140px;
            min-width: 140px;
            border-right: 1px solid #eeeeee;
            padding-left: 10px;
            padding-top: 10px;
            background-color: #f2f6fc;
        }

        .table_body {
            flex-grow: 1;

            .initial {
                margin: 5px auto 10px 10px;

                span {
                    border: 0;
                    margin: 0;
                    padding: 5px 10px;
                    border: 0;
                    border-radius: 0;

                    &:hover {
                        background-color: $base-color;
                        color: #ffffff;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 10px;
                width: 900px;

                .brand-item {
                    margin-right: 10px;
                    margin-top: 10px;
                }

                .el-card {
                    width: 125px;
                    height: 45px;

                    &:hover {
                        border: 1px solid $base-color;
                        cursor: pointer;
                    }
                }

                span {
                    margin: auto 25px;
                }
            }
        }

        .table_op {
            margin-top: 5px;
            margin-right: 5px;
        }

        .el-image {
            width: 100%;
            height: 100%;
        }
    }
}
.empty {
    margin-top: 45px;
}

.pager {
    text-align: center;
    margin-top: 30px;
}
.search_bread-swtich{
        width: 1170px;
        margin: auto;
        padding-right: 40px;
    
        img {
                max-width: 100%;
                height: auto;
                display: block;
            }
    }
</style>