<template>
    <div class="classify">
        <!-- 滚动条 -->
        <div class="classify-barden">
            <el-carousel arrow="hover" height="400px">
                <el-carousel-item v-for="(item, index) in banners" :key="index">
                    <el-image :src="item.src" fit="cover" @click="$router.pushToTab(item.url)" />
                </el-carousel-item>
            </el-carousel>
        </div>
        <div class="classify-box">
            <!-- table商品 -->
            <div class="classify-nv">
                <ul>
                    <li
                        v-for="(item,index) in category"
                        :key="index"
                        @click="onclickData(item.id,1)"
                    >
                        <div>
                            <el-image :src="$util.img(item.logo)" fit="cover"></el-image>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- table品牌-->
            <div class="classify-nv">
                <ul>
                    <li
                        v-for="(item,index) in calssify"
                        :key="index"
                        @click="onclickData(item.id,2)"
                    >
                        <div>
                            <el-image :src="$util.img(item.logo)" fit="cover"></el-image>
                        </div>
                    </li>
                </ul>
            </div>
            <!-- 副选背景图 -->
            <div class="classify-swtich">
                <div class="img-box" v-for="(item,index) in streamer" :key="index">
                    <el-image :src="$util.img(item.src)" @click="onckicswich(item.url)"></el-image>
                </div>
            </div>
            <!-- 楼层 -->
            <classifyFlootr
                v-for="(item,index) in fooltData"
                :key="index"
                :data="item"
                :c_index="index"
            ></classifyFlootr>
        </div>
    </div>
</template>

<script>
import classifyFlootr from "./compoents/ClassifyFlootr.vue"
import classifyData from "./classifyData.js"
export default {
    components: {
        classifyFlootr
    },
    mixins: [classifyData],
    data: () => {
        return {}
    }
}
</script>

<style lang="scss" scoped>
.classify {
    &-box {
        width: 1200px;
        margin: 0 auto;
        .classify-nv {
            margin: 20px 0;
            ul {
                // width: 1200px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                // margin: 0 auto;
                margin-bottom: 30px;
            }
            li {
                // margin-right: 40px;
                text-align: center;
                cursor: pointer;
                border-radius: 5px;
                &:hover {
                    opacity: 0.7;
                }
                .el-image {
                    width: 100px;
                    height: 100px;
                }
                .nv-name {
                    font-weight: bold;
                    text-align: center;
                    color: #303133ed;
                }
            }
        }
        .classify-swtich {
            width: 1200px;
            margin: auto;
            display: flex;
            align-items: center;
            flex-direction: column;
            .img-box{
                margin-bottom: 20px;
                cursor:pointer;
            }
        }
    }
}

.el-carousel {
    width: 100%;
    .el-image__inner {
        width: auto;
    }
}
::v-deep .el-carousel__arrow--right {
    right: 60px;
}
</style>