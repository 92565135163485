export default {

	// api请求地址
	baseUrl: process.env.NODE_ENV==='development'?'/api':'https://manage.2myxc.com/', //预览版： /stag-api  上线：https://manage.2myxc.com/
	// baseUrl: process.env.NODE_ENV==='development'?'/api':'http://192.168.110.111/',
	// baseUrl: process.env.NODE_ENV==='development'?'/api':'http://192.168.101.108/',
  // baseUrl: process.env.NODE_ENV==='development'?'/api':'http://local.em.api.com',
	// 图片域名
	imgDomain: 'https://manage.2myxc.com/',

	// 腾讯地图key
	 mpKey: '',

	// 客服
	 webSocket: 'wss://manage.2myxc.com/wss',

	// api安全
	apiSecurity: false,

	//本地端主动给服务器ping的时间, 0 则不开启 , 单位秒
	pingInterval: 1500,

	// 公钥
	publicKey: ``,

}
