<template>
    <div>
    <el-table
    :data="data"
    stripe
    style="width: 100%">
    <el-table-column
      prop="invite_member_name"
      label="受邀会员账号"
      width="190">
    </el-table-column>
    <el-table-column
      prop="coupon_names"
      label="优惠卷名称"
      width="190">
    </el-table-column>
    <el-table-column
      prop="point"
      label="积分">
    </el-table-column>
    <el-table-column
      prop="balance"
      label="红包"
      width="100">
    </el-table-column>
    <el-table-column
      prop="growth"
      label="成长值">
    </el-table-column>
    <el-table-column
      prop="use_condition"
      label="触发方式">
    </el-table-column>
    <el-table-column
      prop="create_time"
      label="获取时间"
      width="190">
    </el-table-column>
  </el-table>
    </div>
</template>

<script>
    export default {
        props:{
            data:{
                type:Array,
                default:()=>[]
            },
            count:{
                type:Number,
                default:5
            }
        },
        data(){
         return{

         }
        }
    }
</script>

<style lang="postcss" scoped>

</style>