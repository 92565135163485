<template>
    <div class="screelist2">
        <div class="screelist2-list">
            <el-carousel arrow="hover" height="400px">
                <el-carousel-item v-for="(item, index) in banners" :key="index"><el-image :src="item.src" fit="cover" @click="$router.pushToTab(item.url)" /></el-carousel-item>
            </el-carousel>
        </div>
        <!-- 选项模块 -->
        <div class="screelist2-nv">
            <ul style="display: flex">
                <li v-for="(item,index) in data" :key="index" @click="onclickData(item.id)">
                  <div>
                      <el-image :src="$util.img(item.logo)" fit="cover"> </el-image>
                  </div>
                </li>
            </ul>
        </div>
        <div class="screelist2-swtich">
              <el-image :src="$util.img(item.src)" v-for="(item,index) in streamer" :key="index" @click="onckicswich(item.url)"></el-image>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcScreenList2",
    props: {
        loadingAd: {
            type: Boolean,
            default: true
        },
        data: {
            type: Array,
            default: () => []
        },
        banners: {
            type: Array,
            default: () => []
        },
        streamer:{
            type:Array,
            default:()=>[]
        }
    },
    data() {
        return {
            adList: [
                {
                    adv_image: require("@/assets/images/payment/1.jpg"),
                    value: 1
                }
            ],
            goodsCategoryTree: [],
            listdata: []
        }
    },
    created() {
    },
    mounted() {},
    methods: {
        onclickData(val) {
            this.$emit("uploadId", val)
        },
        onckicswich(url){
           if(url) this.$router.pushToTab({path:url})
        }
    }
}
</script>

<style lang="scss" scoped>
.screelist2 {
    &-list {
        height: 400px;
        .el-image {
            width: 100%;
            height: 400px;
            img {
                max-width: 100%;
                height: auto;
                display: block;
            }
        }
    }
    &-nv {
        margin: 20px 0;
        ul {
            padding-right: 40px;
            width: 1200px;
            box-sizing: border-box;
            justify-content: space-between;
            margin: 0 auto;
        }
        li {
            // margin-right: 40px;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            &:hover {
                opacity: 0.7;
            }
            .el-image {
                width: 100px;
                height: 100px;
            }
            .nv-name {
                font-weight: bold;
                text-align: center;
                color: #303133ed;
            }
        }
    }
    &-swtich{
        width: 1170px;
        margin: auto;
        padding-right: 40px;
    }
}
.el-carousel {
    width: 100%;
    .el-image__inner {
        width: auto;
    }
}
::v-deep .el-carousel__arrow--right {
    right: 60px;
}
</style>