<template>
    <div
        class="goods-list"
        :style="{ backgroundImage: 'url(' + navBg + ')', backgroundColor: navbgColor }"
        v-loading="loading"
    >
        <!-- <div
            class="list-img"
            v-if="(filters.tag_id == '2' || filters.tag_id == '1' || filters.tag_id == '11') && (viewStyle == 1 || viewStyle == 3)"
            style="margin-top: 10px"
        >
            <el-image
                v-if="filters.tag_id == '2'"
                fit="scale-down"
                :src="require('../../../assets/images/goods/recommend-1.png')"
            ></el-image>
            <el-image
                v-if="filters.tag_id == '1'"
                fit="scale-down"
                :src="require('../../../assets/images/goods/product.png')"
            ></el-image>
            <el-image
                v-if="filters.tag_id == '11'"
                fit="scale-down"
                :src="require('../../../assets/images/goods/medical.png')"
            ></el-image>
        </div> -->
        <screen-list1
            v-if="viewStyle == 1"
            @setFiindex="setFiindex"
            @setFactory="setFactory"
            @changeinout="changeinout"
            @collapse="collapse"
            @setSpec="setSpec"
            @setBrand="setBrand"
            :first_child_listtype="first_child_list"
            :keyword="keyword"
            :catewords="catewords"
            :filters="filters"
            :factory="factory"
            :spec="spec"
            :brand="brand"
            :dosage_form="dosage_form"
            :first_index_active="first_index_active"
            :first_index="first_index"
            :factory_index="factory_index"
            :spec_collapse="spec_collapse"
            :spec_index="spec_index"
            :brand_collapse="brand_collapse"
            :barndInput="barndInput"
            :brand_index="brand_index"
            :factory_collapse="factory_collapse"
            :dosage_form_collapse="dosage_form_collapse"
            :dosage_form_index="dosage_form_index"
            :streamer="streamer"
            :showgoodsList="showgoodsList"
        ></screen-list1>
        <screen-list2
            v-if="viewStyle == 2"
            :data="subCategory"
            :loadingAd="loading"
            @uploadId="uploadId"
            :banners="banners"
            :streamer="streamer"
        ></screen-list2>
        <screen-list3
            v-if="viewStyle == 3"
            @setFiindex="setFiindex"
            @setFactory="setFactory"
            @changeinout="changeinout"
            @collapse="collapse"
            @setSpec="setSpec"
            @setBrand="setBrand"
            @changeSort="changeSort"
            @handlePriceRange="handlePriceRange"
            :first_child_listtype="first_child_list"
            :keyword="keyword"
            :catewords="catewords"
            :filters="filters"
            :factory="factory"
            :spec="spec"
            :brand="brand"
            :dosage_form="dosage_form"
            :first_index_active="first_index_active"
            :first_index="first_index"
            :factory_index="factory_index"
            :spec_collapse="spec_collapse"
            :spec_index="spec_index"
            :brand_collapse="brand_collapse"
            :barndInput="barndInput"
            :brand_index="brand_index"
            :factory_collapse="factory_collapse"
            :dosage_form_collapse="dosage_form_collapse"
            :dosage_form_index="dosage_form_index"
            :showgoodsList="showgoodsList"
            :streamer="streamer"
        ></screen-list3>
        <div class="list-wrap" :style="textCompNameSecondStyle" v-if="showgoodsList == 1">
            <div class="goods-recommended" v-if="cargoList.length">
                <goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
            </div>
            <div class="list-right">
                <!-- 排序筛选区 -->
                <div v-if="viewStyle != 3">
                    <div class="sort">
                        <div class="item" @click="changeSort('')">
                            <div class="item-name">综合</div>
                        </div>
                        <div class="item" @click="changeSort('sale_num')">
                            <div class="item-name">销量</div>
                            <i
                                v-if="filters.order === 'sale_num' && filters.sort === 'desc'"
                                class="el-icon-arrow-down el-icon--down"
                            />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <div class="item" @click="changeSort('discount_price')">
                            <div class="item-name">价格</div>
                            <i
                                v-if="filters.order === 'discount_price' && filters.sort === 'desc'"
                                class="el-icon-arrow-down el-icon--down"
                            />
                            <i v-else class="el-icon-arrow-up el-icon--up" />
                        </div>
                        <!-- <div class="item-other">
                            <el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
                        </div>
                        <div class="item-other">
                            <el-checkbox label="自营" v-model="is_own"></el-checkbox>
                        </div>-->
                        <div class="input-wrap">
                            <div class="price_range">
                                <el-input
                                    placeholder="最低价格"
                                    size="small"
                                    v-model="filters.min_price"
                                ></el-input>
                                <span>—</span>
                                <el-input
                                    placeholder="最高价格"
                                    size="small"
                                    v-model="filters.max_price"
                                ></el-input>
                            </div>
                            <el-button plain size="mini" @click="handlePriceRange">确定</el-button>
                        </div>
                    </div>
                </div>

                <div v-if="cargoList.length">
                    <div style="display: flex; flex-wrap: wrap;">
                        <goods-info-item
                            v-for="(item, index) in cargoList"
                            :key="item.goods_id"
                            :goods-name="item.goods_name"
                            :goods="item"
                            :factory="item.extension_data.factory"
                            :spec="item.extension_data.spec"
                            :dosage_form="item.extension_data.dosage_form"
                            :unit_package="item.extension_data.unit_package"
                            :min_buy="item.extension_data.min_buy"
                            :expiry_date="item.extension_data.expiry_date"
                            :stock="item.stock"
                            :tag_list="item.tag_list"
                            :control="item.control"
                            :img_url="item.sku_image"
                            @click.native="goSku(item)"
                        >
                            <!-- @click.native="$router.pushToTab({ path: '/sku?id=' + item.sku_id })" -->
                            <!-- <div class="item-bottom">
                                    <div v-if="!item.isCollection" class="collection" @click.stop="editCollection(item)">
                                    <i class="iconfont iconlike"></i>
                                    收藏
                                    </div>
                                    <div v-if="item.isCollection" class="collection" @click.stop="editCollection(item)">
                                    <i class="iconfont iconlikefill ns-text-color"></i>
                                    取消收藏
                                    </div>
                                    <div class="cart" @click.stop="addToCart(item)">
                                    <i class="el-icon-shopping-cart-2"></i>
                                    加入购物车
                                    </div>
                            </div>-->
                            
                    <template v-if="token && item.control.is_member_buy">
                        <div class="cart-layout">
                            <div style="width: 100px;"> <shareCounter :number="item.min_buy" :goodsSkuDetail="{
                                        min_buy:item.min_buy,
                                        package_num:item.package_num,
                                        stock:item.stock
                                    }" size="mini" @upnumber="upnumber"></shareCounter></div>
                            <div class="cart-add" @click.stop="joinCart(item)" v-if="item.package_num<item.stock">加入购物车</div>
                        </div>
                    </template>
                        </goods-info-item>
                    </div>
                    <div style="display: flex; justify-content: flex-end">
                        <el-pagination
                            style="padding-right: 30px; margin-top: 20px"
                            background
                            :pager-count="5"
                            :total="total"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="currentPage"
                            :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange"
                        ></el-pagination>
                    </div>
                    <div class="cart-float-btn" v-if="show_shopping" @click="$router.push('/cart')">
                        <div class="cart-icon">
                            <img
                                src="https://manage.2myxc.com//upload/default/diy_view/bottom/cart_selected.png"
                                mode="aspectFill"
                            />
                            <span class="cart-count">{{cartCount}}</span>
                        </div>
                    </div>
                </div>

                <div class="empty" v-else>
                    <div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs"
import GoodsRecommend from "@/components/GoodsRecommend"
import list from "./list"
import { goodsInfoList, goodsInfoItem } from "@/components/goods-info"
import screenList1 from "./componet/screenList-1.vue"
import screenList2 from "./componet/screenList-2.vue"
import screenList3 from "./componet/screenList-3.vue"
import shareCounter from "@/components/shareCounter.vue"
import router from "@/router"
export default {
    name: "list",
    components: {
        BreadCrumbs,
        GoodsRecommend,
        goodsInfoList,
        goodsInfoItem,
        screenList1,
        screenList2,
        screenList3,
        shareCounter
    },
    data: () => {
        return {
            number:1
        }
    },
    mixins: [list],
    created() {},
    methods: {
        upnumber(val){
              this.number=val
        },
            // 加入购物车
            joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.sku_id,
                    num: this.number
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch((err) => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        }
    },
    computed: {
        textCompNameSecondStyle() {
            let style = {}
            // if(this.viewStyle == '2') {
            //     style.width = '1200px';
            //     style.margin = '0 auto'
            // }
            style.width = "1200px"
            style.margin = "0 auto"
            return style
        }
    }
}
</script>

<style lang="scss" scoped>
        .cart-layout {
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .cart-num {
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;

                        span {
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }

                        span:first-child {
                            left: 0;
                        }

                        span:last-child {
                            right: 0;
                        }

                        .number {
                            .el-input {
                                .el-input__inner {
                                    text-align: center;
                                }
                            }
                        }
                    }
                   ::v-deep .el-input-number--mini{
                        width: 95px !important;
                    }
                    .cart-add {
                        cursor: pointer;
                        border: 1px solid $base-color;
                        color: $base-color;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
.list-img {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;

    .el-image {
        width: 400px;
        height: 100px;

        .img {
            width: 100%;
            height: auto;
        }
    }
}

.bias {
    margin: 0 4px;
}

.ul-height-30 {
    padding-right: 30px;
    max-height: 30px;
    transition: max-height 0.6s ease-out;
    overflow: hidden;
}
.ul-expand {
    padding-right: 30px;
    transition: max-height 0.6s ease-in;
    max-height: 500px;
    /*一个足够大的值*/
    overflow: hidden;
}

.factory_selected {
    color: $base-color;
}

.spec_selected {
    color: $base-color;
}

.dosage_form__selected {
    color: $base-color;
}

.search-type-item {
    padding: 10px;
    font-size: 14px;
    display: flex;

    .title {
        flex-shrink: 0;
    }

    .search-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .factory-search {
            width: 40%;
        }

        .attr-list {
            position: relative;

            &:nth-child(2) {
                margin-top: 10px;
            }

            width: 100%;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            padding: 10px;

            ul {
                display: grid;
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
                column-gap: 25px;
                grid-template-rows: minmax(20px, auto);

                li {
                    cursor: pointer;
                    //文字溢出显示省略号
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    display: block;
                    //margin: 0 20px;
                    flex-shrink: 0;

                    &:hover {
                        color: $base-color;
                    }

                    &.active {
                        color: $base-color;
                    }
                }
            }
        }

        .spec-list {
            ul {
                grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
            }
        }

        .dosage_form-list {
            ul {
                grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
            }
        }
    }

    .collapse-btn {
        position: absolute;
        right: 0;
        top: 8px;
        cursor: pointer;
        text-align: right;
        font-size: 16px;
        font-weight: bold;
        color: $base-color;
        padding: 0 10px;
    }
}

.goods-list {
    padding-bottom: 45px;
    background-repeat: no-repeat;
    background-size: cover;
}

.search_bread {
    display: inline-block;
    font-size: 14px;
    margin: 0px auto;
    width: 100%;
    padding: 10px;

    p {
        float: left;
    }

    li {
        float: left;
        padding: 0 10px;
    }

    .active a {
        color: white !important;
        border: 1px solid $base-color !important;
        padding: 5px;
        border-radius: 5px;
        background: $base-color !important;
    }
}

.categoryLevel2 {
    transform: translateY(10px);
}

.grid-container {
    flex: 1;
    display: grid;
    grid-row-gap: 10px;
    grid-column-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
}

.categoryBox {
    padding: 10px 0;
    display: flex;
    margin-left: 10px;
}

.boxCategory {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 10px !important;
}

.boxactive {
    background-color: #ffffff;
    border: 1px solid $base-color !important;

    a {
        color: $base-color;
    }
}

.selected_border {
    border: 1px solid $base-color;
}

.attr_filter {
    .el-tag {
        margin-right: 5px;
        margin-bottom: 10px;
        border-radius: 0;

        .title {
            color: $base-color;
            font-size: 15px;
        }
    }
}

.category {
    margin: 0 auto 10px auto;
    border: 1px solid #eeeeee;

    .brand {
        border-bottom: 1px solid #eeeeee;
        display: flex;
        flex-direction: row;

        &:last-child {
            border-bottom: none;
        }

        .table_head {
            width: 140px;
            min-width: 140px;
            border-right: 1px solid #eeeeee;
            padding-left: 10px;
            padding-top: 10px;
            background-color: #f2f6fc;
        }

        .table_body {
            flex-grow: 1;

            .initial {
                margin: 5px auto 10px 10px;

                span {
                    border: 0;
                    margin: 0;
                    padding: 5px 10px;
                    border: 0;
                    border-radius: 0;

                    &:hover {
                        background-color: $base-color;
                        color: #ffffff;
                    }
                }
            }

            .content {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 10px;
                width: 900px;

                .brand-item {
                    margin-right: 10px;
                    margin-top: 10px;
                }

                .el-card {
                    width: 125px;
                    height: 45px;

                    &:hover {
                        border: 1px solid $base-color;
                        cursor: pointer;
                    }
                }

                span {
                    margin: auto 25px;
                }
            }
        }

        .table_op {
            margin-top: 5px;
            margin-right: 5px;
        }

        .el-image {
            width: 100%;
            height: 100%;
        }
    }
}
.cart-float-btn {
    position: fixed;
    right: 55px;
    bottom: 180px;
    width: 50px;
    height: 50px;
    /* background-color: #ffebf9; */
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e29ea33b;
    z-index: 999;
    opacity: 0.8;
    .cart-icon {
        width: 20px;
        height: 20px;
        /* background-image: url("path/to/cart-icon.png"); */
        background-size: cover;
        position: relative;
        text-align: center;
        line-height: 20px;
    }
    .cart-icon img {
        width: 100%;
        height: 100%;
    }
    .cart-count {
        position: absolute;
        right: -19px;
        top: -20px;
        background-color: #ff0000;
        color: #ffffff;
        font-size: 12px;
        padding: 4px 4px;
        border-radius: 50%;
        width: 10px;
        height: 10px;
        text-align: center;
        line-height: 10px;
    }
}

.list-wrap {
    overflow: hidden;
}

.goods-recommended {
    width: 200px;
    background-color: #fff;
    float: left;
    margin-right: 10px;
}

.sort {
    display: flex;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;

        &:hover {
            background: $base-color;
            color: #fff;
        }
    }

    .item-other {
        display: flex;
        align-items: center;
        padding: 5px 15px;
        border: 1px solid #f1f1f1;
        border-left: none;
        cursor: pointer;
    }

    .input-wrap {
        display: flex;
        align-items: center;

        .price_range {
            margin-left: 60px;
        }

        span {
            padding-left: 10px;
        }

        .el-input {
            width: 150px;
            margin-left: 10px;
        }

        .el-button {
            margin: 0 17px;
        }
    }

    > div:first-child {
        border-left: 1px solid #f1f1f1;
    }
}

.empty {
    margin-top: 45px;
}

.pager {
    text-align: center;
    margin-top: 30px;
}
</style>
