import { adList, navList } from "@/api/website"
import http from "@/utils/http"
export default {
    data: () => {
        return {
            calssify: [
            ],
            banners: [],
            fooltData: [],
            category: [],
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                tag_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                platform_coupon_type: 0
            },
            keyword: "",
            streamer: [
            ],
            id: 1
        }
    },
    created() {
        this.keyword = this.$route.query.keyword || ""
        this.filters.category_id = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
        this.filters.tag_id = this.$route.query.tag_id || ""
        this.filters.platform_coupon_type = this.$route.query.platform_coupon_type || ""
        this.keyword = this.$route.query.keyword || ""
        this.getnavList()
    },
    methods: {

        onckicswich(url) {
                window.location.href = url
              //刷新当前页面
                // window.location.reload()
       },
      

        getnavList() {
            navList({})
                .then(res => {
                    let { code, data } = res
                    if (code >= 0) {
                        //判断是否为当前页面数据
                        const path = this.$route.fullPath
                        data.list.forEach(item => {
                            //过滤当前为空数据
                            let urlId = JSON.parse(item.nav_url).url
                            if (item.sub_category && urlId.startsWith("/barden")) {
                                this.id = item.id
                                if (item.id) {
                                    this.navFloors()
                                }
                                this.sub_nav = JSON.parse(item.sub_nav)
                                let sub_brand = JSON.parse(item.sub_brand)
                                let sub_category = JSON.parse(item.sub_category)
                                this.streamer = JSON.parse(item.streamer)
                                this.banners = JSON.parse(item.banners)
                                if (sub_brand.length > 0 || category > 0) {
                                    this.calssify = sub_brand
                                    this.category = sub_category
                                }
                           
                                return
                            }
                        })
                    }
                })
                .catch(err => { })
        },
        async navFloors() {
            let res = await http({ url: "/api/pc/navFloors", data: { id: this.id } })
            let { code, data } = res
            if (code >= 0) {
                this.fooltData = data
            }
        },
        onclickData(val, idnex) {
            switch (idnex) {
                case 1:
                    this.$router.pushToTab({ path: '/list',query:{category_id:val}});
                break;
                case 2 :
                    this.$router.pushToTab({ path: '/list',query:{brand_id:val}});
                    break
            }
            console.log(val)

        },
    }
};