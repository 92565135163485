<template>
    <div class="footer-bottom">
        <div class="site-info">
            <a v-for="v in footerList.cert" :key="v.id" class="foot-link" :href="v.link_url" target="_blank"><img v-if="v.icon" :src="v.icon" style="width:25px;height: 25px">{{ v.name }}</a>
        </div>
        <p v-for="v in footerList.platform" :key="v.id">
            <a class="footer-link" :href="v.link_url" target="_blank"><img v-if="v.icon" :src="v.icon" style="width:25px;height: 25px">{{ v.name }}</a>
        </p>
    </div>
</template>

<script>
import { copyRight, footerList } from "@/api/website"
import { mapGetters } from "vuex"
export default {
    props: {},
    data() {
        return {
            footerList: []
        }
    },
    computed: {},
    created() {
        this.$store.dispatch("site/copyRight")
        this.getFooterList()
    },
    mounted() {},
    watch: {},
    methods: {
        getFooterList() {
            footerList().then((res) => {
                if (res.code === 0) {
                    this.footerList = res.data
                }
            })
        }
    },
    computed: {
        ...mapGetters(["copyRight", "siteInfo"])
    }
}
</script>

<style scoped lang="scss">
.footer-bottom {
    width: 100%;
    margin: 0 auto;
    padding: 20px 0;
    .foot-link {
        padding: 0 10px;
        border-right: 1px solid #b3b3b3;
        height: 14px;
        line-height: 14px;
        margin: 120px 0 10px 0;
        color: #b3b3b3;
    }
    .foot-link:last-child {
        border-right: 0px;
    }
    .footer-link {
        padding: 0 10px;
        // border-right: 1px solid #b3b3b3;
        margin-bottom: 10px;
    }
    p {
        margin: 0;
        width: 100%;
        box-sizing: border-box;
        text-align: center;

        img {
            width: 20px;
            height: 20px;
            margin-right: 5px;
        }
        .footer-link,
        .el-link.el-link--default {
            color: #b3b3b3 !important;
        }
        .footer-link:hover,
        .el-link.el-link--default:hover {
            color: $base-color;
        }
    }
    .site-info {
        width: 1200px;
        text-align: center;
        margin: 0px auto;
        p {
            width: auto;
            margin: 0 10px;
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
