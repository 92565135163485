<template>
    <div class="activity-list" v-loading="loading">
        <goods-info-list>
            <goods-info-item v-for="(item, index) in dataList" :key="item.goods_id" :goods-name="item.goods_name"
                :goods="item" :factory="item.extension_data.factory" :spec="item.extension_data.spec"
                :dosage_form="item.extension_data.dosage_form" :unit_package="item.extension_data.unit_package"
                :min_buy="item.extension_data.min_buy" :expiry_date="item.extension_data.expiry_date"
                :stock="item.goods_stock" :tag_list="item.tag_list" :control="item.control" :img_url="item.goods_image"
                :goods-type="info.label" :width="220" :left="19" @click.native="goSku(item)">
                <!-- <template v-if="token && item.control.is_member_buy">
                    <div class="cart-layout">
                        <div style="width: 100px;">
                            <shareCounter :number="Number(item.extension_data.min_buy)" :goodsSkuDetail="{
                                min_buy: Number(item.extension_data.min_buy),
                                package_num: Number(item.extension_data.package_num),
                                stock: Number(item.goods_stock)
                            }" size="mini" @upnumber="upnumber"></shareCounter>
                        </div>
                        <div class="cart-add" @click.stop="joinCart(item)" v-if="Number(item.extension_data.package_num) < Number(item.goods_stock)">加入购物车
                        </div>
                    </div>
                </template> -->
            </goods-info-item>
            <!-- @click.native="$router.pushToTab({ path: info.getDetailUrl(item) })" -->
        </goods-info-list>
        <el-pagination v-if="dataList.length" style="padding-right: 30px; margin-top: 20px" background :pager-count="5"
            :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize"
            @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"></el-pagination>
        <div v-else class="empty">
            <p>暂无数据</p>
        </div>
    </div>
</template>
<script>
import { goodsInfoList, goodsInfoItem } from "@/components/goods-info"
import { onTip } from "@/utils/tips"
import { mapGetters } from "vuex"
import shareCounter from "@/components/shareCounter.vue"
export default {
    name: "index.vue",
    components: { goodsInfoList, goodsInfoItem, shareCounter },
    props: {
        info: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    created() { },
    computed: {
        ...mapGetters(["token", "storeWithoutAuthority"])
    },
    data() {
        return {
            dataList: [],
            total: 0,
            currentPage: 1,
            pageSize: 10,
            loading: false,
            number:1
        }
    },
    methods: {
        upnumber(val){
              this.number=val
         },
            // 加入购物车
            joinCart(item) {
            this.$store
                .dispatch("cart/add_to_cart", {
                    site_id: item.site_id,
                    sku_id: item.id,
                    num: this.number
                })
                .then((res) => {
                    if (res.code === 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch((err) => {
                    if (err.message === "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                })
        },
        goSku(item) {
            if (!this.token) {
                this.$router.pushToTab("/login")
                return
            }
            if (onTip(item.control.show_price)) return

            this.$router.pushToTab({ path: this.info.getDetailUrl(item) })
            // <!-- @click.native="$router.pushToTab({ path: info.getDetailUrl(item) })" -->
        },
        // 请求秒杀商品
        async requestList() {
            this.loading = true
            if (this.info.type) {
                let page = this.currentPage
                let page_size = this.pageSize
                const data = {
                    page,
                    page_size
                }
                this.info.sessionInfo && (data[this.info.sessionInfo.key] = this.info.sessionInfo.getId.call(this.info))
                try {
                    const res = await this.info.listApi(data)
                    if (res.code >= 0) {
                        this.dataList = res.data.list
                        this.total = res.data.count
                    }
                    this.loading = false
                } catch (e) {
                    this.loading = false
                    this.dataList = []
                }
            } else {
                this.loading = false
                this.dataList = []
            }
        },
        handlePageSizeChange(page) { },
        resetPage() {
            this.currentPage = 1
        },
        handleCurrentPageChange() {
            this.requestList()
        }
    },
    watch: {
        info: {
            handler(nValue, oValue) {
                if (nValue) {
                    nValue.type !== oValue.type && this.resetPage()
                    this.requestList()
                }
            },
        }
    }
}
</script>
<style scoped lang="scss">
.activity-list {
    min-height: 200px;
}
.cart-layout {
                    margin: 10px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .cart-num {
                        margin-right: 10px;
                        position: relative;
                        width: 30%;
                        border-radius: 4px;
                        display: flex;
                        border: 1px solid #ccc;
                        justify-content: center;
                        align-items: center;

                        span {
                            z-index: 1;
                            position: absolute;
                            cursor: pointer;
                            width: 20px;
                            text-align: center;
                        }

                        span:first-child {
                            left: 0;
                        }

                        span:last-child {
                            right: 0;
                        }

                        .number {
                            .el-input {
                                .el-input__inner {
                                    text-align: center;
                                }
                            }
                        }
                    }
                   ::v-deep .el-input-number--mini{
                        width: 95px !important;
                    }
                    .cart-add {
                        cursor: pointer;
                        border: 1px solid $base-color;
                        color: $base-color;
                        border-radius: 4px;
                        padding: 2px 10px;
                    }
                }
::v-deep .goods-info {
    justify-content: flex-start !important;
}

.empty {
    text-align: center;
}
</style>