<template>
    <div class="out-box">
        <div class="hint">用于登录，添加成功之后不可编辑，请认真填写</div>
        <div class="out-min">
            <el-form ref="registerRef" :model="form" label-width="106px" :rules="rules">
                <el-form-item label="客户类型" prop="region">
                    <el-select v-model="form.region" @change="handleTypes($event)">
                        <el-option
                            v-for="item in userList"
                            :key="item.level_id"
                            :label="item.level_name"
                            :value="item.level_name"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="用户名" prop="name">
                <el-input v-model="form.name" placeholder="请输入用户名"></el-input>
                </el-form-item>-->
                <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入联系电话"></el-input>
                </el-form-item>
                <el-form-item :label="numtype == '业务经理' ? '业务经理名称' : '联系人'" prop="person" >
                    <el-input
                        v-model="form.person"
                        :placeholder="numtype == '业务经理' ? '请输入联系人' : '请输入联系人'"
                    ></el-input>
                </el-form-item>
                <el-form-item label="单位名称" prop="unit" v-if="numtype == '业务经理' ? false : true">
                    <el-input v-model="form.unit" placeholder="请输入单位名称"></el-input>
                </el-form-item>

                <!-- <el-form-item label="所属业务员" prop="their">
                <el-input v-model="form.unit" placeholder="请输入所属业务员"></el-input>
                </el-form-item>-->
                <el-form-item label="密码" prop="pass">
                    <el-input type="password" placeholder="请输入密码" v-model="form.pass"></el-input>
                </el-form-item>
                <el-form-item label="确认密码" prop="passwrd">
                    <el-input type="password" placeholder="请再次输入密码" v-model="form.passwrd"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="code">
                    <el-input
                        v-model="form.code"
                        autocomplete="off"
                        placeholder="请输入验证码"
                        maxlength="4"
                    >
                        <template slot="append">
                            <img :src="captcha.img" mode class="captcha" @click="getCode" />
                        </template>
                    </el-input>
                </el-form-item>
                <el-form-item label="所在区域" prop="addressChoice" style="width: 100%">
                    <el-select
                        class="addressChoice"
                        v-model="form.province_id"
                        placeholder="请选择省"
                        @change="changeProvice(form.province_id)"
                    >
                        <el-option
                            v-for="option in province"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        >
                            {{
                            option.name }}
                        </el-option>
                    </el-select>
                    <el-select
                        class="addressChoice"
                        v-model="form.city_id"
                        placeholder="请选择市"
                        @change="changeCity(form.city_id)"
                    >
                        <el-option
                            v-for="option in city"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        >
                            {{
                            option.name }}
                        </el-option>
                    </el-select>
                    <el-select
                        class="addressChoice"
                        v-model="form.district_id"
                        placeholder="请选择区/县"
                        @change="changeDistrict(form.district_id)"
                    >
                        <el-option
                            v-for="option in district"
                            :key="option.id"
                            :label="option.name"
                            :value="option.id"
                        >
                            {{
                            option.name }}
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="address" style="width: 100%">
                    <el-input type="text" placeholder="请输入详细地址" v-model="form.address"></el-input>
                </el-form-item>
                <!-- <el-form-item
                    label="社会信用代码"
                    prop="unified_code"
                    style="width: 100%"
                    v-show="numtype == '业务经理' ? false : true"
                >
                    <el-input type="text" placeholder="请输入统一社会信用代码" v-model="form.unified_code"></el-input>
                </el-form-item>-->
          <!--  :rules="numtype == '业务经理' ? [{required: true}] : rules.superior " -->
                <div style="display: flex;">
                    <el-form-item
                        :label="numtype == '业务经理' ? '管理团队' : '所属业务员'"
                        prop="fenxiao_mobile"
                        label-width="99px"
                        class="superior"
                    >
                        <el-input
                            class="superior-input"
                            v-model="form.fenxiao_mobile"
                            :placeholder="numtype == '业务经理' ? '请输入管理团队电话' : '请输入所属业务员电话'"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="邀请码" label-width="70px" class="regions" v-if="numtype != '业务经理'">
                        <el-input
                            class="regions-input"
                            type="password"
                            placeholder="请输入邀请码"
                            v-model="form.inviteCode"
                            autocomplete="off"
                        ></el-input>
                    </el-form-item>
                </div>

                <div style="display: flex;">
                    <el-form-item label-width="10px" class="out-item">
                        <span
                            class="iconfont"
                            @click="check"
                            :class="ischecked ? 'iconxuanze-duoxuan' : 'iconxuanze'"
                        ></span>
                        <span class="content">
                            阅读并同意
                            <b @click.stop="getAggrement">《服务协议》</b>
                        </span>
                    </el-form-item>
                    <el-form-item class="out-item" label-width="10px">
                        <div class="bg-purple-light" @click="toLogin">
                            已有账号，
                            <span style="color: #fd274a">立即登录</span>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
            <div class="out-r">
                <div calss="r-t">
                    <el-image
                        style="width: 100%; height: 400px"
                        :src="$img(info.register_img)"
                        fit="scale-down"
                    ></el-image>
                </div>
                <div class="r-b">
                    <p v-html="info.register_text" style="white-space:pre-wrap"></p>
                    <!-- <p>{{}}</p>
                    <ul class="ul-list">
                        <li>在医药网购买合法经营的商品</li>
                        <li>享受一站式药品采购服务</li>
                        <li>获取平台活动补贴</li>
                        <li>参与平台的活动促销</li>
                    </ul>-->
                </div>
            </div>
        </div>
        <div class="buttons">
            <div class="btt-l">
                <el-button
                    style=" width: 400px;"
                    type="primary"
                    class="rule-button"
                    @click="register('registerRef')"
                >注册</el-button>
            </div>
            <div class="btt-2">
                <p>联系我们：</p>
                <div class="inof">
                    <span  class="ins-1">
                        <i class="el-icon-user in-1"></i>收件人：{{info.web_liner}}
                    </span>
                    <span class="ins-1">
                        <i class="el-icon-mobile-phone in-1"></i>电话： {{info.web_phone}}
                    </span>
                    <span  class="ins-1">
                        <i class="el-icon-location-information in-1"></i>地址：{{info.web_delivery_address}}
                    </span>
                </div>
            </div>
        </div>
        <el-dialog
            :title="agreement.title"
            :visible.sync="aggrementVisible"
            width="60%"
            :before-close="aggrementClose"
            :lock-scroll="false"
            center
        >
            <div v-html="agreement.content" class="xyContent"></div>
        </el-dialog>
        <vShowregiDialog
            v-model="showDialog"
            :numtype="numtype"
            :phne="form.phone"
            :registerId="registerId"
            :info="info"
        ></vShowregiDialog>
    </div>
</template>

<script>
import http from "@/utils/http"
import { addressInfo, saveAddress } from "@/api/member/member"
import { getArea } from "@/api/address"
import { regionData, CodeToText, TextToCode } from "element-china-area-data"
import { getRegisiterAggrement, register, registerConfig, getclientList } from "@/api/auth/register"
import { captcha } from "@/api/website"
import { setToken } from "@/utils/auth"
import vShowregiDialog from "./showregiDialog.vue"
export default {
    components: {
        vShowregiDialog
    },
    data() {
        
        // 确认密码
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"))
            } else if (value !== this.form.pass) {
                callback(new Error("两次输入密码不一致!"))
            } else {
                callback()
            }
        }
        // 手机号
        var isMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("请输入联系电话"))
            } else {
                const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error("请输入正确的手机号"))
                }
            }
        }
        let self = this
        // 密码
        var passwordValidata = function(rule, value, callback) {
            let regConfig = self.registerConfig
            if (!value) {
                return callback(new Error("请输入密码"))
            } else {
                if (regConfig.pwd_len > 0) {
                    if (value.length < regConfig.pwd_len) {
                        return callback(new Error("密码长度不能小于" + regConfig.pwd_len + "位"))
                    } else {
                        callback()
                    }
                } else {
                    if (regConfig.pwd_complexity != "") {
                        let passwordErrorMsg = "密码需包含",
                            reg = ""
                        if (regConfig.pwd_complexity.indexOf("number") != -1) {
                            reg += "(?=.*?[0-9])"
                            passwordErrorMsg += "数字"
                        } else if (regConfig.pwd_complexity.indexOf("letter") != -1) {
                            reg += "(?=.*?[a-z])"
                            passwordErrorMsg += "、小写字母"
                        } else if (regConfig.pwd_complexity.indexOf("upper_case") != -1) {
                            reg += "(?=.*?[A-Z])"
                            passwordErrorMsg += "、大写字母"
                        } else if (regConfig.pwd_complexity.indexOf("symbol") != -1) {
                            reg += "(?=.*?[#?!@$%^&*-])"
                            passwordErrorMsg += "、特殊字符"
                        } else {
                            reg += ""
                            passwordErrorMsg += ""
                        }

                        if (reg.test(value)) {
                            return callback(new Error(passwordErrorMsg))
                        } else {
                            callback()
                        }
                    }
                }
            }
        }
        var validateSuperior= (rule, value, callback)=> {
        if (!value) {
            let errorMessage = this.numtype == '业务经理' ? '请输入管理团队电话' : '请输入所属业务员电话';
            callback(`${errorMessage}`);
        } else {
            callback();
        }
      }
        // 地址
        var fullAddress = (rule, value, callback) => {
            if (self.form.province_id) {
                if (self.form.city_id) {
                    if (self.form.district_id) {
                        return callback()
                    } else {
                        return callback(new Error("请选择区/县"))
                    }
                } else {
                    return callback(new Error("请选择市"))
                }
            } else {
                return callback(new Error("请选择省"))
            }
        }
        return {
            form: {
                working_group_id: "",
                id: "",
                region: "", // 客户类型
                name: "", // 账号
                pass: "", // 密码
                phone: "", // 手机号
                unit: "", // 单位名称
                person: "", // 联系人名字
                passwrd: "", // 确认密码
                code: "", // 验证码
                inviteCode: "", // 邀请码
                province_id: "", //省id
                city_id: "", //市id
                district_id: "", //区id
                address: "", //收货地址
                unified_code: "", //统一信用代码
                // options: regionData,
                // selectedOptions: [], // 位置区域码
                fenxiao_mobile: ""
            },
            province: [],
            city: [],
            district: [],
            pickerValueArray: [],
            // 校验规则
            rules: {
                addressChoice: [{ required: true, validator: fullAddress, trigger: "change" }],
                address: [{ required: true, message: "请输入详细地址", trigger: "change" }],
                region: [{ required: true, message: "请选择客户类型", trigger: "change" }],
                // name: [
                //     { required: true, message: "请输入用户名", trigger: "blur" }
                //     // { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" }
                // ],
                phone: [{ required: true, validator: isMobile, trigger: "blur" }],
                person: [{ required: true, message: "请输入联系人", trigger: "blur" }],
                unit: [{ required: true, message: "请输入单位名称", trigger: "blur" }],
                fenxiao_mobile: [
                    {
                        required: true,
                        validator:validateSuperior,
                        trigger: "blur"
                    }
                ],
                // their: [{ required: true, message: "请输入所属业务员", trigger: "blur" }],
                pass: [
                    { required: true, message: "请输入密码", trigger: "blur" },
                    { validator: passwordValidata, trigger: "blur" }
                ],
                passwrd: [
                    { required: true, validator: passwordValidata, trigger: "blur" },
                    { validator: validatePass2, trigger: "blur" }
                ],
                unified_code: [{ required: false, message: "请输入统一社会信用代码", trigger: "blur" }],
                code: [{ required: true, message: "请输入验证码", trigger: "blur" }]
            },
            ischecked: false, // 勾选协议
            captcha: {
                // 验证码
                id: "",
                img: ""
            },
            aggrementVisible: false,
            agreement: "",
            registerConfig: {},
            userList: [],
            showDialog: false,
            numtype: "",
            registerId: "",
            info: {}, //商家地址联系信息展示
            superiorMessage: "" // 存储提示消息
        }
    },
    created() {
        this.getCode() //获取验证码
        this.regisiterAggrement() //获取用户协议
        this.getRegisterConfig() //获取用户注册配置
        this.form.id = this.$route.query.id
        this.getAddressDetail()
        this.getDefaultAreas(0, {
            level: 0
        })
        this.getinfo()
    },
    mounted() {
        getclientList()
            .then(res => {
                if (res.code >= 0) {
                    this.userList = res.data
                }
            })
            .catch(err => {
                Message.error(err)
            })
    },
    methods: {
        /**
         * 改变省
         */
        changeProvice(id) {
            this.getAreas(id, data => (this.city = data))
            let obj = {}
            obj = this.province.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.form.city_id = ""
            this.form.district_id = ""
            this.form.full_address = obj.name // 设置选中的地址
        },
        /**
         * 改变市
         */
        changeCity(id) {
            this.getAreas(id, data => (this.district = data))
            let obj = {}
            obj = this.city.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.form.district_id = ""
            this.form.full_address = this.form.full_address + "-" + obj.name
        },
        /**
         * 改变区
         */
        changeDistrict(id) {
            let obj = {}
            obj = this.district.find(item => {
                //这里的province就是上面遍历的数据源
                return item.id === id //筛选出匹配数据
            })
            this.form.full_address = this.form.full_address + "-" + obj.name
        },
        /**
         * 获取地址信息
         */
        getAddressDetail() {
            addressInfo({
                id: this.form.id
            })
                .then(res => {
                    let data = res.data
                    if (data != null) {
                        this.form.name = data.name
                        this.form.mobile = data.mobile
                        this.form.telephone = data.telephone
                        this.form.address = data.address
                        this.form.full_address = data.full_address
                        this.form.latitude = data.latitude
                        this.form.longitude = data.longitude
                        this.form.is_default = data.is_default
                        this.form.province_id = data.province_id
                        this.form.city_id = data.city_id
                        this.form.district_id = data.district_id
                        this.form = [data.province_id, data.city_id, data.district_id]
                    }
                })
                .catch(err => {})
        },
        // 获取联系信息
        async getinfo() {
            const res = await http({ url: "/api/Website/info" })
            let { code, data } = res
            if (code >= 0) {
                this.info = data
            }
        },
        // 异步获取地区
        getAreas(pid, callback) {
            getArea({
                pid: pid
            })
                .then(res => {
                    if (res.code == 0) {
                        var data = []
                        res.data.forEach((item, index) => {
                            data.push(item)
                        })
                        if (callback) callback(data)
                    }
                })
                .catch(err => {})
        },

        /**
         * 获取省市区列表
         */
        getDefaultAreas(pid, obj) {
            getArea({
                pid: pid
            })
                .then(res => {
                    // console.log(res)
                    if (res.code == 0) {
                        var data = []
                        var selected = undefined
                        res.data.forEach((item, index) => {
                            if (obj != undefined) {
                                if (obj.level == 0 && obj.province_id != undefined) {
                                    selected = obj.province_id
                                } else if (obj.level == 1 && obj.city_id != undefined) {
                                    selected = obj.city_id
                                } else if (obj.level == 2 && obj.district_id != undefined) {
                                    selected = obj.district_id
                                }
                            }

                            if (selected == undefined && index == 0) {
                                selected = item.id
                            }

                            data.push(item)
                        })
                        // console.log(data)
                        this.pickerValueArray[obj.level] = data
                        if (obj.level + 1 < 3) {
                            obj.level++
                            this.getDefaultAreas(selected, obj)
                        } else {
                            this.isInitMultiArray = true
                            this.isLoadDefaultAreas = true
                        }

                        this.province = this.pickerValueArray[0]
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },

        /**
         * 渲染默认值
         */
        handleDefaultRegions() {
            var time = setInterval(() => {
                if (!this.isLoadDefaultAreas) return
                this.isInitMultiArray = false

                for (let i = 0; i < this.defaultRegions.length; i++) {
                    for (let j = 0; j < this.pickerValueArray[i].length; j++) {
                        this.province = this.pickerValueArray[0]

                        // 匹配省
                        if (this.defaultRegions[i] == this.pickerValueArray[i][j].id) {
                            // 设置选中省
                            this.$set(this.multiIndex, i, j)
                            // 查询市
                            this.getAreas(this.pickerValueArray[i][j].id, data => {
                                this.city = data

                                for (let k = 0; k < this.city.length; k++) {
                                    if (this.defaultRegions[1] == this.city[k].id) {
                                        // 设置选中市
                                        this.$set(this.multiIndex, 1, k)

                                        // 查询区县
                                        this.getAreas(this.city[k].id, data => {
                                            this.district = data

                                            // 设置选中区县
                                            for (let u = 0; u < this.district.length; u++) {
                                                if (this.defaultRegions[2] == this.district[u].id) {
                                                    this.$set(this.multiIndex, 2, u)
                                                    this.handleValueChange({
                                                        detail: {
                                                            value: [j, k, u]
                                                        }
                                                    })
                                                    break
                                                }
                                            }
                                        })

                                        break
                                    }
                                }
                            })
                        }
                    }
                }
                if (this.isLoadDefaultAreas) clearInterval(time)
            }, 100)
        },
        handleValueChange(e) {
            // 结构赋值
            let [index0, index1, index2] = e.detail.value
            let [arr0, arr1, arr2] = this.pickedArr
            let address = [arr0[index0], arr1[index1], arr2[index2]]

            this.formData.full_address = ""
            for (let i = 0; i < address.length; i++) {
                if (this.formData.full_address) {
                    this.formData.full_address = this.formData.full_address + "-" + address[i].name
                } else {
                    this.formData.full_address = this.formData.full_address + address[i].name
                }
            }
        },

        /**
         * 保存地址
         */
        saveAddress(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    var data = {
                        name: this.form.name,
                        mobile: this.form.mobile,
                        telephone: this.form.telephone,
                        province_id: this.form.province_id,
                        city_id: this.form.city_id,
                        district_id: this.form.district_id,
                        community_id: "",
                        address: this.form.address,
                        full_address: this.form.full_address,
                        latitude: this.form.latitude,
                        longitude: this.form.longitude,
                        is_default: this.form.is_default
                    }

                    data.url = "add"
                    if (this.form.id) {
                        data.url = "edit"
                        data.id = this.form.id
                    }
                    if (this.flag) return
                    this.flag = true

                    saveAddress(data)
                        .then(res => {
                            if (res.code == 0) {
                                this.$router.push({ path: "/member/delivery_address" })
                            } else {
                                this.flag = false
                                this.$message({ message: res.message, type: "warning" })
                            }
                        })
                        .catch(err => {
                            this.flag = false
                            this.$message.error(err.message)
                        })
                } else {
                    return false
                }
            })
        },
        handleTypes(e) {
            this.numtype = e
            let target = this.userList.find(ele => ele.level_name === e)
            this.form.working_group_id = target.level_id
        },
        // 在获取详情信息接口中使用 TextToCode 将字符串转换成编码赋给 selectedOptions
        projectInfo() {
            var that = this
            getProjectInfo({ token: getToken(), id: that.id })
                .then(res => {
                    this.addForm = {
                        id: res.id,
                        // 基础信息
                        p_name: res.p_name, //项目名
                        p_message: res.p_message, //项目信息
                        area: res.area, //地区
                        remark: res.remark //备注
                    }
                    this.selectedOptions = TextToCode[this.addForm.area.split("/")[0]][this.addForm.area.split("/")[1]][this.addForm.area.split("/")[2]].code
                })
                .catch(err => {
                    Message.error(err)
                })
        },

        handleChange(value) {
            this.form.province_id = value[0]
            this.form.city_id = value[1]
            this.form.district_id = value[2]
        },
        // 获取协议
        regisiterAggrement() {
            getRegisiterAggrement()
                .then(res => {
                    if (res.code >= 0) {
                        this.agreement = res.data
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        // 服务协议
        getAggrement() {
            this.aggrementVisible = true
        },
        // 服务协议
        aggrementClose() {
            this.aggrementVisible = false
        },
        // 获取验证码
        getCode() {
            captcha({ captcha_id: this.captcha.id})
                .then(res => {
                    if (res.code >= 0) {
                        this.captcha = res.data
                        this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
                    }
                })
                .catch(err => {
                    this.$message.error(err.message)
                })
        },
        // 勾选用户协议
        check() {
            this.ischecked = !this.ischecked
        },
        // 跳转登录页
        toLogin() {
            this.$router.push("/login")
        },
        //  获取注册配置
        getRegisterConfig() {
            registerConfig()
                .then(res => {
                    if (res.code >= 0) {
                        this.registerConfig = res.data.value
                        if (this.registerConfig.register == "") {
                            this.$message({
                                message: "平台未启用注册",
                                type: "warning",
                                duration: 2000,
                                onClose: () => {
                                    this.$router.push("/")
                                }
                            })
                        }
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        // 注册
        register(formName) {
            this.$refs[formName].validate(valid => {
                if (valid) {
                    if (!this.ischecked) {
                        return this.$message({
                            message: "请先阅读协议并勾选",
                            type: "warning"
                        })
                    }
                    const {
                        working_group_id: level_id, //商户入驻经营类型id
                        region: level_name, //商户入驻经营类型名称
                        name, //账号
                        pass, //密码
                        phone: mobile, //商家入驻手机号
                        person: contacts, //联系人
                        province_id, //省id
                        city_id, //市id
                        district_id, //区id
                        unit: nickname, //单位名称
                        inviteCode: invite_code, //邀请码
                        address, //单位地址
                        unified_code, //统一社会信用代码
                        fenxiao_mobile
                    } = this.form

                    let data = {
                        level_id,
                        level_name,
                        contacts,
                        province_id,
                        city_id,
                        district_id,
                        nickname,
                        invite_code,
                        // username: name,
                        password: pass,
                        mobile,
                        address,
                        unified_code,
                        fenxiao_mobile
                    }

                    if (this.captcha.id != "") {
                        data.captcha_id = this.captcha.id
                        data.captcha_code = this.form.code
                    }
                    register(data)
                        .then(res => {
                            if (res.code >= 0) {
                                this.$message.success("注册成功")
                                this.showDialog = true
                                this.registerId = res.data.token
                                // setToken(res.data.token,86400000);
                                // this.$router.push("/member/index")
                            }
                        })
                        .catch(err => {
                            this.$message.error(err.message)
                            this.getCode()
                        })
                } else {
                    console.log("error submit!!")
                    return false
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.out-box {
    width: 1200px;
    margin: 20px auto;
    box-sizing: border-box;
    padding: 19px;
    border: 1px solid #ddd;
    border-radius: 15px;
    position: relative;

    .hint {
        position: absolute;
        color: #6b6b6b;
        top: 56px;
        // right: 22px;
        left: 125px;
    }

    .out-min {
        display: flex;
        .el-form {
            // display: flex;
            // flex-wrap: wrap;
            // justify-content: space-between;
            ::v-deep .el-form-item__label {
                text-align: left !important;
            }
            ::v-deep .el-select {
                width: 542px;
            }
            .addressChoice {
                width: 33% !important;
            }
            .regions {
                width: 250px !important;
                .regions-input {
                    width: 250px
                }
                ::v-deep .el-form-item__content {
                    width: 137px !important;
                }
            }
            .superior {
                width: 328px !important;
                .superior-input {
                    width:173px!important;
                }
                ::v-deep .el-form-item__content {
                    width: 196px !important;
                }
            }
            // .superior{
            //     width: 35%;
            // }
            ::v-deep .el-form-item__content {
                // width: 580px;
            }
            .out-item {
                // width: 15vw !important;
            }
            .el-form-item {
                // width: 480px;

                .captcha {
                    vertical-align: top;
                    max-width: inherit;
                    max-height: 38px;
                    line-height: 38px;
                    cursor: pointer;
                }

                .iconxuanze-duoxuan {
                    color: $base-color;
                }

                .content {
                    cursor: pointer;
                    margin-left: 10px;

                    b {
                        color: $base-color;
                    }
                }
            }
        }
        .out-r {
            flex: 1;

            .r-b {
                width: 472px;
                margin-top: 100px;
                padding: 0 20px;
                .ul-list {
                    li {
                        list-style: block !important;
                    }
                }
            }
        }
    }
    .buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .btt-l {
            margin-left: 93px;
        }
        .btt-2 {
            display: flex;
           width: 500px;
           p{
            width: 80px;
            height: 20px;
            white-space:nowrap;
           }
            .inof {
                .ins-1 {
                    display: inline-block;
                    margin-right: 10px;
                    text-align: justify;
                    margin-right: 40px;
                }
                .in-1 {
                  
                    margin-right: 10px;
                }
            }
        }
    }

    .bg-purple-light {
        cursor: pointer;
    }
}
</style>