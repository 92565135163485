<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <div class="member-index" v-loading="loading">
            <div class="member-top">
                <div class="info-wrap">
                    <div class="info-top">
                        <div class="avtar">
                            <router-link to="/member/info">
                                <img v-if="member.headimg" :src="$img(member.headimg)"
                                    @error="member.headimg = defaultHeadImage" />
                                <img v-else :src="$img(defaultHeadImage)" />
                            </router-link>
                        </div>
                        <div class="member-wrap">
                            <template v-if="member">
                                <div class="name member-name" v-if="member.nickname">
                                    <router-link to="/member/info">{{ member.nickname }}</router-link>
                                </div>
                                <div>

                                    <div style="display: flex;">
                                        <div class="level" v-if="member.member_level_name">{{ member.member_level_name
                                            }}
                                        </div>
                                        <template v-if="member.status == 1">
                                            <span class="status-1">正常</span>
                                        </template>

                                        <template v-else>
                                            <span class="status-2">待审核</span>
                                        </template>
                                    </div>
                                </div>
                                <div class="growth">
                                    成长值：
                                    <el-progress :text-inside="true" :stroke-width="10" :percentage="progress"
                                        :show-text="false"></el-progress>
                                    <div></div>
                                </div>
                                <div class="sign" v-if="token">
                                       <span style="margin-right: 10px;">连续签到：<span>{{ member.sign_days_series }}</span> 天</span>
                                  <el-button type="primary" size="mini" :class="hasSign == 1 || signState == 0 ? 'btn-active' : ''" @click="sign()">{{ hasSign == 1 ? '已签到' : '签到' }}</el-button>
                                </div>
                            </template>
                            <div class="no-login name" v-else>未登录</div>
                        </div>
                    </div>
                    <div class="account">
                        <div class="content">
                            <div class="item">
                                <router-link to="/member/my_coupon" class="item-content">
                                    <img src="@/assets/images/coupon.png" alt />
                                    <div class="name">优惠券</div>
                                    <div class="num" v-if="member.member_id && couponNum">{{ couponNum.coupon_num+couponNum.platform_num }}
                                    </div>
                                    <div class="num" v-else>0</div>
                                </router-link>
                            </div>

                            <div class="item">
                                <router-link to="/member/my_point" class="item-content">
                                    <img src="@/assets/images/point.png" alt />
                                    <div class="name">积分</div>
                                    <div class="num" v-if="member.point">{{ member.point }}</div>
                                    <div class="num" v-else>0</div>
                                </router-link>
                            </div>
                            <div class="item">
                                <router-link to="/member/account" class="item-content">
                                    <img src="@/assets/images/balance.png" alt />
                                    <div class="name">余额</div>
                                    <div class="num" v-if="member.balance || member.balance_money">
                                        {{ (parseFloat(member.balance) + parseFloat(member.balance_money)).toFixed(2) }}
                                    </div>
                                    <div class="num" v-else>0</div>
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="switch-account">
                        <el-button type="primary" size="mini" @click="dialogFormVisible = true">切换账户</el-button>
                    </div>
                </div>
                <div class="collection">
                    <div class="title">我的关注</div>
                    <div class="xian"></div>
                    <div class="item-wrap">
                        <div class="item">
                            <div class="num">{{ shopTotal }}</div>
                            <div class="collect">店铺关注</div>
                        </div>
                        <div class="item">
                            <div class="num">{{ goodsTotal }}</div>
                            <div class="collect">商品关注</div>
                        </div>
                    </div>
                </div>
            </div>
            <el-card class="member-card" v-if="member.will_expire_aptitude_json?.length>0">
                <h3 style="color:#e6a23c">资质到期预警<i class="el-icon-warning" ></i></h3>
                <div class="cred-box">
                    <div class="box-cont" v-for="(item,index) in member.will_expire_aptitude_json" :key="index">
                     <span v-if="item.day>0"> {{item.name}}距离到期时间剩余：<span class="day">{{item.day}}</span> 天 </span>
                     <span  v-else>{{item.name}}<span class="day">已过期!</span></span>
                    </div>
                    <div class="onclk-to">
                        <el-button type="info" round @click="$router.push({path:'/member/certiys'})">上传资质</el-button>
                    </div>
                </div>
            </el-card>
            <div class="member-bottom">
                <div class="my-order">
                    <div class="order-title">我的订单</div>
                    <div class="xian"></div>
                    <div class="order-item">
                        <router-link to="/member/order_list?status=waitpay" class="item">
                            <i class="iconfont icondaifukuan"></i>
                            <div v-if="orderNum.waitPay" class="order-num">{{ orderNum.waitPay }}</div>
                            <div class="name">待付款</div>
                        </router-link>
                        <router-link to="/member/order_list?status=waitsend" class="item">
                            <i class="iconfont icondaifahuo"></i>
                            <div v-if="orderNum.readyDelivery" class="order-num">{{ orderNum.readyDelivery }}</div>
                            <div class="name">待发货</div>
                        </router-link>
                        <router-link to="/member/order_list?status=waitconfirm" class="item">
                            <i class="iconfont icontubiaolunkuo-"></i>
                            <div v-if="orderNum.waitDelivery" class="order-num">{{ orderNum.waitDelivery }}</div>
                            <div class="name">待收货</div>
                        </router-link>
                        <router-link to="/member/order_list?status=waitrate" class="item">
                            <i class="iconfont icondaipingjia"></i>
                            <div v-if="orderNum.waitEvaluate" class="order-num">{{ orderNum.waitEvaluate }}</div>
                            <div class="name">已完成</div>
                        </router-link>
                        <router-link to="/member/activist" class="item">
                            <i class="iconfont iconshouhou"></i>
                            <div v-if="orderNum.refunding" class="order-num">{{ orderNum.refunding }}</div>
                            <div class="name">退款/售后</div>
                        </router-link>
                    </div>
                    <div v-if="orderList.length">
                        <div class="order-goods-wrap" v-for="(orderItem, orderIndex) in orderList" :key="orderIndex">
                            <div class="order-goods" v-for="(goodsItem, goodsIndex) in orderItem.order_goods"
                                :key="goodsIndex">

                                <!-- <div class="goods-item">
                                    <div class="goods-img"
                                         @click="$router.pushToTab({ path:'/sku?id=' + goodsItem.sku_id })">
                                        <img :src="$img(goodsItem.sku_image, { size: 'mid' })"
                                             @error="imageErrorOrder(orderIndex, goodsIndex)" />
                                    </div>
                                    <div class="info-wrap">
                                        <div class="goods-name"
                                             @click="$router.pushToTab({ path:'/sku?id=' + goodsItem.sku_id })">
                                            {{ goodsItem.sku_name }}
                                        </div>
                                    </div>
                                </div> -->
                                <goods-info-item-row :goods="goodsItem"></goods-info-item-row>
                                <div class="price">￥{{ goodsItem.price }}</div>
                                <div class="payment">{{ orderItem.order_status_name }}</div>
                                <div class="goods-detail" @click="orderDetail(orderItem)">
                                    <p>查看详情</p>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="empty" v-else>
                        <img src="@/assets/images/member-empty.png" alt />
                        <div>您买的东西太少了，这里都空空的，快去挑选合适的商品吧！</div>
                    </div>
                </div>
                <div class="bottom-right">
                    <div class="my-foot">
                        <div class="title">我的足迹</div>
                        <div class="xian"></div>
                        <div class="foot-content" v-for="(item, index) in footList" :key="item.goods_id">
                            <div class="foot-item" @click="$router.pushToTab({ path: '/sku?id=' + item.sku_id })">
                                <div class="foot-img"><img :src="$img(item.sku_image, { size: 'mid' })"
                                        @error="imageErrorFoot(index)" /></div>
                                <div class="foot-info">
                                    <div class="foot-name">{{ item.goods_name }}</div>
                                    <div class="foot-price" v-if="item.control.show_price">￥{{ item.discount_price }}
                                    </div>
                                    <div class="foot-price" v-else> {{ storeWithoutAuthority }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <el-dialog title="切换账户" :visible.sync="dialogFormVisible" width="30%">

                <template v-if="multiaccount.length">
                    <div v-for="(item, index) in multiaccount" :key="index">
                        <el-card class="account-card" style="margin-bottom: 20px">
                            <el-row>
                                <el-col :span="8">
                                    <span>{{ item.nickname }}</span>
                                </el-col>
                                <el-col :span="8">
                                    <span>{{ item.mobile }}</span>
                                </el-col>
                                <el-col :span="8" class="current-btn">
                                    <el-button v-if="item.is_current_member" disabled>当前登录账户</el-button>
                                    <el-button type="primary" v-else @click="switchAccount(item)">切换当前账户</el-button>
                                </el-col>
                            </el-row>
                        </el-card>
                    </div>
                </template>

                <template v-else>
                    <div class="account-card">
                        <div class="account-empty">
                            <img src="@/assets/images/member-empty.png" alt />
                            <div>您还没有绑定任何账户</div>
                        </div>
                    </div>
                </template>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="dialogFormVisible = false">取 消</el-button>
                    <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import { orderNum, couponNum, footprint, levelList, getBindList ,getIsSign,getIsSignin,getSignStatus} from "@/api/member/index"
import { shopCollect, goodsCollect } from "@/api/member/collection"
import { apiOrderList } from "@/api/order/order"
import { memberInfo } from "@/api/member/index"
import { mapGetters } from "vuex"
import { goodsInfoItemRow } from "@/components/goods-info"

export default {
    name: "member",
    components: {
        goodsInfoItemRow
    },
    data: () => {
        return {
            couponNum: 0,
            orderNum: {
                waitPay: 0, //待付款
                readyDelivery: 0, //待发货
                waitDelivery: 0, //待收货
                refunding: 0 // 退款中
            },
            orderList: [],
            orderStatus: "all",
            footInfo: {
                page: 1,
                page_size: 6
            },
            total: 0,
            footList: [],
            currentPage: 1,
            loading: true,
            goodsTotal: 0,
            shopTotal: 0,
            state: "",
            growth: "",
            levelList: [],
            member_level: {},
            progress: 0,
            yes: true,
            dialogFormVisible: false,
            multiaccount: [],
            hasSign:'',
            signState:'',
            signDaysSeries:''
        }
    },
    created() {
        this.getCouponNum()
        this.getOrderNum()
        this.getOrderList()
        this.getFootprint()
        this.getGoodsCollect()
        this.getShopCollect()
        this.memberInfo()
        this.getSwitchAccountList()
        this.getIsSignshow()
        this.getSignStatesow()
    },
    computed: {
        ...mapGetters(["defaultHeadImage", "defaultGoodsImage", "member", "storeWithoutAuthority","token"])
    },
    watch: {
        member: {
            handler() {
                if (this.member) this.getLevelList()
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        getSignStatesow() {
            getSignStatus().then((res)=>{
                if (res.code >= 0) {
						this.signState = res.data.is_use;
					}
            }).catch((err)=>{
                console.log(err)
            })
		},
        sign(){
            if (this.signState == 0) {
				this.$message.error('签到未开启')
			}
			if (!this.hasSign && this.signState == 1) {
                getIsSignin().then((res)=>{
               if(res.code>=0){
                let successTip = res.data;
                if(successTip.point || successTip.growth){
                    this.$message.success('恭喜您获得' + successTip.point + '积分' + '成长值' + successTip.growth)
                }
                this.hasSign = 1;
                this.member.sign_days_series = this.member.sign_days_series + 1;
               }
                }).catch((err)=>{
                    this.$message.error(err)
                })
            }

        },
        getIsSignshow(){
            getIsSign().then((res)=>{
                if(res.code>=0){
                this.hasSign=res.data
                }
            }).catch((err)=>{
                    console.log(err)
            })
        },
        switchAccount(account) {
            let data = {
                bind_code: account.bind_code,
                member_id: account.member_id,
                switch: 1
            }
            this.$store
                .dispatch("member/currentLogin", data)
                .then(res => {
                    if (res.code >= 0) {
                        this.$message({
                            message: "登录成功！",
                            type: "success"
                        })
                        this.dialogFormVisible = false
                        this.$router.go(0)
                    } else {
                        this.$message({
                            message: res.message,
                            type: "warning"
                        })
                    }
                })
                .catch(err => {
                    this.isSub = false
                    this.$message.error(err.message)
                    this.getCaptcha()
                })
        },
        async getSwitchAccountList() {
            const res = await getBindList().catch((e) => {
                console.log("error---", e)
            })
            if (!res) return
            if (res.code >= 0 && res.data) {
                this.multiaccount = res.data
                this.multiaccount.forEach(item => {
                    item.is_current_member = item.member_id === this.member.member_id
                })
            }
        },
        memberInfo() {
            memberInfo()
                .then(res => {
                    const {
                        data
                    } = res
                    this.$store.commit("member/SET_MEMBER", data)
                })
                .catch(err => {
                    console.log("err")
                })
        },
        getLevelList() {
            levelList()
                .then(res => {
                    if (res.data && res.code == 0) {
                        this.levelList = res.data
                        let listIndex = this.levelList.findIndex(item => item.level_id == this.member.member_level)

                        const max = this.levelList.length
                        if (max > listIndex + 1) {
                            if (this.member.growth > this.levelList[listIndex + 1].growth) {
                                this.progress = 100
                            } else {
                                if(this.member.growth<=0 || this.levelList[listIndex + 1].growth<=0) return this.progress=0
                                this.progress = (Number(this.member.growth) / Number(this.levelList[listIndex + 1].growth)) * 100
                            }
                        } else {
                            this.progress = 100
                        }
                       
                    } else {
                        this.$message.error(err.message)
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        //获取优惠券数量
        getCouponNum() {
            couponNum()
                .then(res => {
                    this.couponNum = res.data
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        //获取订单数量
        getOrderNum() {
            orderNum({
                order_status: "waitpay,waitsend,waitconfirm,waitrate,refunding"
            })
                .then(res => {
                    if (res.code == 0) {
                        this.orderNum.waitPay = res.data.waitpay
                        this.orderNum.readyDelivery = res.data.waitsend
                        this.orderNum.waitDelivery = res.data.waitconfirm
                        this.orderNum.waitEvaluate = res.data.waitrate
                        this.orderNum.refunding = res.data.refunding
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        //获取订单列表
        getOrderList() {
            apiOrderList({
                order_status: this.orderStatus,
                page: 1,
                page_size: 3
            })
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.orderList = res.data.list
                    }
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        //获取我的足迹
        getFootprint() {
            footprint(this.footInfo)
                .then(res => {
                    if (res.code == 0 && res.data) {
                        this.footList = res.data.list
                        this.total = res.data.count
                    }
                })
                .catch(err => {
                    console.log(err.message)
                })
        },
        orderDetail(data) {
            switch (parseInt(data.order_type)) {
                case 2:
                    // 自提订单
                    this.$router.push({
                        path: "/member/order_detail_pickup",
                        query: { order_id: data.order_id }
                    })
                    break
                case 3:
                    // 本地配送订单
                    this.$router.push({
                        path: "/member/order_detail_local_delivery",
                        query: { order_id: data.order_id }
                    })
                    break
                case 4:
                    // 虚拟订单
                    this.$router.push({
                        path: "/member/order_detail_virtual",
                        query: { order_id: data.order_id }
                    })
                    break
                default:
                    this.$router.push({
                        path: "/member/order_detail",
                        query: { order_id: data.order_id }
                    })
                    break
            }
        },
        imageErrorOrder(orderIndex, goodsIndex) {
            this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage
        },
        imageErrorFoot(index) {
            this.footList[index].sku_image = this.defaultGoodsImage
        },
        getGoodsCollect() {
            goodsCollect()
                .then(res => {
                    this.goodsTotal = res.data.count
                })
                .catch(err => {
                    this.loading = false
                    console.log(err.message)
                })
        },
        getShopCollect() {
            shopCollect()
                .then(res => {
                    this.shopTotal = res.data.count
                })
                .catch(err => {
                    this.loading = false
                    console.log(err.message)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.account-card {
    margin-bottom: 12px;

    .current-btn {
        display: flex;
        align-items: center;
        justify-content: flex-end
    }

    .account-empty {
        text-align: center;
    }
}

.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.member-index {
    .member-top {
        width: 100%;
        display: flex;

        .info-wrap {
            position: relative;
            width: 75%;
            height: 160px;
            border: 1px solid #e9e9e9;
            background: #ffffff;
            display: flex;

            .info-top {
                display: flex;
                align-items: center;
                margin: 22px;
                padding-right: 20px;
                min-width: 30%;
                border-right: 1px solid #e9e9e9;

                .avtar {
                    width: 84px;
                    height: 84px;
                    margin: 20px 0 10px 0;
                    border: 1px solid #e9e9e9;
                    border-radius: 50%;
                    overflow: hidden;
                    display: block;
                    cursor: pointer;
                    margin-left: 21px;
                    text-align: center;
                    // line-height: 84px;
                    box-sizing: border-box;

                    a {
                        width: 100%;
                        height: 100%;
                    }

                    img {
                        display: inline-block;
                    }
                }

                .member-wrap {
                    margin-left: 20px;

                    .name {
                        font-size: 18px;
                        font-weight: 600;
                        cursor: pointer;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }

                    .growth {
                        display: flex;
                        align-items: center;
                    }
                   .sign{
                    display: flex;
                    justify-content: flex-end;
            //         .btn-active {
			// 	    color: #ddd;
			// }
                   }
                    .el-progress {
                        width: 100px;
                    }

                    .level {
                        padding: 3px 4px;
                        line-height: 1;
                        color: #ffffc1;
                        margin: 6px 0;
                        cursor: default;
                        background: linear-gradient(#636362, #4e4e4d);
                        border-radius: 3px;
                        display: inline-block;
                    }
                }
            }

            .account {
                flex: 1;
                display: flex;
                align-items: center;

                .content {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;

                    .item {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;

                        .item-content {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                        }

                        img {
                            width: 50px;
                            height: 50px;
                        }

                        .name {
                            margin-top: 5px;
                            color: #666666;

                            &:hover {
                                color: $base-color;
                            }
                        }

                        .num {
                            color: $ns-text-color-black;
                        }
                    }
                }
            }

            .switch-account {
                position: absolute;
                top: 10px;
                left: 10px;
            }
        }

        .collection {
            background: #ffffff;
            margin-left: 20px;
            width: 210px;
            border: 1px solid #e9e9e9;
            padding-left: 20px;

            .title {
                padding: 10px 0;
                display: inline-block;
                border-bottom: 1px solid $base-color;
            }

            .xian {
                height: 1px;
                background: #f1f1f1;
            }

            .item-wrap {
                display: flex;
                justify-content: center;
                align-items: center;

                .item {
                    flex: 1;
                    margin-top: 37px;

                    .num {}

                    .collect {
                        color: #666666;
                    }
                }
            }
        }
    }
    .member-card{
        width: 74.4%;
        h3{
            font-size: 25px;
            text-align: center;
          margin-bottom: 10px;
        }
        .cred-box{
            .box-cont{
                margin-left: 10%;
                     .day{
                        font-weight: bold;
                        color: red;
                     }
            }
            .onclk-to{
                margin-top: 10px;
                margin-left: 40%;
            }
        }
    }

    .member-bottom {
        width: 100%;
        margin-top: 15px;
        height: 553px;
        display: flex;
        overflow: hidden;

        .my-order {
            width: 75%;
            background-color: #ffffff;

            .order-title {
                font-size: $ns-font-size-base;
                padding: 10px 0;
                margin-left: 15px;
                border-bottom: 1px solid $base-color;
                display: inline-block;
            }

            .xian {
                height: 1px;
                background: #f1f1f1;
                margin-left: 15px;
            }

            .order-item {
                display: flex;
                justify-content: center;
                align-content: center;

                .item {
                    flex: 1;
                    text-align: center;
                    height: 85px;
                    padding-top: 20px;
                    cursor: pointer;
                    position: relative;

                    &:hover {
                        background: #ffffff;
                        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                    }

                    .order-num {
                        position: absolute;
                        top: 23px;
                        right: 50px;
                        background: $base-color;
                        border-radius: 50%;
                        width: 18px;
                        height: 18px;
                        line-height: 18px;
                        color: #ffff;
                    }

                    i {
                        font-size: 30px;
                    }

                    .name {
                        font-size: $ns-font-size-base;
                    }
                }
            }

            .order-goods {
                display: flex;
                padding: 14px;
                border-top: 1px solid #f1f1f1;

                .price {
                    flex: 2;
                    color: $base-color;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-overflow: ellipsis;
                }

                .payment {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-overflow: ellipsis;
                }

                .goods-detail {
                    flex: 2;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    &:hover {
                        color: $base-color;
                    }
                }

            }
        }

        .bottom-right {
            .my-foot {
                background: #ffffff;
                margin-left: 20px;
                width: 230px;

                .title {
                    font-size: $ns-font-size-base;
                    padding: 10px 0;
                    display: inline-block;
                    border-bottom: 1px solid $base-color;
                    margin: 0 15px;
                }

                .xian {
                    margin-left: 15px;
                    background: #f1f1f1;
                    height: 1px;
                }

                .foot-content {
                    .foot-item {
                        display: flex;
                        padding: 10px 0;
                        margin: 0 15px;

                        .foot-img {
                            width: 57px;
                            height: 57px;
                            cursor: pointer;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .foot-info {
                            margin-left: 5px;
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;

                            .foot-name {
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 2;
                                -webkit-box-orient: vertical;
                                line-height: 1;
                                width: 140px;
                            }

                            .foot-price {
                                color: $base-color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.empty {
    text-align: center;
    margin-top: 65px;
}

.status-1 {
    padding-top: 4px;
    padding-left: 5px;
    color: rgb(65, 168, 99)
}

.status-2 {
    padding-top: 4px;
    padding-left: 5px;
    color: red;
}
</style>
