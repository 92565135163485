<template>
    <div class="ns-coupon">
        <div class="ns-coupon-info">
            <div class="ns-coupon-wrap">
                <div class="coupon-name">
                    <span class="ns-text-color">优惠券中心</span>
                    <span class="ns-text-color-gray text12">省钱更多，领券更多</span>
                </div>
                <!-- <ul class="coupon-type clear-float">
					<li>
						<i></i>
						<span>限时抢券</span>
					</li>
					<li>
						<i></i>
						<span>叠加使用</span>
					</li>
					<li>
						<i></i>
						<span>种类多样</span>
					</li>
				</ul> -->
                <el-button @click="myCoupon">我的优惠券</el-button>
            </div>
            <div class="ns-coupon-img" v-loading="loadingAd">
                <el-carousel height="200px">
                    <el-carousel-item v-for="item in adList" :key="item.adv_id">
                        <div class="box">
                            <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
                        </div>
                    </el-carousel-item>
                </el-carousel>
            </div>
        </div>

        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="平台优惠券" name="second"></el-tab-pane>
            <el-tab-pane label="店铺优惠券" name="first"></el-tab-pane>
            <div v-loading="loading">
                <ul class="ns-coupon-list">
                    <!--
                    <li class="ns-bg-color ns-coupon-li" v-for="(item, index) in couponList" :key="index">
                        <div class="describe">
                            <template>
                                <span v-if="!item.discount || item.discount == '0.00'">￥{{ item.money }}</span>
                                <span v-else>{{ item.discount }}折</span>
                            </template>
                            <span>{{ activeName == "first" ? item.coupon_name : item.platformcoupon_name }}</span>
                            <span v-if="activeName == 'first'">使用店铺：{{ item.site_name }}</span>
                            <template>
                                <span v-if="item.at_least == '0.00'">无门槛优惠券</span>
                                <span v-else>满{{ item.at_least }}可使用</span>
                            </template>

                            <template>
                                <span class="coupon-wrap-time" v-if="item.validity_type">领取之日起{{ item.fixed_term }}日内有效</span>
                                <span class="coupon-wrap-time" v-else>有效期至{{ $timeStampTurnTime(item.end_time) }}</span>
                            </template>
                        </div>
                        <div class="receive">
                            -->
                    <!-- 如果限领数为0 或者 领取数小于最大领取数 -->
                    <!--
                            <a class="ns-text-color" @click="couponTap(item, index)">
                                <span v-if="item.useState == 0">立即领取</span>
                                <span v-else>去使用</span>
                            </a>
                        </div>
                    </li>
                    -->
                    <li class="ns-coupon-li" v-for="(item, index) in couponList" :key="index">
                        <div class="l">
                            <h4 class="ns-h">
                                <template>
                                    <span v-if="!item.discount || item.discount == '0.00'"><span style="font-size: 13px">￥</span>{{ item.money }}</span>
                                    <span v-else>{{ item.discount }}折</span>
                                </template>
                            </h4>
                            <template>
                                <p class="ns-p1" v-if="item.at_least == '0.00'">无门槛优惠券</p>
                                <p v-else>满{{ item.at_least }}可使用</p>
                            </template>
                            <template v-if="activeName == 'first'">
                                <p v-if="item.goods_type == 1">全场商品</p>
                                <p v-else>指定商品</p>
                            </template>
                            <template v-if="activeName != 'first'">
                                <p v-if="item.use_scenario == 1">全场店铺</p>
                                <p v-else>指定店铺</p>
                            </template>
                        </div>
                        <div class="r">
                            <div class="ns-t">
                                <div class="nst-t1">{{ activeName == "first" ? item.coupon_name : item.platformcoupon_name }}</div>
                                <div class="ns-t-2" v-if="activeName == 'first' && item.site_name">{{ item.site_name }}</div>
                                <div class="ns-t-2" v-if="item.discount_limit && item.discount_limit != '0.00'">(最大优惠{{ item.discount_limit }}元)</div>
                            </div>

                            <div class="ns-b">
                                <p class="ns-b-t">
                                    <template>
                                        <span v-if="item.validity_type">领取之日起{{ item.fixed_term }}日内有效</span>
                                        <span v-else>
                                            <p>有效期至：{{ $timeStampTurnTime(item.start_time) }}</p>
                                            <p style="padding-left: 50px">{{ $util.timeStampTurnTime(item.end_time) }}</p>
                                        </span>
                                    </template>
                                </p>
                            </div>
                            <div class="receive">
                                <!-- 如果限领数为0 或者 领取数小于最大领取数 -->
                                <a class="receivereceive" @click="couponTap(item, index)">
                                    <span class="ns-to-2" v-if="item.useState == 0">立即领取</span>
                                    <span v-else class="ns-to">去使用</span>
                                </a>
                            </div>
                        </div>
                    </li>
                </ul>

                <div class="empty-wrap" v-if="couponList.length <= 0">
                    <div class="ns-text-align">暂无优惠券</div>
                </div>

                <div class="pager">
                    <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
                </div>
            </div>
        </el-tabs>
    </div>
</template>

<script>
import { couponList, receiveCoupon } from "@/api/coupon"
import { mapGetters } from "vuex"
import { adList } from "@/api/website"

export default {
    name: "coupon",
    components: {},
    data: () => {
        return {
            couponList: [],
            total: 0,
            currentPage: 1,
            pageSize: 9,
            couponBtnSwitch: false,
            activeName: "second",
            loading: true,
            loadingAd: true,
            adList: [],
            can_receive :1
        }
    },
    created() {
        if (this.addonIsExit && this.addonIsExit.coupon != 1) {
            this.$message({
                message: "优惠券插件未安装",
                type: "warning",
                duration: 2000,
                onClose: () => {
                    this.$route.push("/")
                }
            })
        } else {
            this.getAdList()
            this.getCanReceiveCouponQuery()
        }
    },
    computed: {
        ...mapGetters(["addonIsExit"])
    },
    watch: {
        addonIsExit() {
            if (this.addonIsExit.coupon != 1) {
                this.$message({
                    message: "优惠券插件未安装",
                    type: "warning",
                    duration: 2000,
                    onClose: () => {
                        this.$route.push("/")
                    }
                })
            }
        }
    },
    methods: {
        getAdList() {
            adList({ keyword: "NS_PC_COUPON" })
                .then((res) => {
                    this.adList = res.data.adv_list
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch((err) => {
                    this.loadingAd = false
                })
        },
        handleClick(tab, event) {
            this.loading = true
            ;(this.currentPage = 1), this.getCanReceiveCouponQuery()
        },
        /**
         * 我的优惠券
         */
        myCoupon() {
            this.$router.pushToTab("/member/my_coupon")
        },
        /**
         * 获取优惠券列表
         */
        getCanReceiveCouponQuery() {
            couponList({
                page: this.currentPage,
                page_size: this.pageSize,
                activeName: this.activeName,
                can_receive :this.can_receive
            })
                .then((res) => {
                    this.couponList = res.data.list
                    this.total = res.data.count
                    // this.couponList.forEach((v) => {
                    //     v.useState = 0
                    // })
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.loading = true
            this.getCanReceiveCouponQuery()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.loading = true
            this.getCanReceiveCouponQuery()
        },
        /**
         * 点击优惠券
         */
        couponTap(item, index) {
            if (item.useState == 0) this.receiveCoupon(item, index)
            else this.toGoodsList(item)
        },
        /**
         * 领取优惠券
         */
        receiveCoupon(item, index) {
            if (this.couponBtnSwitch) return
            this.couponBtnSwitch = true

            var data = {
                site_id: item.site_id,
                activeName: this.activeName
            }
            if (this.activeName == "first") {
                data.coupon_type_id = item.coupon_type_id
            } else {
                data.platformcoupon_type_id = item.platformcoupon_type_id
            }

            receiveCoupon(data)
                .then((res) => {
                    var data = res.data
                    let msg = res.message
                    if (res.code == 0) {
                        msg = "领取成功"
                        this.$message({ message: msg, type: "success" })
                    } else {
                        this.$message({ message: msg, type: "warning" })
                    }
                    let list = this.couponList
                    if (res.data.is_exist == 1) {
                        for (let i = 0; i < list.length; i++) {
                            if (this.activeName == "first") {
                                if (list[i].coupon_type_id == item.coupon_type_id) {
                                    list[i].useState = 1
                                }
                            } else {
                                if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
                                    list[i].useState = 1
                                }
                            }
                        }
                    } else {
                        for (let i = 0; i < list.length; i++) {
                            if (this.activeName == "first") {
                                if (list[i].coupon_type_id == item.coupon_type_id) {
                                    list[i].useState = 2
                                }
                            } else {
                                if (list[i].platformcoupon_type_id == item.platformcoupon_type_id) {
                                    list[i].useState = 2
                                }
                            }
                        }
                    }

                    this.couponBtnSwitch = false
                    this.$forceUpdate()
                })
                .catch((err) => {
                    if (err.message == "token不存在") {
                        this.$router.pushToTab("/login")
                    } else {
                        this.$message.error(err.message)
                    }
                    this.couponBtnSwitch = false
                })
        },
        /**
         * 去购买
         */
        toGoodsList(item) {
            if (this.activeName == "first") {
                if (item.goods_type != 1) {
                    this.$router.push({ path: "/shop_list", query: { site_id: item.site_id, couponId: item.coupon_type_id } })
                } else {
                    this.$router.push({ path: "/shop_list", query: { site_id: item.site_id } })
                }
            } else {
                if (item.use_scenario != 1) {
                    this.$router.push({ path: "/list", query: { platform_coupon_type: item.platformcoupon_type_id } })
                } else {
                    this.$router.push("/list")
                }
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.empty-wrap {
    margin-top: 20px;
}

.ns-coupon {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
}

.ns-coupon-info {
    background: url(../../assets/images/coupon-bg.png) no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 240px;
    display: flex;
    overflow: hidden;
    .ns-coupon-wrap {
        width: 320px;
        padding: 20px;
        box-sizing: border-box;
        text-align: center;
        .coupon-name {
            margin-top: 20px;
            span:nth-child(1) {
                display: block;
                line-height: 45px;
                font-size: 30px;
            }

            .ns-text-color-gray {
                color: #898989 !important;
            }
            .text12 {
                font-size: 12px;
            }
        }

        .coupon-type {
            margin-left: 20px;
            li {
                float: left;
                width: 80px;
                height: 100px;

                i {
                    display: block;
                    width: 50px;
                    height: 50px;
                    margin: 8px auto;
                }
                &:nth-child(1) i {
                    background: url(../../assets/images/limited_time.png) no-repeat center;
                }
                &:nth-child(2) i {
                    background: url(../../assets/images/superposition.png) no-repeat center;
                }
                &:nth-child(3) i {
                    background: url(../../assets/images/coupon_type.png) no-repeat center;
                }
            }
        }
        .el-button {
            width: 200px;
            background-color: $base-color;
            color: #fff;
            margin-top: 20px;
            font-size: 18px;
        }
    }

    .ns-coupon-img {
        width: 850px;
        padding: 20px;
        box-sizing: border-box;
        .el-image {
            width: 100%;
            height: 200px;
        }
        img {
            width: 100%;
            height: auto;
        }
        .el-carousel__item:nth-child(2n) {
            background-color: #99a9bf;
        }

        .el-carousel__item:nth-child(2n + 1) {
            background-color: #d3dce6;
        }
    }
}

.el-tabs {
    margin-top: 20px;
}
::v-deep .el-tabs__header {
    margin: 0px 479px 15px !important;
}
.ns-coupon-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    padding: 60px 0px 0px;
    .ns-coupon-li {
        border-radius: 15px;
        overflow: hidden;
        margin-right: 30px;
        display: flex;
        width: 30%;
        height: 134px;
        background-color: #fff2f0;
        background-size: 100% 100%;
        .l {
            border-radius: 15px;
            position: relative;
            width: 40%;
            height: 100%;
            text-align: center;
            background: url(../../assets/images/bg_lingqu.png) no-repeat;
            // background-image: linear-gradient(to right top, #fc6831, #ff4544);
            // 	background-repeat: no-repeat;
            // 	padding: 18px 5px 18px 8px;
            background-size: contain;
            background-position: center center; /* 居中显示背景图片 */
            color: #ffffffce;

            > p {
                font-size: 11px;
                overflow: hidden; //超出的文本隐藏
                text-overflow: ellipsis; //溢出用省略号显示
                white-space: nowrap; //溢出不换行
            }
            .ns-h {
                padding-top: 10px;
                font-size:40px;
            }
            ::before {
                position: absolute;
                left: -9px;
                top: 78px;
                content: "";
                display: block;
                width: 20px;
                height: 20px;
                background-color: #fff;
                border-radius: 50%;
            }
        }
        .r {
            position: relative;
            flex: 1;
            padding-left: 5px;
            .receive {
                position: absolute;
                right: 1px;
                top: 70px;
                float: right;
                font-size: 9px;
                text-align: center;
                .ns-to {
                    color: #fff;
                    display: inline-block;
                    padding: 1px 10px;
                    border-radius: 15px;
                    cursor: pointer;
                    box-sizing: border-box;
                    background-image: linear-gradient(to right top, #fc6831, #ff4544);
                }
                .ns-to-2 {
                    color: #fff;
                    display: inline-block;
                    padding: 1px 10px;
                    border-radius: 15px;
                    cursor: pointer;
                    box-sizing: border-box;
                    background-image: linear-gradient(to right top, #fc6831, #ff4544);
                }
                a {
                    display: inline-block;
                    padding: 3px 6px;
                    background-color: #fff;
                    border-radius: 15px;
                    cursor: pointer;
                    box-sizing: border-box;
                    span {
                        display: inline-block;
                        line-height: 20px;
                        vertical-align: middle;
                    }
                }
            }
            .receivereceive {
                background: none !important;
                padding: 0px !important;
            }
            .ns-t {
                width: 100%;
                min-height: 55%;
                padding: 10px 0 0 0px;
                font-size: 14px;
                font-weight: 600;
               
                .ns-t-2 {
                    font-size: 12px;
                    color: #9b9999fa;
                }
                .ns-t-3 {
                    font-size: 12px;
                }
            }

            .ns-b {
                width: 70%;
                height: 30%;
                padding-top: 8px;
                white-space:nowrap;
                border-top: 1px dotted #ccc;
                .ns-b-t {
                    font-size: 10px;
                }
                .ns-b-p2 {
                    font-size: 14px;
                    padding-left: 50px;
                }
            }
            //     .describe {

            //     float: left;
            //     width: 210px;
            //     height: inherit;
            //     text-align: center;
            //     color: #fff;
            //     span {
            //         display: block;
            //     }
            //     span:nth-child(1) {
            //         font-size: 40px;
            //         margin-top: 13px;
            //         margin-bottom: 5px;
            //         line-height: 40px;
            //     }
            //     span:nth-child(3),
            //     span:nth-child(4),
            //     span:nth-child(5) {
            //         font-size: 12px;
            //         margin-left: 15px;
            //         line-height: 20px;
            //     }
            // }
        }

        // &:nth-child(3n) {
        //     margin-right: 0;
        // }
    }
}
</style>
