<template>
    <div>
        <!-- 轮播 -->
        <el-carousel indicator-position="outside" v-loading="loadingSwiper" arrow="hover" height="300px">
            <el-carousel-item v-for="(item,index) in adListWiper" :key="index">
                <el-image class="el-image" :src="$img(item.adv_image)" fit="cover"
                        @click="$router.pushToTab(item.adv_url.url)" />
            </el-carousel-item>
        </el-carousel>
        <!-- table切换 -->
        <div class="table">
            <el-radio-group
                v-model="tabPosition"
                @change="handleClick"
                style="margin-bottom: 30px;"
            >
                <el-radio-button label="1">集团介绍</el-radio-button>
                <el-radio-button label="2">集团实力</el-radio-button>
                <el-radio-button label="3">组织架构</el-radio-button>
            </el-radio-group>
        </div>
        <div class="street" v-loading="loading">
            <vCommunityInfo
                :keyword="keyword"
                :filters="filters"
                :shopList="shopList"
                @getShopList="getShopList"
                :defaultShopImage="defaultShopImage"
                @changeSort="changeSort"
                @toDetail="toDetail"
            ></vCommunityInfo>
            <div class="pager">
                <el-pagination
                    background
                    :pager-count="5"
                    :total="total"
                    prev-text="上一页"
                    next-text="下一页"
                    :current-page.sync="currentPage"
                    :page-size.sync="pageSize"
                    @size-change="handlePageSizeChange"
                    @current-change="handleCurrentPageChange"
                    hide-on-single-page
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { adList } from '@/api/website';
import { mapGetters } from "vuex"
import BreadCrumbs from "@/components/BreadCrumbs"
import { shopList } from "@/api/shop"
import vCommunityInfo from "./components/communityInfo.vue"
export default {
    data: () => {
        return {
            shopList: [], // 店铺列表
            total: 0,
            keyword: "",
            currentPage: 1,
            pageSize: 12,
            loading: true,
            loadingSwiper:true,
            filters: {
                order: "",
                sort: "desc"
            },
            tabPosition: "1",
            adListWiper:[]
        }
    },
    components: { BreadCrumbs, vCommunityInfo },
    created() {
        this.keyword = this.$route.query.keyword || ""
        this.getShopList()
        this.getStreetAdList()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "defaultShopImage"])
    },
    methods: {
        getStreetAdList() {
            adList({ keyword: '2M_COMMUNITY' })
                .then(res => {
                    this.adListWiper = res.data.adv_list;
                    for (let i = 0; i < this.adListWiper.length; i++) {
                        if (this.adListWiper[i].adv_url) this.adListWiper[i].adv_url = JSON.parse(this.adListWiper[i].adv_url);
                    }
                    this.loadingSwiper = false;
                })
                .catch(err => {
                    this.loadingSwiper = false;
                });
        },
        handleClick(tab) {
            console.log(tab)
        },
        getShopList() {
            const params = {
                keyword: this.keyword,
                page: this.currentPage,
                page_size: this.pageSize,
                ...this.filters
            }
            shopList(params || {})
                .then(res => {
                    const { count, page_count, list } = res.data
                    this.total = count
                    this.shopList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getShopList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getShopList()
        },
        changeSort(type) {
            this.filters.order = type
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }
            this.getShopList()
        },
        toDetail(site_id) {
            this.$router.pushToTab({
                path: "/shop-" + site_id
            })
        }
    },
    watch: {
        $route: function(curr) {
            if (this.keyword !== curr.query.keyword) {
                this.currentPage = 1
                this.keyword = curr.query.keyword
                this.getShopList()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.table {
    display: flex;
    justify-content: center;
}
.street {
    width: 1200px;
    margin: 0 auto;

    .pager {
        text-align: center;
        margin-top: 30px;
    }
    .empty {
        line-height: 500px;
    }
}
::v-deep .el-carousel {
    width: 100%;
    .el-carousel {
        width: 100%;
        .el-image__inner {
            width: auto;
        }
    }
    .el-carousel__arrow--right {
        right: 60px;
    }
}
</style>
