<template>
    <div class="ns-brand">
        <div class="banner">
            <el-carousel v-loading="loadingAd" arrow="hover" height="300px">
                <el-carousel-item v-for="item in adList" :key="item.adv_id">
                    <el-image
                        :src="$img(item.adv_image)"
                        fit="cover"
                        @click="$router.pushToTab(item.adv_url.url)"
                    />
                </el-carousel-item>
            </el-carousel>
        </div>

        <!-- 品牌列表 -->
        <div class="ns-brand-box" v-loading="loading">
            <div>
                <!-- 导航切换 -->
                <div class="imgtable">
          
                    <div class="table-i"   v-for="(item,index) in sub_nav" :key="index">
                        <el-image
                            :src="$img(item.src)"
                            @click="onclicUrl(item.url,index)"
                            style="height: 100px; width:300px;"
                        ></el-image>
                        <div class="highlight" :class="style==1?'highlight':''" :style="{ backgroundColor:index==0 ? '#1e8eff85' : '#e6a23cb0',}"></div>
                          
                    </div>
                    <div class="cicle-1"  :style="{ backgroundColor:style==1 ? '#1e8eff85' : '#ddd',}"></div>
                    <div class="cicle-2" :style="{ backgroundColor:style==2 ? '#e6a23cb0' : '#ddd',}"></div>
                </div>
                <template v-if="style==1">
                    <BarndLlist :subCategory="subCategory" :sub_brand="sub_brand"></BarndLlist>
                </template>
                <template v-else>
                    <div class="ns-brand-title-wrap ns-text-align">
                        <p class="ns-brand-title">品牌专区</p>
                        <img src="@/assets/images/goods/split.png" alt />
                        <p class="ns-brand-en">Brand zone</p>
                    </div>
                    <div class="ns-brand-list" v-if="brandList.length > 0">
                        <div
                            class="ns-barnd-box"
                            v-for="(item, index) in brandList"
                            :key="index"
                            @click="pushPath(item.brand_id)"
                        >
                            <div class="ns-brand-li">
                                <div class="bom-img-1">
                                    <div class="min">
                                        <el-image
                                            fit="scale-down"
                                            :src="$img(item.image_url)"
                                            lazy
                                            @error="imageError(index)"
                                        ></el-image>
                                    </div>
                                </div>
                                <div class="t">
                                    <span class="names">{{ item.brand_name }}</span>
                                    <span class="bom-info">></span>
                                </div>
                            </div>
                            <div class="bom">
                                <div
                                    class="bom-box"
                                    v-for="(items, indexs) in item.goods_list"
                                    :key="indexs"
                                >
                                    <div class="bom-img">
                                        <el-image
                                            style="border-radius: 5px"
                                            :src="$img(items.sku_image)"
                                            lazy
                                            @error="imageError(indexs)"
                                        ></el-image>
                                    </div>
                                    <template v-if="token">
                                        <div class="bom-tex">{{ items.sku_name }}</div>
                                    </template>
                                    <div v-else style="text-align: center; margin-right: 10px">
                                        <span class="price-num">登录可见</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="empty-wrap" v-if="brandList.length <= 0">
                        <div class="ns-text-align">暂无更多品牌,去首页看看吧</div>
                    </div>

                    <div class="pager">
                        <el-pagination
                            background
                            :pager-count="5"
                            :total="total"
                            prev-text="上一页"
                            next-text="下一页"
                            :current-page.sync="currentPage"
                            :page-size.sync="pageSize"
                            @size-change="handlePageSizeChange"
                            @current-change="handleCurrentPageChange"
                            hide-on-single-page
                        ></el-pagination>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { brandList } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { adList, navList } from "@/api/website"
import BarndLlist from "./list/componet/barndLlist.vue"
export default {
    name: "brand",
    components: {
        BarndLlist
    },
    data: () => {
        return {
            total: 0,
            currentPage: 0,
            pageSize: 9,
            brandList: [],
            siteId: 0,
            loading: true,
            loadingAd: true,
            adList: [],
            pagerCount: 5,
            newfolg: true,
            sub_nav: [], //获取当前是否为推荐品牌或是全部品牌图片导航
            sub_brand: [],
            subCategory: [
                {
                    logo: "https://cloud.2myxc.com/upload/common/images/20240223/20240223045427170867846733781.png"
                },
                {
                    logo: "https://cloud.2myxc.com/upload/common/images/20240223/20240223045427170867846733781.png"
                },
                {
                    logo: "https://cloud.2myxc.com/upload/common/images/20240223/20240223045427170867846733781.png"
                },
                {
                    logo: "https://cloud.2myxc.com/upload/common/images/20240223/20240223045427170867846733781.png"
                }
            ],
            extracted: "",
            isurl: "",
            onIindex:0
        }
    },
    created() {
        this.getAdList()
        this.getBrandList()
        this.getnavList()
        this.isuri()
        this.onIindex=this.$route.params.onIindex || 0
    },
    computed: {
        ...mapGetters(["defaultBrandImage", "token", "storeWithoutAuthority"])
    },
    methods: {
        isuri() {
            console.log(this.$route.query)
            this.style = this.$route.query.style || 1
        },
        onclicUrl(url,index) {
            this.onIindex=index
            this.$router.push({ path: url})
        },
        getnavList() {
            navList({})
                .then(res => {
                    let { code, data } = res
                    if (code >= 0) {
                        //判断是否为当前页面数据
                        const path = this.$route.fullPath
                        data.list.forEach(item => {
                            //过滤当前为空数据
                            let urlId = JSON.parse(item.nav_url).url
                            if (item.sub_category && urlId.startsWith("/brand")) {
                                this.sub_nav = JSON.parse(item.sub_nav)
                                let category = JSON.parse(item.sub_brand)
                                if (category.length > 0) {
                                    this.subCategory = category
                                }
                                return
                            }
                        })
                    }
                })
                .catch(err => {})
        },
        pushPath(brand_id) {
            this.token ? this.$router.pushToTab({ path: "/list", query: { brand_id: brand_id } }) : this.$router.pushToTab({ path: "/login" })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            if (!page) return
            this.currentPage = page
            this.refresh()
            this.changescroll()
        },
        changescroll() {
            const currentScroll = window.pageYOffset || document.documentElement.scrollTop
            const targetPosition = 600
            const distance = targetPosition - currentScroll
            this.$nextTick(() => {
                document.addEventListener("mousewheel", this.mousewheelHandler, { passive: false })
            })
            if (currentScroll >= targetPosition) {
                this.newfolg = false
                setTimeout(() => {
                    this.$nextTick(() => {
                        document.removeEventListener("mousewheel", this.mousewheelHandler, { passive: false })
                    })
                }, 500)
            }
            // 设置滚动动画
            window.scrollBy({
                top: distance,
                left: 0,
                behavior: "smooth" // 平滑滚动
            })
        },
        mousewheelHandler(e) {
            e.preventDefault()
        },
        refresh() {
            this.loading = true
            setTimeout(() => {
                this.loading = false
            }, 500)
            this.getBrandList()
        },
        getBrandList() {
            brandList({
                page_size: this.pageSize,
                page: this.currentPage,
                site_id: this.siteId
            })
                .then(res => {
                    let { page_count, count, list } = res.data
                    this.total = count
                    // this.pageSize=page_count
                    this.brandList = list
                    res.data.list.forEach((item, index) => {
                        item.goods_list.forEach(items => {
                            this.brandList[index].logUrl = items.shop_info.logo
                        })
                    })
                    this.total = res.data.count
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        /**
         * 图片加载失败
         */
        imageError(index) {
            this.brandList[index].image_url = this.defaultBrandImage
        },
        getAdList() {
            adList({ keyword: "NS_PC_BRAND" })
                .then(res => {
                    this.adList = res.data.adv_list
                    for (let i = 0; i < this.adList.length; i++) {
                        if (this.adList[i].adv_url) this.adList[i].adv_url = JSON.parse(this.adList[i].adv_url)
                    }
                    this.loadingAd = false
                })
                .catch(err => {
                    this.loadingAd = false
                })
        }
    }
}
</script>
<style lang="scss" scoped>
.ns-text-align {
    text-align: center;
}

.ns-brand-box {
    width: 100%;
    background: #ffffff;
    > div {
        width: $width;
        margin: 0 auto;
    }
}
.imgtable {
    width: 640px;
    margin: 0 auto;
    display: flex;
    position: relative;
    .cicle-1{
           width: 15px;
           height: 15px;
           border-radius: 50%;
           background-color: #1e8effa8;
           position: absolute;
            bottom: 10px;
            left: 22%; /* 初始位置在中间 */
        }
        .cicle-2{
           width: 15px;
           height: 15px;
           border-radius: 50%;
           background-color: #e6a23cb0;
           position: absolute;
            bottom: 10px;
            right: 192px; /* 初始位置在中间 */
            
        }
    .table-i {
        position: relative;
        // & ::after {
        //     position: absolute;
        //     content: "";
        //     left: 50%; /* 初始位置在中间 */
        //     transform: translateX(-50%); /* 居中显示 */
        //     bottom: 0;
        //     height: 5px;
        //     width: 10px;
        //     background-color: dodgerblue;
        //     transition: width 0.5s; /* 宽度过渡动画 */
        // }
        .highlight {
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 50%; /* 初始位置在中间 */
            transform: translateX(-50%); /* 居中显示 */
            height: 5px;
            width: 10px;
            background-color: #1e8eff85;
            transition: width 0.5s; /* 宽度过渡动画 */
        }
        /* 鼠标悬停时，伪元素宽度展开 */
        &:hover .highlight {
            width: 100%;
            opacity: 1;
            transform:0.5 initial;
        }

    }
}
.ns-brand-title-wrap {
    padding-top: 54px;
    .ns-brand-title {
        font-size: 26px;
        font-weight: 600;
        line-height: 30px;
    }
    .ns-brand-en {
        font-size: 24px;
        font-weight: 600;
        color: #383838;
        opacity: 0.2;
        text-transform: uppercase;
        letter-spacing: 5px;
        line-height: 30px;
    }
}

.ns-brand-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 30px;
    .ns-barnd-box {
        cursor: pointer;
        margin-left: 20px;
        &:hover {
            box-shadow: 0 0 6px 3px rgba(0, 0, 0, 0.1);
        }
        width: 380px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
        margin-bottom: 50px;
        border-radius: 5px;
        box-sizing: border-box;
        .ns-brand-li {
            margin: 40px 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .bom-img-1 {
                width: 120px;
                height: 120px;
                .min {
                    width: 100%;
                    height: 100%;
                    .el-image {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .t {
                display: flex;
                align-items: center;
                margin-right: 15px;
                .names {
                    margin-right: 10px;
                    font-size: 18px;
                    font-weight: 500;
                    margin-left: 10px;
                    color: #a7a4a4;
                }
            }
            .img-1 {
                width: 80px;
                height: 50px;
                margin-right: 5px;
            }
            .bom-info {
                font-size: 20px;
                color: #a7a4a4;
            }
        }
        .con {
            width: 400px;
            height: 150px;
            margin: 5px 10px;
            border-radius: 5px;
            box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            .el-image {
                width: 100%;
                height: 150px;
            }
        }
        .bom {
            display: flex;
            img {
                align-content: center;
            }
            .bom-box {
                width: 120px;
                height: 170px;
                margin: 10px 10px;
                margin-right: 10px;

                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                .bom-img {
                    width: 100%;
                    padding-bottom: 100%;
                    position: relative;
                    display: flex;
                    border-radius: 5px;
                    margin-bottom: 10px;
                    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
                    .el-image {
                        position: absolute;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}
.bom-tex {
    font-size: 13px;
    line-height: 17px;
}
.price-num {
    text-align: center;
    color: rgb(255, 0, 0);
    font-size: 16px;
}
.empty-wrap {
    margin-top: 30px;
}
</style>
<style lang="scss">
.ns-brand {
    .banner {
        width: 100%;
        height: 305px;
        .el-image {
            width: 100%;
            height: 300px;
        }
        .img {
            max-width: 100%;
            height: auto;
        }
    }
    .el-carousel {
        width: 100%;
        .el-image__inner {
            width: auto;
        }
    }
    .el-carousel__arrow--right {
        right: 60px;
    }
}
</style>
