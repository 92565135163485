<template>
	<div></div>
</template>

<script>
export default {
	name: 'signin',
	components: {},
	data: () => {
		return {};
	},
	created() {},
	methods: {}
};
</script>
<style lang="scss" scoped></style>
