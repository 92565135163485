<template>
    <div class="fest3">
        <h5 class="fest3-h">猜你喜欢</h5>
        <div class="fest3-box">
            <div class="list" v-for="(item,index) in list" :key="index">
                <div class="imgs"><img :src="item.url" style="width: 180px; height: 170px" /></div>
                <div class="r">
                    <div class="r-t1">{{item.title1}}</div>
                    <div class="r-t2">{{item.title2}}</div>
                    <div class="r-t3">{{item.title3}}</div>
                    <div class="onclik">抢购</div>
                </div>
                <div class="li-flot">{{item.any}}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PcFestivalList3",

    data() {
        return {
          list:[
            {
              url:"https://cloud.2myxc.com/upload/2/common/images/20231128/20231128102620170113838065593.jpg",
              title1:"環球嚴選 60*40*20公分 枕頭 護頸枕頭 脖子枕頭 牽引枕 頸椎枕頭 枕头 頸椎牽引枕 側睡枕頭 頸部枕頭 減壓枕頭 護頸枕 高枕頭",
              title2:"￥100.00",
              title3:"￥100.00",
              any:"8折"
            },
            {
              url:"https://cloud.2myxc.com/upload/2/common/images/20231128/20231128102620170113838065593.jpg",
              title1:"環球嚴選 60*40*20公分 枕頭 護頸枕頭 脖子枕頭 牽引枕 頸椎枕頭 枕头 頸椎牽引枕 側睡枕頭 頸部枕頭 減壓枕頭 護頸枕 高枕頭",
              title2:"￥100.00",
              title3:"￥100.00",
              any:"8折"
            },
            {
              url:"https://cloud.2myxc.com/upload/2/common/images/20231128/20231128102620170113838065593.jpg",
              title1:"環球嚴選 60*40*20公分 枕頭 護頸枕頭 脖子枕頭 牽引枕 頸椎枕頭 枕头 頸椎牽引枕 側睡枕頭 頸部枕頭 減壓枕頭 護頸枕 高枕頭",
              title2:"￥100.00",
              title3:"￥100.00",
              any:"8折"
            },
            {
              url:"https://cloud.2myxc.com/upload/2/common/images/20231128/20231128102620170113838065593.jpg",
              title1:"環球嚴選 60*40*20公分 枕頭 護頸枕頭 脖子枕頭 牽引枕 頸椎枕頭 枕头 頸椎牽引枕 側睡枕頭 頸部枕頭 減壓枕頭 護頸枕 高枕頭",
              title2:"￥100.00",
              title3:"￥100.00",
              any:"8折"
            },
          ]
        }
    },

    mounted() {},

    methods: {}
}
</script>

<style lang="scss" scoped>
.fest3 {
    &-h {
        margin: 20px 0;
        font-size: 30px;
        text-align: center;
    }
    &-box {
      overflow: hidden;
      display: flex;
      flex-wrap: wrap;
        .list {
          cursor:pointer;
          margin-right: 30px;
            position: relative;
            padding: 8px ;
            display: flex;
            width: 350px;
            height:180px;
            border-radius: 5px;
            background: #fff;
            margin-bottom: 10px;
            // img{
            //   border-radius: 5px; ;
            // }
            &:hover{
        -webkit-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
					-webkit-transform: translate3d(0, -2px, 0);
					transform: translate3d(0, -2px, 0);
        }
        .imgs{
          width: 170px;
          height: 180px;
        }
            .li-flot {
                position: absolute;
                right: -5px;
                top: 50%;
                background-color: #ff4e30;
                padding: 2px 5px 2px 6px;
                margin-top: -14px;
                border-radius: 20px 0 5px 20px;
                -webkit-box-shadow: -2px 2px 4px rgba(255, 78, 48, 0.5);
                box-shadow: -2px 2px 4px rgba(255, 78, 48, 0.5);
                color: #fff;
                height: 28px;
                line-height: 24px;
                &::before {
                    position: absolute;
                    top: -4px;
                    right: 0;
                    content: "";
                    width: 0;
                    height: 0;
                    border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #a91300;
                    border-style: solid;
                    border-width: 0 4px 4px 0;
                }
            }
            .r {
                margin-left: 10px;
                width: 170px;
                .r-t1 {
                  font-size: 14px;
                    margin-bottom: 20px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    word-break: break-all;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; /* 行数*/
                    -webkit-box-orient: vertical;
                }
                .r-t2 {
                    font-size: 18px;
                    font-weight: 600;
                    color: #ddddddd1;
                    text-decoration: line-through;
                }
                .r-t3 {
                    font-size: 18px;
                    font-weight: 600;
                    color: red;
                }
                .onclik {
                    margin-top: 10px;
                    text-align: center;
                    border-radius: 25px;
                    color: #323232;
                    font-weight: 700;
                    background: linear-gradient(97deg, #ffc00e, #fff1bd);
                }
            }
        }
    }
}
</style>