<template>
    <div class="basic-info-wrap">
        
        <h1>{{ goodsSkuDetail.sku_name }}</h1>
        <p class="desc ns-text-color" v-if="goodsSkuDetail.introduction">{{ goodsSkuDetail.introduction }}</p>
        <!-- 横幅 -->
        <div class="discount-banner ns-bg-color"
            v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime && addonIsExit.discount">
            <div class="activity-name">
                <i class="discount-icon iconfont iconicon_naozhong"></i>
                <span>限时折扣</span>
            </div>
            <div class="surplus-time">
                <span>{{ discountText }}</span>
                <count-down class="count-down" v-on:start_callback="countDownS_cb()" v-on:end_callback="countDownE_cb()"
                    :currentTime="discountTimeMachine.currentTime" :startTime="discountTimeMachine.startTime"
                    :endTime="discountTimeMachine.endTime" :dayTxt="'天'" :hourTxt="'小时'" :minutesTxt="'分钟'"
                    :secondsTxt="'秒'"></count-down>
            </div>
        </div>
        <!-- end横幅 -->

        <!-- 价格 -->
        <div class="item-block">
            <div class="promotion-price">
                <dl class="item-line">
                    <dt class="ns-text-color-gray">{{ activityMapItem.priceText }}</dt>
                    <dd style="position: relative">
                        <em class="yuan ns-text-color">¥</em>
                        <template v-if="token">
                            <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.show_price && goodsSkuDetail.price>0">
                                <span class="price ns-text-color">{{ goodsSkuDetail.price }}<span style="font-size: 13px;">/{{goodsSkuDetail.unit}}</span></span>
                                <!-- <span class="group-price" v-if="goodsSkuDetail.goods_promotion.length !== 0">
                                    <span v-if="goodsSkuDetail.goods_promotion[0].pintuan_price">拼团价为：{{
                                        goodsSkuDetail.goods_promotion[0].pintuan_price }}</span>
                                    <span v-if="goodsSkuDetail.goods_promotion[0].price_array">范围价格</span>
                                </span> -->
                            </template>
                            <template v-else>
                                <span class="price ns-text-color">{{ storeWithoutAuthority }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="price ns-text-color">登陆可见</span>
                        </template>
                    </dd>
                </dl>
                <dl class="item-line" v-if="goodsSkuDetail.promotion_type == 1 && discountTimeMachine.currentTime">
                    <dt class="ns-text-color-gray">原价</dt>
                    <dd>
                        <em class="market-yuan">¥</em>
                        <template v-if="token">
                            <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.show_price">
                                <span class="market-price">{{ goodsSkuDetail.price }}</span>
                            </template>
                            <template v-else>
                                <span class="market-price">{{ storeWithoutAuthority }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="market-price">登陆可见</span>
                        </template>
                    </dd>
                </dl>
                <dl class="item-line" v-if="goodsSkuDetail.member_price > 0">
                    <dt class="ns-text-color-gray">会员价</dt>
                    <dd>
                        <em class="market-yuan">¥</em>

                        <template v-if="token">
                            <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.show_price">
                                <span class="member-price">{{ goodsSkuDetail.member_price }}</span>
                            </template>
                            <template v-else>
                                <span class="member-price">{{ storeWithoutAuthority }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="member-price">登陆可见</span>
                        </template>
                    </dd>
                </dl>
                <dl class="item-line" v-if="goodsSkuDetail.market_price > 0">
                    <dt class="ns-text-color-gray">市场价</dt>
                    <dd>
                        <em class="market-yuan">¥</em>
                        <template v-if="token">
                            <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.show_price">
                                <span class="market-price">{{ goodsSkuDetail.market_price }}</span>
                            </template>
                            <template v-else>
                                <span class="market-price">{{ storeWithoutAuthority }}</span>
                            </template>
                        </template>
                        <template v-else>
                            <span class="market-price">登陆可见</span>
                        </template>
                    </dd>
                </dl>

                <div class="statistical">
                    <ul>
                        <li>
                            <p>累计评价</p>
                            <span>{{ goodsSkuDetail.evaluate }}</span>
                        </li>
                        <li>
                            <p>累计销量</p>
                            <span>{{ goodsSkuDetail.sale_num }}</span>
                        </li>
                    </ul>
                </div>
                <dl class="item-line" v-if="goodsSkuDetail.is_virtual == 0">
                    <dt>运费</dt>
                    <dd>
                        <i class="i-activity-flag ns-text-color ns-border-color"
                            v-if="goodsSkuDetail.is_free_shipping">快递免邮</i>
                        <i class="i-activity-flag ns-text-color ns-border-color" v-else>快递不免邮</i>
                    </dd>
                </dl>
                <dl class="item-line coupon-list" v-if="addonIsExit.coupon && couponList.length">
                    <dt class="ns-text-color-gray">优惠券</dt>
                    <div>
                        <dd>
                            <p v-for="(item, index) in couponList" :key="index" class="ns-text-color"
                                @click="receiveCoupon(item.coupon_type_id)">
                                <span class="ns-border-color" v-if="item.type == 'discount'">{{ item.discount }}折</span>
                                <span class="ns-border-color" v-if="item.type == 'reward'">￥{{ item.money }}</span>
                                <label v-if="item.type == 'discount'">{{ item.discount }}折</label>
                                <label v-if="item.type == 'reward'">￥{{ item.money }}</label>
                            </p>
                        </dd>
                    </div>
                </dl>
                <dl class="item-line manjian" v-if="addonIsExit.manjian && manjian.manjian_name">
                    <dt>满减</dt>
                    <dd>
                        <i class="i-activity-flag ns-text-color ns-border-color">{{ manjian.manjian_name }}</i>
                        <span v-for="(item, index) in manjian.rule_json" :key="index">满{{ item.money }}减{{
                            item.discount_money }}元；</span>
                    </dd>
                </dl>

            </div>
        </div>
        <!-- end价格 -->

        <dl class="item-line service">
            <dt>服务</dt>
            <dd>
                <span>
                    由
                    <router-link :to="'/shop-' + shopInfo.site_id" class="ns-text-color">{{ shopInfo.site_name
                    }}</router-link>
                    发货并提供售后服务
                </span>
            </dd>
        </dl>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec">
                <dt>商品规格</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.spec }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%" v-if="goodsSkuDetail.unit">
                <dt>包装单位</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.unit }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope">
                <dt>经营类型</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.business_scope }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy">
                <dt>中包装</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.min_buy }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory">
                <dt>生产厂家</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.factory }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package">
                <dt>件装量</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.unit_package }}
                    </span>
                </dd>
            </dl>
        </div>
        <div style="display: flex">
            <dl class="item-line service" style="width: 60%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code">
                <dt>国家码</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.country_code }}
                    </span>
                </dd>
            </dl>
            <dl class="item-line service" style="width: 40%"
                v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date">
                <dt>效期</dt>
                <dd>
                    <span>
                        {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.expiry_date }}
                    </span>
                </dd>
            </dl>
        </div>
        <div  style="display: flex">
            <dl class="item-line service"  style="width: 60%"
             v-if="goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number">
            <dt>批准文号</dt>
            <dd>
                <span>
                    {{ goodsSkuDetail.extension_data && goodsSkuDetail.extension_data.approval_number }}
                </span>
            </dd>
            </dl>
            <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.show_price">
                <dl class="item-line service" style="width: 40%">
              <dt>建议零售价</dt>
               <dd>
                {{goodsSkuDetail.reference_price}}
               </dd>
             </dl>
            </template>
            <template v-else>
              <dl class="item-line service" style="width: 40%">
              <dt>建议零售价</dt>
               <dd style="color: #FD274A; font-weight: 600;">
                {{storeWithoutAuthority}}
               </dd>
             </dl>
            </template>
        </div>
        <hr class="divider" />
        <div class="sku-list" v-if="goodsSkuDetail.goods_spec_format">
            <dl class="item-line" v-for="(item, index) in goodsSkuDetail.goods_spec_format" :key="index">
                <dt>{{ item.spec_name }}</dt>
                <dd>
                    <ul>
                   
                        <li v-for="(item_value, index_value) in item.value" :key="index_value">
                            <!-- {{item_value['selected']}} -->
                            <div :class="{
                                'selected ns-border-color': item_value['selected'] || skuId == item_value.sku_id,
                                disabled: item_value['disabled'] || (!item_value['selected'] && specDisabled)
                            }" @click="changeSpec(item_value.sku_id, item_value.spec_id)">
                                <img v-if="item_value.image" :src="$img(item_value.image, { size: 'small' })" />
                                <span>{{ item_value.spec_value_name }}</span>
                                <i class="iconfont iconduigou1 ns-text-color"></i>
                            </div>
                        </li>
                    </ul>
                </dd>
            </dl>
        </div>

        <div class="buy-number">
            <dl class="item-line" v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 &&goodsSkuDetail.stock > 0">
                <dt>数量</dt>
                <dd style="display: flex;">
                    <div >
                        <!-- <el-input-number v-model="number"
                            :step="this.goodsSkuDetail.min_buy > 0 && this.goodsSkuDetail.package_num ? Number(this.goodsSkuDetail.package_num) : 1"
                            size="mini"
                            :min="goodsSkuDetail.package_num?goodsSkuDetail.min_buy:1"
                            :max="goodsSkuDetail.stock" @change="keyInput($event)" @blur="deve($event)">
                        </el-input-number> -->
                       <shareCounter :number="number" :goodsSkuDetail="goodsSkuDetail" size="mini" @upnumber="upnumber"></shareCounter>
                    </div>
                    <span class="inventory" v-if="goodsSkuDetail.package_num<goodsSkuDetail.stock">库存{{ goodsSkuDetail.stock }}</span>
                    <span
                        style="display: none; margin-left: 20px; width: 179px;height: 13px;font-size: 12px;font-family: Microsoft YaHei;font-weight: 400;color: #FD274A;">由官方旗舰店发货并提供售后服务</span>
                </dd>
            </dl>
        </div>

        <dl class="item-line buy-btn">
            <dt></dt>
            <dd>
                <template
                    v-if="goodsSkuDetail.goods_state == 1 && goodsSkuDetail.verify_state == 1 && goodsSkuDetail.stock > 0">
                    <!-- plain  鼠标划入变换效果 -->
                    <template v-if="goodsSkuDetail.control && goodsSkuDetail.control.is_member_buy">
                        <el-button type="primary" v-if="activityType === 'sku'" @click="buyNow">立即购买</el-button>
                        <el-button type="primary" icon="el-icon-shopping-cart-2" v-if="goodsSkuDetail.is_virtual == 0"
                            @click="joinCart" style="background: #FF860F;">加入购物车</el-button>
                    </template>
                    <template v-else>
                        <el-button type="infor" :disabled="true">{{ goodsSkuDetail.control.control_msg }}</el-button>
                    </template>
                </template>
                <template v-else>
                    <el-button type="info" plain disabled>库存不足</el-button>
                </template>
                <!--					<div href="javascript:;" class="go-phone">-->
                <!--						<span style="color: #666666;margin-right: 10px;">手机购买</span>-->
                <!--						<img src="@/assets/images/goods/qrcode.png" /> -->
                <!--						<div class="qrcode-wrap"><img :src="qrcode" alt="二维码图片" /></div>-->
                <!--					</div>-->
            </dd>
        </dl>

        <!-- 店铺信息 -->
        <dl class="item-line merchant-service" v-show="shopInfo.shop_baozh == 1 ||
            shopInfo.shop_qtian == 1 ||
            shopInfo.shop_zhping == 1 ||
            shopInfo.shop_erxiaoshi == 1 ||
            shopInfo.shop_tuihuo == 1 ||
            shopInfo.shop_shiyong == 1 ||
            shopInfo.shop_shiti == 1 ||
            shopInfo.shop_xiaoxie == 1
            ">
            <dt>商家服务</dt>
            <div>
                <dd v-if="shopInfo.shop_baozh == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="保证服务">保证服务</span>
                </dd>
                <dd v-if="shopInfo.shop_qtian == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="满足7天无理由退换货申请的前提下，包邮商品需要买家承担退货邮费，非包邮商品需要买家承担发货和退货邮费">7天退换</span>
                </dd>
                <dd v-if="shopInfo.shop_zhping == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="商品支持正品保障服务">正品保障</span>
                </dd>
                <dd v-if="shopInfo.shop_erxiaoshi == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="付款后2小时内发货">两小时发货</span>
                </dd>
                <dd v-if="shopInfo.shop_tuihuo == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="退货承诺">退货承诺</span>
                </dd>
                <dd v-if="shopInfo.shop_shiyong == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="试用中心">试用中心</span>
                </dd>
                <dd v-if="shopInfo.shop_shiti == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="实体验证">实体验证</span>
                </dd>
                <dd v-if="shopInfo.shop_xiaoxie == 1">
                    <i class="el-icon-success"></i>
                    <span class="ns-text-color-gray" title="如有商品质量问题、描述不符或未收到货等，您有权申请退款或退货，来回邮费由卖家承担">消协保证</span>
                </dd>
            </div>
        </dl>
    </div>
</template>

<script>
import mixin from './common/mixin';

export default {
    mixins: [mixin],
}
</script>