import { navList } from "@/api/website"
export default {
  data: () => {
    return {
      loadingAd: false,
      adList: [
        {
          adv_image: 'https://h2.appsimg.com/b.appsimg.com/upload/mst/2024/02/06/163/20f7e738b0211d5dacb03734a14f24fc.jpg!75.webp',
          value: 1
        }
      ],
      // 背景图片及颜色样式选择
      rgbaColor: {
        navImg: "",
        navcolor: ""
      },
    };
  },
  created() {
    this.getNavList()
  },
  methods: {
    getNavList() {
      navList({})
        .then(res => {
          let { code, data } = res
          if (code >= 0) {
            //判断是否为当前页面数据
            const path = this.$route.fullPath
            let wbstyleStart = path.indexOf("&wbstyle=")
            let wbstyleStart2 = path.indexOf("?wbstyle=")
            let isurl = wbstyleStart != -1 ? wbstyleStart : wbstyleStart2
            let extractedString = path.substring(0, isurl)
            data.list.forEach(item => {
              //过滤当前为空数据
              let urlId = JSON.parse(item.nav_url).url
              if (urlId == extractedString) {
                this.rgbaColor.navImg = item.nav_bg_img
                this.rgbaColor.navcolor = item.nav_bg_color
              }
            })
            // 清除底部边距
            let pa = document.getElementsByClassName("el-footer")
            let myElement = pa[0]
            myElement.style.paddingTop = '0';
          }
        }).catch((err) => {

        })
    }
  },
  foort() {

  }

}