<template>
    <div class="wregi">
        <vPopUpDialogs v-model="showdlog" :width="700" v-loading="loading">
            <template #poboady>
                <p>
                    恭喜手机号为<span style="color: red">{{ phne }}</span
                    >用户成功注册
                </p>
                <h3 class="hn-2">资质材料如下</h3>
                <div class="boxs">
                    <div class="l">
                        <h3 class="hn">
                            注册<span style="color: red">{{ numtype }}</span
                            >会员类型所需材料如下：
                        </h3>
                        <div class="cont" v-for="(item, index) in rigtData" :key="index" v-if="item.name.indexOf(numtype) > -1">
                            <p v-for="(i, d) in item.datas" :key="d">
                                <span>{{ i.value }}</span>
                                <span>{{ i.text }}</span>
                            </p>
                        </div>
                    </div>
                </div>
                <p class="zhu">注：<span >如不上传认证资质部分功能将无法正常使用</span></p>
                <div class="info">
                    <div  class="info-ittl">
                        <span style="margin-right: 10px;"><i class="el-icon-phone-outline"></i></span><span class="titl-span">电话： {{info.web_phone}}</span>
                    </div>
                    <div  class="info-ittl">
                        <span style="margin-right: 10px;"><i class="el-icon-user"></i></span> <span  class="titl-span">收件人：{{info.web_liner}}</span>
                    </div>
                    <div  class="info-ittl">
                        <span style="margin-right: 10px;"><i class="el-icon-location-information"></i></span><span  class="titl-span">地址：{{info.web_delivery_address}}</span>
                    </div>
                </div>
            </template>
            <template #pofooter>
                <div>
                    <el-button type="primary" @click="clors(1)">前往首页</el-button>
                    <el-button type="success" @click="clors(2)">前往上传资质</el-button>
                </div>
            </template>
        </vPopUpDialogs>
    </div>
</template>

<script>
import vPopUpDialogs from "@/components/popUpDialogs.vue"
import { setToken } from "@/utils/auth"
import http from "@/utils/http"
export default {
    name: "PcShowregiDialog",
    components: {
        vPopUpDialogs
    },
    props: {
        value: {
            type: Boolean,
            default: false
        },
        numtype: {
            type: String,
            default: ""
        },
        phne: {
            type: String,
            default: ""
        },
        registerId: {
            type: String,
            default: ""
        },
        info:{
            type:Object,
            default:()=>{}
        }
    },
    data() {
        return {
            loading: false,
            showdlog: this.value,
            // 1：单体药房/加盟店,2:业务经理
            rigtData: [
                {
                    name: ["单体药房", "加盟药店", "单体诊所", "医院会员", "商业连锁",  "招商分销", ],
                    datas: [
                        {
                            value: "1.",
                            text: "营业执照"
                        },
                        {
                            value: "2.",
                            text: "药品经营许可证"
                        },
                        {
                            value: "3.",
                            text: "采购人员身份证头面像及国徽面"
                        },
                        {
                            value: "4.",
                            text: "采购人委托书"
                        },
                        {
                            value: "5.",
                            text: "统一社会信用编码"
                        },
                        {
                            value: "6.",
                            text: "食品经营许可证"
                        },
                        {
                            value: "7.",
                            text: "医疗器械经营许可证"
                        }
                    ]
                },
                {
                    name: ["业务经理"],
                    datas: [
                        {
                            text: "身份证头像面及国徽面"
                        }
                    ]
                },
            ],
        }
    },
    watch: {
        value(val) {
            this.showdlog = val
        },
        showdlog(val) {
            this.$emit("input", val) // 保持父组件的 v-model 同步
        }
    },
    mounted() {},

    methods: {
        clors(val) {
            if (val == 1) {
                this.$emit("input", false)
                setToken(this.registerId, 86400000)
                this.$store.commit("member/SET_TOKEN", this.registerId)
                this.$router.push({
                    name: "index"
                })
            } else {
                this.$emit("input", false)
                setToken(this.registerId, 86400000)
                this.$store.commit("member/SET_TOKEN", this.registerId)
                this.$router.push({
                    name: "certiys"
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.wregi {
    .hn {
        font-size: 16px;
    }
    .boxs {
        display: flex;
        width: 100%;
        justify-content: center;
        .l {
            min-width: 600px;
            min-height: 100px;
            border: 1px dotted #00b4ff;
            padding: 10px;
            background: #b0c4dd1f;
            .cont {
                padding: 10px 0;
            }
        }
        .c {
            width: 300px;
            height: 200px;
            border: 1px dotted #ddd;
        }

        .r {
            width: 300px;
            height: 200px;
            border: 1px dotted #ddd;
        }
    }
    .zhu {
        color: rgb(0, 180, 255);
        & > span {
            margin-right: 10px;
        }
    }
    .info {
        display: flex;
        // justify-content: center;
        margin: 10px 0px;
        // align-items: center;
        .info-ittl{
                width: 220px;
                min-height: 20px;
                display: flex;
                word-wrap:break-word;

word-break:normal; 
       .titl-span{
        width: 180px;
        display: inline-block;
       }
         }
        & > div {
            margin-right: 10px;
         
        }
    }
    .hn-2 {
        width: 100%;
        color: rgb(0, 115, 255);
        text-align: center;
        font-size: 25px;
    }
}
</style>